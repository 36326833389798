export const StyledText = ({ text }) => {
  const separted = text.split('[')
  const items = []
  separted.forEach((e, idx) => {
    if (e.includes(']')) {
      const [strong, weak] = e.split(']')
      items.push(
        <strong key={idx}>
          [ <u>{strong}</u> ]
        </strong>
      )
      items.push(<span key={`${idx}-weak`}>{weak}</span>)
    } else {
      items.push(<span key={idx}>{e}</span>)
    }
  })
  return items
}
