import { useNavigate } from 'react-router-dom'

import { useService } from 'common/service/context'
import { formatDate } from 'common/utils/format'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { SystemPage } from 'system/page/core'

export const QualificationsList = () => {
  const service = useService()

  /**
   * Fetch present records using the given query parameters.
   *
   * @param {*} params query params
   * @returns
   */
  const fetch = async (params) =>
    await service.get('/contacts/defs/qualification-categories', params)

  return (
    <SystemPage
      style={{
        display: 'grid',
        gap: 0,
      }}
    >
      <QualificationsTable fetch={fetch} />
    </SystemPage>
  )
}

export const QualificationsTable = ({
  fetch,
  onAdd,
  disableAdd,
  minimal,
  extraButtons,
  handler,
}) => {
  // Get navigation context
  const navigate = useNavigate()

  return (
    <SimpleDataTable
      name="qualifications"
      title={'Qualifications'}
      fetch={fetch}
      handler={handler}
      onAddClick={
        !disableAdd
          ? () =>
              onAdd
                ? onAdd()
                : navigate(`/master-data/contacts/qualifications/add`)
          : null
      }
      navBaseUrl="/master-data/contacts/qualification"
      rowColor={(r) => r.color}
    >
      <SimpleColumn
        s={minimal ? 11 : 3}
        n={minimal ? 11 : 3}
        field={'name'}
        label={'Name'}
        header
      />
      {!minimal && (
        <SimpleColumn s={3} n={3} field="comment" label={'Comment'} />
      )}
      {!minimal && (
        <SimpleColumn
          s={3}
          n={3}
          sortable
          field="created_at"
          label={'Created at'}
          value={(r) => formatDate(r.created_at)}
        />
      )}
      {!minimal && (
        <SimpleColumn
          s={3}
          n={3}
          field="creator_user"
          label={'Created by'}
          value={(r) => r.creator_user.fullname}
        />
      )}
      {extraButtons && (
        <SimpleColumn s={1} n={1} value={(r) => extraButtons(r)} />
      )}
    </SimpleDataTable>
  )
}
