import React, { useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactToPrint from 'react-to-print'

import { useService } from 'common/service/context'
import { Container } from 'common/widgets/container'
import { DataSource } from 'common/widgets/data-source'
import { SystemPage } from 'system/page/core'
import { ConfirmOverlay } from 'common/widgets/overlay'
import {
  DeleteIconButton,
  BackIconButton,
  PrintIconButton,
  CopyIconButton,
  EditIconButton,
  ArchiveIconButton,
  FoldButtons,
} from 'common/widgets/button'
import { useToast } from 'system/toast/context'

/**
 * Renders general detail page.
 *
 * @param {string} url base url for get call
 * @param {string} title page title
 * @returns
 */
export const DetailViewPage = ({
  // this should be called "baseUrl", "url" is misleading
  url,
  // alternate baseUrl for navigation. Pattern is {baseUrl}/{id}/{action}
  navUrl,
  title,
  addExtraButtons,
  editable,
  archivable,
  removable,
  clonable,
  toggleArchiveURL,
  children,
}) => {
  const service = useService()
  const { id } = useParams()
  // Defines data source handler.
  const handler = {}
  const navigate = useNavigate()
  const { toasts } = useToast()
  const { t } = useTranslation()

  // Yet another hack to use vanilla strings as title
  let titleFn = title
  if (typeof title !== 'function') {
    if (title) {
      titleFn = () => t(title)
    } else {
      // Use `name` prop if exist as title - we want all detail
      // pages look similar, so it's okay to try to set title here
      titleFn = (r) => (r?.name ? t(r.name) : '')
    }
  }

  const handleLegacyArchive = async (data) => {
    const [, error] = await service.put(`${url}/${id}`, {
      archived_on: data.archived_on ? null : new Date(),
    })
    await handler.reload()
    if (!error) {
      data.archived_on
        ? toasts.success(`${titleFn(data)} ${t('sucessfully activated.')}`)
        : toasts.success(`${titleFn(data)} ${t('sucessfully archived.')}`)
    }
  }

  const handleToggleArchive = async (data) => {
    const [result, error] = await service.put(
      `${url}/${id}/${toggleArchiveURL}`
    )
    await handler.reload()
    if (!error) {
      result?.data?.archived
        ? toasts.success(`${titleFn(data)} ${t('sucessfully archived.')}`)
        : toasts.success(`${titleFn(data)} ${t('sucessfully activated.')}`)
    }
  }

  const handleArchive = async (data) => {
    if (toggleArchiveURL) {
      await handleToggleArchive(data)
      return
    }
    await handleLegacyArchive(data)
  }

  const handleRemove = async (data) => {
    await service.delete(`${url}/${id}`)
    navigate(-1)
  }

  // Create a reference for printing
  const contentRef = useRef()

  const getFlag = (flag, data) => {
    if (typeof flag === 'function') {
      return flag(data)
    }
    return flag
  }

  const nav = (action) => {
    // TODO: user proper URL joining
    return navigate(`${navUrl ?? url}/${id}/${action}`)
  }

  return (
    <DataSource
      handler={handler}
      fetch={async () => await service.get(`${url}/${id}`)}
    >
      {({ data, error, loading, reload, update }) => (
        <SystemPage loading={loading}>
          <Container flex vertical ref={contentRef} gap="10px">
            {data && (
              <DetailViewHeader
                reload={reload}
                addExtraButtons={addExtraButtons}
                data={data}
                title={titleFn(data)}
                onEdit={getFlag(editable, data) ? () => nav('edit') : undefined}
                onRemove={getFlag(removable, data) ? handleRemove : undefined}
                onArchive={
                  getFlag(archivable, data) ? handleArchive : undefined
                }
                onClone={
                  getFlag(clonable, data) ? () => nav('clone') : undefined
                }
                contentRef={contentRef}
              />
            )}
            {data && children(data, reload, update)}
          </Container>
        </SystemPage>
      )}
    </DataSource>
  )
}

const DetailViewHeader = ({
  data,
  title,
  addExtraButtons,
  renderRemoveContent,
  onArchive,
  onEdit,
  onRemove,
  onClone,
  contentRef,
}) => {
  const { t } = useTranslation()
  const [removeFormIsVisible, showRemoveForm] = useState(false)
  const removeConfirmSentence = t('Are you sure you want to delete [{}]?')
  const [part1, part2] = removeConfirmSentence.split('{}')

  return (
    <Container flex grow align="center" gap="10px">
      <BackIconButton />
      <h1 style={{ width: '100%' }}>{title}</h1>
      <FoldButtons autoFlatten>
        <ReactToPrint
          trigger={() => <PrintIconButton tooltip={t('Print')} />}
          content={() => contentRef?.current}
        />
        {onClone && (
          <CopyIconButton
            tooltip={t('Duplicate')}
            onClick={() => onClone(data)}
          />
        )}
        {onEdit && (
          <EditIconButton tooltip={t('Edit')} onClick={() => onEdit(data)} />
        )}
        {onArchive && (
          <ArchiveIconButton
            tooltip={!data.archived_on ? t('Archive') : t('Activate')}
            archived={data.archived_on}
            onClick={() => onArchive(data)}
          />
        )}
        {onRemove && (
          <DeleteIconButton
            tooltip={t('Delete')}
            onClick={() => showRemoveForm(true)}
          />
        )}
        {addExtraButtons && addExtraButtons(data)}
      </FoldButtons>
      {removeFormIsVisible && (
        <ConfirmOverlay
          open={removeFormIsVisible}
          title={t('Remove')}
          onReject={() => showRemoveForm(false)}
          onConfirm={onRemove}
        >
          <p style={{ strong: { color: '#fc4242' } }}>
            {part1}
            <strong>{title}</strong>
            {part2}
          </p>
        </ConfirmOverlay>
      )}
    </Container>
  )
}
