import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  AlertTriangle,
  Book,
  Mail,
  MapPin,
  Phone,
  Tag,
  User,
  Users,
} from 'react-feather'

import { useService } from 'common/service/context'
import { Container } from 'common/widgets/container'
import { When } from 'common/utils/conditional'
import { CommentView } from 'common/widgets/view'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { formatDistanceFromNow } from 'common/utils/format'
import { IconButton } from 'common/widgets/button'

import { RateLabel } from './view/rates'
import {
  ContactTypeEnum,
  formatContactAddress,
  getPrimaryAddress,
} from './utils'
import { MapOverlay } from './widgets/map'

export const ContactsList = ({ path }) => {
  const { t } = useTranslation()
  const service = useService()
  const fetch = async (params) =>
    await service.get(
      '/contacts',
      path ? [...params, { path: `${path}.%` }] : params
    )
  const navigate = useNavigate()
  const [mapOverlayVisible, setMapOverlayVisible] = useState(false)

  return (
    <SimpleDataTable
      name="contacts"
      fetch={fetch}
      title="Contacts"
      extraIconButtons={
        <IconButton
          onClick={() => {
            setMapOverlayVisible(true)
          }}
          icon={<MapPin />}
        />
      }
      onAddClick={() => navigate('/master-data/contacts/add')}
      navBaseUrl="/master-data/contacts"
      archive
      filters={[
        {
          key: 'internal',
          section: 'internal',
          boolean: true,
        },
      ]}
      rowColor={(r) => r.state?.color ?? 'darkgrey'}
    >
      <MapOverlay
        open={mapOverlayVisible}
        onClose={() => setMapOverlayVisible(false)}
      />
      <SimpleColumn n={12} sortable field="name" header>
        {(r) => (
          <>
            <Container flex grow repel align="center">
              <h3>{r.name}</h3>
              <RateLabel rate={r.rate} />
            </Container>
            <CommentView comment={r.comment} />
          </>
        )}
      </SimpleColumn>
      <SimpleColumn
        n={3}
        s={6}
        label="Type"
        field="type"
        sortable
        filterUrl="/contacts/available-types"
      />
      <SimpleColumn
        n={3}
        s={6}
        label="State"
        field="state_id"
        sortable
        filterUrl="/contacts/available-states"
        filter={(r) => r.id}
        title={(r) => r.name}
        value={(r) => r.state?.name}
      />
      <SimpleColumn
        n={3}
        s={6}
        label="Internal"
        field="internal"
        title={(r) => r.title}
        value={(r) => (r.is_internal ? t('Yes') : t('No'))}
      />
      <SimpleColumn
        n={3}
        s={6}
        label="Creation date"
        field="created_at"
        sortable
        value={(r) => formatDistanceFromNow(r.created_at)}
      />
      <SimpleColumn
        n={3}
        s={6}
        label="Parent"
        field="parent_id"
        sortable
        value={(r) => r.parent?.name}
        link={(r) => (r.parent ? `/master-data/contacts/${r.parent.id}` : null)}
      />
      <SimpleColumn n={12}>
        {(r) => (
          <h5>
            <Book width="10px" height="10px" />
            {' ' + formatContactAddress(getPrimaryAddress(r), true).join(', ')}
          </h5>
        )}
      </SimpleColumn>
    </SimpleDataTable>
  )
}

/**
 * Renders contact data in a compact way.
 *
 * @param {any} contact contact data
 * @returns ReactElement
 */
export const ContactCompact = ({ contact, symbol }) => (
  <>
    <Container
      flex
      padding="10px"
      grow
      vertical
      justify="space-between"
      style={{
        borderLeft: contact.state?.color
          ? `10px solid ${contact.state?.color}`
          : 'inherit',
      }}
    >
      <Container flex vertical>
        <Container flex justify="space-between" align="center">
          <h3>{contact.name}</h3>
          <When criteria={symbol}>
            {contact.type === ContactTypeEnum.COMPANY ? <Users /> : <User />}
          </When>
        </Container>
        <h5>{contact.state?.name}</h5>
      </Container>
      <Container flex vertical>
        {getPrimaryAddress(contact)?.email ? (
          <Container onClick={(e) => e.stopPropagation()}>
            <Mail />
            <a href={`mailto:${getPrimaryAddress(contact)?.email}`}>
              {getPrimaryAddress(contact)?.email}
            </a>
          </Container>
        ) : getPrimaryAddress(contact)?.phone ? (
          <Container onClick={(e) => e.stopPropagation()}>
            <Phone />
            <a href={`tel:${getPrimaryAddress(contact)?.phone}`}>
              {getPrimaryAddress(contact)?.phone}
            </a>
          </Container>
        ) : getPrimaryAddress(contact)?.webpage ? (
          <Container onClick={(e) => e.stopPropagation()}>
            <Tag />
            <a href={`${getPrimaryAddress(contact)?.webpage}`}>
              {getPrimaryAddress(contact)?.webpage}
            </a>
          </Container>
        ) : (
          <AlertTriangle />
        )}
      </Container>
    </Container>
  </>
)
