import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import { useForm } from 'common/widgets/form/context'
import { Container } from 'common/widgets/container'
import {
  AddIconButton,
  SubmitButton,
  BackIconButton,
} from 'common/widgets/button'
import { DataSource } from 'common/widgets/data-source'
import { FreeChooseField, TextField } from 'common/widgets/form/field'
import { Row, Column } from 'common/widgets/grid'
import { GridFieldView, SectionView } from 'common/widgets/view'
import { ProjectSelect } from 'modules/projects/widgets/select'
import { useService } from 'common/service/context'

import {
  AdHocItemSearchOverlay,
  AdHocSignAndSubmitOverlay,
} from '../overlays/adhoc'

import { AdHocSelectedItemsList } from './adhoc-items'

export const AdHocFormBody = () => {
  const { t } = useTranslation()
  const { values } = useForm()
  const [selectedItems, setSelectedItems] = useState([])
  const [sourceProject, setSourceProject] = useState(null)
  const [targetProject, setTargetProject] = useState(null)
  const [searchVisible, setSearchVisible] = useState(false)
  const [signVisible, setSignVisible] = useState(false)

  return (
    <>
      <Container
        flex
        repel
        align="center"
        gap="10px"
        title="Ad Hoc Delivery"
        before=<BackIconButton />
      >
        <AddIconButton
          onClick={() => {
            setSearchVisible(true)
          }}
          style={{ marginLeft: 'auto' }}
        />
      </Container>
      <AdHocDeliveryFormFields
        excludeProjectId={values.get('project_id')}
        excludeSourceProjectId={sourceProject?.id}
        onSourceProjectSelect={(project) => {
          values.set('source_project_id', project?.id)
          setSourceProject(project)
          if (project?.id) {
            setSelectedItems(selectedItems?.filter((e) => !e.is_consumable))
          }
        }}
        onTargetProjectSelect={(project) => {
          setTargetProject(project)
          values.set('project_id', project?.id)
        }}
        selectedTargetProject={targetProject}
        selectedSourceProject={sourceProject}
      />
      {selectedItems?.length > 0 && (
        <AdHocSelectedItemsList
          items={selectedItems}
          onEdit={(item) => {
            setSelectedItems((previousItems) => {
              const itemIndex = previousItems.findIndex((v) => v.id == item.id)
              // Keep the order of the array while updating the item
              return [
                ...previousItems.slice(0, itemIndex),
                item,
                ...previousItems.slice(itemIndex + 1),
              ]
            })
          }}
          onDelete={(item) => {
            setSelectedItems((previousItems) => {
              const itemIndex = previousItems.findIndex((v) => v.id == item.id)
              // Keep the order of the array while deleting the item
              return [
                ...previousItems.slice(0, itemIndex),
                ...previousItems.slice(itemIndex + 1),
              ]
            })
          }}
        />
      )}
      {searchVisible && (
        <AdHocItemSearchOverlay
          open={searchVisible}
          sourceProject={sourceProject}
          baseitemId={values.json.baseitemId}
          onSelect={(item, waitForMore) => {
            if (waitForMore) {
              setSearchVisible(true)
            } else {
              setSearchVisible(false)
            }
            setSelectedItems((previousItems) => {
              // Replace the item it if it is already added
              const itemIndex = previousItems.findIndex((v) => v.id == item.id)
              if (itemIndex >= 0) {
                return [
                  ...previousItems.slice(0, itemIndex),
                  item,
                  ...previousItems.slice(itemIndex + 1),
                ]
              }
              // Append the item to the list in other cases
              return [...previousItems, item]
            })
          }}
          onClose={() => setSearchVisible(false)}
        />
      )}
      {signVisible && (
        <AdHocSignAndSubmitOverlay
          formValues={values}
          items={selectedItems}
          open
          onClose={() => setSignVisible(false)}
        />
      )}
      <SubmitButton
        default
        text={t('Complete')}
        disabled={(ctx) =>
          !ctx.ready || !ctx.dirty || !selectedItems?.length > 0
        }
        onClick={() => setSignVisible(true)}
      />
    </>
  )
}

const AdHocDeliveryFormFields = ({
  onSourceProjectSelect,
  onTargetProjectSelect,
  excludeProjectId,
  excludeSourceProjectId,
  selectedTargetProject,
  selectedSourceProject,
}) => {
  return (
    <Row>
      <Column flex n={6}>
        <SectionView title="Delivery data">
          <GridFieldView
            n={6}
            label="Pick-up location"
            render={
              <ProjectSelect
                name="source_project_id"
                hint="Building yard"
                allowYard
                onSelectChange={onSourceProjectSelect}
                excludeProjectId={excludeProjectId}
                selectedProjectId={selectedSourceProject?.id}
                removable
              />
            }
          />
          <GridFieldView n={6} label="Status" value="COMMISSIONING" />
          <FreeUserSelectField n={6} label="Recipient" name="recipient" />
        </SectionView>
      </Column>
      <Column flex n={6}>
        <SectionView title="Order data">
          <GridFieldView
            n={6}
            label="Project"
            render={
              <ProjectSelect
                name="project_id"
                mandatory
                onSelectChange={onTargetProjectSelect}
                excludeProjectId={excludeSourceProjectId}
                selectedProjectId={selectedTargetProject?.id}
                removable
              />
            }
          />
          <GridFieldView n={6} label="Delivery option" value="Self-pickup" />
          <GridFieldView
            n={6}
            label="Planned date"
            render={<TextField name="delivery_date" disabled />}
          />
        </SectionView>
      </Column>
    </Row>
  )
}

const FreeUserSelectField = ({ label, name, ...rest }) => {
  const service = useService()
  return (
    <GridFieldView
      label={label}
      render={
        <DataSource
          fetch={async (params) => await service.get(`/users`)}
          render={({ data }) => (
            <FreeChooseField
              name={name}
              mandatory
              items={data?.map((e) => e.fullname)}
            />
          )}
        />
      }
      {...rest}
    />
  )
}
