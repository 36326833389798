.headerContainer {
  height: 60px;
  padding: 5px 0px 0px 5px;
  margin-bottom: 10px;
}

.nextWeekButton {
  border-color: #333333 !important;
  background: #303030 !important;
}

.nextWeekButton:hover {
  background: #303030;
}

.previousWeekButton {
  border-color: #333333 !important;
  background: #303030 !important;
}

.previousWeekButton :hover {
  background: #303030;
}

.outlinedConainter {
  overflow-x: hidden;
  overflow-y: auto;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #bababa;
}

.ellipsisedText {
  width: 12 0px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.taskStatusItem {
  display: flex;
  flex: 0 0 auto;
  width: 5px;
  border-radius: 2px;
}
