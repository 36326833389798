import React, { useState, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import ResourceTimelinePlugin from '@fullcalendar/resource-timeline'
// import multiMonthPlugin from '@fullcalendar/multimonth'
import allLocales from '@fullcalendar/core/locales-all'

import { toDate } from 'common/utils/date'
import {
  formatDate,
  formatDateTime,
  formatDateRange,
} from 'common/utils/format'
import { useService } from 'common/service/context'
import { useMediaQuery } from 'common/utils/hooks'

import { FULLCALENDAR_LICENSE_KEY } from '/src/env'

import './calendar.css'
import { Container } from './container'
import { SearchInput } from './search'

export const FullCalendarWrapper = forwardRef(function FC(
  { events, plugins, headerToolbar, ...rest },
  ref
) {
  const { i18n } = useTranslation()
  const service = useService()
  const [holidays, setHolidays] = useState({
    start: null,
    end: null,
    holidays: [],
  })

  // "events" should either not exist or be an array
  // For programming errors we just need to inform the developer. "events"
  // prop is not user provided, so if it is not an array, then programmer
  // has made an error and should fix it.
  console.assert(
    !events || typeof events === 'function' || Array.isArray(events),
    'Programming error. "events" prop must be an array. Fix your code.'
  )

  const fetchHolidays = async (start, end) => {
    let params = []
    if (start && end) {
      params.push({ start, end })
    } else if (start) {
      params.push({ start })
    } else if (end) {
      params.push({ end })
    }
    // This service returns ISO 8601 *dates only*
    const [response, error] = await service.get(`/calendar/holidays`, params)
    if (!error) {
      let results = []
      response.data.forEach((item, idx) => {
        let suffix = ''
        if (!item['nationwide']) {
          suffix = item['states'].join(',')
        }
        if (item['nationwide']) {
          let h = {
            // See https://fullcalendar.io/docs/event-parsing
            // The start date is inclusive and the end is *exclusive*
            start: `${item['start_date']}T00:00:00+00:00`,
            allDay: true,
            id: idx,
            title: item['name'] + suffix,
            type: 'holiday',
            display: 'background',
            backgroundColor: '#f4953769',
          }
          results.push(createEvent(h))
        }
      })
      return results
    }
  }

  // Download holidays only once and only add to previously downloaded
  // ones if the new data range exceeds the existing one.
  const fetchEvents = async (
    { start, end },
    successCallback,
    failureCallback
  ) => {
    if (
      start.getFullYear() < holidays.start ||
      end.getFullYear() > holidays.end
    ) {
      const effectiveStart = holidays.start
        ? Math.min(holidays.start, start.getFullYear())
        : start.getFullYear()
      const effectiveEnd = Math.max(holidays.end, end.getFullYear())
      setHolidays({
        start: effectiveStart,
        end: effectiveEnd,
        holidays: await fetchHolidays(effectiveStart, effectiveEnd),
      })
    }
    return holidays.holidays
  }

  const getAspectRatio = (resources, events, initialView, calendarType) => {
    console.assert(
      !resources || Array.isArray(resources),
      'Programming error. "resources" parameter must be an array. Fix your code.'
    )
    // Consider 100px for calendar top bar.
    let topHeight = 100
    // Event height for different calendar types.
    let formworkEventHeight = 75
    let resourceEventHeight = 75
    let maintenanceEventHeight = 28
    // Resource height for different calendar types.
    let formworkResourceHeight = 86
    let resourceResourceHeight = 56
    let maintenanceResourceHeight = 111

    // Select event height based on calendar type.
    let eventSize =
      calendarType === 'resource'
        ? resourceEventHeight
        : calendarType === 'formwork'
        ? formworkEventHeight
        : calendarType === 'maintenance'
        ? maintenanceEventHeight
        : 60
    // Select resource height based on calendar type.
    let itemSize =
      calendarType === 'resource'
        ? resourceResourceHeight
        : calendarType === 'formwork'
        ? formworkResourceHeight
        : calendarType === 'maintenance'
        ? maintenanceResourceHeight
        : 80

    // Consider a default value of 1
    // TODO: this is a workaround. We have to take the number of events
    // returned by the fetchEvent function (when the event prop is a fn)
    // calling the function is not also an option, since its result depend
    // on the date range
    let eventsCount = 1
    if (typeof events !== 'function') {
      // Only count events which are not background events.
      eventsCount = events?.filter((e) => e.display !== 'background').length
    }

    let itemsCount = resources ? resources.length : 0
    let minHeightForEvents = eventsCount * eventSize
    let minHeightForItems = itemsCount * itemSize

    // If events/resources ratio is higher than 30%, we should add extra
    // height for rows which may not have any events inside them.
    // For this we multiply the events/resources ratio in the event height
    // and add it to the already calculated height for items.
    if (
      (calendarType === 'resource' ||
        calendarType === 'formwork' ||
        calendarType === 'maintenance') &&
      eventsCount > 0 &&
      itemsCount > 0 &&
      eventsCount / itemsCount >= 0.3
    ) {
      // I don't know why should I add +1 to it, otherwise most of the time it will result in scroll bars!
      let eventItemRatio = (eventsCount + 1) / itemsCount
      minHeightForItems = minHeightForItems + eventSize * eventItemRatio
    }
    let minHeightForDayGrid = (initialView === 'dayGridMonth' ? 6 : 0) * 100
    let minHeight = Math.max(
      minHeightForItems,
      minHeightForEvents,
      minHeightForDayGrid
    )
    if (minHeight === 0) {
      minHeight = 50
    }

    return window.innerWidth / (minHeight + topHeight)
  }
  const isRowBased = useMediaQuery('(max-width: 768px)')
  const { resources, initialView, calendarType } = rest
  return (
    <FullCalendar
      ref={ref}
      schedulerLicenseKey={FULLCALENDAR_LICENSE_KEY}
      plugins={[
        dayGridPlugin,
        interactionPlugin,
        listPlugin,
        ResourceTimelinePlugin,
        timeGridPlugin,
        // multiMonthPlugin,
      ]}
      eventSources={[events, fetchEvents]}
      // Our datetimes are timezone, mostly Europe/Berlin. Local is better than
      // UTC, since UTC is confusing for the submitter user, since we display
      // local time when entering data and should display the same when showing.
      // See: https://fullcalendar.io/docs/timeZone
      timeZone="local"
      locales={allLocales}
      locale={i18n.language}
      eventTimeFormat={{
        hour: 'numeric',
        minute: '2-digit',
        timeZoneName: 'short',
      }}
      nowIndicator
      // 'aspectRatio' has shortcoming in case the actual table rows are more
      // than what could be rendered in this limited area, so there would be scroll bars.
      // I couldn't find any css equivalent for this based on my limited knowledge.
      // those already available css styles were causing rendering errors so I have commented them out.
      // So I have implemented a basic aspect ratio calculator for now. without providing aspect ratio
      // each row of the calendar would be too wide.
      // See also: https://fullcalendar.io/docs/v5/aspectRatio
      aspectRatio={getAspectRatio(resources, events, initialView, calendarType)}
      businessHours={{ daysOfWeek: [1, 2, 3, 4, 5] }}
      eventMouseEnter={(info) => {
        if (info.event.end) {
          info.el.title = `${formatDateRange(info.event.start, info.event.end, {
            time: !info.event?.allDay,
          })} ${info.event.title}`
        } else {
          info.el.title = `${
            info.event?.allDay
              ? formatDate(info.event.start)
              : formatDateTime(info.event.start)
          } ${info.event.title}`
        }
      }}
      headerToolbar={
        isRowBased
          ? {
              left: 'title',
              center: '',
              right: 'prev,next',
            }
          : {
              left: 'listMonth',
              center: 'title',
              // right: 'today,timeGridWeek,dayGridMonth,multiMonthYear,prev,next',
              right: 'today,timeGridWeek,dayGridMonth,prev,next',
              ...headerToolbar,
            }
      }
      // default initial view
      // initialView={isRowBased ? 'listMonth' : 'dayGridMonth'}
      // Save space by not showing weekends on small screens
      weekends={!isRowBased}
      {...rest}
    />
  )
})

export const CalendarSearch = ({ title }) => {
  return (
    <Container flex repel padding="5px" gap="5px" subtitle={title}>
      <SearchInput style={{ width: '100%' }} />
    </Container>
  )
}

/**
 * Creates an event object.
 *
 * @param {Object} data event data
 * @param {Boolean} editable editable event flag
 * @param {Boolean} allDay all day event flag
 * @param {Boolean} overlap overlap event flag
 * @returns {Object}
 */
export const createEvent = ({
  start,
  end,
  id,
  title,
  type,
  display,
  color,
  backgroundColor,
  borderColor,
  textColor,
  resourceId,
  classNames,
  className,
  extendedProps,
  editable = false,
  allDay = false,
  overlap = true,
}) => {
  // See also:
  // https://fullcalendar.io/docs/event-object
  // https://fullcalendar.io/docs/event-parsing
  // for the structure of an event object before changing anything.
  let result = {}

  result.start = toDate(start)
  result.end = toDate(end)

  if (id) {
    result.id = id
  }

  if (title) {
    result.title = title
  }

  if (classNames) {
    result.classNames = classNames
  }

  if (display) {
    result.display = display
  }

  if (backgroundColor) {
    result.backgroundColor = backgroundColor
  }

  if (borderColor) {
    result.borderColor = borderColor
  }

  if (color) {
    result.color = color
  }

  if (textColor) {
    result.textColor = textColor
  }

  if (resourceId) {
    result.resourceId = resourceId
  }

  if (className) {
    result.className = className
  }

  result.editable = editable
  result.allDay = allDay
  result.overlap = overlap

  if (type && !extendedProps) {
    extendedProps = {}
  }

  if (type) {
    extendedProps.type = type
  }

  if (extendedProps) {
    result.extendedProps = extendedProps
  }

  return result
}
