import React from 'react'

import { useService } from 'common/service/context'
import { Form } from 'common/widgets/form/context'
import { SystemPage } from 'system/page/core'

import { ProjectFormContent } from './form'

/**
 * Renders page for adding a project.
 *
 * @returns JSX.Element
 */
export const ProjectAddPage = () => {
  const service = useService()

  const handleAdd = async (values) => {
    return await service.post('projects/', values)
  }

  return (
    <SystemPage>
      <Form
        // TODO: default values should be removed or fetched from the
        // API, not hardcoded. A hardcoded value would need a new build
        // and release to be updated.
        data={{
          rent_rate: 1.0,
          load_rate: 0.5,
        }}
      >
        <ProjectFormContent onAdd={handleAdd} />
      </Form>
    </SystemPage>
  )
}
