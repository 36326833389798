.absenceEvent {
  overflow: hidden;
}
.absenceEvent h5 {
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  color: #000000cc;
}
.absenceEvent h4 {
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  color: #000000;
}
.absenceEvent p {
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  color: #000000cc;
}
.absenceEvent svg {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  color: #000000cc;
}

.chartContainer {
  flex: 1 1 auto;
  display: flex;
  height: 255px;
  width: 255px;
}

.chartContent {
  flex: 1 1 auto;
  height: 250px;
  width: 250px;
  border-radius: 10px;
  background: #ffffff;
}

.verticalDivider {
  display: flex;
  flex: 0 0 auto;
  width: 15px;
  border-radius: 2px;
}

.statusContainer {
  display: inline-block;
  padding-top: 3px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  margin-right: 10px;
  height: 25px;
  border-radius: 5px;
  text-align: center;
  align-items: center;
}
