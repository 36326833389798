import { Package } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'
import { DropDown } from 'common/widgets/dropdown'
import { FieldWrapper } from 'common/widgets/form/field'
import { formatDate } from 'common/utils/format'

/**
 * Renders a trip select widget.
 */
export const TripSelectField = ({
  name = 'trip_id',
  title = 'Trips',
  orderId,
  onSelectChange,
  ...rest
}) => {
  const service = useService()
  const { t } = useTranslation()

  const fetch = async () =>
    await service.get(`/plannings/trips?order_id=${orderId}`)

  const render = ({ data }) => {
    let items = []
    if (data) {
      for (const trip of data) {
        if (orderId && trip.order_id != orderId) {
          continue
        }
        if (trip.state == 'PLANNED') {
          items.push({
            key: trip.id,
            title: `${trip.resource?.resource?.name} - created at ${formatDate(
              trip.created_at
            )} by ${trip.creator_user.fullname}`,
            data: trip,
          })
        }
      }
    }
    return (
      <FieldWrapper {...rest}>
        {(getValue, setValue, values) => (
          <DropDown
            minWidth="320px"
            maxWidth="320px"
            title={t(title)}
            icon={<Package />}
            items={items}
            selectedIndex={items?.findIndex(
              (i) => i.id == values.get(name)?.id
            )}
            onSelectChange={(e) => {
              onSelectChange && onSelectChange(e.data)
              values.set(name, e.data)
            }}
          />
        )}
      </FieldWrapper>
    )
  }

  return <DataSource fetch={fetch} render={render} />
}
