import { register } from 'system/container'

import { ContactTagAddPage } from './add'
import { ContactTagEditPage } from './edit'
import { TagsListPage } from './list'
import { ContactTagViewPage } from './view'

register({
  render: () => <ContactTagAddPage />,
  title: 'Neu',
  path: '/master-data/contacts/tags/add',
})

register({
  render: () => <ContactTagEditPage />,
  title: 'Edit',
  path: '/master-data/contacts/tags/:id/edit',
})

register({
  menu: true,
  render: () => <TagsListPage />,
  title: 'Tags',
  path: '/master-data/contacts/tags',
  priority: 0.0,
})

register({
  render: () => <ContactTagViewPage />,
  title: 'Details',
  path: '/master-data/contacts/tags/:id',
  priority: 0.0,
})
