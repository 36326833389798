import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { formatDistanceFromNow } from 'common/utils/format'
import { Avatar } from 'common/widgets/avatar'

export const DeviceList = ({ path }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <SimpleDataTable
      name="telematics"
      url="/telematics"
      title="Devices"
      onAddClick={() => navigate('/logistics/iot/devices/add')}
      navBaseUrl="/logistics/iot/devices"
    >
      <SimpleColumn fixed>
        {(r) => <Avatar name={r.name} id={r.photo_id} />}
      </SimpleColumn>
      <SimpleColumn n={12} field="id" header />
      <SimpleColumn
        n={3}
        s={6}
        label={t('Creation date')}
        field="created_at"
        sortable
        value={(r) => formatDistanceFromNow(r.created_at)}
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="creator_user"
        label="Creator user"
        value={(r) => r.creator_user?.fullname}
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="type"
        label="Status"
        value={(r) => r.type}
      />
      <SimpleColumn
        n={3}
        s={6}
        label="Resource"
        field="resource"
        sortable
        value={(r) => r.resource_id}
        link={(r) => `/master-data/equipments/${r.resource_id}`}
      />
    </SimpleDataTable>
  )
}
