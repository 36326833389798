import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useService } from 'common/service/context'
import { Form, useForm } from 'common/widgets/form/context'
import { SystemPage } from 'system/page/core'
import { CardView, Divider, GridFieldView } from 'common/widgets/view'
import { Container } from 'common/widgets/container'
import { Column, Row } from 'common/widgets/grid'
import {
  CheckBoxField,
  DateField,
  DropDownField,
  TextAreaField,
} from 'common/widgets/form/field'
import { BackIconButton } from 'common/widgets/button'
import { DataSource } from 'common/widgets/data-source'
import { SimpleFormAction } from 'common/widgets/form/utils'

export const AbsenceAddPage = () => (
  <SystemPage>
    <AbsenceView />
  </SystemPage>
)

export const AbsenceView = ({ absence }) => {
  const { t } = useTranslation()

  return (
    <Form
      data={{
        start: absence?.start,
        end: absence?.end,
        contact_id: absence?.contact_id,
        substitute_id: absence?.substitute_id,
        type: absence?.type,
        comment: absence?.comment,
        daily: absence?.daily ?? true,
      }}
    >
      {!absence && (
        <Container flex gap="10px" style={{ alignItems: 'center' }}>
          <BackIconButton />
          <h3>{t('Details')}</h3>
        </Container>
      )}
      <AddAbsenceForm absence={absence} />
    </Form>
  )
}

const AddAbsenceForm = ({ absence }) => {
  const service = useService()

  const handleSubmit = async (values) => {
    const [result, error] = await service.post(`/absences`, values)

    return [result, error]
  }

  const handleEdit = async (values) => {
    const [result, error] = await service.put(`/absences/${absence.id}`, values)

    return [result, error]
  }

  return (
    <CardView>
      <Row style={{ padding: '10px' }}>
        <Column n={4} m={4} s={12}>
          <AbsenceDetails />
        </Column>
        <Column n={4} m={4} s={12}>
          <ScheduleDetails isAdd={!absence} />
        </Column>
        <Column n={4} m={4} s={12}>
          <SubstituteDetails />
        </Column>
      </Row>
      <Divider />
      <SimpleFormAction
        onAdd={!absence && handleSubmit}
        onUpdate={absence && handleEdit}
      />
    </CardView>
  )
}

const AbsenceDetails = () => {
  return (
    <>
      <Row title="Absence">
        <GridFieldView n={12} m={12} s={12} label="Employee">
          <EmployeeSelectField
            title="Pick employee"
            removable={false}
            mandatory
          />
        </GridFieldView>
        <GridFieldView n={12} m={12} s={12} label="Note">
          <TextAreaField name="comment" rows={4} maxLength={512} />
        </GridFieldView>
      </Row>
    </>
  )
}

const ScheduleDetails = (isAdd) => {
  const { t } = useTranslation()
  const { values } = useForm()
  const [isDaily, setIsDaily] = useState(isAdd || values?.json?.daily)

  const types = [
    {
      key: 'VACATION',
      title: t('Vacation'),
    },
    {
      key: 'ILLNESS',
      title: t('Sick leave'),
    },
    {
      key: 'SCHOOL',
      title: t('School'),
    },
    {
      key: 'SPECIAL_LEAVE',
      title: t('Special'),
    },
  ]

  useEffect(() => {
    if (values?.json?.daily !== null && values?.json?.daily !== undefined) {
      setIsDaily(values.json.daily)
    } else if (isDaily === null || isDaily === undefined) {
      setIsDaily(true)
    }
  }, [values?.json?.daily])

  return (
    <>
      <Row title="Schedule">
        <GridFieldView n={12} m={12} s={12} label="Type">
          <DropDownField
            style={{ minWidth: '200px' }}
            title="Pick type"
            name="type"
            items={types}
            mandatory
          />
        </GridFieldView>
        <GridFieldView n={6} m={6} s={12} label="Start date">
          <DateField name="start" mandatory excludeTime={isDaily} />
        </GridFieldView>
        <GridFieldView n={6} m={6} s={12} label="End date">
          <DateField
            name="end"
            mandatory
            inclusive={isDaily}
            excludeTime={isDaily}
            validate={(date) => {
              if (values?.json?.start > date) {
                return t('The end date is before the start date')
              }
            }}
          />
        </GridFieldView>
        <GridFieldView n={12} m={12} s={12}>
          <CheckBoxField name="daily">{t('Daily')}</CheckBoxField>
        </GridFieldView>
      </Row>
    </>
  )
}

const SubstituteDetails = () => {
  return (
    <>
      <Row title="Substitute">
        <GridFieldView n={12} m={12} s={12} label="Employee">
          <SubstituteSelectField title="Pick substitute employee" removable />
        </GridFieldView>
      </Row>
    </>
  )
}

const EmployeeSelectField = ({
  handler,
  name = 'contact_id',
  removable = true,
  ...rest
}) => {
  const service = useService()

  const fetch = async () => await service.get('/absences/selectable-contacts')

  const render = ({ data, error, loading }) => {
    const items =
      data?.map((item) => {
        return {
          key: item.id,
          title: item.fullname,
        }
      }) || []

    return (
      <DropDownField
        name={name}
        items={items}
        minWidth="276px"
        removable={removable}
        {...rest}
      />
    )
  }

  return <DataSource fetch={fetch} handler={handler} render={render} />
}

const SubstituteSelectField = ({
  handler,
  name = 'substitute_id',
  removable = true,
  ...rest
}) => {
  const service = useService()

  const params = [{ internal: 'true' }, { archived: 'false' }]

  const fetch = async () => await service.get('/contacts', params)

  const render = ({ data, error, loading }) => {
    const items =
      data?.map((item) => {
        return {
          key: item.id,
          title: item.fullname,
        }
      }) || []

    return (
      <DropDownField
        name={name}
        items={items}
        minWidth="276px"
        removable={removable}
        {...rest}
      />
    )
  }

  return <DataSource fetch={fetch} handler={handler} render={render} />
}
