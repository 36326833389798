import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { SystemPage } from 'system/page/core'
import { formatDate } from 'common/utils/format'
import { Container } from 'common/widgets/container'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { CommentView } from 'common/widgets/view'
import { useMediaQuery } from 'common/utils/hooks'

import { ContactsTagTree } from './widgets/tree'

/**
 *  Renders the Delivery page
 *
 * @returns ReactElement
 */
export const TagsListPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  // Check if we should display mobile view
  const isRowBased = useMediaQuery('(max-width: 768px)')

  return (
    <SystemPage
      nopadding
      style={{
        display: 'grid',
        gridTemplateColumns: isRowBased ? '1fr' : '1fr 1fr',
        gap: 0,
      }}
    >
      <SimpleDataTable
        name="tags"
        url="contacts/defs/tags"
        title={t('Tags')}
        onAddClick={() => navigate('/master-data/contacts/tags/add')}
        addTooltip="Add tag"
        navBaseUrl="/master-data/contacts/tags"
        style={{ padding: '10px' }}
        rowColor={(r) => r.color}
      >
        <SimpleColumn s={12} n={12} sortable header>
          {(r) => (
            <Container flex vertical grow>
              <h3>{r.name}</h3>
              <CommentView comment={r.comment} />
            </Container>
          )}
        </SimpleColumn>
        <SimpleColumn
          n={6}
          s={6}
          sortable
          label={t('Creation date')}
          field="created_at"
          value={(r) => formatDate(r.created_at)}
        />
        <SimpleColumn
          n={6}
          s={6}
          sortable
          label={t('Contacts')}
          field="contact_count"
        />
      </SimpleDataTable>
      <ContactsTagTree
        style={{
          position: 'sticky',
          top: 0,
          borderRadius: '0',
        }}
      />
    </SystemPage>
  )
}
