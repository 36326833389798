import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * A simple toggle switch.
 *
 * The tailwind styles are taken from flowbite project. Do not modify.
 * For more information see: https://flowbite.com/docs/forms/toggle/
 *
 * Flowbite is a collection of community components on top of tailwindcss.
 **/
export const ToggleSwitch = ({ text, checked, disabled, onChange }) => {
  const [inputChecked, setInputChecked] = useState(checked)
  const { t } = useTranslation()

  useEffect(() => {
    setInputChecked(checked)
  }, [checked])

  return (
    <label className="relative inline-flex flex-row items-center cursor-pointer">
      <input
        type="checkbox"
        value=""
        className={`sr-only peer ${inputChecked ? 'checked' : ''}`}
        disabled={disabled}
        onClick={() => {
          setInputChecked((prev) => !prev)
          onChange && onChange(!inputChecked)
        }}
      />
      <div
        className="w-11 h-6 bg-gray-200 peer-focus:outline-none
         peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800
         rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full
         peer-checked:after:border-white after:content-[''] after:absolute
         after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300
         after:border after:rounded-full after:h-5 after:w-5 after:transition-all
         dark:border-gray-600 peer-checked:bg-blue-600"
      ></div>
      <p className="ml-3 text-gray-900 dark:text-gray-300">{t(text)}</p>
    </label>
  )
}
