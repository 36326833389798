import React from 'react'
import { useTranslation } from 'react-i18next'

import { NumericField, SelectField, TextField } from 'common/widgets/form/field'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { Row, Column } from 'common/widgets/grid'
import { SectionView } from 'common/widgets/view'
import {
  EquipmentCategoryFreeField,
  EquipmentMaincategoryFreeField,
  EquipmentSubcategoryFreeField,
} from 'modules/master-data/equipments/widgets/category'

import { AccountingGroupField } from '../widgets/accounting-group'
import { QuantityUnitField } from '../widgets/quantity-unit'
import { OrderableField } from '../widgets/orderable'
import { ItemTypeEnum } from '../utils'

import { StockField, MinStockField, MaxStockField } from './widgets/stockinputs'
import { ResponsibleOrdererField } from './widgets/responsible-orderer'

/**
 * Renders consumables's data entry form.
 *
 * @param {Function} onClose closing hook
 * @returns
 */
export const ConsumablesFormContent = ({
  onAdd,
  onUpdate,
  onRemove,
  onSuccess,
}) => {
  const { t } = useTranslation(['equipments'])

  return (
    <>
      <Row>
        <Column>
          <SectionView>
            <h1>{t('Master data')}</h1>
            <h5>{t('Label')}*</h5>
            <TextField name="name" mandatory />
            <h5>{t('Inventory number')}</h5>
            <TextField name="inventory_number" />
            <h5>{t('Main Group')}*</h5>
            <EquipmentMaincategoryFreeField
              mandatory
              params={[{ type: ItemTypeEnum.CONSUMABLE }]}
            />
            <h5>{t('Group')}*</h5>
            <EquipmentCategoryFreeField
              mandatory
              dependable={!onAdd}
              params={[{ type: ItemTypeEnum.CONSUMABLE }]}
            />
            <h5>{t('Subgroup')}</h5>
            <EquipmentSubcategoryFreeField
              dependable={!onAdd}
              params={[{ type: ItemTypeEnum.CONSUMABLE }]}
            />
            <h5>{t('Commission type')}*</h5>
            <SelectField
              name="commission_type"
              mandatory
              items={[
                { key: 'COMMISSIONER', title: t('COMMISSIONER') },
                { key: 'SELF', title: t('SELF') },
              ]}
            />
            <h5>{t('Packaging unit')}</h5>
            <TextField name="packaging_unit" />
            <QuantityUnitField mandatory label="Quantity unit" />
            <h5>{t('Stock level')}</h5>
            <StockField name="stock_level" />
            <h5>{t('Min stock level')}</h5>
            <MinStockField name="min_stock" />
            <h5>{t('Max stock level')}</h5>
            <MaxStockField name="max_stock" />
            <h5>{t('Storage')}</h5>
            <TextField name="storage" />
            <h5>{t('Supplier')}</h5>
            <TextField name="supplier" />
            <h5>{t('Responsible orderer')}</h5>
            <ResponsibleOrdererField />
            <h5>{t('Internal billing rate')}*</h5>
            <NumericField
              name="internal_billing_rate"
              decimalScale={2}
              mandatory
            />
            <h5>{t('Orderable')}*</h5>
            <OrderableField mandatory selected={false} />
            <h5>{t('Accounting group')}</h5>
            <AccountingGroupField params={[{ type: 'CONSUMABLE' }]} />
          </SectionView>
        </Column>
      </Row>
      <SimpleFormAction
        onAdd={onAdd}
        onUpdate={onUpdate}
        onRemove={onRemove}
        onSuccess={onSuccess}
      />
    </>
  )
}
