import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useService } from 'common/service/context'
import { SystemPage } from 'system/page/core'
import { formatDate } from 'common/utils/format'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { formatProjectName } from 'modules/projects/utils'
import {
  FoldButtons,
  DetailsIconButton,
  EditIconButton,
  ArchiveIconButton,
} from 'common/widgets/button'
import { useToast } from 'system/toast/context'

import { formatOrderNumber, OrderStateColor } from './utils'

/**
 *  Renders the Order page
 *
 * @returns ReactElement
 */
export const OrderListPage = ({ actions }) => {
  const { t } = useTranslation(['orders'])
  const service = useService()
  const navigate = useNavigate()
  const { toasts } = useToast()

  // Calls a put call and archives the given recortd
  const archiveOrder = async (r) => {
    await service.put(`orders/${r.id}`, {
      archived_on: r.archived_on === null ? new Date() : null,
    })
    r.archived_on
      ? toasts.success(
          `${t('Order')} ${t(r.number)} ${t('sucessfully activated.')}`
        )
      : toasts.success(
          `${t('Order')} ${t(r.number)} ${t('sucessfully archived.')}`
        )
  }

  return (
    <SystemPage>
      <SimpleDataTable
        url="/orders"
        name="orders"
        archive
        title="Orders"
        rowColor={(r) => OrderStateColor[r.state]}
      >
        <SimpleColumn
          n={3}
          s={6}
          sortable
          default="desc"
          field="number"
          label={t('Order number')}
          value={(r) => formatOrderNumber(r?.number)}
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          label={t('Category')}
          value={(r) => r.baseitem_types.map((e) => t(e.type)).join(', ')}
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          field="project_id"
          label={t('Project')}
          value={(r) => formatProjectName(r.project)}
          link={(r) => (r.project_id ? `/projects/${r.project_id}` : null)}
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          field="creator_id"
          label={t('Creator')}
          value={(r) => r.creator.fullname}
          link={(r) => `/security/users/${r.creator?.id}`}
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          field="ordered_at"
          label={t('Ordered at')}
          value={(r) => formatDate(r.ordered_at)}
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          field="requires_delivery"
          label={t('Delivery option')}
          value={(r) =>
            r.requires_delivery ? t('Requires delivery') : t('Self-pickup')
          }
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          filter={(e) => e}
          field="state"
          label={t('Status')}
          value={(r) => t(r.state)}
          filterUrl="orders/states"
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          field="planned_delivery_date"
          label={t('Planned delivery date')}
          value={(r) => formatDate(r.planned_delivery_date)}
        />
        <SimpleColumn
          n={3}
          s={6}
          label={t('Transport Status')}
          value={(r) =>
            r.transport_planned ? `${r.transport_count} PLANNED` : 'NOT PLANNED'
          }
        />
        <SimpleColumn fixed>
          {(r, reload) =>
            actions ? (
              actions(r, reload)
            ) : (
              <FoldButtons>
                {r.archivable && (
                  <ArchiveIconButton
                    tooltip={t('Archive')}
                    white
                    onClick={async () => {
                      await archiveOrder(r)
                      await reload()
                    }}
                    archived={r.archived_on}
                  />
                )}
                {r.editable && (
                  <EditIconButton
                    tooltip={t('Edit')}
                    white
                    onClick={() => navigate(`/orders/${r.id}/edit`)}
                  />
                )}
                <DetailsIconButton
                  tooltip={t('Details')}
                  white
                  onClick={() => navigate(`/orders/${r.id}`)}
                />
              </FoldButtons>
            )
          }
        </SimpleColumn>
      </SimpleDataTable>
    </SystemPage>
  )
}
