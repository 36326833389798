import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import { QueryConsumer } from 'common/query/context'
import { useService } from 'common/service/context'
import { formatUserName } from 'modules/security/users/utils'
import { FlagIconButton } from 'common/widgets/button'
import { DeliveryStateColor, formatDeliveryNumber } from 'modules/yard/utils'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { formatProjectName } from 'modules/projects/utils'
import { formatOrderNumber } from 'modules/orders/utils'

import { CancelDeliveryOverlay } from './overlays/cancelation'
import { DeliveryTripleDotMenu } from './action-menus'

export const DeliveryDataTable = ({ order }) => {
  const service = useService()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const formatDeliveryProjectName = (project) =>
    project ? formatProjectName(project) : t('Building yard')
  const [cancelDeliveryObject, setCancelDeliveryObject] = useState(null)

  const fetch = async (params) =>
    order
      ? await service.get('kommission/delivery', [
          ...params,
          { order_id: parseInt(order.id) },
        ])
      : await service.get('kommission/delivery', params)

  return (
    <SimpleDataTable
      name="deliverynotes"
      fetch={fetch}
      title={t('Delivery notes')}
      addTooltip="Create ad hoc delivery note"
      onAddClick={() => {
        navigate(`/yard/commission/deliveries/adhoc`)
      }}
      archive
      rowColor={(r) => DeliveryStateColor[r.state]}
    >
      <SimpleColumn
        n={4}
        s={6}
        sortable
        field="id"
        label={t('Delivery number')}
        value={(r) => formatDeliveryNumber(r?.order?.number, r?.number)}
      />
      <SimpleColumn
        n={4}
        s={6}
        field="order"
        label={t('Order number')}
        sortable
        value={(r) => formatOrderNumber(r.order?.number)}
        link={(r) => `/orders/${r.order?.id}`}
      />
      <SimpleColumn
        n={4}
        s={6}
        filter={(p) => p.id}
        title={(p) => formatDeliveryProjectName(p)}
        filterUrl="/orders/projects"
        field="project_id"
        label={t('Project')}
        value={(r) => formatDeliveryProjectName(r.order.project)}
        link={(r) => `/projects/${r.order?.project_id}`}
      />
      <SimpleColumn
        n={4}
        s={6}
        filter={(p) => p.id}
        title={(p) => formatDeliveryProjectName(p)}
        filterUrl="/kommission/delivery/source_projects"
        field="source_project_id"
        label={t('Source project')}
        value={(r) => formatDeliveryProjectName(r.source_project)}
        link={(r) =>
          r.source_project?.id ? `/projects/${r.source_project?.id}` : null
        }
      />
      <SimpleColumn
        n={4}
        s={6}
        filter={(p) => p.id}
        title={(p) => formatUserName(p)}
        filterUrl="/kommission/delivery/creators"
        field="creator_user_id"
        label={t('Logistic user')}
        value={(r) => formatUserName(r.creator_user)}
        link={(r) => `/security/users/${r.creator_user?.id}`}
      />
      <SimpleColumn
        n={4}
        s={6}
        field="recipient"
        label={t('Recipient')}
        value={(r) => t(r.recipient)}
      />
      <SimpleColumn
        n={4}
        s={6}
        title={(p) => p}
        field="requires_delivery"
        label={t('Delivery option')}
        value={(r) =>
          r.order.requires_delivery ? t('Requires delivery') : t('Self-pickup')
        }
      />
      <SimpleColumn
        n={4}
        s={6}
        filter={(p) => p}
        field="state"
        filterUrl="/kommission/delivery/states"
        label={t('Status')}
        value={(r) => t(r.state)}
      />
      <SimpleColumn fixed>
        {(r, reload) => (
          <div style={{ width: '150px' }}>
            <DeliveryTripleDotMenu
              delivery={r}
              onChange={() => reload()}
              onCancel={setCancelDeliveryObject}
            />
          </div>
        )}
      </SimpleColumn>
      <SimpleColumn fixed>
        {(r) =>
          r.failed_count > 0 && (
            <FlagIconButton
              disabled
              danger
              style={{ marginTop: 60, marginLeft: 'auto' }}
            />
          )
        }
      </SimpleColumn>
      <QueryConsumer>
        {({ reload }) => (
          <CancelDeliveryOverlay
            delivery={cancelDeliveryObject}
            onClose={() => setCancelDeliveryObject(null)}
            reload={reload}
          />
        )}
      </QueryConsumer>
    </SimpleDataTable>
  )
}
