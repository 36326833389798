import { useState, useEffect } from 'react'
import { Menu } from 'react-feather'

import { useMediaQuery } from 'common/utils/hooks'
import Logo from 'assets/system-logo.svg'

import styles from './logo.module.css'

export const SystemTogglableLogo = ({ toggled, onToggle }) => {
  const [open, setOpen] = useState(toggled)

  useEffect(() => {
    setOpen(toggled)
  }, [toggled])

  const isRowBased = useMediaQuery('(max-width: 768px)')

  return (
    <div
      className={styles.logo}
      onClick={() => {
        setOpen(!open)
        onToggle && onToggle(!open)
      }}
    >
      {isRowBased ? (
        <div
          className={styles.mobile}
          style={{
            transform: open ? 'rotate(90deg)' : 'rotate(0)',
          }}
        >
          <Menu />
        </div>
      ) : (
        <div className={styles.desktop}>
          <Logo />
        </div>
      )}
    </div>
  )
}
