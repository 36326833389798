import { register } from 'system/container'
import { SystemPage } from 'system/page/core'

import { ContactsList } from './list'
import { ContactAddPage } from './add'
import { ContactEditPage } from './edit'

register({
  menu: true,
  render: () => (
    <SystemPage>
      <ContactsList />
    </SystemPage>
  ),
  title: 'Contacts',
  path: '/master-data/contacts',
  priority: 0,
})

register({
  render: () => <ContactAddPage />,
  title: 'New',
  path: '/master-data/contacts/add',
  priority: 0.0,
})

register({
  render: () => <ContactEditPage />,
  title: 'Edit',
  path: '/master-data/contacts/:id/edit',
  priority: 0.0,
})
