import { useTranslation } from 'react-i18next'

import { formatDate } from 'common/utils/format'
import { useService } from 'common/service/context'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'

export const TaskHistory = ({ resource, white }) => {
  const { t } = useTranslation()
  const service = useService()
  const taskStates = { true: 'success', false: 'fail' }

  const fetch = async (params) => {
    return await service.get('/maintenance/tasks', [
      ...params,
      // fetch only the completed tasks for a resource
      { done: true },
      { baseitem_id: resource?.id },
    ])
  }

  return (
    <SimpleDataTable
      name="taskhistory"
      fetch={fetch}
      title={t('Task History')}
      white={white}
    >
      <SimpleColumn
        n={3}
        s={6}
        filter={(p) => p}
        title={(p) => t(p)}
        fetch={async () => [
          {
            data: [
              'planned_repair',
              'check_electrical_device',
              'UVV_check',
              'visual_check',
              'expert_check',
              'other_check',
            ],
          },
        ]}
        field="task_type"
        label={t('Task type')}
        value={(r) => t(r?.plan?.task_type)}
      />
      <SimpleColumn
        n={3}
        s={6}
        filter={(r) => `${r?.performer?.id}`}
        title={(r) => r?.performer?.fullname}
        filterUrl="/maintenance/tasks/performers?internal=true"
        field="performer_id"
        label={t('Internal tester')}
        value={(r) => r?.performer?.fullname}
      />
      <SimpleColumn
        n={3}
        s={6}
        filter={(r) => r?.performer_name}
        title={(r) => r?.performer_name}
        filterUrl="/maintenance/tasks/performers?external=true"
        field="performer_name"
        label={t('External tester')}
        value={(r) => r?.performer_name}
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="done_date"
        label={t('Performed on')}
        value={(r) => formatDate(r?.done_date)}
      />
      <SimpleColumn
        n={3}
        s={6}
        title={(r) => t(taskStates[r?.ok])}
        sortable
        field="task_result"
        label={t('Carried out')}
        value={(r) => formatDate(r?.done_date)}
      />
    </SimpleDataTable>
  )
}

/**
 * Renders the resource task which are ongoing
 *
 * @param {any} resource selected resource object
 *
 * @returns ReactElement
 */
export const OnGoingTasks = ({ resource, white }) => {
  const { t } = useTranslation()
  const service = useService()

  /**
   * Calls completed tasks api.
   *
   * @param {Array} params query params
   */
  const fetch = async (params) => {
    return await service.get('/maintenance/tasks', [
      ...params,
      // fetch only the tasks for a resource which are ongoing
      { done: false },
      { baseitem_id: resource?.id },
    ])
  }

  return (
    <SimpleDataTable
      name="plannedtasks"
      fetch={fetch}
      title={t('Planned Tasks')}
      white={white}
    >
      <SimpleColumn
        n={3}
        s={6}
        filter={(p) => p}
        title={(p) => t(p)}
        fetch={async () => [
          {
            data: [
              'planned_repair',
              'check_electrical_device',
              'UVV_check',
              'visual_check',
              'expert_check',
              'other_check',
            ],
          },
        ]}
        field="task_type"
        label={t('Task type')}
        value={(r) => t(r?.plan?.task_type)}
      />
      <SimpleColumn
        n={3}
        s={6}
        filter={(r) => `${r?.performer?.id}`}
        title={(r) => r?.performer?.fullname}
        filterUrl="/maintenance/tasks/performers?internal=true"
        field="performer_id"
        label={t('Internal tester')}
        value={(r) => r?.performer?.fullname}
      />
      <SimpleColumn
        n={3}
        s={6}
        filter={(r) => r?.performer_name}
        title={(r) => r?.performer_name}
        filterUrl="/maintenance/tasks/performers?external=true"
        field="performer_name"
        label={t('External tester')}
        value={(r) => r?.performer_name}
      />
      <SimpleColumn
        n={3}
        s={6}
        sortable
        field="done_date"
        label={t('Performed on')}
        value={(r) => formatDate(r?.done_date)}
      />
    </SimpleDataTable>
  )
}
