import { useTranslation } from 'react-i18next'

import { Container } from 'common/widgets/container'
import { FieldView, GridFieldView } from 'common/widgets/view'

import { formatItemName, getEquipmentNavPath } from '../utils'

import { ItemTypeCommentView } from './type'

export const EquipmentLinkFieldView = ({ label, baseitem }) => {
  const { t } = useTranslation()
  return (
    <FieldView label={label || t('Item')} value={baseitem.name}>
      <EquipmentLink equipment={baseitem} />
    </FieldView>
  )
}

export const EquipmentGridLinkFieldView = ({
  n,
  s,
  label,
  baseitem,
  ...rest
}) => {
  const { t } = useTranslation()
  return (
    <GridFieldView n={n} s={s} label={label || t('Item')} {...rest}>
      <EquipmentLink equipment={baseitem} {...rest} />
    </GridFieldView>
  )
}

export const EquipmentLink = ({
  equipment,
  showType = true,
  showInventoryNumber = false,
}) => {
  return (
    <Container flex vertical>
      <FieldView
        href={getEquipmentNavPath(equipment)}
        value={
          showInventoryNumber ? formatItemName(equipment) : equipment?.name
        }
      />
      {showType && <ItemTypeCommentView equipment={equipment} />}
    </Container>
  )
}
