import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Row } from 'common/widgets/grid'
import { Container } from 'common/widgets/container'
import { GridFieldView, RowView, LinkFieldView } from 'common/widgets/view'
import { AddIconButton, DeleteIconButton } from 'common/widgets/button'
import { DropDown } from 'common/widgets/dropdown'

import { TripCRUDOverlay } from '../trip/add'

// A list of trips for internal use in this module
export const QueuedTrips = ({
  trips,
  transporters = [],
  activeTransporter,
  onActiveTransporterChange,
  onTripAdd,
  onTripCreate,
  readonly,
}) => {
  const { t } = useTranslation(['logistics'])
  const items = transporters.map((t) => [
    {
      key: t.id,
      title: t.name,
      obj: t,
    },
  ])

  const [crudVisible, setCrudVisible] = useState(false)

  return (
    <RowView vertical gap="10px">
      <Container flex grow>
        <h2>{t('Queued Trips')}</h2>
        {/* <AddIconButton
          marginLeft="auto"
          onClick={() => setCrudVisible((prevState) => !prevState)}
        /> */}
        {crudVisible && (
          <TripCRUDOverlay
            open={crudVisible}
            onAdd={() => {
              setCrudVisible((prevState) => !prevState)
              onTripCreate && onTripCreate()
            }}
            onClose={() => setCrudVisible((prevState) => !prevState)}
          />
        )}
      </Container>
      <DropDown
        title={t('Choose logistic vehicle')}
        selectedIndex={transporters?.findIndex(
          (i) => i.id == activeTransporter?.id
        )}
        onSelectChange={(selection) => {
          onActiveTransporterChange && onActiveTransporterChange(selection.obj)
        }}
        items={items.flat()}
      ></DropDown>
      <Container grow>
        {trips ? (
          !activeTransporter ? (
            <p>{t('Choose a transporter from list to begin planning.')}</p>
          ) : (
            trips.map((trip) => (
              <Trip
                readonly={readonly}
                trip={trip}
                key={trip.id}
                onAdd={onTripAdd}
              />
            ))
          )
        ) : (
          <p> {t('There are no waiting trips.')}</p>
        )}
      </Container>
    </RowView>
  )
}

// A simple row view of a trip for internal use in this module
export const Trip = ({ trip, onRemove, onAdd, readonly = false }) => {
  const { t } = useTranslation(['logistics'])
  return (
    <RowView
      style={{
        borderLeftColor: TripStateColor[trip.state] || '#376DF4',
        borderLeftWidth: '10px',
        boxSizing: 'border-box',
      }}
      border
      gap="10px"
    >
      <Row>
        <GridFieldView
          n={5}
          s={9}
          label={t('Destination')}
          value={trip.location}
        />
        <LinkFieldView
          n={2}
          s={9}
          label={t('Order')}
          value={trip.order?.number}
          url={`/orders/${trip.order_id}`}
        />
        <GridFieldView n={5} s={9} label={t('Description')} value={trip.info} />
      </Row>
      {!readonly && onAdd && (
        <AddIconButton
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            onAdd(trip)
          }}
        />
      )}
      {!readonly && onRemove && (
        <DeleteIconButton style={{ marginLeft: 'auto' }} onClick={onRemove} />
      )}
    </RowView>
  )
}

// Trip status colors
export const TripStateColor = {
  PENDING: '#888888',
  PLANNED: '#AEC4FA',
  STARTED: '#F9F29A',
  DONE: '#95E68B',
  FAULT: '#FC4242',
}
