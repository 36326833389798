import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useService } from 'common/service/context'
import { Row, Column } from 'common/widgets/grid'
import { dateToISOString } from 'common/utils/format'
import { useQueryParams } from 'common/utils/routing'
import { isPastDate } from 'common/utils/date'

import { QueuedTrips } from '../trip/trip'

import { TransportsPageHeader } from './navigation'
import { LogisticVehicles } from './logistics'

export const TransportsOverviewPage = () => {
  const service = useService()
  const { date } = useParams()
  const { t } = useTranslation(['logistics'])
  const navigate = useNavigate()

  const showTripSelector = isPastDate(date)

  const { transporter_id } = useQueryParams()
  const transporterIdParam = transporter_id

  // Keep track of one transporter as visually active to be able
  // to add queued trips one at a time
  const [activeTransporter, setActiveTransporter] = useState(null)

  // Combinded data from multiple logistcs-related endpoints
  const [logisticsData, setLogisticsData] = useState({
    transporters: [],
    transports: [],
    trips: [],
  })

  // Function to fetch all necessary data at once
  const reload = async () => {
    // A function for fetching a single endpoint
    const fetch = async (key, url, params) => {
      const [result, error] = await service.get(url, params)
      return [key, result, error]
    }
    // Run all requests in parallel
    const results = await Promise.all([
      // A transporter is a vehicles used for logistics
      // TODO: hide trucks with maintenance planned for today
      // TODO: update trips before pickup
      fetch(
        'transporters',
        `items/resources?archived=false&maincategory=Logistics`
      ),
      // A transport is a delivery with at least one trip for a transporter
      fetch(
        'transports',
        `/plannings/transports?date[eq]=${dateToISOString(date, true)}`
      ),
      // A trip is a request to move from a source to a destination
      // Only unplanned trips
      fetch('trips', `plannings/trips?transport`),
    ])
    // Inflate collected data in a JSON object
    const combinedData = Object.fromEntries(
      results.map((e) => {
        const [key, result, error] = e
        const data = error ? [] : result.data
        return [key, data]
      })
    )
    // Add any truck which happens to have a trip to the list
    // even if the truck is not from our logistics category
    let newTrucks = []
    combinedData.transports.forEach((t) => {
      let logisticTruck = combinedData.transporters.filter(
        (transporter) => transporter.id == t.resource.id
      )
      // If logisticTruck is empty, we have a new turck
      if (logisticTruck.length == 0) {
        newTrucks.push(t.resource)
      }
    })
    combinedData.transporters = combinedData.transporters.concat(newTrucks)

    // Add each trucks transports to it
    // TODO: we would need a logistics endpoint to give us logistic vehicles
    // and their planned transports with even trips information at once
    combinedData.transporters.forEach((transporter) => {
      transporter['transports'] = []
      combinedData.transports.forEach((t) => {
        if (transporter.id == t.resource_id) {
          transporter['transports'].push(t)
        }
      })
    })

    // Sort transporters based on their booking state
    combinedData.transporters.sort(
      (t1, t2) => t1.transports.length <= t2.transports.length
    )

    // Finally update the state with the combined data
    setLogisticsData(combinedData)
  }

  useEffect(() => {
    // Load data only once upon render
    reload()
  }, [])

  useEffect(() => {
    // Load data also on date
    reload()
  }, [date])

  useEffect(() => {
    // Keep track of an active card
    setActiveTransporter((prevState) =>
      transporterIdParam
        ? logisticsData.transporters.filter(
            (t) => t.id == transporterIdParam
          )[0]
        : prevState || null
    )
  }, [logisticsData.transporters])

  useEffect(() => {
    // Add the transporter id to query params if necessary
    if (activeTransporter && activeTransporter.id != transporterIdParam) {
      navigate(`?transporter_id=${activeTransporter.id}`, { replace: true })
    }
  }, [activeTransporter])

  const switchActiveTransporter = (transporter) => {
    setActiveTransporter(transporter)
  }

  return (
    <Row>
      <Column n={6} grow width="100%">
        <TransportsPageHeader />
        {logisticsData.transporters.length > 0 ? (
          <LogisticVehicles
            transporters={logisticsData.transporters}
            activeTransporter={activeTransporter}
            onActiveTransporterChange={switchActiveTransporter}
            onTourChange={async ({ driverName }) => {
              const activeTransporterIndex =
                logisticsData.transporters.findIndex(
                  (t) => t.id == activeTransporter.id
                )
              const hasTransports =
                logisticsData.transporters[activeTransporterIndex].transports
                  .length > 0
              if (!hasTransports) {
                return
              }
              await service.put(
                `/plannings/transports/${activeTransporter.transports[0].id}`,
                { driver_name: driverName }
              )
              setLogisticsData((prevState) => {
                prevState.transporters[
                  activeTransporterIndex
                ].transports[0].driver_name = driverName
                return {
                  ...prevState,
                }
              })
            }}
            onTripRemove={
              showTripSelector
                ? null
                : async (trip) => {
                    // Remove the trip from the transport
                    const [, error] = await service.put(
                      `/plannings/trips/${trip.id}`,
                      {
                        transport_id: null,
                      }
                    )
                    // Don't continue if the above step failed
                    // Generic error handling is done at app-level
                    if (error) {
                      return
                    }
                    if (activeTransporter.transports[0].trips.length == 1) {
                      // This was the last trip for this transporter, so delete it
                      await service.delete(
                        `/plannings/transports/${trip.transport_id}`
                      )
                    }
                    reload()
                  }
            }
          />
        ) : (
          <h2>{t('No logistic vehicles found.')}</h2>
        )}
      </Column>
      {showTripSelector ? (
        <p> {t('Can not modify past tours.')} </p>
      ) : (
        <Column n={5} s={5} grow width="100%">
          <QueuedTrips
            trips={logisticsData.trips}
            transporters={logisticsData.transporters}
            activeTransporter={activeTransporter}
            onActiveTransporterChange={switchActiveTransporter}
            readonly={!activeTransporter}
            onTripCreate={() => reload()}
            onTripAdd={
              showTripSelector
                ? null
                : async (trip) => {
                    // Check if the transporter has a transport for today
                    let newTransport
                    if (activeTransporter.transports.length == 0) {
                      // Create a transport for this logistic vehicle
                      const [response] = await service.post(
                        '/plannings/transports',
                        {
                          resource_id: activeTransporter.id,
                          driver_name: activeTransporter.driver_name,
                          date: date,
                        }
                      )
                      newTransport = response?.data ?? []
                      newTransport['trips'] = []
                      activeTransporter.transports.push(newTransport)
                    }
                    // Add the trip to transport
                    await service.put(`/plannings/trips/${trip.id}`, {
                      transport_id: activeTransporter.transports[0].id,
                      date: date,
                      truck: { id: activeTransporter.id },
                    })
                    reload()
                  }
            }
          />
        </Column>
      )}
    </Row>
  )
}
