import { useTranslation } from 'react-i18next'

import { GridFieldView, SectionView } from 'common/widgets/view'
import { Row } from 'common/widgets/grid'

/**
 * Telematics data view for equipments
 *
 * @returns ReactElement
 */
export const TelematicsDataView = ({ data }) => {
  const { t } = useTranslation(['equipments'])
  return (
    <SectionView>
      <h1>{t('Telematics data')}</h1>
      <Row>
        <GridFieldView
          n={6}
          label={t('Telematics id')}
          value={data.telematics_id}
        />
        <GridFieldView
          n={6}
          label={t('Last updated')}
          value={data.last_location?.recorded_at}
        />
        <GridFieldView
          n={6}
          label={`${t('Ambient temperature')} [°Celsius]`}
          value={
            data.last_location?.temperature
              ? `${data.last_location?.temperature} °C`
              : '-'
          }
        />
        <GridFieldView
          n={6}
          label={`${t('Battery level (SOC)')} [%]`}
          value={
            data.last_location?.battery_level
              ? `${data.last_location?.battery_level} %`
              : '-'
          }
        />
      </Row>
    </SectionView>
  )
}
