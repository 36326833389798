import { useNavigate } from 'react-router-dom'

import { formatDate } from 'common/utils/format'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { SystemPage } from 'system/page/core'

export const CostCenterList = () => {
  return (
    <SystemPage>
      <CostCenterTable />
    </SystemPage>
  )
}

export const CostCenterTable = ({
  onAdd,
  disableAdd,
  extraButtons,
  handler,
}) => {
  const navigate = useNavigate()

  return (
    <SimpleDataTable
      name="cost-centers"
      title={'Cost Centers'}
      url="/billing/accounts/cost-centers"
      handler={handler}
      archive
      onAddClick={
        !disableAdd
          ? () => (onAdd ? onAdd() : navigate(`/master-data/cost-centers/add`))
          : null
      }
      navBaseUrl="/master-data/cost-centers"
    >
      <SimpleColumn s={3} n={3} field={'code'} label={'Code'} header />
      <SimpleColumn s={3} n={3} field={'name'} label={'Name'} />
      <SimpleColumn
        s={3}
        n={3}
        sortable
        field="created_at"
        label={'Created at'}
        value={(r) => formatDate(r.created_at)}
      />
      <SimpleColumn
        s={3}
        n={3}
        field="creator_user"
        label={'Created by'}
        value={(r) => r.creator_user.fullname}
      />
      {extraButtons && (
        <SimpleColumn s={1} n={1} value={(r) => extraButtons(r)} />
      )}
    </SimpleDataTable>
  )
}
