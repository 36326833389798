import { Box } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { AddressMap } from 'common/widgets/map/address'
import { Container } from 'common/widgets/container'
import { register } from 'system/container'
import { DetailViewPage } from 'system/utils/view'
import { CommentView, SectionView, GridFieldView } from 'common/widgets/view'
import { Row, Column } from 'common/widgets/grid'
import { Tab } from 'common/widgets/tab'
import { sum } from 'common/utils/collection'
import { useMediaQuery } from 'common/utils/hooks'

import { formatContactAddress, getPrimaryAddress } from '../utils'
import { ContactsList } from '../list'
import { ContactPresentsList } from '../presents/list'

import { ContactTagsView } from './tags'
import { ContactDocumentsView } from './documents'
import { ContactRelationView } from './relations'
import { ContactRatesList, RateLabel } from './rates'
import { ContactQualificationsView } from './qualifications'

const ContactViewPage = () => {
  const { t } = useTranslation()
  // Check if we should display mobile view
  const showMobileView = useMediaQuery('(max-width: 768px)')
  /**
   * Calculates average rate of contact.
   *
   * @param {any} contact contact data
   * @returns
   */
  const getRate = (contact) => {
    const count = contact?.rates?.length
    const total = count ? sum(contact.rates, 'rate') : 0.0
    return count > 0 ? total / count : 0.0
  }

  return (
    <DetailViewPage
      url="/contacts"
      navUrl="/master-data/contacts"
      title={(e) => e.name}
      removable
      editable
    >
      {(data, reload) => (
        <Row>
          <Column flex n={6} s={12}>
            <SectionView style={{ flexDirection: 'row', padding: '0' }}>
              <Container
                flex
                vertical
                grow
                padding="10px 10px 0 10px"
                style={{
                  borderLeft: data.state?.color
                    ? `10px solid ${data.state?.color}`
                    : 'inherit',
                }}
              >
                <Container flex repel>
                  <h1>{data.fullname}</h1>
                  <RateLabel rate={getRate(data)} />
                </Container>
                <CommentView comment={data.comment} />
                <GridFieldView n={6} label="Address">
                  {formatContactAddress(getPrimaryAddress(data)).map(
                    (line, i) => (
                      <p key={i}>{line}</p>
                    )
                  )}
                </GridFieldView>
                <Row>
                  <GridFieldView
                    n={6}
                    label="E-Mail"
                    value={getPrimaryAddress(data)?.email}
                  />
                  <GridFieldView
                    n={6}
                    label="Phone"
                    value={getPrimaryAddress(data)?.phone}
                  />
                  <GridFieldView
                    n={6}
                    label="Fax"
                    value={getPrimaryAddress(data)?.fax}
                  />
                  <GridFieldView
                    n={6}
                    label="Webpage"
                    value={getPrimaryAddress(data)?.webpage}
                  />
                  <GridFieldView
                    n={6}
                    label="Job title"
                    value={data.job_title}
                  />
                  <GridFieldView
                    n={6}
                    label="Internal"
                    value={data.is_internal ? t('Yes') : t('No')}
                  />
                  {data.is_internal && (
                    <GridFieldView
                      n={6}
                      label="User"
                      value={data.system_user?.fullname}
                    />
                  )}
                  <GridFieldView n={6} label={data.state?.name}>
                    {data.children_count > 0 && (
                      <CommentView
                        icon={<Box />}
                        comment={`${data.children_count}`}
                      />
                    )}
                  </GridFieldView>
                </Row>
              </Container>
            </SectionView>
          </Column>
          <Column flex n={6} s={12}>
            <ContactMap data={data} mobileView={showMobileView} />
          </Column>
          <Tab
            selected="rates"
            items={[
              {
                key: 'rates',
                title: 'Rates',
                render: () => (
                  <ContactRatesList contact={data} reload={reload} />
                ),
              },
              {
                key: 'qualifications',
                title: 'Qualifications',
                render: () => <ContactQualificationsView contact={data} />,
              },
              {
                key: 'tags',
                title: 'Tags',
                render: () => (
                  <ContactTagsView contact={data} reload={reload} />
                ),
              },
              {
                key: 'relations',
                title: 'Relations',
                render: () => (
                  <ContactRelationView contact={data} reload={reload} />
                ),
              },
              {
                key: 'files',
                title: 'Files',
                render: () => <ContactDocumentsView contact={data} />,
              },
              {
                key: 'presents',
                title: 'Presents',
                render: () => (
                  <ContactPresentsList contact={data} reload={reload} />
                ),
              },
              {
                visible: data.children_count > 0,
                key: 'contacts',
                title: 'Contacts',
                render: () => <ContactsList path={data.path} />,
              },
            ]}
          />
        </Row>
      )}
    </DetailViewPage>
  )
}

const ContactMap = ({ data, mobileView }) => {
  const address = getPrimaryAddress(data)
  return (
    <SectionView>
      <AddressMap address={address} style={mobileView && { height: '250px' }} />
    </SectionView>
  )
}

register({
  render: () => <ContactViewPage />,
  title: 'Details',
  path: '/master-data/contacts/:id',
  priority: 0.0,
})
