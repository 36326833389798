import { useEffect, useState, createContext, useContext, Suspense } from 'react'

import { useService } from 'common/service/context'
import { Pagination } from 'common/widgets/pagination'

// The following contexts will save us handing over parameters
// to pagination components (maybe others too). It has the benefit
// of making pagination consistent by omitting parameters.
const DataSourceContext = createContext(null)
export const useDataSourceContext = () => useContext(DataSourceContext)

/**
 * Downloads data and populates its children
 */
export const DataSource = ({
  fetch,
  url,
  // initial parameters
  params,
  handler,
  render,
  // only for internal use
  extendContext,
  children,
}) => {
  const service = useService()

  const [state, setState] = useState({
    data: null,
    loading: true,
    totalCount: 0,
    setQueryParameter: () => {},
    getQueryParameter: () => {},
  })

  const [queryParams, setQueryParams] = useState(params ?? [])

  // Keep initial parameters for future updates
  const [initialParameters] = useState(params ?? [])

  useEffect(() => {
    reload()
  }, [url, queryParams])

  useEffect(() => {
    compareParams()
  }, [params])

  async function defaultFetch(qParams) {
    // multiple "params" are confusing, therefore renamed to "qParams"
    return await service.get(url, qParams)
  }

  const reload = async () => {
    const effectiveFetch = fetch ?? defaultFetch

    // This is in case the fetch function does not return any results
    const initialResult = await effectiveFetch(queryParams ?? [])

    const [response, , totalCount] = initialResult ?? [null, null, 0]

    setState((prev) => ({
      ...prev,
      data: response?.data,
      totalCount,
      loading: false,
    }))
  }

  /**
   * WIP:
   * Add a query parameter to the query string according to RFC 3986
   * https://datatracker.ietf.org/doc/html/rfc3986#section-3.4
   */
  function setQueryParameter(name, value) {
    setQueryParams((prev) => {
      let newParams = [...prev]
      const existingIndex = newParams.findIndex((qp) => name in qp)
      // Update or add the new parameter to the existing parameters
      if (existingIndex >= 0) {
        newParams[existingIndex][name] = value
      } else {
        newParams.push({ [name]: value })
      }
      return newParams
    })
  }

  function unsetQueryParameter(name) {
    setQueryParams((prev) => {
      return prev.filter((qp) => !(name in qp))
    })
  }

  function getQueryParameter(name) {
    const existingIndex = queryParams.findIndex((qp) => name in qp)
    if (existingIndex >= 0) {
      return queryParams[existingIndex][name]
    }
  }

  /**
   * Convert an array of paramter objects into a hash map
   */
  function makeParametersMap(params) {
    const result = {}
    for (const kv of params ?? []) {
      Object.keys(kv).forEach((key) => {
        result[key] = kv[key]
      })
    }
    return result
  }

  function compareParams() {
    // Keys which were present initially, but are not present anymore,
    // are in fact removed and therefore, should be unset.
    const initialParametersMap = makeParametersMap(initialParameters)
    const incomingParametersMap = makeParametersMap(params)
    const previousParametersMap = makeParametersMap(queryParams)

    if (params === undefined) {
      return
    }

    for (const kv of params) {
      Object.keys(kv).forEach((key) => {
        if (kv[key] === undefined) {
          // Keys which are undefined, should be removed, since they
          // do not represent any value (unlike null)
          unsetQueryParameter(key)
        } else {
          if (
            key in previousParametersMap &&
            key in initialParametersMap &&
            previousParametersMap[key] === initialParametersMap[key]
          ) {
            // Do nothing
          } else {
            setQueryParameter(key, kv[key])
          }

          if (key in previousParametersMap || key in initialParametersMap) {
            if (
              key in previousParametersMap &&
              kv[key] !== previousParametersMap[key]
            ) {
              setQueryParameter(key, kv[key])
            } else if (
              key in initialParametersMap &&
              kv[key] !== initialParametersMap[key]
            ) {
              setQueryParameter(key, kv[key])
            }
          } else {
            setQueryParameter(key, kv[key])
          }
        }
      })
    }

    // Check previous params agains new paramters too
    Object.keys(previousParametersMap).forEach((key) => {
      if (!(key in incomingParametersMap)) {
        // This key is removed
        unsetQueryParameter(key)
      }
    })
  }

  // TODO: this is an anti-pattern and should be removed. This makes
  // debugging, development, and maintenance of this software a constant
  // headache. Implementation details should remain private, not exposed
  // to everyone everywhere.
  if (handler) {
    handler.reload = reload
    handler.params = queryParams
    handler.setParams = setQueryParams
  }

  // "params" prop should not be provided or must be an array of objects in
  // this form [..., {queryParameterName: queryParameterValue}]
  // For programming errors we just need to inform the developer. "params"
  // prop is not user provided, so if it is not an array, then programmer
  // has made an error and should fix it.
  console.assert(
    !params || Array.isArray(params),
    'Programming error. "params" prop must be an array. Fix your code.'
  )

  // Either url or fetch props should be provided, otherwise this is another
  // programming error and should be fixed.
  console.assert(
    url || fetch,
    'Programming error: DataSource has no url or fetch. Fix your code.'
  )

  const extendedContext =
    (extendContext &&
      extendContext({ setQueryParameter, getQueryParameter })) ??
    {}

  const contextValues = {
    ...state,
    reload,
    // TODO: letting anyone from anywhere manipulate the state of a
    // component, is a receipt for misery. Any potential caller, should
    // break and then fixed (didn't find any at the time of writing).
    update: () => console.error('Deprecated'),
    setParams: () => {
      console.error('Deprecated: use setQueryParameter insetad.')
    },
    // Adds or updates a single query parameter
    setQueryParameter,
    getQueryParameter,
    params: queryParams ?? [],
    ...extendedContext,
  }

  console.assert(
    !render || typeof children !== 'function',
    'Programming error. "children" prop can not be a function ',
    'when "render" prop is present.'
  )

  return (
    <DataSourceContext.Provider value={contextValues}>
      {state?.loading ? (
        <Suspense fallback="Loading..." />
      ) : typeof children == 'function' ? (
        children(contextValues)
      ) : render ? (
        render(contextValues)
      ) : (
        children
      )}
    </DataSourceContext.Provider>
  )
}

export const PagedDataSource = ({
  name,
  limit = 10,
  params,
  children,
  ...rest
}) => {
  return (
    <DataSource
      params={[...(params ?? []), { ipp: limit ?? 10 }]}
      extendContext={({ getQueryParameter, setQueryParameter }) => {
        return {
          limit: getQueryParameter('ipp') ?? 10,
          setLimit: (limit) => setQueryParameter('ipp', limit ?? 10),
          offset: (getQueryParameter('page') ?? 1) - 1,
          setOffset: (offset) => setQueryParameter('page', (offset ?? 0) + 1),
        }
      }}
      {...rest}
    >
      {(ctx) => {
        return (
          <>
            {typeof children === 'function' ? children(ctx) : children}
            <Pagination {...ctx} style={{ padding: '5px' }} />
          </>
        )
      }}
    </DataSource>
  )
}
