import { createContext, useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useService } from 'common/service/context'
import { SimpleFormAction } from 'common/widgets/form/utils'

import { FormDebug } from './debug'
import styles from './form.module.css'

const FormContext = createContext()

const DebugContext = createContext(null)
export const useDebugContext = () => useContext(DebugContext)

/**
 * Component which provides data and functionalities to build a form.
 *
 * @returns Context for form containing form state and functions.
 */
export const Form = ({
  // base path to the resource endpoint. We "assume" the API consistently
  // provides us with POST to baseUrl/ to create and POST to baseUrl/:id
  // to update the resource in question.
  baseUrl,
  data,
  onSubmit,
  onChange,
  onDelete,
  children,
  handler,
  debug,
  // Used in child inputs to reflect the state of the form
  readOnly,
  // Header title for the form
  title,
  showActions = false,
  // temporary flag to automatically generate submit method, based on
  // form data and id parameter in path
  auto,
  ...rest
}) => {
  // The initial form state. All entries are key/value pairs, one for each field.
  // values: a set of keys and their values available in this form
  // (think of an HTML form, each field has a name and value)
  // dirtyValues: like above, but only those fields which are modified
  // normalizations: a set of functions to transform values described above
  // errors: a set of errors produced by validator functions
  // fields: list of form fields independent of their values
  const [state, setState] = useState({
    values: data ?? {},
    initialValues: data ?? {},
    fields: {},
    dirtyValues: {},
    normalizations: {},
    validators: {},
    errors: {},
    readOnly,
  })

  const { t } = useTranslation()

  // A handy variable to decied which mode is active. This will
  // affect the actions
  const mode = data ? (readOnly ? 'view' : 'update') : 'create'

  // Check if there is an id field in the path
  const { id } = useParams()
  console.assert(
    !(onSubmit && auto),
    'onSubmit and the auto flag are mutually exclusive.'
  )
  const service = useService()
  if (auto && baseUrl) {
    if (mode == 'update' && id) {
      onSubmit = async () => {
        const [response, error] = await service.put(
          `${baseUrl}/${id}`,
          state.dirtyValues
        )
        if (!error) {
          reset(response.data)
        }
        return [response, error]
      }
    }
    if (mode == 'create') {
      onSubmit = async () => {
        const [response, error] = await service.post(`${baseUrl}`, state.values)
        if (!error) {
          reset(response.data)
        }
        return [response, error]
      }
    }
  }

  useEffect(() => {
    console.debug('Form.useEffect.data', data)
    if (state.initialValues !== data) {
      setState((prev) => ({
        ...prev,
        values: data ?? {},
        initialValues: data ?? {},
        dirtyValues: {},
      }))
    } else {
      console.debug('Form.useEffect.data NO CHANGE')
    }
  }, [data])

  useEffect(() => {
    console.debug('Form.useEffect.readOnly')
    if (state.readOnly !== readOnly) {
      console.debug(
        `Form.readOnly changed from ${state.readOnly} to ${readOnly}`
      )
      setState((prev) => ({ ...prev, readOnly }))
    }
  }, [readOnly])

  /**
   * Sets all relevant data of a form field.
   *
   * @param {string} name Name of the field.
   * @param {*} value Value of the field.
   * @param {object} various functions, see setMany
   */
  const set = (name, value, options) => {
    setMany({
      [name]: {
        value,
        ...options,
      },
    })
  }

  /**
   * Sets all relevant data of mulitple form fields.
   *
   * @param {object} a map of fields names to their values and some functions
   * Structure of object:
     {
      ...
      name: --> name of the field
      {
        value: "123", --> the value to set
        validate: (v) => {...} validates the input value to error string or null
        format: (v) => {...} formats value for representation
        normalize: (v) => {...} converts input value to correct API type
      },
      ...
     }
   */
  const setMany = (obj) => {
    let errors = {}
    let values = {}
    let dirtyValues = {}
    let normalizations = {}
    let validators = {}
    let fields = {}
    // An array of field names to drop them from dirty fields, in order to
    // eventually compute a correct "dirty" form state and "enable/disable"
    // state for form actions
    let unchangedFields = []
    // Loop over each object entry
    for (const [
      name,
      { value, format, validate, normalize, ignore },
    ] of Object.entries(obj)) {
      // format value
      const val = format ? format(value) : value

      // Update dirty value if new value is different than existing one
      if (!ignore) {
        if (values[name] !== val) {
          dirtyValues[name] = val
        }
        if (name in state.initialValues) {
          if (state.initialValues[name] === val) {
            delete dirtyValues[name]
            unchangedFields.push(name)
          }
        }
      }

      // Add the value to be stored in the context
      values[name] = val

      // Add validator function
      if (validate) {
        validators[name] = validate
        const res = validate(normalize ? normalize(value) : value, state.values)
        if (res?.length) {
          errors[name] = res
        } else {
          delete state.errors[name]
        }
      }

      // Add normalizer function
      if (normalize) {
        normalizations[name] = normalize
      }

      // Append the fields array
      fields[name] = {
        name,
        format,
        validate,
        normalize,
      }
    }

    // Update form state
    setState((prev) => {
      // Drop fields which have not changed compared to their initial values
      // This is useful for computing a "dirty" flag, which in turn is used
      // to enable/disable action buttons.
      unchangedFields.forEach((uf) => {
        if (uf in prev.dirtyValues) {
          delete prev.dirtyValues[uf]
        }
      })
      return {
        ...prev,
        values: { ...prev.values, ...values },
        dirtyValues: { ...prev.dirtyValues, ...dirtyValues },
        errors: { ...prev.errors, ...errors },
        normalizations: { ...prev.normalizations, ...normalizations },
        validators: { ...prev.validators, ...validators },
        fields: { ...prev.fields, ...fields },
      }
    })
    // Calls onChange hook if it available
    if (onChange) {
      onChange({ values: normalizeValues(state.values), errors })
    }
  }

  /**
   * Process the values using the corresponding normalize function of each field.
   *
   * @returns Object with keys as field names and values as normalized values.
   */
  const normalizeValues = (values) => {
    // Loop over each normalize function
    for (const [name, normalize] of Object.entries(state.normalizations)) {
      // Only add values to the result that actually exist
      if (name in values) {
        // Normalize value and set it inside values
        values[name] = normalize(values[name])
      }
    }

    /**
     *
     * @param {any} obj object instance
     * @param {string} path property path
     * @param {any} value property value
     */
    const setDeep = (obj, path, value) => {
      const keys = path.split('.')
      const lastKey = keys.pop()
      const lastObj = keys.reduce(
        (obj, key) => (obj[key] = obj[key] || {}),
        obj
      )
      lastObj[lastKey] = value
    }

    const result = {}
    for (const [key, value] of Object.entries(values)) {
      setDeep(result, key, value)
    }

    return result
  }

  /**
   * Checks, if form is valid. This means if every field has no error.
   *
   * @returns Boolean. True, if form is valid, else false.
   */
  const isValid = () => {
    return Object.keys(state.errors).length == 0
  }

  const exclude = (keys) => {
    const values = normalizeValues({ ...state.values })
    const result = {}
    for (const [name, value] of Object.entries(values)) {
      if (!keys.includes(name)) {
        result[name] = value
      }
    }
    return result
  }

  /**
   * Check if anything has changed.
   */
  const isDirty = () => {
    return Object.keys(state.dirtyValues).length > 0
  }

  const actions = showActions ? (
    <SimpleFormAction
      onAdd={mode == 'create' ? onSubmit : null}
      onUpdate={mode == 'update' ? onSubmit : null}
      onDelete={onDelete}
    />
  ) : null

  let content = onSubmit ? (
    <form
      className={styles.form}
      onSubmit={(e) => {
        e.preventDefault()
        if (isValid()) {
          const values = normalizeValues({ ...state.values })
          // Remove undefined values
          for (const [name, value] of Object.entries(values)) {
            // Normalize value and set it inside values
            if (value === undefined) {
              delete values[name]
            }
          }
          // For the time being don't call submit when showActions is set,
          // untill old forms are migrated and showActions is removed
          !showActions && onSubmit(values, context)
        }
      }}
      {...rest}
    >
      {children}
      {actions}
    </form>
  ) : (
    <>
      {children}
      {actions}
    </>
  )

  const debugSection = (
    <FormDebug state={state} valid={isValid()} dirty={isDirty()} />
  )

  if (debug) {
    content = (
      <DebugContext.Provider value={debugSection}>
        {content}
      </DebugContext.Provider>
    )
  }

  const reset = (newData) => {
    setState((prev) => ({
      ...prev,
      values: newData ?? data ?? {},
      dirtyValues: {},
    }))
  }

  const context = {
    values: {
      json: normalizeValues({ ...state.values }),
      dirty: normalizeValues({ ...state.dirtyValues }),
      exclude: (keys) => exclude(keys),
      set: (name, value, obj) => set(name, value, obj || {}),
      setMany,
      get: (name, defaultValue) => {
        let result = state.values[name]
        if (result === undefined && defaultValue !== undefined) {
          result = defaultValue
        }
        return result
      },
      reset,
    },
    errors: {
      get: (name) => (state.errors[name] ? state.errors[name] : null),
      json: state.errors,
    },
    // A form is ready to submit if all input values are valid
    ready: isValid(),
    // Check if form contains dirty values (needs submission)
    dirty: isDirty(),
    readOnly: state.readOnly,
  }

  return (
    <FormContext.Provider value={context}>
      {debug && debugSection}
      {title && <h2>{t(title)}</h2>}
      {content}
    </FormContext.Provider>
  )
}

/**
 * Form provider widgets which returns an initiliazed form to children widgets.
 *
 * @param {any} children children widgets to be rendered
 * @returns ReactElement
 */
export const FormProvider = ({ children }) => children(useForm())

/**
 * Hook for consuming the overlaying form context.
 *
 * @returns Object of overlaying form context.
 */
export const useForm = () => useContext(FormContext)
