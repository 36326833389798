import { useTranslation } from 'react-i18next'

import { NoButton, YesButton } from 'common/widgets/button'
import { OverlayBody, OverlayFooter, OverlayForm } from 'common/widgets/overlay'
import { useService } from 'common/service/context'
import { formatBackDeliveryNumber } from 'modules/yard/utils'
import { Row } from 'common/widgets/grid'
import { GridFieldView, GridLinkFieldView } from 'common/widgets/view'
import { formatDate } from 'common/utils/format'

/**
 * Renders the back delivery note cancelation overlay.
 *
 * @param {any} backDelivery back delivery note object
 * @param {any} onClose state of closing the dialog
 * @param {any} reload callback for reloading the page
 *
 * @returns ReactElement
 */
export const CancelBackDeliveryOverlay = ({
  backDelivery,
  onClose,
  onDeliveryCanceled,
}) => {
  const { t } = useTranslation()
  const service = useService()

  /**
   * Cancels the back delivery note.
   *
   * @returns Array[result, error]
   */
  const handleBackDeliveryCancelation = async () => {
    const [result, error] = await service.put(
      `back/delivery/${backDelivery.id}/cancel`
    )

    if (!error) {
      onDeliveryCanceled()
      onClose()
    }

    return [result, error]
  }

  return (
    <OverlayForm
      open={backDelivery}
      onClose={onClose}
      title={t('Cancel back delivery')}
    >
      <OverlayBody>
        <Row>
          <GridFieldView
            n={6}
            s={6}
            label={t('Back delivery number')}
            value={formatBackDeliveryNumber(backDelivery)}
          />
          <GridLinkFieldView
            n={6}
            s={6}
            label={t('Project')}
            value={backDelivery?.project?.short_name}
            url={`/projects/${backDelivery?.project.id}`}
          />
          <GridLinkFieldView
            n={6}
            s={6}
            label={t('Destination')}
            value={
              backDelivery?.dest_project
                ? backDelivery?.dest_project?.short_name
                : t('Building yard')
            }
          />
          <GridLinkFieldView
            n={6}
            s={6}
            label={t('State')}
            value={t(backDelivery?.state)}
          />
          <GridFieldView
            n={6}
            s={6}
            label={t('Delivery option')}
            value={
              backDelivery?.adhoc ? t('Self-pickup') : t('Requires delivery')
            }
          />
          <GridFieldView
            n={6}
            s={6}
            label={t('Creation date')}
            value={formatDate(backDelivery?.created_at)}
          />
        </Row>
      </OverlayBody>
      <OverlayFooter repel gap="10px">
        <YesButton onClick={handleBackDeliveryCancelation} />
        <NoButton onClick={onClose} />
      </OverlayFooter>
    </OverlayForm>
  )
}
