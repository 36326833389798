.limitLabel {
  text-align: right;
}

.btnList {
  display: flex;
  gap: 5px;
}

.btnList svg {
  color: #8b8b8b;
}

.btnList label {
  color: #8b8b8b;
  font-size: 11px;
}
