import React, { useState, useEffect } from 'react'

export const BuildInfo = ({ showBuildDate = true, ...rest }) => {
  const [version, setVersion] = useState('')
  const [builton, setBuilton] = useState('')

  useEffect(() => {
    import('/src/env')
      .then((mod) => {
        setVersion(mod.VERSION)
        setBuilton(mod.BUILTON)
      })
      .catch((e) => console.warn('env.js not found'))
  })

  return (
    <small
      style={{
        position: 'absolute',
        bottom: '0',
        width: '100%',
        color: 'grey',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
      {...rest}
    >
      <span>{version}</span>
      {showBuildDate && <span>&nbsp;built on {builton}</span>}
    </small>
  )
}
