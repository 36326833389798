import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Column, Row } from 'common/widgets/grid'
import { DetailViewPage } from 'system/utils/view'
import {
  CardView,
  Divider,
  GridFieldView,
  LinkFieldView,
} from 'common/widgets/view'
import { Box, Container } from 'common/widgets/container'
import {
  AddIconButton,
  CancelIconButton,
  ClockIconButton,
  CloseIconButton,
  CompleteButton,
  DeleteIconButton,
  EditIconButton,
  ReopenButton,
} from 'common/widgets/button'
import { formatDate, formatDateTime } from 'common/utils/format'
import { UserView } from 'modules/security/users/widgets'
import {
  DropDownField,
  FileField,
  TextField,
  TextAreaField,
} from 'common/widgets/form/field'
import { Form, useForm } from 'common/widgets/form/context'
import { useService } from 'common/service/context'
import { DataSource, PagedDataSource } from 'common/widgets/data-source'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { SearchInput } from 'common/widgets/search'
import { OverlayBody, OverlayForm } from 'common/widgets/overlay'
import { ActivityView } from 'common/widgets/activity'
import { Avatar } from 'common/widgets/avatar'
import { useToast } from 'system/toast/context'
import { Tag } from 'common/widgets/tag'

import { StatusField } from './list'
import { BookingHoursOverlay, CreateTaskBookingOverlay } from './overlay'
import styles from './tasks.module.css'

/**
 * Renders the task detail view page
 *
 * @returns ReactElement
 */
export const TaskDetailViewPage = () => {
  // State for opening the book time overlay
  const [selectedTask, setSelectedTask] = useState(null)
  // Handler for the timeline
  const timelineHandler = {}
  // Handler for the time tracking data source
  const timeTrackingHandler = {}

  return (
    <DetailViewPage
      url="/tasks"
      navUrl="/projects/tasks"
      title={(е) => е?.title}
      archivable={(task) => task.status === 'Closed'}
      editable={(task) => task.status !== 'Closed'}
      addExtraButtons={(e) => (
        <ClockIconButton
          disabled={e.status === 'Closed'}
          onClick={(r) => {
            setSelectedTask(e)
          }}
          style={{ alignSelf: 'center' }}
        />
      )}
    >
      {(data, reload) => {
        return (
          data && (
            <>
              <TaskDetails data={data} reload={reload} />
              <ActionsDetails
                task={data}
                reload={reload}
                handler={timelineHandler}
              />
              <LinkedTasks task={data} reload={reload} />
              <TimeTracking
                task={data}
                reload={reload}
                handler={timeTrackingHandler}
              />
              <PersonalDetails task={data} />
              <EquipmentDetails task={data} />
              <Timeline task={data} handler={timelineHandler} />
              {selectedTask && (
                <BookingHoursOverlay
                  task={selectedTask}
                  open={() => selectedTask !== null}
                  onClose={() => setSelectedTask(null)}
                  onSubmit={() => {
                    reload()
                    timeTrackingHandler.reload()
                  }}
                />
              )}
            </>
          )
        )
      }}
    </DetailViewPage>
  )
}

/**
 * Renders the task details
 *
 * @param {any} data task object
 * @param {any} reload reloads the page
 *
 * @returns ReactElement
 */
const TaskDetails = ({ data, reload }) => {
  const { t } = useTranslation()
  const service = useService()
  const { toasts } = useToast()

  const handleTaskComplete = async () => {
    const [result, error] = await service.post(`/tasks/${data.id}/close`)
    if (result && !error) {
      toasts.success(t('Task was closed successfully'))
      reload()
    }

    return [result, error]
  }

  const handleTaskReopen = async () => {
    const [result, error] = await service.post(`/tasks/${data.id}/reopen`)
    if (result && !error) {
      toasts.success(t('Task was reopned successfully'))
      reload()
    }

    return [result, error]
  }

  return (
    <CardView style={{ padding: '0px' }}>
      <Container flex repel style={{ padding: '15px' }}>
        <h1>{t('Details')}</h1>
        {data.status === 'Open' || data.status === 'Reopened' ? (
          <CompleteButton
            onClick={() => handleTaskComplete()}
            style={{ maxWidth: '150px' }}
          />
        ) : (
          <ReopenButton
            onClick={() => handleTaskReopen()}
            style={{ maxWidth: '150px' }}
          />
        )}
      </Container>
      <Box nopadding top>
        <Row>
          <Column n={4} m={4} s={12} style={{ margin: '0px' }}>
            <Box>
              <TaskInfo task={data} />
            </Box>
          </Column>
          <Column n={4} m={4} s={12} style={{ margin: '0px' }}>
            <Box left right>
              <ScheduleInfo task={data} />
            </Box>
          </Column>
          <Column n={4} m={4} s={12} style={{ margin: '0px' }}>
            <Box>
              <ProjectInfo task={data} />
            </Box>
          </Column>
        </Row>
      </Box>
    </CardView>
  )
}

/**
 * Renders the task info
 *
 * @param {any} task task object
 *
 * @returns ReactElement
 */
const TaskInfo = ({ task }) => {
  const { t } = useTranslation()

  return (
    <Row>
      <GridFieldView n={12} m={6} s={12}>
        <h3>{t('Task')}</h3>
      </GridFieldView>
      <GridFieldView
        n={12}
        m={12}
        s={12}
        label={t('Task')}
        value={task.title}
      />
      <GridFieldView n={12} m={6} s={12} label={t('Created by')}>
        <UserView user={task.creator_user} />
      </GridFieldView>
      <GridFieldView
        n={12}
        m={12}
        s={12}
        label={t('Description')}
        value={task.description}
      />
    </Row>
  )
}

/**
 * Renders the Task schedule
 *
 * @param {any} task task object
 *
 * @returns ReactElement
 */
const ScheduleInfo = ({ task }) => {
  const { t } = useTranslation()

  return (
    <Row>
      <GridFieldView n={12} m={6} s={12}>
        <h3>{t('Schedule')}</h3>
      </GridFieldView>
      <GridFieldView
        n={6}
        m={6}
        s={12}
        label={t('Start date')}
        value={formatDate(task.schedule?.start_date)}
      />
      <GridFieldView
        n={6}
        m={6}
        s={12}
        label={t('End date')}
        value={formatDate(task.schedule?.due_date, { friendly: true })}
      />
      <GridFieldView
        n={6}
        m={6}
        s={12}
        label={t('Planned hours')}
        value={task.planned_time}
      />
      <GridFieldView
        n={6}
        m={6}
        s={12}
        label={t('Tracked hours')}
        value={task.spent_time}
      />
    </Row>
  )
}

/**
 * Renders the task` project info
 *
 * @param {any} task task object
 *
 * @returns ReactElement
 */
const ProjectInfo = ({ task }) => {
  const { t } = useTranslation()

  return (
    <Row>
      <GridFieldView n={12} m={6} s={12}>
        <h3>{t('Project')}</h3>
      </GridFieldView>
      <GridFieldView
        n={12}
        m={12}
        s={12}
        label={t('Name')}
        value={task.project?.name}
      />
      <GridFieldView
        n={12}
        m={12}
        s={12}
        label={t('Phase')}
        value={task.phase?.name}
      />
    </Row>
  )
}

/**
 * Renders the linked tasks
 *
 * @param {any} task task object
 * @param {any} reload reloads the page
 *
 * @returns ReactElement
 */
const LinkedTasks = ({ task, reload }) => {
  const { t } = useTranslation()
  const [shouldLinkTask, setShouldLinkTask] = useState(false)
  const service = useService()

  const handleTaskLink = async (data) => {
    const [result, error] = await service.put(`/tasks/${data.linked_task_id}`, {
      parent_task_id: data.parent_task_id,
    })
    if (result && !error) {
      reload()
      setShouldLinkTask(false)
    }

    return [result, error]
  }

  const handleTaskUnlink = async (linkedTask) => {
    const [result, error] = await service.put(`/tasks/${linkedTask.id}`, {
      parent_task_id: null,
    })
    if (result && !error) {
      reload()
    }

    return [result, error]
  }

  return (
    <CardView style={{ padding: '0px' }}>
      <Container flex style={{ padding: '15px' }} repel>
        <h1>{t('Linked tasks')}</h1>
        <AddIconButton onClick={() => setShouldLinkTask(true)} />
      </Container>
      <Box top nopadding>
        {task.subtasks?.map((e, i) => (
          <>
            <Container
              key={i}
              flex
              repel
              padding="15px"
              style={{ alignItems: 'center' }}
            >
              <LinkFieldView
                label="title"
                value={e.title}
                url={`/projects/tasks/${e.id}`}
              />
              <CloseIconButton onClick={() => handleTaskUnlink(e)} />
            </Container>
            <Divider nopadding />
          </>
        ))}
      </Box>
      {shouldLinkTask && (
        <LinkTaskOverlay
          task={task}
          open={shouldLinkTask}
          onClose={() => setShouldLinkTask(false)}
          onSubmit={handleTaskLink}
        />
      )}
    </CardView>
  )
}

/**
 * Renders the time tracking
 *
 * @param {any} task task object
 * @param {any} reload reloads the page
 *
 * @returns ReactElement
 */
const TimeTracking = ({ task, reload, handler }) => {
  const service = useService()

  const handleTimeRemove = async (trackedTime) => {
    const [result, error] = await service.delete(
      `tasks/${task.id}/time/${trackedTime.id}`
    )
    if (result && !error) {
      reload()
      handler.reload()
    }

    return [result, error]
  }

  return (
    <CardView style={{ padding: '0px' }}>
      <Container
        title="Time tracking"
        flex
        style={{ padding: '15px' }}
        repel
      ></Container>
      <Box top nopadding>
        <PagedDataSource
          url={`/tasks/${task.id}/time`}
          limit={5}
          handler={handler}
        >
          {({ data }) => (
            <>
              {data &&
                data.map((e, i) => {
                  return (
                    <>
                      <Container
                        key={i}
                        flex
                        repel
                        padding="15px"
                        gap="10px"
                        style={{ alignItems: 'center' }}
                      >
                        <Row>
                          <GridFieldView
                            n={3}
                            m={3}
                            s={12}
                            label="User"
                            value={e.creator_user.fullname}
                          />
                          <GridFieldView
                            n={3}
                            m={3}
                            s={12}
                            label="Spent at"
                            value={formatDateTime(e.spent_at)}
                          />
                          <GridFieldView
                            n={3}
                            m={3}
                            s={12}
                            label="Spent time"
                            value={e.spent_time}
                          />
                        </Row>
                        <DeleteIconButton
                          onClick={() => handleTimeRemove(e)}
                          disabled={task.status === 'Closed'}
                        />
                      </Container>
                      <Divider nopadding />
                    </>
                  )
                })}
            </>
          )}
        </PagedDataSource>
      </Box>
    </CardView>
  )
}

/**
 * Renders the task`s person details
 *
 * @param {any} task task object
 *
 * @returns ReactElement
 */
const PersonalDetails = ({ task }) => {
  const { t } = useTranslation()
  const service = useService()
  // Handler for the person data source
  const personHandler = {}
  const [triggerBookingOverlay, setTriggerBookingOverlay] = useState(false)
  const [booking, setBooking] = useState(null)

  const fetch = async (params) =>
    await service.get('projects/bookings', [
      ...params,
      ...[{ task_id: task.id }, { revoked: false }],
    ])

  const formRequestBody = (values) => {
    const requestBody = {
      task_id: task.id,
      contact_id: values.member_id,
      comment: values.comment,
    }
    return requestBody
  }

  const handleBookingSubmit = async (values) => {
    const [result, error] = await service.post(
      `/projects/bookings/`,
      formRequestBody(values)
    )
    if (result && !error) {
      setBooking(null)
      setTriggerBookingOverlay(false)
      personHandler.reload()
    }

    return [result, error]
  }

  const handleBookingEdit = async (values) => {
    const [result, error] = await service.put(
      `/projects/bookings/${booking.id}/`,
      formRequestBody(values)
    )
    if (result && !error) {
      setBooking(null)
      setTriggerBookingOverlay(false)
      personHandler.reload()
    }

    return [result, error]
  }

  const handleBookingRevoke = async (bookingId) => {
    const [result, error] = await service.put(
      `/projects/bookings/${bookingId}/revoke/`
    )
    if (result && !error) {
      personHandler.reload()
    }

    return [result, error]
  }
  // Currently we did not include a contact picture because of a bug
  // If you have one contact and you add another the picture of the last contact
  // is not the picture of the added contact itself. So far no solution found.
  // Because of that currently we are showing only simple avatar.
  return (
    <CardView style={{ padding: '0px' }}>
      <Container flex repel style={{ padding: '15px' }}>
        <h1>{t('Personal')}</h1>
        <AddIconButton onClick={() => setTriggerBookingOverlay(true)} />
      </Container>
      <Box top nopadding>
        <DataSource fetch={fetch} handler={personHandler}>
          {({ data, loading }) =>
            !loading &&
            data?.map((e, i) => (
              <Container key={i} flex repel style={{ padding: '15px' }}>
                <Container flex gap="10px">
                  <Avatar name={e.contact.system_user.fullname} thumbnail />
                  <Column>
                    <h3>{e.contact.system_user.fullname}</h3>
                    <h4>{e.contact.job_title}</h4>
                    <h5>{e.contact.system_user?.email}</h5>
                    <h5>{e.contact.system_user?.phone_number}</h5>
                  </Column>
                </Container>
                <Container flex gap="10px">
                  <EditIconButton
                    onClick={() => {
                      setBooking(e)
                      setTriggerBookingOverlay(true)
                    }}
                  />
                  <DeleteIconButton onClick={() => handleBookingRevoke(e.id)} />
                </Container>
              </Container>
            ))
          }
        </DataSource>
      </Box>
      {triggerBookingOverlay && (
        <CreateTaskBookingOverlay
          open={triggerBookingOverlay}
          onClose={() => {
            setBooking(null)
            setTriggerBookingOverlay(false)
          }}
          onSubmit={handleBookingSubmit}
          onEdit={handleBookingEdit}
          task={task}
          booking={booking}
        />
      )}
    </CardView>
  )
}

/**
 * Renders the task`s equipment details
 *
 * @param {any} task task object
 *
 * @returns ReactElement
 */
const EquipmentDetails = ({ task }) => {
  const { t } = useTranslation()

  return (
    <CardView style={{ padding: '0px' }}>
      <Container style={{ padding: '15px' }}>
        <h1>{t('Equipment')}</h1>
      </Container>
      <Box top />
    </CardView>
  )
}

/**
 * Renders the task`s actions
 *
 * @param {any} task task object
 * @param {any} reload reloads the page
 *
 * @returns ReactElement
 */
const ActionsDetails = ({ task, reload, handler }) => {
  const { t } = useTranslation()

  return (
    <CardView style={{ padding: '0px' }}>
      <Container padding="15px">
        <h1>{t('Actions')}</h1>
      </Container>
      <Box nopadding top>
        <Row>
          <Column n={6} m={6} s={12} style={{ margin: '0px' }}>
            <Box right>
              <TaskConversation task={task} handler={handler} />
            </Box>
          </Column>
          <Column n={6} m={6} s={12} style={{ margin: '0px' }}>
            <Box>
              <TaskAssignee task={task} reload={reload} />
            </Box>
          </Column>
          <Column n={6} m={6} s={12} style={{ margin: '0px' }}>
            <Box right top>
              <TaskStatus task={task} reload={reload} />
            </Box>
          </Column>
          <Column n={6} m={6} s={12} style={{ margin: '0px' }}>
            <Box top>
              <TaskTags task={task} reload={reload} />
            </Box>
          </Column>
        </Row>
      </Box>
    </CardView>
  )
}

/**
 * Renders the task comment section
 *
 * @param {any} task task object
 * @param {any} handler reloads the timeline
 *
 * @returns ReactElement
 */
const TaskConversation = ({ task, handler }) => {
  const { t } = useTranslation()
  const service = useService()

  // Submits a comment
  const handleCommentSubmit = async (data, ctx) => {
    const [result, error] = await service.post(
      `/tasks/${task.id}/comments`,
      data
    )
    if (result && !error) {
      ctx.values.reset()
      handler.reload()
    }

    return [result, error]
  }

  return (
    <Form>
      <Container>
        <h3>{t('Task')}</h3>
        <Row>
          <GridFieldView n={12} m={12} s={12} label={t('Comment')}>
            <TextAreaField name="text" mandatory rows={3} />
          </GridFieldView>
          <GridFieldView n={12} m={12} s={12}>
            <Container flex gap="10px" style={{ paddingTop: '15px' }}>
              <SimpleFormAction onAdd={handleCommentSubmit} />
              <FileField name="attachments" />
            </Container>
          </GridFieldView>
        </Row>
      </Container>
    </Form>
  )
}

/**
 * Renders the task`s assignee
 *
 * @param {any} task task object
 * @param {any} reload reloads the page
 *
 * @returns ReactElement
 */
const TaskAssignee = ({ task, reload }) => {
  const { t } = useTranslation()
  const service = useService()
  // Reasigns the task`s assignee
  const handleAssigneeSubmit = async (data, ctx) => {
    const [result, error] = await service.put(`/tasks/${task.id}`, {
      assignee_user_id: data.assignee_user_id,
    })
    if (result && !error) {
      ctx.values.reset()
      reload()
    }

    return [result, error]
  }

  return (
    <Form>
      <Container>
        <h3>{t('Assignee')}</h3>
        <Row>
          <GridFieldView n={12} m={12} s={12} label={'Assign to'}>
            {task.assignee_user && <UserView user={task.assignee_user} />}
          </GridFieldView>
          <GridFieldView
            n={12}
            m={12}
            s={12}
            label={'Reasign to'}
            style={{ paddingTop: '15px' }}
          >
            <DataSource
              url={`/contacts/internal`}
              render={({ data }) => (
                <DropDownField
                  disabled={task.status === 'Closed'}
                  removable
                  mandatory
                  title={'Assignee'}
                  name="assignee_user_id"
                  items={data?.map((e) => ({
                    key: e.id,
                    title: e.fullname,
                  }))}
                />
              )}
            />
          </GridFieldView>
          <SimpleFormAction
            onAdd={handleAssigneeSubmit}
            style={{ paddingTop: '10px' }}
          />
        </Row>
      </Container>
    </Form>
  )
}

const TaskStatus = ({ task, reload }) => {
  const { t } = useTranslation()
  const service = useService()
  const priorities = [
    {
      key: 'Low',
      title: t('Low'),
    },
    {
      key: 'Medium',
      title: t('Medium'),
    },
    {
      key: 'High',
      title: t('High'),
    },
  ]
  // Reasigns the task`s assignee
  const handlePrioritySubmit = async (data, ctx) => {
    const [result, error] = await service.put(`/tasks/${task.id}`, {
      priority: data.priority,
    })
    if (result && !error) {
      ctx.values.reset()
      reload()
    }

    return [result, error]
  }

  return (
    <Form>
      <Container>
        <h3>{t('Status')}</h3>
        <Row>
          <GridFieldView n={12} m={12} s={12} label={'Priority'}>
            <Container flex gap="10px" style={{ alignItems: 'center' }}>
              {task.priority && <StatusField priority={task.priority} />}
              <DropDownField
                disabled={task.status === 'Closed'}
                removable
                mandatory
                title={'Priority'}
                name="priority"
                items={priorities}
              />
            </Container>
          </GridFieldView>
          <GridFieldView n={12} m={12} s={12} label={'Status'}>
            <StatusField priority={task.status} />
          </GridFieldView>
          <SimpleFormAction
            onAdd={handlePrioritySubmit}
            style={{ paddingTop: '10px' }}
          />
        </Row>
      </Container>
    </Form>
  )
}

const TaskTags = ({ task, reload }) => {
  const { t } = useTranslation()
  const service = useService()

  // Asigns a tag to the task
  const handleTagSubmit = async (data, ctx) => {
    const tags = []
    task.tags?.filter((e) => {
      tags.push(e)
    })
    const newTags =
      data.tag
        ?.split(',')
        .map((e) => e.trim())
        .filter((e) => e !== '') ?? []
    const [result, error] = await service.put(`/tasks/${task.id}`, {
      tags: [...tags, ...newTags],
    })
    if (result && !error) {
      ctx.values.reset()
      reload()
    }

    return [result, error]
  }

  // Remove a tag from a task
  const handleTagRemove = async (tag) => {
    const [result, error] = await service.put(`/tasks/${task.id}`, {
      tags: task.tags?.filter((e) => e !== tag),
    })
    if (result && !error) {
      reload()
    }

    return [result, error]
  }

  return (
    <Form data={{ tag: null }}>
      <Container>
        <h3>{t('Tags')}</h3>
        <Row>
          <GridFieldView
            n={12}
            m={12}
            s={12}
            label={'Add tags separated by comma'}
          >
            <TextField name="tag" mandatory rows={1} />
          </GridFieldView>
          <GridFieldView n={12} m={12} s={12} label={'Assigned tags'}>
            <Container flex>
              {task.tags?.map((e, i) => (
                <TagsField key={i} tag={e} onTagRemove={handleTagRemove} />
              ))}
            </Container>
          </GridFieldView>
          <SimpleFormAction
            onAdd={handleTagSubmit}
            style={{ paddingTop: '10px' }}
          />
        </Row>
      </Container>
    </Form>
  )
}

/**
 * Renders the task`s timeline
 *
 * @param {any} task task object
 * @param {any} handler reloads the timeline
 *
 * @returns ReactElement
 */
const Timeline = ({ task, handler }) => (
  <DataSource
    url={`tasks/${task.id}/comments`}
    handler={handler}
    render={({ data }) => {
      const comments = data?.map((r) => ({
        user: r.creator_user,
        from: null,
        to: null,
        name:
          r.text && r.attachments_snapshot?.length > 0
            ? 'comment/file'
            : r.text
            ? 'comment'
            : 'file',
        text: r.text,
        timestamp: r.created_at,
        attachments_snapshot: r.attachments_snapshot,
      }))

      return (
        comments && (
          <ActivityView activities={[...(task.activity ?? []), ...comments]} />
        )
      )
    }}
  />
)

/**
 * Renders the linking task overlay.
 *
 * @param {any} task task object
 * @param {any} open state of opening the overlay
 * @param {any} onClose state of closing the dialog
 * @param {any} onSubmit submits the form
 *
 * @returns ReactElement
 */
const LinkTaskOverlay = ({ task, open, onClose, onSubmit }) => {
  const [selectedTask, setSelectedTask] = useState(null)

  return (
    <OverlayForm
      data={{
        parent_task_id: task.id,
        linked_task_id: selectedTask?.id,
      }}
      title="Link existing task"
      open={open}
      onClose={() => onClose()}
    >
      <OverlayBody style={{ padding: '0px', minWidth: '800px' }}>
        <Box nopadding top>
          <Box>
            <Container style={{ paddingBottom: '5px' }}>
              <SearchInput />
            </Container>
            <PagedDataSource
              url="/tasks"
              params={[{ archived: false }]}
              limit={3}
            >
              {({ data }) => (
                <>
                  {data
                    ?.filter((e) => e.id !== task.id)
                    ?.map((e, i) => (
                      <TaskSelect
                        key={i}
                        task={e}
                        selectedTask={selectedTask}
                        onSelectChange={() => setSelectedTask(e)}
                      />
                    ))}
                </>
              )}
            </PagedDataSource>
          </Box>
        </Box>
        <Box nopadding top>
          <SimpleFormAction
            onAdd={onSubmit}
            disabled={selectedTask === null}
            style={{ padding: '15px' }}
          />
        </Box>
      </OverlayBody>
    </OverlayForm>
  )
}

/**
 * Renders the task`s tag field
 *
 * @param {any} tags task`s tags
 *
 * @returns ReactElement
 */
const TagsField = ({ tag, onTagRemove }) => {
  return (
    <Container flex>
      <div className={styles.tagsContainer}>
        {!onTagRemove ? (
          <Tag text={tag} />
        ) : (
          <Container flex gap="10px" style={{ alignItems: 'center' }}>
            <Tag text={tag} />
            <CancelIconButton
              onClick={() => onTagRemove(tag)}
              size="25px"
              noborder
              white
            />
          </Container>
        )}
      </div>
    </Container>
  )
}

const TaskSelect = ({ task, selectedTask, onSelectChange, disabled }) => {
  const { values } = useForm()
  const selected = selectedTask?.id === task.id
  return (
    <Container
      className={selected ? styles.selectedTask : styles.task}
      flex
      gap="10px"
      onClick={
        disabled
          ? null
          : () => {
              values.set('linked_task_id', task.id)
              onSelectChange(task)
            }
      }
    >
      <Row style={{ paddingBottom: '10px' }}>
        <GridFieldView n={12} m={12} s={12}>
          <h4>{task.title}</h4>
        </GridFieldView>
        <GridFieldView
          n={2.4}
          m={2.4}
          s={2.4}
          label={'Project'}
          value={task.project?.name}
        />
        <GridFieldView
          n={2.4}
          m={2.4}
          s={2.4}
          label={'Phase'}
          value={task.phase?.name}
        />
        <GridFieldView
          n={2.4}
          m={2.4}
          s={2.4}
          label={'Hours'}
          value={`${task.spent_time} / ${task.planned_time}`}
        />
        <GridFieldView n={2.4} m={2.4} s={2.4} label={'Priority'}>
          {task.priority && <StatusField priority={task.priority} />}
        </GridFieldView>
        <GridFieldView n={2.4} m={2.4} s={2.4} label="Status">
          <StatusField priority={task.status} />
        </GridFieldView>
      </Row>
    </Container>
  )
}
