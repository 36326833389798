import { useTranslation } from 'react-i18next'

import { FieldView, GridFieldView, SectionView } from 'common/widgets/view'
import { Row } from 'common/widgets/grid'

import { formatItemName, getEquipmentNavPath } from '../utils'

import { ItemTypeCommentView } from './type'

/**
 * Renders a simple view for all equipments.
 *
 * @param {any} equipment equipment data
 * @returns ReactElement
 */
export const EquipmentSimpleView = ({ equipment }) => {
  const { t } = useTranslation(['equipments'])
  const value = (e, dfl) => (e ? e : dfl !== undefined ? dfl : '-')

  return (
    <SectionView grow="0">
      <FieldView
        href={getEquipmentNavPath(equipment)}
        value={formatItemName(equipment)}
      />
      <ItemTypeCommentView equipment={equipment} />
      <Row>
        <GridFieldView
          n={4}
          s={6}
          label={t('Main group')}
          value={equipment.maincategory}
        />
        <GridFieldView
          n={4}
          s={6}
          label={t('Group')}
          value={equipment.category}
        />
        <GridFieldView
          n={4}
          s={6}
          label={t('Subgroup')}
          value={value(equipment.subcategory)}
        />
        <GridFieldView
          n={4}
          s={6}
          label={t('Cost center')}
          value={value(equipment.cost_center)}
        />
        <GridFieldView
          n={4}
          s={6}
          label={t('Accounting group')}
          value={t(value(equipment.accounting_group))}
        />
        <GridFieldView
          n={4}
          s={6}
          label={t('Inventory number')}
          value={t(value(equipment.inventory_number))}
        />
        {equipment.is_consumable && (
          <GridFieldView
            n={4}
            s={6}
            label={t('Reserved amount')}
            value={value(equipment.reserved)}
          />
        )}
      </Row>
      {!equipment.is_resource && (
        <>
          <h3>{t('Inventory')}</h3>
          <Row>
            <GridFieldView
              n={4}
              s={6}
              visible={equipment.is_consumable}
              label={t('Quantity unit')}
              value={value(equipment.quantity_unit)}
            />
            <GridFieldView
              n={4}
              s={6}
              visible={equipment.is_consumable}
              label={t('Storage')}
              value={value(equipment.storage)}
            />
            <GridFieldView
              visible={equipment.is_formwork}
              n={4}
              s={6}
              label={t('Price when new')}
              value={value(equipment.cost_rate)}
            />
            <GridFieldView
              visible={equipment.is_formwork}
              n={4}
              s={6}
              label={t('Total stock')}
              value={equipment.total_amount}
            />
            <GridFieldView
              visible={equipment.is_consumable || equipment.is_formwork}
              n={4}
              s={6}
              label={t('Stock level')}
              value={equipment.stock_level}
            />
            <GridFieldView
              visible={equipment.is_consumable}
              n={4}
              s={6}
              label={t('Internal billing rate')}
              value={value(equipment.internal_billing_rate)}
            />
          </Row>
        </>
      )}
      {equipment.is_formwork && (
        <>
          <h3>{t('Dimensions and cargo')}</h3>
          <Row>
            <GridFieldView
              n={4}
              s={6}
              label={t('Packaging unit')}
              value={value(equipment.packaging_unit)}
            />
            <GridFieldView
              n={4}
              s={6}
              label={t('Transportation')}
              value={value(equipment.transportation)}
            />
            <GridFieldView
              n={4}
              s={6}
              label={t('Clear inner dimensions')}
              value={value(equipment.dimensions_1)}
            />
            <GridFieldView
              n={4}
              s={6}
              label={t('Outer dimensions')}
              value={value(equipment.dimensions_2)}
            />
            <GridFieldView
              n={4}
              s={6}
              label={t('Maximum outer dimensions')}
              value={value(equipment.dimensions_3)}
            />
          </Row>
        </>
      )}
    </SectionView>
  )
}
