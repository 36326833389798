import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { Info } from 'react-feather'

import { Column, Row } from 'common/widgets/grid'
import { CardView, GridFieldView, GridLinkFieldView } from 'common/widgets/view'
import { useQueryParams } from 'common/utils/routing'
import { useService } from 'common/service/context'
import { Form, useForm } from 'common/widgets/form/context'
import {
  DateField,
  DropDownField,
  FieldWrapper,
  TextField,
  TextAreaField,
} from 'common/widgets/form/field'
import { DataSource } from 'common/widgets/data-source'
import { Select } from 'common/widgets/select'
import { Container } from 'common/widgets/container'
import { DocumentsView } from 'common/widgets/document'
import { toDate, differenceInDays } from 'common/utils/date'
import {
  CancelButton,
  NoButton,
  SubmitButton,
  YesButton,
} from 'common/widgets/button'
import { dateToISOString, formatDate } from 'common/utils/format'
import { OverlayBody, OverlayForm } from 'common/widgets/overlay'
import { getEquipmentNavPath } from 'modules/master-data/equipments/utils'
import { AddressMap } from 'common/widgets/map/address'

import { OnGoingTasks, TaskHistory } from './history'

export const MaintenanceTaskPlanPage = () => {
  const { t } = useTranslation()
  const service = useService()
  const { resourceId, taskId, start, end, damage } = useQueryParams()
  const resourceFetch = async () =>
    await service.get(`/items/resources/${resourceId}`)

  const title = `${
    damage === 'true'
      ? t('Plan damage report')
      : taskId
      ? t('Edit task')
      : t('Plan task')
  }`

  return (
    <DataSource fetch={resourceFetch}>
      {({ data }) => (
        <Container title={title}>
          {data && (
            <TaskDetails
              resource={data}
              taskId={taskId}
              start={start}
              end={end}
            />
          )}
        </Container>
      )}
    </DataSource>
  )
}

const TaskDetails = ({ resource, taskId, start, end }) => {
  const service = useService()
  const taskFetch = async () =>
    await service.get(`/maintenance/tasks/${taskId}`)

  return taskId ? (
    <DataSource fetch={taskFetch}>
      {({ data }) =>
        data && (
          <Row>
            <Column n={6} m={6} s={12}>
              <TaskEdit resource={resource} task={data} />
            </Column>
            <Column n={6} m={6} s={12}>
              <AddressMap
                address={{
                  lat: resource.last_location?.gps_lat,
                  lng: resource.last_location?.gps_lon,
                }}
              />
            </Column>
            <Column n={6} m={6} s={12}>
              <CardView>
                <TaskHistory resource={resource} white />
              </CardView>
            </Column>
            <Column n={6} m={6} s={12}>
              <CardView>
                <OnGoingTasks resource={resource} white />
              </CardView>
            </Column>
          </Row>
        )
      }
    </DataSource>
  ) : (
    <Row>
      <Column n={6} m={6} s={12}>
        <TaskPlan resource={resource} start={start} end={end} />
      </Column>
      <Column n={6} m={6} s={12}>
        <AddressMap
          address={{
            lat: resource.last_location?.gps_lat,
            lng: resource.last_location?.gps_lon,
          }}
        />
      </Column>
      <Column n={6} m={6} s={12}>
        <CardView>
          <TaskHistory resource={resource} white />
        </CardView>
      </Column>
      <Column n={6} m={6} s={12}>
        <CardView>
          <OnGoingTasks resource={resource} white />
        </CardView>
      </Column>
    </Row>
  )
}

/**
 * Renders the task planning wrapper.
 *
 * @param {any} resource resource object
 * @param {date} start starting date
 * @param {date} end ending date
 *
 * @returns ReactElemnt
 */
const TaskPlan = ({ resource, start, end }) => {
  var resourceName
  if (resource?.inventory_number) {
    resourceName = `${resource?.inventory_number} - ${resource?.name}`
  } else {
    resourceName = `${resource?.name}`
  }
  const formData = {
    name: resourceName,
    resource: resource,
    start: toDate(start),
    end: toDate(end),
    task_type: null,
    task_subtype: null,
    performer_id: null,
    performer_name: null,
    periodic_on: 0,
    comment: null,
  }

  return (
    <Form data={formData}>
      <TaskPlanForm />
    </Form>
  )
}

/**
 * Renders the task planning form.
 *
 * @returns ReactElemnt
 */
const TaskPlanForm = () => {
  const { t } = useTranslation()
  const service = useService()
  const navigate = useNavigate()
  const { values, ready } = useForm()
  const usersFetch = async () => await service.get('users')
  const taskTypes = {
    planned_repair: ['tyre_change', 'service', 'others'],
    check_electrical_device: ['initial_check', 'visual_check', 'recheck'],
    UVV_check: [],
    visual_check: [],
    expert_check: [],
    other_check: [],
  }
  /// Periodic types for planning a task
  const periodicTypes = {
    DAILY: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
    WEEKLY: [0, 1, 2, 3, 4, 5, 6],
    MONTHLY: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
    YEARLY: [0, 1, 2, 3, 4, 5],
  }

  const handleTaskSubmit = async () => {
    const requestBody = {
      baseitem_id: values.json.resource.id,
      start: dateToISOString(values.json.start),
      task_type: values.json.task_type,
      task_subtype: values.json.task_subtype,
      duration:
        values.json.task_type === 'planned_repair'
          ? differenceInDays(values.json.end, values.json.start)
          : 0,
      performer_id: values.json.performer_id,
      performer_name: values.json.performer_name,
      comment: values.json.comment,
    }
    if (values.json.periodic_on) {
      requestBody['periodic_on'] = values.json.periodic_on
      requestBody['periodic_end'] = values.json.periodic_end
      requestBody['periodic_unit'] = values.json.periodic_unit
      requestBody['periodic_every'] = values.json.periodic_every
    }

    const [result, error] = await service.post(
      '/maintenance/plans',
      requestBody
    )
    if (result && !error) {
      navigate(-1)
    }
  }

  return (
    <Container flex vertical gap="10px">
      <CardView>
        <Row>
          <GridLinkFieldView
            n={12}
            label={t('Name')}
            value={
              <Container flex>
                <h4>{values.json.name}</h4>
                <Info height={10} />
              </Container>
            }
            url={getEquipmentNavPath(values.json.resource)}
          />
          <GridFieldView n={6} label={t('Task type') + '*'}>
            <DropDownField
              removable
              name="task_type"
              mandatory
              items={Object.keys(taskTypes).map((e) => ({
                key: e,
                title: `${t(e)}`,
              }))}
              selectedIndex={Object.keys(taskTypes).findIndex(
                (r) => r === values?.json?.task_type
              )}
              minWidth="276px"
            />
          </GridFieldView>
          <GridFieldView
            n={6}
            label={
              t('Task subtype') +
              (values.json.task_type &&
              taskTypes[values.json.task_type].length > 0
                ? '*'
                : '')
            }
          >
            {values.json.task_type &&
              taskTypes[values.json.task_type].length > 0 && (
                <DropDownField
                  name="task_subtype"
                  removable
                  mandatory
                  items={taskTypes[values.json.task_type].map((e) => ({
                    key: e,
                    title: `${t(e)}`,
                  }))}
                  selectedIndex={taskTypes[values.json.task_type].findIndex(
                    (r) => r === values.json.task_subtype
                  )}
                  minWidth="276px"
                />
              )}
          </GridFieldView>
          <GridFieldView
            n={values.json.task_type === 'planned_repair' ? 6 : 12}
            label={t('Planned from') + '*'}
          >
            <DateField name="start" mandatory />
          </GridFieldView>
          {values.json.task_type === 'planned_repair' && (
            <GridFieldView n={6} label={t('Inspected on')}>
              <DateField
                name="end"
                inclusive
                mandatory={values.json.task_type === 'planned_repair'}
                validate={(date) => {
                  if (values.json.task_type !== 'planned_repair') {
                    return null
                  }
                  if (toDate(values.json.start) > toDate(date)) {
                    return t('The date is before the scheduled date')
                  }
                }}
              />
            </GridFieldView>
          )}
          <GridFieldView n={6} label={`${t('Internal tester')}*`}>
            <DataSource
              fetch={usersFetch}
              render={({ data }) => (
                <DropDownField
                  removable
                  disabled={values.json.performer_name}
                  name="performer_id"
                  items={
                    data
                      ? data.map((e) => ({
                          key: e?.id,
                          title: `${e?.first_name} ${e?.last_name}`,
                        }))
                      : []
                  }
                  selectedIndex={data?.findIndex(
                    (r) => r.id === values.json.performer_id
                  )}
                  minWidth="276px"
                />
              )}
            />
          </GridFieldView>
          <GridFieldView n={6} label={`${t('External tester')}*`}>
            <TextField
              name="performer_name"
              disabled={values.json.performer_id}
            />
          </GridFieldView>
          {values.json.task_type !== 'planned_repair' && (
            <GridFieldView n={12} label={t('Recurring exam')}>
              <TriggerPeriodField />
            </GridFieldView>
          )}
          {values.json.task_type !== 'planned_repair' &&
            values.json.periodic_on && (
              <GridFieldView n={6} label={t('Last recurring')}>
                <DateField
                  name="periodic_end"
                  disabled={!values.json.periodic_on}
                  mandatory={values.json.periodic_on}
                  excludeTime
                />
              </GridFieldView>
            )}
          {values.json.task_type !== 'planned_repair' &&
            values.json.periodic_on && (
              <GridFieldView n={3} label={t('Period')}>
                <DropDownField
                  removable
                  disabled={!values.json.periodic_on}
                  name="periodic_unit"
                  mandatory={values.json.periodic_on}
                  items={Object.keys(periodicTypes).map((e) => ({
                    key: e,
                    title: `${t(e)}`,
                  }))}
                  selectedIndex={Object.keys(periodicTypes).findIndex(
                    (r) => r === values.json.periodic_unit
                  )}
                  minWidth="276px"
                />
              </GridFieldView>
            )}
          {values.json.task_type !== 'planned_repair' &&
            values.json.periodic_on && (
              <GridFieldView n={3} label={t('Every')}>
                <DropDownField
                  removable
                  disabled={!values.json.periodic_on}
                  name="periodic_every"
                  mandatory={values.json.periodic_on}
                  items={
                    !values.json.periodic_unit
                      ? []
                      : periodicTypes[values.json.periodic_unit].map((e) => ({
                          key: e,
                          title: `${t(e)}`,
                        }))
                  }
                  selectedIndex={periodicTypes[
                    values.json.periodic_unit
                  ]?.findIndex((r) => r === values.json.periodic_every)}
                  minWidth="276px"
                />
              </GridFieldView>
            )}
          <GridFieldView n={12} label={t('Comment')}>
            <TextAreaField name="comment" disabled={false} rows={5} />
          </GridFieldView>
        </Row>
      </CardView>
      <SubmitButton
        default
        text={t('Complete')}
        disabled={
          (!ready &&
            !(
              values.json.periodic_on === 0 &&
              !(
                values.json.periodic_end &&
                values.json.periodic_every &&
                values.json.periodic_unit
              )
            )) ||
          (values.json.task_type === 'planned_repair' && !values.json.end) ||
          (values.json.task_type === 'planned_repair' &&
            toDate(values.json.start)?.getTime() >=
              toDate(values.json.end)?.getTime()) ||
          !(values.json.performer_name || values.json.performer_id)
        }
        onClick={handleTaskSubmit}
      />
    </Container>
  )
}

/**
 * Renders the task edit wrapper.
 *
 * @param {any} resource resource object
 * @param {any} task task object
 *
 * @returns ReactElemnt
 */
const TaskEdit = ({ resource, task }) => {
  var resourceName
  if (resource?.inventory_number) {
    resourceName = `${resource?.inventory_number} - ${resource?.name}`
  } else {
    resourceName = `${resource?.name}`
  }
  const formData = {
    name: resourceName,
    resource: resource,
    start: task?.plan_date,
    end: task?.done_date ? task?.done_date : task?.end_date,
    task_type: task?.plan?.task_type,
    task_subtype: task?.plan?.task_subtype,
    duration:
      task?.plan?.task_type === 'planned_repair'
        ? differenceInDays(task?.plan_date, task?.done_date)
        : differenceInDays(task?.plan_date, task?.end_date),
    done: task?.done_date,
    ok: task?.ok ? true : false,
    recheck: false,
    performer_id: task?.performer_id,
    performer_name: task?.performer_name,
    comment: task?.comment,
    damage_comment: task?.damage_report?.comment,
  }

  if (task?.plan?.task_type === 'planned_repair') {
    formData['done'] = task?.done_date
  }

  return (
    <Form data={formData}>
      <TaskEditForm task={task} />
    </Form>
  )
}

/**
 * Renders the task edit form.
 *
 * @param {any} task task object
 *
 * @returns ReactElemnt
 */
const TaskEditForm = ({ task }) => {
  const { t } = useTranslation()
  const service = useService()
  const navigate = useNavigate()
  const [taskForCancel, setTaskForCancel] = useState(null)
  const { values, ready } = useForm()
  const damageReport = task?.damage_report
  const usersFetch = async () => await service.get('users')
  const taskTypes = {
    planned_repair: ['tyre_change', 'service', 'others'],
    check_electrical_device: ['initial_check', 'visual_check', 'recheck'],
    UVV_check: [],
    visual_check: [],
    expert_check: [],
    other_check: [],
    damage: [],
  }

  const handleTaskSubmit = async () => {
    const doneDate =
      values.json.task_type === 'planned_repair'
        ? dateToISOString(values.json.done)
        : dateToISOString(values.json.end)
    const requestBody = {
      baseitem_id: values.json.resource.id,
      plan_date: dateToISOString(values.json.start),
      duration:
        values.json.task_type === 'planned_repair'
          ? differenceInDays(values.json.end, values.json.start)
          : 0,
      done_date: doneDate,
      task_type: values.json.task_type,
      task_subtype: values.json.task_subtype,
      performer_id: values.json.performer_id,
      performer_name:
        values.json.performer_id !== null ? null : values.json.performer_name,
      comment: values.json.comment,
      ok: values.json.ok,
    }
    if (values.json.recheck) {
      requestBody['recheck'] = values.json.recheck
      requestBody['repeat_date'] = values.json.repeat_date
    }

    const [result, error] = await service.put(
      `/maintenance/tasks/${task.id}`,
      requestBody
    )
    if (result && !error) {
      navigate(-1)
    }
  }

  return (
    <>
      <CardView>
        <Row>
          <GridLinkFieldView
            n={12}
            label={t('Name')}
            value={
              <Container flex>
                <h4>{values.json.name}</h4>
                <Info height={10} />
              </Container>
            }
            url={getEquipmentNavPath(values.json.resource)}
          />
          <GridFieldView n={6} label={t('Task type') + '*'}>
            <DropDownField
              name="task_type"
              disabled={damageReport}
              removable
              mandatory
              items={Object.keys(taskTypes).map((e) => ({
                key: e,
                title: `${t(e)}`,
              }))}
              selectedIndex={Object.keys(taskTypes).findIndex(
                (r) => r === values.json.task_type
              )}
              minWidth="276px"
            />
          </GridFieldView>
          <GridFieldView
            n={6}
            label={
              t('Task subtype') +
              (values.json.task_type &&
              taskTypes[values.json.task_type].length > 0
                ? '*'
                : '')
            }
          >
            {values.json.task_type &&
              taskTypes[values.json.task_type].length > 0 && (
                <DropDownField
                  name="task_subtype"
                  disabled={damageReport}
                  removable
                  mandatory
                  items={taskTypes[values.json.task_type].map((e) => ({
                    key: e,
                    title: `${t(e)}`,
                  }))}
                  selectedIndex={taskTypes[values.json.task_type].findIndex(
                    (r) => r === values.json.task_subtype
                  )}
                  minWidth="276px"
                />
              )}
          </GridFieldView>
          <GridFieldView n={6} label={t('Planned from') + '*'}>
            <DateField
              name="start"
              disabled={damageReport}
              mandatory
              excludeTime
            />
          </GridFieldView>
          <GridFieldView n={6} label={t('End of examination') + '*'}>
            <DateField
              name="end"
              disabled={false}
              mandatory
              excludeTime
              validate={(date) => {
                if (toDate(values.json.start) > toDate(date)) {
                  return t('The date is before the scheduled date')
                }

                return null
              }}
            />
          </GridFieldView>
          {values.json.task_type === 'planned_repair' && (
            <GridFieldView n={12} label={t('Performed on')}>
              <DateField name="done" disabled={false} excludeTime />
            </GridFieldView>
          )}
          <GridFieldView n={6} label={`${t('Internal tester')}*`}>
            <DataSource
              fetch={usersFetch}
              render={({ data }) => (
                <DropDownField
                  disabled={values.json.performer_name}
                  removable
                  name="performer_id"
                  items={
                    data
                      ? data.map((e) => ({
                          key: e?.id,
                          title: `${e?.first_name} ${e?.last_name}`,
                        }))
                      : []
                  }
                  selectedIndex={data?.findIndex(
                    (e) => e.id === values.json.performer_id
                  )}
                  minWidth="276px"
                />
              )}
            />
          </GridFieldView>
          <GridFieldView n={6} label={`${t('External tester')}*`}>
            <TextField
              name="performer_name"
              disabled={values.json.performer_id}
            />
          </GridFieldView>
          <GridFieldView n={12} label={t('Task documents')}>
            <DocumentsView docRef={`maintenance-plan=${task?.plan_id}`} />
          </GridFieldView>
          {damageReport && (
            <GridFieldView n={12} label={t('Damage report documents')}>
              <DocumentsView docRef={`damage=${damageReport.id}`} readonly />
            </GridFieldView>
          )}
          <GridFieldView n={12} label={t('Task Result')}>
            <TaskResultField enable />
          </GridFieldView>
          <GridFieldView n={12} label={t('Re-examination')}>
            <TriggerReExaminationField />
          </GridFieldView>
          {values.json.recheck && (
            <GridFieldView n={6} label={t('Re-examination date')}>
              <DateField
                name="repeat_date"
                disabled={!values.json.recheck}
                mandatory={values.json.recheck}
                excludeTime
              />
            </GridFieldView>
          )}
          <GridFieldView n={12} label={t('Comment')}>
            <TextAreaField name="comment" disabled={false} rows={5} />
          </GridFieldView>
          {damageReport && (
            <GridFieldView n={12} label={t('Damage report comment')}>
              <TextAreaField name="damage_comment" disabled rows={5} />
            </GridFieldView>
          )}
        </Row>
      </CardView>
      <Row style={{ paddingTop: '10px', gap: '10px' }}>
        <SubmitButton
          default
          text={t('Complete')}
          disabled={
            (!ready &&
              (values.json.recheck || values.json.repeat_date) &&
              !(
                values.json.task_type === 'planned_repair' && values.json.done
              ) &&
              !(values.json.performer_name && values.json.performer_id)) ||
            !(values.json.performer_name || values.json.performer_id) ||
            (damageReport && !values.json.end)
          }
          onClick={handleTaskSubmit}
        />
        <CancelButton
          danger
          onClick={() => setTaskForCancel(task)}
          text={t('Cancel task')}
        />
      </Row>
      <CancelTaskOverlay
        task={taskForCancel}
        onClose={() => setTaskForCancel(null)}
      />
    </>
  )
}

/**
 * Renders a trigger for re-examination of a tasks.
 *
 * @returns ReactElemnt
 */
const TriggerReExaminationField = () => {
  const { t } = useTranslation()

  const items = {
    Yes: true,
    No: false,
  }
  const mapped = Object.entries(items).map(([title, key]) => {
    return { key, title: t(title) }
  })

  return (
    <FieldWrapper name="recheck">
      {(getValue, setValue) => (
        <Select
          selected={getValue()}
          items={mapped}
          onSelectChange={(item) => {
            setValue(item.key)
          }}
        />
      )}
    </FieldWrapper>
  )
}

/**
 * Renders a trigger for periodic planning of tasks.
 *
 * @returns ReactElemnt
 */
const TriggerPeriodField = () => {
  const { t } = useTranslation()

  const items = {
    Yes: 1,
    No: 0,
  }
  const mapped = Object.entries(items).map(([title, key]) => {
    return { key, title: t(title) }
  })

  return (
    <FieldWrapper name="periodic_on">
      {(getValue, setValue) => (
        <Select
          selected={getValue()}
          items={mapped}
          onSelectChange={(item) => {
            setValue(item.key)
          }}
        />
      )}
    </FieldWrapper>
  )
}

/**
 * Renders Planned task result.
 *
 * @param {bool} enable is the widget enabled or not
 *
 * @returns ReactElemnt
 */
const TaskResultField = ({ enable }) => {
  const { t } = useTranslation()

  const items = {
    Successful: true,
    'Not Successful': false,
  }
  const mapped = Object.entries(items).map(([title, key]) => {
    return { key, title: t(title) }
  })

  return (
    <FieldWrapper name="ok">
      {(getValue, setValue) => (
        <Select
          selected={getValue()}
          items={mapped}
          onSelectChange={(item) => {
            if (enable === true) {
              setValue(item.key)
            }
          }}
        />
      )}
    </FieldWrapper>
  )
}

/**
 * Renders the task cancelation overlay.
 *
 * @param {any} task task object
 * @param {any} onClose state of closing the dialog
 *
 * @returns ReactElement
 */
const CancelTaskOverlay = ({ task, onClose }) => {
  const { t } = useTranslation()
  const service = useService()
  const navigate = useNavigate()

  const handleTaskCancelation = async () => {
    const [result, error] = await service.delete(
      `/maintenance/tasks/${task?.id}?hard-delete=true`
    )
    if (result && !error) {
      onClose()
      navigate(-1)
    }

    return [result, error]
  }

  return (
    <OverlayForm open={task} onClose={onClose} title="Cancel task">
      <OverlayBody>
        <Row>
          <GridFieldView
            n={4}
            label={t('RESOURCE')}
            value={`${task?.plan?.baseitem?.name}`}
          />
          <GridFieldView
            n={4}
            label={t('Planned from')}
            value={formatDate(toDate(task?.plan_date))}
          />
          <GridFieldView
            n={4}
            label={t('Planned till')}
            value={formatDate(
              toDate(task?.done_date ? task?.done_date : task?.end_date),
              { friendly: true }
            )}
          />
          <GridFieldView
            n={4}
            label={t('Tester')}
            value={
              task?.performer_name
                ? task?.performer_name
                : task?.performer?.fullname
            }
          />
          <GridFieldView
            n={4}
            label={t('Task type')}
            value={t(task?.plan?.task_type)}
          />
          <GridFieldView
            n={4}
            label={t('Task subtype')}
            value={t(task?.plan?.task_subtype)}
          />
        </Row>
        <Container flex repel>
          <YesButton onClick={handleTaskCancelation} />
          <NoButton onClick={onClose} />
        </Container>
      </OverlayBody>
    </OverlayForm>
  )
}
