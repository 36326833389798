import { register } from 'system/container'

import { FormworksAddPage } from './add'
import { FormworksListPage } from './list'
import { FormworksEditPage } from './edit'
import { FormworkViewPage } from './view'

// Registers projects page inside system container.
register({
  render: () => <FormworksAddPage />,
  path: '/master-data/equipments/formworks/add',
  title: 'Neu',
})

// Registers formworks edit page inside system container.
register({
  render: () => <FormworksEditPage />,
  path: '/master-data/equipments/formworks/:id/edit',
  title: 'Edit',
})

// Registers formworks page
register({
  menu: true,
  path: '/master-data/equipments/formworks',
  title: 'Formworks',
  render: () => <FormworksListPage />,
})

register({
  render: () => <FormworkViewPage />,
  title: 'Details',
  path: '/master-data/equipments/formworks/:id',
  priority: 0.0,
})
