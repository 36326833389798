import { register } from 'system/container'

import { ContactStateAddPage } from './add'
import { ContactStateEditPage } from './edit'
import { StatesListPage } from './list'
import { ContactStateViewPage } from './view'

register({
  render: () => <ContactStateAddPage />,
  title: 'New',
  path: '/master-data/contacts/states/add',
})

register({
  render: () => <ContactStateEditPage />,
  title: 'Edit',
  path: '/master-data/contacts/states/:id/edit',
})

register({
  menu: true,
  render: () => <StatesListPage />,
  title: 'States',
  path: '/master-data/contacts/states',
  priority: 0.0,
})
register({
  render: () => <ContactStateViewPage />,
  title: 'Details',
  path: '/master-data/contacts/states/:id',
  priority: 0.0,
})
