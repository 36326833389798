import { useParams } from 'react-router-dom'

import { Form } from 'common/widgets/form/context'
import { SystemPage } from 'system/page/core'
import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'

import { RoleFormContent } from './form'

/**
 * Renders role edit page.
 *
 * @returns ReactElement
 */
export const RoleEditPage = () => {
  const { id } = useParams()
  const service = useService()

  const handleSave = async (values) => {
    return await service.put(`security/roles/${id}`, values)
  }

  const fetch = async () => await service.get(`/security/roles/${id}`)

  return (
    <DataSource
      fetch={fetch}
      render={({ data, error, loading }) => (
        <SystemPage>
          {data && (
            <Form data={{ name: data.name }}>
              <RoleFormContent onUpdate={handleSave} />
            </Form>
          )}
        </SystemPage>
      )}
    />
  )
}
