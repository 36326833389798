import styled from 'styled-components'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { Card } from './card'
import { Container } from './container'

export const RemovableTag = ({ onRemove, children, ...rest }) => (
  <TagContainer border {...rest}>
    <Container flex gap="10px">
      <Container>{children}</Container>
      <X onClick={onRemove} />
    </Container>
  </TagContainer>
)

const TagContainer = styled(Card)`
  padding: 5px;
  svg {
    color: #808080;
    cursor: pointer;
    :hover {
      color: #303030;
    }
  }
`

export const Tag = ({ text, color, children }) => {
  const { t } = useTranslation()
  return (
    <StyledP bgColor={color}>
      {t(text)}
      {children}
    </StyledP>
  )
}

const StyledP = styled.p`
  height: 24px;
  min-width: 64px;
  border-radius: 5px;
  background-color: ${(props) => props?.bgColor ?? '#e32d2d'};
  color: white;
  font-size: small;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
`
