import { Form } from 'common/widgets/form/context'
import { SystemPage } from 'system/page/core'
import { useService } from 'common/service/context'

import { ContactQualificationFormContent } from './form'

export const ContactQualificationsAddPage = () => {
  const service = useService()

  /**
   * Calls a post call and creates a new contact present.
   *
   * @param {any} values contact present data
   * @returns
   */
  const handleAdd = async (values) => {
    const [result, error] = await service.post(
      `/contacts/defs/qualification-categories`,
      values
    )

    return [result, error]
  }

  return (
    <SystemPage>
      <Form>
        <ContactQualificationFormContent onAdd={handleAdd} />
      </Form>
    </SystemPage>
  )
}
