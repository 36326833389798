import { useNavigate } from 'react-router-dom'

import { TextField, CheckBoxField } from 'common/widgets/form/field'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { SectionView } from 'common/widgets/view'

/**
 * Renders roles' data entry form.
 *
 * @param {Function} onClose closing hook
 * @returns
 */
export const RoleFormContent = ({ onAdd, onUpdate, onRemove }) => {
  const navigate = useNavigate()

  const onSuccess = async (response, ctx) => {
    if (response?.data?.id && ctx.values.json?.goto) {
      navigate(`/security/roles/${response?.data?.id}`)
    } else {
      navigate('/security/roles')
    }
  }
  return (
    <>
      <SectionView title="Master data" grow="0">
        <TextField name="name" label="Name" mandatory />
        {!onRemove && (
          <CheckBoxField
            name="goto"
            text="Continue to scopes"
            defaultValue={!!onAdd}
          />
        )}
      </SectionView>
      <SimpleFormAction
        onAdd={onAdd}
        onUpdate={onUpdate}
        onRemove={onRemove}
        onSuccess={onSuccess}
      />
    </>
  )
}
