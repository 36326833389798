import { useState } from 'react'
import { Search } from 'react-feather'
import { useTranslation } from 'react-i18next'
import styled, { css, keyframes } from 'styled-components'

import { useDataSourceContext } from 'common/widgets/data-source'
import { useOverlayContext } from 'common/widgets/overlay'

import { Container } from './container'
import { Input } from './input'

const TIMEOUT = 700

/**
 * Renders generic search widget.
 *
 * @param {string} defaultValue value for search input
 * @param {Function} onChange text change handler
 * @param {boolean} nodelay forces search with no delay
 * @returns
 */
export const SearchInput = ({
  defaultValue,
  nodelay,
  loading,
  onChange,
  style,
}) => {
  const { t } = useTranslation()
  const [state, setState] = useState({
    timeout: null,
  })
  const [hasFocus, setHasFocus] = useState(!!defaultValue)
  const [lastResult, setLastResult] = useState(null)

  const stopPropagation = !!useOverlayContext()

  const ctx = useDataSourceContext()

  const effectiveOnChange =
    onChange ?? ((text) => ctx?.setQueryParameter('search', text))

  return (
    <SearchInputContainer
      onClick={(e) => {
        stopPropagation && e.stopPropagation()
        setHasFocus(true)
      }}
      onKeyDown={(e) => {
        if (e.code === 'Escape') {
          setHasFocus(false)
        } else {
          setHasFocus(true)
        }
      }}
      onBlur={(e) => setHasFocus(false)}
      hasFocus={hasFocus}
      style={style}
    >
      <StyledInput
        autoFocus={hasFocus}
        onMouseUp={(e) => stopPropagation && e.stopPropagation()}
        placeholder={t('Search') + '...'}
        onChange={(e) => {
          if (effectiveOnChange && !nodelay) {
            if (state.timeout) {
              clearTimeout(state.timeout)
            }
            setState({
              timeout: setTimeout(
                () => effectiveOnChange(e.target.value),
                TIMEOUT
              ),
            })
            setLastResult(e.target.value)
          } else if (effectiveOnChange) {
            effectiveOnChange(e.target.value)
            setLastResult(e.target.value)
          }
        }}
        onKeyDown={(e, i) =>
          e.key === 'Enter' ? effectiveOnChange(lastResult) : null
        }
        defaultValue={defaultValue}
      />
      <Container style={{ marginLeft: '-25px' }}>
        <Spinner loading={loading ? 'true' : 'false'} />
        <Search />
      </Container>
    </SearchInputContainer>
  )
}

const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  transition: 0.3s;

  div {
    svg {
      margin-top: 5px;
      margin-right: 5px;
      color: #8b8b8b;
      width: 20px;
      height: 20px;
    }
  }

  @media print {
    display: none;
  }
  ${(props) =>
    css`
      flex-grow: ${props.hasFocus ? 1 : 0};
    `}
`

const StyledInput = styled(Input)`
  flex: 1 1 auto;
  width: 100%;
  padding-left: 15px;
  border: none;
  background-color: unset;

  :focus {
    outline: none;
    border: none;
  }
`

const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(200, 200, 240, 1.0);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
`

const Spinner = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  margin-left: 2px;
  margin-top: 7px;
  opacity: ${(props) => (props.loading === 'true' ? 1 : 0)};
  transition: 0.1s;

  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  transform: scale(1);
  animation: ${pulse} 1s infinite;
`
