import { DetailViewPage } from 'system/utils/view'

import { CategoryForm } from './form'

export const CategoryView = () => {
  return (
    <DetailViewPage
      url="/pim/categories"
      navUrl="/master-data/categories"
      editable
    >
      {(data) => <CategoryForm data={data} readOnly />}
    </DetailViewPage>
  )
}
