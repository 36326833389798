import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { Container } from 'common/widgets/container'
import { Card } from 'common/widgets/card'
import { useService } from 'common/service/context'
import { UserRoleSelect } from 'modules/security/users/roles'
import { EditIconButton } from 'common/widgets/button'
import { DataSource } from 'common/widgets/data-source'
import { formatProjectName } from 'modules/projects/utils'
import { FieldView } from 'common/widgets/view'
import { formatDateRange } from 'common/utils/format'
import { useMediaQuery } from 'common/utils/hooks'

import styles from './toolbox.module.css'

/**
 * Renders a toolbox to see information about selected phase
 * @param {any} type project type instance
 * @param {any} phase phase instance
 * @param {Function} reload callback function to reload content
 * @param {Function} setBusy a function to mark page busy or idle
 * @returns ReactElement
 */
export const ProjectPhaseToolbox = ({ type, phase, reload, onEdit }) => {
  const service = useService()
  const { t } = useTranslation()
  // Check if we should display mobile view
  const isMobile = useMediaQuery('(max-width: 768px)')
  /**
   * Calls a delete call and removes the given next phase.
   *
   * @param {any} next next phase data
   * @returns
   */
  const handleRemoveNextPhase = async (next) => {
    phase.nexts = phase.nexts.filter((e) => e.id !== next.id)
    await service.delete(`/projects/phases/${phase.id}/nexts/${next.id}`)
    await reload()
  }

  /**
   * Updates project phase assigned roles.
   * @param {Array} roles list of new roles
   */
  const handleUpdateRoles = async (roles) => {
    phase.roles = roles
    await service.put(`projects/phases/${phase.id}/roles`, {
      role_ids: roles.map((e) => e.id),
    })
    await reload()
  }

  return (
    <Container
      className={styles.toolbox}
      flex
      vertical
      style={{ width: isMobile ? '70%' : '20%' }}
    >
      <Container className={styles.section} flex vertical>
        <h4>{type.name}</h4>
        <h5>{type.description}</h5>
      </Container>
      {phase && (
        <>
          <Container className={styles.section} flex vertical>
            <Container flex repel align="center">
              <h4>{phase.name}</h4>
              <EditIconButton onClick={onEdit} />
            </Container>
            {!phase?.is_template && (
              <h5>{formatDateRange(phase.start_date, phase.end_date)}</h5>
            )}
            <h5>{phase.description}</h5>
          </Container>
          {phase && (
            <Container className={styles.section}>
              <h5>{t('Roles')}</h5>
              <UserRoleSelect
                value={phase.roles}
                onChange={async (roles) => await handleUpdateRoles(roles)}
                minRoles={0}
              />
            </Container>
          )}
          {phase.nexts?.length > 0 && (
            <Container className={styles.section}>
              <h5>{t('Next phases')}</h5>
              <Container flex wrap gap="10px">
                {phase.nexts.map((e) => (
                  <Card
                    className={styles.nextPhase}
                    key={e.id}
                    color={e.color}
                    style={{ border: `0.5px solid ${(props) => props.color}` }}
                  >
                    <p>{e.name}</p>
                    {<X onClick={async () => handleRemoveNextPhase(e)} />}
                  </Card>
                ))}
              </Container>
            </Container>
          )}
          <DataSource url={`projects/minimal?phase_id=${phase.id}`}>
            {({ data }) => (
              <Container>
                {data && data.length > 0 && (
                  <Container className={styles.section}>
                    <h5>{t('Projects')}</h5>
                    {data.map((e) => (
                      <FieldView
                        key={e.id}
                        value={formatProjectName(e)}
                        href={`/projects/${e.id}`}
                      />
                    ))}
                  </Container>
                )}
              </Container>
            )}
          </DataSource>
        </>
      )}
    </Container>
  )
}
