.desktop {
  display: flex;
  align-items: center;
  width: 60px;
  height: 60px;
}
.desktop svg {
  margin: auto;
}

.mobile {
  display: flex;
  align-items: center;
  width: 60px;
  height: 60px;

  transition: 0.3s ease-in-out;
}
.mobile svg {
  color: #ffffff;
  margin: auto;
}

@media (min-width: 768px) {
  .mobile {
    display: none;
  }
}

.logo {
  width: 60px;
  height: 60px;
  border-bottom: 1px solid #5e8af6;
  background: #376df4;
  box-sizing: border-box;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  z-index: 210;
}
