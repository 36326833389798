import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useService } from 'common/service/context'
import { SimpleFormAction } from 'common/widgets/form/utils'
import {
  ColorPickerField,
  TextField,
  DateRangeField,
  TextAreaField,
} from 'common/widgets/form/field'
import { OverlayBody, OverlayFooter, OverlayForm } from 'common/widgets/overlay'

export const PhaseFormOverlay = ({ open, onClose, type, phase }) => {
  const { t } = useTranslation(['projects'])
  const service = useService()
  // Extracts type if from url path
  const { id } = useParams()

  /**
   * Creates a new project phase.
   * @returns Array
   */
  const handleAdd = async (data) => {
    const [result, error] = await service.post('projects/phases', {
      ...data,
      project_type_id: id,
    })
    if (!error) {
      onClose()
    }
    return [result, error]
  }

  /**
   * Updates the given phase with new data.
   * @returns Array
   */
  const handleUpdate = async (values) => {
    const [result, error] = await service.put(
      `projects/phases/${phase.id}`,
      values
    )
    if (!error) {
      onClose()
    }
    return [result, error]
  }

  /**
   * Makes a delete call and removes the given instance.
   * @returns Array
   */
  const handleRemove = async () => {
    const [result, error] = await service.delete(`/projects/phases/${phase.id}`)
    if (!error) {
      onClose()
    }
    return [result, error]
  }

  return (
    <OverlayForm
      open={open}
      title={phase ? t('Edit project phase') : t('Add project phase')}
      onClose={onClose}
      data={phase}
    >
      <OverlayBody>
        <TextField name="name" label="Name" mandatory />
        {!type?.is_template && (
          <DateRangeField
            label="Phase schedule"
            nameStart="start_date"
            nameEnd="end_date"
          />
        )}
        <ColorPickerField name="color" label="Color" />
        <TextAreaField name="description" label="Description" rows="4" />
      </OverlayBody>
      <OverlayFooter>
        <SimpleFormAction
          onAdd={phase ? null : handleAdd}
          onUpdate={phase ? handleUpdate : null}
          onRemove={phase ? handleRemove : null}
          onClose={onClose}
          onSuccess={onClose}
        />
      </OverlayFooter>
    </OverlayForm>
  )
}
