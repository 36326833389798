import { addTranslation } from 'common/i18n/core'

addTranslation('de', 'security', {
  'Send invitation E-Mail': 'Einladungs-E-Mail vesenden',
  'security.register.welcome': 'Willkommen in Orinoco!',
  'security.register.motto': 'Die digitale Betriebsmittelverwaltung',
  'security.register.strongpass':
    'Bitte wählen Sie ein starkes, sicheres Passwort mit min. 8 Buchstaben',
  'security.register.passmismatch': 'Die Passwörter stimmen nicht überein',
  'security.register.shortpass': 'Das Passwort ist zu kurz',
  'security.register.passlower':
    'Das Passwort muss min. eine Kleinbuchstabe erhalten',
  'security.register.passcaps':
    'Das Passwort muss min. eine Großbuchstabe erhalten',
  'security.register.passchars':
    'Das Passwort muss min. ein Sonderzeichen erhalten',
  'security.register.howto':
    'Bitte füllen Sie das Formular aus, um die Registrierung abzuschließen.',
  'security.register.howto.passreset': 'Bitte wählen Sie ein neues Passwort.',
})

addTranslation('en', 'security', {
  'security.register.welcome': 'Welcome to Orinoco!',
  'security.register.motto': 'The digital resource planner',
  'security.register.strongpass':
    'Please choose a strong passwort with at least 8 characters',
  'security.register.passmismatch': 'Passwords do not match',
  'security.register.shortpass': 'The password is too short',
  'security.register.passlower':
    'The password should contain at least one lowercase letter',
  'security.register.passcaps':
    'The password should contain at least one capital letter',
  'security.register.passchars':
    'The password should contain at least one symbol',
  'security.register.howto':
    'Please fill the form to complete the registration.',
  'security.register.howto.passreset': 'Please choose a new password.',
})
