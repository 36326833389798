import { Form } from 'common/widgets/form/context'
import { SystemPage } from 'system/page/core'
import { useSession } from 'common/session/context'
import { formatDate } from 'common/utils/format'
import { useQueryParams } from 'common/utils/routing'

import { AdHocFormBody } from './details'

export const AdHocDeliveryPage = () => {
  const { data } = useSession()
  const { baseitemId } = useQueryParams()

  return (
    <SystemPage>
      <Form
        data={{
          recipient: data.user.fullname,
          delivery_date: formatDate(new Date()),
          source_project_id: null,
          project_id: null,
          baseitemId: baseitemId,
        }}
      >
        <AdHocFormBody />
      </Form>
    </SystemPage>
  )
}
