import React from 'react'

import { register } from 'system/container'
import { SystemPage } from 'system/page/core'

import { TransportList } from './list'
import { TransportsOverviewPage } from './add'

register({
  render: () => (
    <SystemPage>
      <TransportList />
    </SystemPage>
  ),
  menu: true,
  title: 'Transports',
  path: '/logistics/planning/transports',
  priority: 0.1,
})

register({
  render: () => (
    <SystemPage>
      <TransportsOverviewPage />
    </SystemPage>
  ),
  menu: false,
  title: 'New Tours',
  path: '/logistics/planning/transports/:date',
  priority: 0.1,
})
