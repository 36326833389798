import { createContext, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useQueryParams } from 'common/utils/routing'

const DataTableContext = createContext()

/**
 * Provide data table context for child elements
 *
 * @param {children} list  list of react elements
 * @returns
 */
export const DataTableProvider = ({ name, children }) => {
  const key = name ? `dt${name}` : 'dt'
  const searchKey = `${key}rowId`
  const [, setSearch] = useSearchParams()
  const rowId = useQueryParams()[searchKey]

  return (
    <DataTableContext.Provider
      value={{
        lastClickedRow: rowId,
        setLastClickedRow: (rowId) => setSearch({ [searchKey]: rowId }),
      }}
    >
      {children}
    </DataTableContext.Provider>
  )
}

/**
 * Returns the provided data table context.
 *
 * @returns DataTableContext
 */
export const useDataTable = () => useContext(DataTableContext)
