import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { register } from 'system/container'
import { DetailViewPage } from 'system/utils/view'
import { Container } from 'common/widgets/container'
import { Row, Column } from 'common/widgets/grid'
import {
  FieldView,
  GridFieldView,
  GridLinkFieldView,
  SectionView,
} from 'common/widgets/view'
import { Tab } from 'common/widgets/tab'
import { DeliveryDataTable } from 'modules/yard/commission/deliveries/list'
import { CancelIconButton } from 'common/widgets/button'
import { Barcode } from 'common/widgets/barcode'
import { formatDateTime } from 'common/utils/format'

import { OrderDates } from './order-dates'
import { OrderCancelConfirmOverlay } from './overlays'
import { OrderedItems } from './ordered-items'

const OrderDetailsViewPage = () => {
  const { t } = useTranslation(['orders'])
  const [cancelOrderOverlayVisible, setCancelOrderOverlayVisible] =
    useState(false)

  return (
    <DetailViewPage
      url="/orders"
      title={(order) =>
        `${t('Ordered item number')}: ${order.ordered_items[0]?.id}`
      }
      editable={(order) => order?.editable}
      archivable={(order) => order?.archivable}
      addExtraButtons={(order) =>
        order?.cancelable && (
          <Container flex align="center">
            <CancelIconButton
              onClick={() => {
                setCancelOrderOverlayVisible(true)
              }}
            />
            <OrderCancelConfirmOverlay
              open={cancelOrderOverlayVisible}
              onClose={() => setCancelOrderOverlayVisible(false)}
              order={order}
            />
          </Container>
        )
      }
    >
      {(order, reload) => (
        <>
          <OrderViewData order={order} />
          <Tab
            items={[
              {
                key: 'ordered_items',
                title: t('Ordered items'),
                render: () => <OrderedItemsTab order={order} reload={reload} />,
              },
              {
                key: 'delivery_notes',
                title: t('Delivery notes'),
                render: () => <DeliveryDataTable order={order} />,
              },
            ]}
          />
        </>
      )}
    </DetailViewPage>
  )
}

const OrderedItemsTab = ({ order, reload }) => {
  const resources = order?.ordered_items?.filter(
    (item) => item.item.is_resource
  )
  const formworks = order?.ordered_items?.filter(
    (item) => item.item.is_formwork
  )
  const consumables = order?.ordered_items?.filter(
    (item) => item.item.is_consumable
  )
  return (
    <Container flex vertical>
      {resources?.length > 0 && (
        <OrderedItems
          reload={reload}
          orderedItems={resources}
          title={'RESOURCE'}
        />
      )}
      {formworks?.length > 0 && (
        <OrderedItems
          reload={reload}
          orderedItems={formworks}
          title={'FORMWORK'}
        />
      )}
      {consumables?.length > 0 && (
        <OrderedItems
          reload={reload}
          orderedItems={consumables}
          title={'CONSUMABLE'}
        />
      )}
    </Container>
  )
}

const OrderViewData = ({ order }) => {
  const { t } = useTranslation(['orders'])

  return (
    <Container flex vertical>
      <Row>
        <Column flex n={6}>
          <SectionView>
            <Container flex justify="space-between">
              <Container>
                <h1>{t('Project data')}</h1>
                <Row>
                  <GridLinkFieldView
                    n={6}
                    label={t('Project')}
                    value={order.project.short_name || order.project.name}
                    url={`/projects/${order.project.id}`}
                  />
                  <GridLinkFieldView
                    n={6}
                    label={t('Orderer')}
                    value={order.creator.fullname}
                    url={`/security/users/${order.creator.id}`}
                  />
                  <GridFieldView
                    n={6}
                    label={t('Status')}
                    value={t(order.state)}
                  />
                  <GridFieldView
                    n={6}
                    label={t('Ordered at')}
                    value={formatDateTime(order.ordered_at)}
                  />
                </Row>
              </Container>
              <Container flex>
                <Barcode value={`${order.id}`} size={192} />
              </Container>
            </Container>
          </SectionView>
        </Column>
        <Column flex n={6}>
          <SectionView>
            <Container flex grow vertical>
              <h1>{t('Delivery data')}</h1>
              <FieldView label={t('Delivery option')}>
                <p>
                  {order.requires_delivery
                    ? t('Requires delivery')
                    : t('Self-pickup')}
                </p>
              </FieldView>
              {order && <OrderDates order={order} />}
            </Container>
          </SectionView>
        </Column>
      </Row>
    </Container>
  )
}

register({
  render: () => <OrderDetailsViewPage />,
  title: 'Details',
  path: '/orders/:id',
})
