import React from 'react'
import { useTranslation } from 'react-i18next'

import { useService } from 'common/service/context'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { SectionView } from 'common/widgets/view'
import { formatBackDeliveryNumber } from 'modules/yard/utils'
import { Barcode } from 'common/widgets/barcode'
import { Container } from 'common/widgets/container'

/**
 * Renders tab content for return Delivery
 *
 * @returns ReactElement
 */
export const ReturnDeliveryTabContent = ({ data }) => {
  const service = useService()
  const { t } = useTranslation(['projects'])

  const normalize = (params) => {
    return [...params, { project_id: data.id }]
  }

  const fetch = async (params) =>
    await service.get(`/back/delivery`, normalize(params))

  return (
    <SectionView>
      <SimpleDataTable
        name="backdeliveries"
        white
        nomargin
        nopadding
        divide
        fetch={fetch}
        navBaseUrl="/yard/retour/deliveries"
      >
        <SimpleColumn fixed>
          {(r) => (
            <Container padding="5px 10px 0px 0">
              <Barcode value={r.id} width="32px" height="32px" />
            </Container>
          )}
        </SimpleColumn>
        <SimpleColumn
          n={3}
          s={6}
          sortable
          label={t('Delivery number')}
          field="id"
          value={(r) => formatBackDeliveryNumber(r)}
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          filter={(e) => e}
          title={(e) => e?.fullname}
          label={t('Recipient')}
          field="recipient"
          fetch={async (params) =>
            await service.get('back/delivery/recipients', normalize(params))
          }
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          filter={(e) => e.id}
          title={(e) => e.fullname}
          label={t('Creator user')}
          field="creator_user_id"
          value={(r) => r.creator_user.fullname}
          fetch={async (params) =>
            await service.get('back/delivery/creators', normalize(params))
          }
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          filter={(e) => e}
          title={(e) => e}
          label={t('State')}
          field="state"
          value={(r) => t(r.state)}
          fetch={async (params) =>
            await service.get('back/delivery/states', normalize(params))
          }
        />
      </SimpleDataTable>
    </SectionView>
  )
}
