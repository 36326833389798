import { addTranslation } from 'common/i18n/core'

addTranslation('de', 'logistics', {
  'No logistic vehicles found.': 'Keine Logistikfahrzeuge vorhanden.',
  'No trips are added to this truck yet.':
    'Keine Trips sind diesem Fahrzeug zugewiesen.',
  trip: 'Trip',
  trips: 'Trips',
  'Planned trips': 'Geplante Trips',
  'Planned transports': 'Geplante Touren',
  'Trip count': 'Tourenzahl',
  'Choose a transporter from list to begin planning.':
    'Wählen Sie einen Transporter aus der Liste aus.',
  'Choose logistic vehicle': 'Wählen Sie das Fahrzeug aus',
  'Can not modify past tours.': 'Alte Touren dürfen nicht geändert werden.',
  'Add a new trip': 'Trip hinzufügen',
  'Edit trip': 'Trip bearbeiten',
})
