import styled from 'styled-components'
import { Check } from 'react-feather'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { isNilOrEmpty } from 'common/utils/conditional'

/**
 * Renders check box
 *
 * @param {boolean} value check box value
 * @param {boolean} defaultValue check box default value
 * @param {Function} onChange on value change hook
 * @returns
 */
export const CheckBox = ({
  value,
  defaultValue = false,
  onChange,
  disabled,
  children,
  // text value shown in front of the checkbox in a pragraph
  text,
  ...rest
}) => {
  const { t } = useTranslation()
  // Keeps state for checked and not checked state.
  const [isChecked, setChecked] = useState(defaultValue)
  const flag = isNilOrEmpty(value) ? isChecked : value

  useEffect(() => {
    setChecked(value)
  }, [value])

  return (
    <CheckBoxContainer {...rest}>
      <Rect
        disabled={disabled}
        isChecked={flag}
        onClick={(e) => {
          e.stopPropagation()
          if (!disabled) {
            if (onChange) {
              onChange(!flag)
            }
            if (isNilOrEmpty(value)) {
              setChecked(!flag)
            }
          }
        }}
      >
        {flag && <Check />}
      </Rect>
      {text && <p>{t(text)}</p>}
      {children}
    </CheckBoxContainer>
  )
}

const Rect = styled.div`
  background: ${(props) =>
    props.isChecked ? (props.disabled ? '#4B83FF88' : '#4B83FF') : '#FFFFFF'};
  border: 2px solid ${(props) => (props.isChecked ? '#4B83FF' : '#B1B1B1')};
  border-radius: 5px;
  min-width: 18px;
  min-height: 18px;
  max-width: 18px;
  max-height: 18px;
  margin-right: 10px;
  cursor: pointer;

  svg {
    color: #ffffff;
    padding: 1px;
    width: 16px;
    height: 16px;
  }
`

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
`
