const BASE_INNER_HEIGHT = 1022
const MIN_HEIGHT = 400

/**
 * Get the maximum height that would be visible on current screen.
 * Larger reducer number results in a smaller height.
 *
 * @param {number} reducer a multiplier number for a given form that must be between 0 and 1
 * @returns string
 */
export const getMaxHeight = (reducer) => {
  let height = window.innerHeight
  let factor = height / BASE_INNER_HEIGHT
  reducer = reducer / factor
  let result = height - reducer * height

  if (result < MIN_HEIGHT) {
    result = MIN_HEIGHT
  }

  return `${result}px`
}
