import { useCallback, useState } from 'react'
import { ArrowDown } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { useService } from 'common/service/context'
import { Card } from 'common/widgets/card'
import { CheckBox } from 'common/widgets/checkbox'
import { Container } from 'common/widgets/container'
import { DataSource } from 'common/widgets/data-source'
import { Row, Column } from 'common/widgets/grid'
import { SearchInput } from 'common/widgets/search'
import { CardView } from 'common/widgets/view'
import { SubmitButton } from 'common/widgets/button'
import { useToast } from 'system/toast/context'

import styles from '../roles.module.css'

/**
 * Renders scope select view inside role tab widget.
 *
 * @param {any} defaultActiveScopes default active scopes of role
 * @returns ReactElement
 */
export const RoleScopesSelectView = ({ defaultActiveScopes }) => {
  const service = useService()
  // extract role id from url params
  const { id } = useParams()
  const { t } = useTranslation()
  const { toasts } = useToast()

  // create state for keeping active scopes
  const [activeScopes, setActiveScopes] = useState(
    defaultActiveScopes ? defaultActiveScopes.map((e) => e.id) : []
  )
  const [prevActiveScopes, setPrevActiveScopes] = useState(
    defaultActiveScopes ? defaultActiveScopes.map((e) => e.id) : []
  )

  const disableSubmit = () => {
    if (activeScopes.length !== prevActiveScopes.length) {
      return false
    }

    return activeScopes.every(
      (element, index) => element === prevActiveScopes[index]
    )
  }

  /**
   * Adds or removes scope from role.
   *
   * @param {int} scopeId scope id
   * @param {boolean} check boolean value which marks whether the scope should be removed from or added to role
   */
  const handleToggle = (scopeId, check) => {
    check
      ? setActiveScopes(Array.from(new Set([...activeScopes, scopeId])))
      : setActiveScopes(activeScopes.filter((e) => e != scopeId))
  }

  const handleToggleBulk = (scopes, check) => {
    check
      ? setActiveScopes(Array.from(new Set([...activeScopes, ...scopes])))
      : setActiveScopes(activeScopes.filter((e) => !scopes.includes(e)))
  }

  const handleSubmit = async () => {
    const [result, error] = await service.post('security/accesses/overwrite', {
      role: id,
      scopes: activeScopes,
    })
    if (result && !error) {
      setPrevActiveScopes(activeScopes)
      toasts.success(t('Scopes were edited'))
    }

    return [result, error]
  }

  const isActive = useCallback(
    /**
     * Checks, if scope was granted to role which means if it is activated.
     *
     * @param {int} scope_id scope id
     * @returns boolean. True if active, false if not.
     */
    (scope_id) => {
      return activeScopes.includes(scope_id)
    },
    [activeScopes]
  )

  const areAllActive = useCallback(
    /**
     * Checks, if the scopes were granted to role which means they are activated.
     *
     * @param {array} scopes scopes id array
     * @returns boolean. True if active, false if not.
     */
    (scopes) => {
      var isFamilyChecked = true
      scopes.filter((e) => {
        if (!activeScopes.includes(e)) {
          isFamilyChecked = false
          return
        }
      })

      return isFamilyChecked
    },
    [activeScopes]
  )

  return (
    <Container flex>
      <Row>
        <Column n={12}>
          <RoleScopesSelect
            onToggle={handleToggle}
            onTogleBulk={handleToggleBulk}
            handleSubmit={handleSubmit}
            disableSubmit={disableSubmit}
            isActive={isActive}
            areAllActive={areAllActive}
          />
        </Column>
      </Row>
    </Container>
  )
}

/**
 * Renders scope select.
 *
 * @param {Array} params query params
 * @param {Function} isActive gets called to determine scope state
 * @param {Function} onToggle gets called when scope state changes
 * @returns ReactElement
 */
const RoleScopesSelect = ({
  params,
  isActive,
  areAllActive,
  onToggle,
  onTogleBulk,
  handleSubmit,
  disableSubmit,
}) => {
  const service = useService()

  /**
   * Fetches available scopes.
   *
   * @returns Array
   */
  const fetch = async (params) => {
    return await service.get(`/security/scopes`, [...params])
  }

  return (
    <DataSource
      params={params}
      fetch={fetch}
      render={({ data }) => {
        return (
          <CardView padding="20px">
            <Container flex title="Permissions">
              <Container flex gap="10px">
                <SearchInput />
                <SubmitButton
                  default
                  onClick={handleSubmit}
                  disabled={disableSubmit}
                />
              </Container>
            </Container>
            {data && (
              <ScopeList
                scopes={data}
                isActive={isActive}
                areAllActive={areAllActive}
                onToggle={onToggle}
                onTogleBulk={onTogleBulk}
              />
            )}
          </CardView>
        )
      }}
    />
  )
}

const ScopeList = ({
  scopes,
  isActive,
  areAllActive,
  onToggle,
  onTogleBulk,
}) => {
  const root = {
    id: null,
    children: [],
  }
  scopes.forEach((e) => grouped(scopes, e.id))

  function grouped(records, itemId) {
    const item = findInGrouped(root, itemId)
    if (!item) {
      const filtered = records.filter((e) => e.id === itemId)
      const data = { ...filtered[0], children: [] }
      const splitted = itemId.split('.')
      var parent = root
      if (splitted.length > 1) {
        const parentId = splitted.slice(0, -1).join('.')
        parent = grouped(records, parentId)
      }
      parent.children = [...parent.children, data]
      return data
    }
    return item
  }

  function findInGrouped(item, itemId) {
    if (item.id === itemId) {
      return item
    } else {
      for (let index = 0; index < item.children.length; index++) {
        const element = findInGrouped(item.children[index], itemId)
        if (element != null) {
          return element
        }
      }
    }
    return null
  }

  return (
    <>
      {root.children.map((scopeSection) => (
        <ScopeSection
          isActive={isActive}
          areAllActive={areAllActive}
          key={`scope_section_${scopeSection.id}`}
          scopeSection={scopeSection}
          onToggle={onToggle}
          onTogleBulk={onTogleBulk}
        ></ScopeSection>
      ))}
    </>
  )
}

const ScopeSection = ({
  scopeSection,
  isActive,
  areAllActive,
  onToggle,
  onTogleBulk,
}) => {
  function createScopeSection(scope) {
    const triggerChildren = (scope, checked) => {
      const children = []
      if (!scope.children || scope.children.length === 0) {
        return
      }
      scope.children.filter(async (e) => {
        if (e.children && e.children.length > 0) {
          triggerChildren(e, checked)
        } else {
          children.push(e.id)
        }
      })
      onTogleBulk(children, checked)
    }

    const checkChildren = (scope) => {
      const children = []
      if (!scope.children || scope.children.length === 0) {
        return
      }
      scope.children.filter((e) => {
        if (e.children && e.children.length > 0) {
          checkChildren(e)
        } else {
          children.push(e.id)
        }
      })
      return children
    }

    return (
      <Row key={scope.id} style={{ paddingLeft: '12px' }}>
        {scope.parent ? (
          <Container grow>
            <Row>
              <Column n={8}>
                <StyledExpansionPanelSummary expandIcon={<ArrowDown />}>
                  <p>{scope.name}</p>
                </StyledExpansionPanelSummary>
              </Column>
              <Column n={4} style={{ padding: '13.5px' }}>
                {!scope.children[0]?.parent && (
                  <CheckBox
                    onChange={(checked) => triggerChildren(scope, checked)}
                    value={areAllActive(checkChildren(scope))}
                  />
                )}
              </Column>
            </Row>

            {scope.children.map((e, index) => (
              <Container style={{ paddingLeft: '40px' }} key={index}>
                {createScopeSection(e)}
              </Container>
            ))}
          </Container>
        ) : (
          // <p style={{ borderBottom: "1px solid #eee" }}>{scope.name}</p>
          <Column n={12}>
            <ScopeCard scope={scope} isActive={isActive} onToggle={onToggle} />
          </Column>
        )}
      </Row>
    )
  }

  return (
    <Card
      style={{
        margin: '10px 0px',
        borderBottom: '1px solid #eeeeee',
      }}
    >
      {createScopeSection(scopeSection)}
    </Card>
  )
}

const ScopeCard = ({ scope, onToggle, isActive }) => {
  return (
    <CardView>
      <Row>
        <Column n={8}>
          <p>{scope.name}</p>
        </Column>
        <Column flex justify="end" n={4}>
          <CheckBox
            onChange={(checked) => onToggle(scope.id, checked)}
            value={isActive(scope.id)}
          />
        </Column>
      </Row>
    </CardView>
  )
}

const StyledExpansionPanelSummary = ({ children, ...rest }) => {
  return (
    <Container
      className={styles.expansionPanel}
      IconButtonProps={{ edge: 'start' }}
      {...rest}
    >
      {children}
    </Container>
  )
}
