import { SystemPage } from 'system/page/core'
import { register } from 'system/container'

import { DeviceList } from './devices/list'
import { DeviceEdit } from './devices/edit'
import { DeviceView } from './devices/view'
import { DeviceAdd } from './devices/add'

register({
  menu: true,
  render: () => (
    <SystemPage>
      <p>Coming soon</p>
    </SystemPage>
  ),
  title: 'IoT',
  path: '/logistics/iot',
})

register({
  menu: true,
  render: () => (
    <SystemPage>
      <DeviceList />
    </SystemPage>
  ),
  title: 'Devices',
  path: '/logistics/iot/devices',
})

register({
  render: () => (
    <SystemPage>
      <DeviceAdd />
    </SystemPage>
  ),
  title: 'New',
  path: '/logistics/iot/devices/add',
})

register({
  render: () => <DeviceEdit />,
  title: 'Edit',
  path: '/logistics/iot/devices/:id/edit',
})

register({
  render: () => <DeviceView />,
  title: 'View',
  path: '/logistics/iot/devices/:id',
})
