import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'
import { DropDownField } from 'common/widgets/form/field'

import { ContactTypeEnum } from '../utils'

/**
 * Renders company select field.
 *
 * @param {string} name field name
 * @returns
 */
export const ContactCompanyField = ({ ...rest }) => {
  const service = useService()
  const fetch = async () =>
    await service.get(`/contacts/minimal?type=${ContactTypeEnum.COMPANY}`)
  return (
    <DataSource
      fetch={fetch}
      render={({ data }) => (
        <DropDownField
          items={
            data
              ? data.map((e) => ({
                  key: e.id,
                  title: e.name,
                }))
              : []
          }
          minWidth="276px"
          {...rest}
        />
      )}
    />
  )
}
