/*
 * position should not be absolute, otherwise this element
 * will be removed from normal flow of elements inside the page
 * and messes our map component location and causes it to jump
 * left and right. The reason is, the map appears earlier than
 * our DataTables. Meanwhile, DataTable shows the loading component
 * which was previously absolute and wouldn't be counted toward
 * page width, so the map appears instead of the table and as soon
 * as the table is loaded, the map is pushed right, hence jumping.
 **/
.loading {
  position: relative;
  box-sizing: content-box;
  z-index: 1;
  height: 3px;
  top: 60px;
  background: #108010;
  transition: width 0.3s;
}

.loadingPassive {
  composes: loading;
  width: 0;
  opacity: 0;
}

.loadingActive {
  position: relative;
  top: 0;
  composes: loading;
  width: 100%;
  opacity: 1;
}

.loadingActiveAnimate {
  composes: loadingActive;
  width: 80%;
}
