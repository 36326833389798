import { FieldView } from 'common/widgets/view'

import { formatProjectName, getProjectLink } from '../utils'

export const ProjectLink = ({ project, showCostCenter = true }) => {
  return (
    <FieldView
      href={getProjectLink(project)}
      value={showCostCenter ? formatProjectName(project) : project?.name}
    />
  )
}
