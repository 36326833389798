import React, { useEffect, useState } from 'react'

import { GridFieldView, SectionView } from 'common/widgets/view'
import { Row } from 'common/widgets/grid'
import { Container } from 'common/widgets/container'
import { useService } from 'common/service/context'
import { AddIconButton, RemoveIconButton } from 'common/widgets/button'
import { PagedDataSource } from 'common/widgets/data-source'
import { SystemOverlay } from 'common/widgets/overlay'
import { SearchInput } from 'common/widgets/search'

import { formatItemName } from '../utils'
import styles from '../equipments.module.css'

export const RelatedItemView = ({ data }) => {
  const service = useService()
  const [relatedItems, setRelatedItems] = useState(null)
  const [overlayVisible, setOverlayVisible] = useState(false)

  const fetchRelatedItems = async () => {
    const [result, error] = await service.get(`/items/${data.id}/relateds`)
    if (error) {
      console.error(error)
    } else {
      setRelatedItems(result.data)
    }
    return [result, error]
  }

  // Runs for the first time to load settings
  useEffect(() => {
    if (relatedItems === null) {
      setRelatedItems([])
      fetchRelatedItems()
    }
  }, [])

  /**
   * Excludes the given items from equipment.
   *
   * @param item
   * @returns
   */
  const handleAdd = async (item) => {
    const [result, error] = await service.post(
      `/items/${data.id}/relateds/${item.id}`
    )
    if (!error && result) {
      await fetchRelatedItems()
    }
  }

  /**
   * Removes the related item .
   *
   * @param {string} item item info
   */
  const handleRemove = async (item) => {
    const [result, error] = await service.delete(
      `/items/${data.id}/relateds/${item.id}`
    )
    if (!error && result) {
      await fetchRelatedItems()
    }
  }

  return (
    <Container flex grow vertical>
      <ItemSelectOverlay
        itemId={data.id}
        open={overlayVisible}
        onClose={() => setOverlayVisible(false)}
        fetch={fetchRelatedItems}
        onSelect={async (item) => await handleAdd(item)}
      />
      <RelatedItems
        item={data}
        relatedItems={relatedItems}
        onRemove={handleRemove}
        onAdd={() => setOverlayVisible(true)}
      />
    </Container>
  )
}

/**
 * Renders a widget to display responsible user  for project.
 *
 * @param {any} project contact info
 * @returns ReactElement
 */
const RelatedItems = ({ relatedItems, onRemove, onAdd }) => {
  return (
    <SectionView>
      <Container flex repel align="center" title="Related items">
        <AddIconButton tooltip="Add" onClick={onAdd} />
      </Container>
      {relatedItems?.map((e) => (
        <Container className={styles.accessoriesListItem} key={e.id}>
          <Container flex grow vertical>
            <h3>{formatItemName(e)}</h3>
            <Row>
              <GridFieldView n={3} label="Main group" value={e.maincategory} />
              <GridFieldView n={3} label="Group" value={e.category} />
              <GridFieldView n={3} label="Subgroup" value={e.subcategory} />
            </Row>
          </Container>
          <RemoveIconButton onClick={async () => await onRemove(e)} />
        </Container>
      ))}
    </SectionView>
  )
}

/**
 * Renders a system overlay to select an item.
 * @param {boolean} open
 * @param {Function} onAdd
 * @param {Function} onClose
 */
const ItemSelectOverlay = ({ itemId, open, onSelect, onClose }) => {
  return (
    <SystemOverlay open={open} title="Equipments" onClose={onClose}>
      <PagedDataSource
        params={[{ hide_linked: itemId }]}
        url="/items"
        limit={5}
      >
        {({ data, reload }) => (
          <>
            <SearchInput />
            {data?.map((e) => (
              <ItemView key={e.id} e={e}>
                <AddIconButton
                  onClick={async () => {
                    await onSelect(e)
                    await reload()
                  }}
                />
              </ItemView>
            ))}
          </>
        )}
      </PagedDataSource>
    </SystemOverlay>
  )
}

const ItemView = ({ e, children }) => {
  return (
    <Container className={styles.accessoriesListItem} key={e.id}>
      <Container flex grow vertical>
        <h3>{formatItemName(e)}</h3>
        <Row>
          <GridFieldView n={3} label="Main group" value={e.maincategory} />
          <GridFieldView n={3} label="Group" value={e.category} />
          <GridFieldView n={3} label="Subgroup" value={e.subcategory} />
          <GridFieldView n={3} label="Cost center" value={e.cost_center} />
        </Row>
      </Container>
      {children}
    </Container>
  )
}
