import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Container } from 'common/widgets/container'
import { Preview } from 'common/widgets/file/preview'
import { Row, Column } from 'common/widgets/grid'
import { CardView } from 'common/widgets/view'
import { DocumentsSectionView } from 'common/widgets/document'

/**
 * Renders tab content for documents
 *
 * @returns ReactElement
 */
export const ContactDocumentsView = ({ contact }) => {
  const { t } = useTranslation()
  // Create state for selected file
  const [file, setFile] = useState(null)

  return (
    <Row>
      <Column n={6}>
        <Container flex vertical gap="10px">
          <DocumentsSectionView
            docRef={`file-document-contact-${contact?.id}`}
            onFileClick={(file) => setFile(file)}
            onFileRemoved={(fileID) => {
              if (fileID === file?.id) {
                setFile(null)
              }
            }}
          />
        </Container>
      </Column>
      <Column n={6}>
        {file && (
          <CardView padding="20px" flex vertical>
            <h2>{t('Preview')}</h2>
            <h3>{file.name}</h3>
            <Preview file={file} />
          </CardView>
        )}
      </Column>
    </Row>
  )
}
