import { useMemo } from 'react'
import { useStore, getBezierPath } from 'reactflow'

import { getEdgeParams } from './utils'

const FloatingEdge = ({
  id,
  source,
  target,
  markerEnd,
  sourcePosition,
  targetPosition,
  style,
  ...rest
}) => {
  const nodesLengthSelector = (state) =>
    Array.from(state.nodeInternals.values())
  const nodes = useStore(nodesLengthSelector)

  const sourceNode = useMemo(
    () => nodes.find((n) => n.id === source),
    [source, nodes]
  )
  const targetNode = useMemo(
    () => nodes.find((n) => n.id === target),
    [target, nodes]
  )

  if (!sourceNode || !targetNode) {
    return null
  }

  const { sx, sy, tx, ty } = getEdgeParams(sourceNode, targetNode)

  const d = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePosition,
    targetPosition: targetPosition,
    targetX: tx,
    targetY: ty,
  })

  return (
    <g className="react-flow__connection">
      <path
        id={id}
        className="react-flow__edge-path"
        d={d}
        markerEnd={markerEnd}
        style={style}
      />
    </g>
  )
}

export default FloatingEdge
