import { SystemOverlay } from 'common/widgets/overlay'

import { NotificationList } from './list'

export const NotificationOverlay = ({ name, open, onClose }) => {
  return (
    <SystemOverlay name={name} open={open} onClose={onClose}>
      <NotificationList />
    </SystemOverlay>
  )
}
