import { Truck } from 'react-feather'

import { register } from 'system/container'
import { SystemPage } from 'system/page/core'

register({
  icon: <Truck />,
  title: 'Yard',
  // Subsystem base route, no trailing slash
  path: '/yard',
  // mainmenu priority, lower is higher
  priority: 30,
  render: () => <SystemPage>Welcome to yard index page</SystemPage>,
})
