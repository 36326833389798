import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { DetailViewPage } from 'system/utils/view'
import { formatBackDeliveryNumber } from 'modules/yard/utils'
import { CancelIconButton, SubmitButton } from 'common/widgets/button'
import { useService } from 'common/service/context'

import { BackDeliveryViewDetails } from './details'
import { BackDeliveredItems } from './items'
import { CancelBackDeliveryOverlay } from './overlays/cancel'

/**
 * Renders the back delivery pickup view page.
 *
 * @returns ReactElemnt
 */
export const PickupBackDeliveryViewPage = () => {
  const { t } = useTranslation()
  const service = useService()
  const navigate = useNavigate()
  const [cancelBackDeliveryObject, setCancelBackDeliveryObject] = useState(null)

  const handleDeliveryNotePickup = async (backDeliveryId) => {
    const [result, error] = await service.put(
      `back/delivery/${backDeliveryId}/pickup`
    )

    if (!error) {
      navigate(-1)
    }

    return [result, error]
  }

  return (
    <DetailViewPage
      url="/back/delivery"
      title={(backDelivery) =>
        `${t('Back delivery number')} : ${formatBackDeliveryNumber(
          backDelivery
        )}`
      }
      addExtraButtons={(r) => (
        <CancelIconButton
          onClick={(e) => {
            e.stopPropagation()
            setCancelBackDeliveryObject(r)
          }}
        />
      )}
    >
      {(backDelivery) => (
        <>
          {backDelivery && (
            <BackDeliveryContent
              backDelivery={backDelivery}
              handleDeliveryNotePickup={handleDeliveryNotePickup}
            />
          )}
          <CancelBackDeliveryOverlay
            backDelivery={cancelBackDeliveryObject}
            onClose={() => setCancelBackDeliveryObject(null)}
            onDeliveryCanceled={() => navigate(-1)}
          />
        </>
      )}
    </DetailViewPage>
  )
}

/**
 * Renders the back delivery content
 *
 * @param {any} backDelivery Back delivery object
 *
 * @returns ReactElemnt
 */
const BackDeliveryContent = ({ backDelivery, handleDeliveryNotePickup }) => {
  const { t } = useTranslation()
  const resources = backDelivery.items?.filter(
    (item) => item.baseitem?.is_resource
  )
  const formworks = backDelivery.items?.filter(
    (item) => item.baseitem?.is_formwork
  )

  return (
    <>
      <BackDeliveryViewDetails backDelivery={backDelivery} />
      {resources?.length > 0 && (
        <BackDeliveredItems backDeliveryItems={resources} title={'RESOURCE'} />
      )}
      {formworks?.length > 0 && (
        <BackDeliveredItems backDeliveryItems={formworks} title={'FORMWORK'} />
      )}
      <SubmitButton
        default
        text={t('Complete')}
        onClick={() => {
          handleDeliveryNotePickup(backDelivery.id)
        }}
      />
    </>
  )
}
