import { useContext, createContext, useEffect, useState } from 'react'

import { useService } from 'common/service/context'

const SettingsContext = createContext()

export const SettingsProvider = ({ children }) => {
  const service = useService()
  const [settings, setSettings] = useState(null)
  const [apiError, setApiError] = useState(null)
  const [dataURLs, setDataURLs] = useState({})

  const reload = async () => {
    const [response, error] = await service.get('/settings')
    if (error) {
      const msg = 'Can not load settings. Probably the API is unreachable.'
      setApiError(`${msg}\n\n${error}\n\n${error?.response?.data}`)
    } else {
      setSettings(response.data)
    }
  }

  // Run once to load settings
  useEffect(() => {
    if (settings === null) {
      reload()
    }
  }, [])

  const set = async (key, value) => {
    const [result, error] = await service.put(`settings/${key}`, { value })
    if (!error) {
      await reload()
    }
    return [result, error]
  }

  const get = (key, fetchDataURL) => {
    const config = settings?.find((e) => e.key === key)
    if (
      fetchDataURL &&
      // We have to compensate for the lack of corrct file and mimetype
      // information on the server side, FILE with a mimetype would be
      // correct.
      (config?.type === 'FILE' || config?.type === 'IMAGE') &&
      !(key in dataURLs)
    ) {
      if (config?.value) {
        // Fetch data URL for this entry
        reloadDataURL(key, config?.value)
      }
    }
    return fetchDataURL ? config?.dataURL : config?.value
  }

  const reloadDataURL = async (key, fileId) => {
    const [response, error] = await service.get(`/settings/${key}/dataurl`)
    if (!error) {
      setDataURLs((prev) => {
        return { ...prev, [key]: response?.data }
      })
      setSettings((prev) => {
        const entry = prev.find((e) => e.key === key)
        const rest = prev.filter((e) => e.key !== key)
        return [...rest, { ...entry, dataURL: response?.data }]
      })
    }
  }

  const update = async (values) => {
    const [result, error] = await service.put(`settings/`, {
      company_name: values.name,
      primary_color: values.primaryColor,
      secondary_color: values.secondaryColor,
      company_address: values?.address,
    })
    if (!error) {
      await reload()
    }

    return [result, error]
  }

  return (
    <SettingsContext.Provider
      value={{
        settings,
        set,
        get,
        update,
      }}
    >
      {(settings !== null && children) || <pre>{apiError}</pre>}
    </SettingsContext.Provider>
  )
}

export const useSettings = () => useContext(SettingsContext)
