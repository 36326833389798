import { Form } from 'common/widgets/form/context'
import { SystemPage } from 'system/page/core'
import { useService } from 'common/service/context'

import { ContactTagFormContent } from './form'

/**
 * Renders contact add page.
 *
 * @returns ReactElemtn
 */
export const ContactTagAddPage = () => {
  const service = useService()

  /**
   * Handles adding a tag, send a post call and creates a new tag.
   */
  const handleAdd = async (values) =>
    await service.post('contacts/defs/tags', values)

  return (
    <SystemPage>
      <Form>
        <ContactTagFormContent onAdd={handleAdd} />
      </Form>
    </SystemPage>
  )
}
