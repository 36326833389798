import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useService } from 'common/service/context'
import { Form } from 'common/widgets/form/context'
import { SystemPage } from 'system/page/core'

import { FormworksFormContent } from './form'

/**
 * Renders page for adding a formwork
 *
 * @returns ReactElement
 */
export const FormworksAddPage = () => {
  const service = useService()
  const navigate = useNavigate()

  const handleAdd = async (values) =>
    await service.post('items/formworks/', values)

  const handleSuccess = async (response) => {
    navigate(`/master-data/equipments/formworks/${response.data.id}`)
  }

  return (
    <SystemPage>
      <Form>
        <FormworksFormContent onAdd={handleAdd} onSuccess={handleSuccess} />
      </Form>
    </SystemPage>
  )
}
