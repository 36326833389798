import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { X, ChevronDown } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { Button } from 'common/widgets/button'
import { useDataSourceContext } from 'common/widgets/data-source'

import { SearchInput } from './search'

/**
 * Renders a drop down element.
 *
 * @param {string} title drop down title
 * @param {any} selected selected item key
 * @param {function} onSelectChange selected item key
 * @param {function} searchFilter External function to filter options on search
 * @param {int} maxItems maximum number of items which are shown on the dropdown list
 * @returns ReactElement
 */
export const DropDown = ({
  name,
  title,
  items = [],
  selectedIndex,
  onSelectChange,
  maxItems,
  disabled,
  removable,
  searchFilter,
  style,
}) => {
  const myRef = useRef()
  const [dropDownOpen, setDropDownOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedKey, setSelectedKey] = useState(selectedIndex)
  const { t } = useTranslation()

  const ctx = useDataSourceContext()
  const effectiveOnSelectChange =
    onSelectChange ??
    ((selectedOption) => ctx?.setQueryParameter(name, selectedOption?.key))

  // Translate title if provided
  title = title ? t(title) : title

  if (items?.length == 0) {
    disabled = true
  }

  if (Number.isInteger(selectedIndex) && selectedKey !== selectedIndex) {
    setSelectedKey(selectedIndex)
  }

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.code === 'Escape') {
        setDropDownOpen(false)
      }
    }
    const handleMouseUp = (e) => {
      // Ignore our own mouseup, we are interested in clicking outside of this
      // component not inside.
      if (myRef.current && !myRef.current.contains(e.target)) {
        // Close the dropdown if somewhere outside is clicked
        setDropDownOpen(false)
      }
    }
    if (dropDownOpen) {
      document.addEventListener('mouseup', handleMouseUp)
      document.addEventListener('keydown', handleEscape)
    } else {
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('keydown', handleEscape)
    }

    // and finally make sure they are removed when we are unmounted
    return () => {
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('keydown', handleEscape)
    }
  }, [dropDownOpen])

  // Search bar only filter current items, so there is no harm to display it all
  // the time. For good measure, we display it when we have more than 10 items.
  const showSearch = (maxItems && items.length > maxItems) || items.length > 10
  // If max items is set, tries to filter it with search string
  let options = items
  if (searchTerm) {
    if (searchFilter) {
      options = options.filter((option) => searchFilter(option, searchTerm))
    } else {
      options = options.filter((option) =>
        option.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    }
  }
  // Splits the extra items
  const visibleOptions = showSearch ? options.slice(0, maxItems) : options

  const icon = removable ? (
    <X
      onMouseUp={(e) => {
        setSelectedKey(null)
        setDropDownOpen(false)
        e.preventDefault()
        effectiveOnSelectChange(null)
      }}
    />
  ) : (
    <ChevronDown />
  )

  return (
    <div ref={myRef} style={style ? style : {}}>
      <StyledDropDownButton
        background={disabled ? '#E2E2E2' : '#fcfcfc'}
        onMouseUp={(e) => {
          !disabled &&
            !e.isDefaultPrevented() &&
            setDropDownOpen((dropDownOpen) => !dropDownOpen)
        }}
        disabled={disabled}
      >
        <p>{items[selectedKey]?.title || t(title)}</p>
        {icon}
      </StyledDropDownButton>
      {/*
          This div with position:relative is important. See my comments
          on the DropDownContent component.

          Moreover, absolute elements are removed from normal flow of the
          document. Therefore they do not participate in event capturing
          and bubbling.
        */}
      <div style={{ position: 'relative' }}>
        <DropDownContent open={dropDownOpen} width="100%">
          {showSearch && (
            <SearchInput
              value={searchTerm}
              onChange={(text) => {
                setSearchTerm(text)
              }}
              nodelay
            />
          )}
          {visibleOptions.map((option, i) => {
            // Handles click on the dropdown item.
            const handleClick = (event) => {
              setDropDownOpen(false)
              const originalIndex = items.findIndex((i) => i.key === option.key)
              setSelectedKey(originalIndex)
              if (effectiveOnSelectChange) {
                effectiveOnSelectChange(option)
              }
            }
            return (
              <DropDownItemContainer
                onMouseUp={disabled ? undefined : handleClick}
                key={i}
                top={i === 0 && !showSearch}
                bottom={i === visibleOptions.length - 1}
              >
                <StyledDiv>
                  <p>{option?.title}</p>
                  {option?.icon}
                </StyledDiv>
              </DropDownItemContainer>
            )
          })}
          {showSearch && options.length - maxItems > 0 && (
            <Overflow>
              {t('There are [{{overflow}}] more results...', {
                overflow: options.length - maxItems,
              })}
            </Overflow>
          )}
        </DropDownContent>
      </div>
    </div>
  )
}

const StyledDiv = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
  justify-content: space-between;
  svg {
    width: 16px;
    height: 16px;
    color: #808080;
  }
  p {
    /* monospace is necessary when padding numbers, otherwise
       numbers would not be aligned in different dropdown rows */
    font-family: monospace;
  }
`

/* Dropdown Content (Hidden by Default)
 *
 * Note: make sure the parent has position:relative
 * and this element position:absolute to have this
 * element size position itself relative to the parent
 */
const DropDownContent = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.open ? 'flex' : 'none')};
  width: ${(props) => props.width};
  margin-top: 0;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 3px 24px #e0e0e0;
  border-radius: 5px;
  z-index: 10000; /* should be above leaflet map elements */
  flex-direction: column;

  max-height: 400px;

  /* #549: auto works both in Firefox and Chrome; scroll works only in FF */
  overflow: auto;
`

const DropDownItemContainer = styled.div`
  padding: 6px 15px;
  text-decoration: none;
  display: inline-flex;
  flex-direction: column;
  flex: 1 1 auto;
  transition: 0.3s;
  background: #ffffff;
  cursor: pointer;
  border-radius: ${(props) => {
    const top = props.top ? '5px 5px' : '0px 0px'
    const bottom = props.bottom ? '5px 5px' : '0px 0px'
    return `${top} ${bottom}`
  }};

  :hover {
    background: #e2e2e2;
  }
`

const StyledDropDownButton = styled(Button)`
  flex: 1 1 auto;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  max-height: 36px;
  border: 1px solid #eeeeee;
  background: ${(props) => {
    const background = props.background ? props.background : '#fcfcfc'
    return `${background}`
  }};

  p {
    margin-left: 0;
    margin-right: 0;
    color: #303030;
  }

  svg {
    margin-right: 0;
    color: #303030;
  }

  padding: 0 5px 0 5px;
`

const Overflow = styled.h5`
  padding-left: 10px;
  color: #808080 !important;
`
