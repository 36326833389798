import { useParams } from 'react-router-dom'

import { Form } from 'common/widgets/form/context'
import { SystemPage } from 'system/page/core'
import { DataSource } from 'common/widgets/data-source'
import { useService } from 'common/service/context'

import { TeamsFormContent } from './form'

/**
 * Renders contact state add page.
 *
 * @returns ReactElemtn
 */
export const TeamsEditPage = () => {
  // Extracts state id from url params
  const { id } = useParams()
  const service = useService()

  /**
   * Gets information of state using it's id.
   *
   * @returns any
   */
  const fetch = async () => await service.get(`/teams/${id}`)

  /**
   * Handles adding a new contact, send a post call and creates a new contact.
   */
  const handleSave = async (values) => await service.put(`/teams/${id}`, values)

  return (
    <DataSource
      fetch={fetch}
      render={({ data, loading }) => (
        <SystemPage loading={loading}>
          {data && (
            <Form data={data}>
              <TeamsFormContent id={id} onUpdate={handleSave} />
            </Form>
          )}
        </SystemPage>
      )}
    />
  )
}
