import { useState } from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

import { useService } from 'common/service/context'
import { SystemPage } from 'system/page/core'
import { formatUserName } from 'modules/security/users/utils'
import { formatDate } from 'common/utils/format'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { formatProjectName } from 'modules/projects/utils'
import {
  FoldButtons,
  ArchiveIconButton,
  CancelIconButton,
  DetailsIconButton,
  LoadIconButton,
  SignButton,
  ConfirmIconButton,
} from 'common/widgets/button'
import { QueryConsumer } from 'common/query/context'
import { useToast } from 'system/toast/context'
import {
  BackDeliveryStateColor,
  formatBackDeliveryNumber,
} from 'modules/yard/utils'

import { CancelBackDeliveryOverlay } from './overlays/cancel'

/**
 *  Renders the Back Delivery page
 *
 * @returns ReactElement
 */
export const BackDeliveryListPage = () => {
  const { t } = useTranslation()
  const service = useService()
  const navigate = useNavigate()
  // Format the name of the project inside the back-delivery object
  const formatDeliveryProjectName = (project) =>
    project ? formatProjectName(project) : t('Yard')
  // Back delivery cancelation ovarlay state
  const [cancelBackDeliveryObject, setCancelBackDeliveryObject] = useState(null)
  const { toasts } = useToast()

  /**
   * Archive/Unarchive the given resource calling the standard API.
   *
   * @param {boolean} archived or unarchived flag
   * @returns
   */
  const archive = async (data) => {
    const [result, error] = await service.put(
      `back/delivery/${data.id}/toggle-archived`
    )
    if (!error) {
      !result.archived
        ? toasts.success(
            `${t('Back delivery note')} ${data.id} ${t(
              'sucessfully activated.'
            )}`
          )
        : toasts.success(
            `${t('Back delivery note')} ${data.id} ${t(
              'sucessfully archived.'
            )}`
          )
    }

    return [result, error]
  }

  return (
    <SystemPage>
      <SimpleDataTable
        name="backdeliverynotes"
        url="back/delivery"
        title={t('Back delivery notes')}
        archive
        onAddClick={() => {
          navigate(`/yard/retour/deliveries/adhoc`)
        }}
        addTooltip="Create ad hoc return delivery note"
        rowColor={(r) => BackDeliveryStateColor[r.state]}
      >
        <SimpleColumn
          n={4}
          s={6}
          sortable
          field="id"
          label={t('Delivery number')}
          value={(r) => formatBackDeliveryNumber(r)}
        />
        <SimpleColumn
          n={4}
          s={6}
          field="project_id"
          label={t('Project')}
          filter={(p) => p.id}
          title={(p) => formatDeliveryProjectName(p)}
          filterUrl="/back/delivery/projects"
          value={(r) => formatDeliveryProjectName(r.project)}
          link={(r) => `/projects/${r.project?.id}`}
        />
        <SimpleColumn
          n={4}
          s={6}
          filterable
          field="recipient"
          label={t('Recipient')}
          value={(r) => r.recipient}
        />
        <SimpleColumn
          n={4}
          s={6}
          filter={(p) => p.id}
          title={(p) => formatUserName(p)}
          filterUrl="/back/delivery/creators"
          field="creator_user_id"
          label={t('Logistic user')}
          value={(r) => formatUserName(r.creator_user)}
          link={(r) => `/security/users/${r.creator_user?.id}`}
        />
        <SimpleColumn
          n={4}
          s={6}
          sortable
          field="created_at"
          label={t('Delivery required by')}
          value={(r) => formatDate(r.created_at)}
        />
        <SimpleColumn
          n={4}
          s={6}
          filter={(p) => p}
          title={(p) => t(p)}
          fetch={async () => [
            {
              data: ['READY', 'CANCELED', 'TRANSPORT', 'DELIVERED', 'CHECKED'],
            },
          ]}
          field="state"
          label={t('Status')}
          value={(r) => t(r.state)}
        />
        <SimpleColumn fixed>
          {(r, reload) => (
            <div style={{ width: '150px' }}>
              <FoldButtons>
                {r?.archivable && (
                  <ArchiveIconButton
                    onClick={async (e) => {
                      e.stopPropagation()
                      await archive(r)
                      reload()
                    }}
                    archived={r.archived_on}
                    tooltip="Archive"
                  />
                )}
                {r?.cancelable && (
                  <CancelIconButton
                    tooltip="Cancelation"
                    onClick={(e) => {
                      e.stopPropagation()
                      setCancelBackDeliveryObject(r)
                    }}
                  />
                )}
                {r?.transportable && (
                  <LoadIconButton
                    tooltip="Load"
                    onClick={(e) => {
                      e.stopPropagation()
                      navigate(`/yard/retour/deliveries/${r?.id}/pickup`)
                    }}
                  />
                )}
                {r?.signable && (
                  <SignButton
                    tooltip="Handover"
                    onClick={(e) => {
                      e.stopPropagation()
                      navigate(`/yard/retour/deliveries/${r?.id}/handover`)
                    }}
                  />
                )}
                {r?.checkable && (
                  <ConfirmIconButton
                    tooltip="Check"
                    onClick={(e) => {
                      e.stopPropagation()
                      navigate(`/yard/retour/deliveries/${r?.id}/confirm`)
                    }}
                  />
                )}
                <DetailsIconButton
                  tooltip="Details"
                  onClick={() => navigate(`/yard/retour/deliveries/${r?.id}`)}
                />
              </FoldButtons>
            </div>
          )}
        </SimpleColumn>
        <QueryConsumer>
          {({ reload }) => (
            <CancelBackDeliveryOverlay
              backDelivery={cancelBackDeliveryObject}
              onClose={() => setCancelBackDeliveryObject(null)}
              reload={reload}
              onDeliveryCanceled={() => reload()}
            />
          )}
        </QueryConsumer>
      </SimpleDataTable>
    </SystemPage>
  )
}
