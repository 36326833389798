import { useState, useEffect } from 'react'

import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'
import { useForm } from 'common/widgets/form/context'
import { DropDownField, FreeChooseField } from 'common/widgets/form/field'
import { isNilOrEmpty } from 'common/utils/conditional'

const ChangeMonitor = ({ name, depends = [], data, reload, children }) => {
  // Extracts values out of form context.
  const { values } = useForm()
  // Keeps an state for depend key change
  const [lastDependKey, setLastDependKey] = useState(
    depends.map((name) => values.get(name)).join('#')
  )
  // Makes depend key value
  const dependKey = depends.map((name) => values.get(name)).join('#')

  useEffect(() => {
    setLastDependKey(lastDependKey)
    reload()
  }, [dependKey])

  useEffect(() => {
    const value = values.get(name)
    if (data && !isNilOrEmpty(value)) {
      const found = data.filter((e) => e === value)
      if (found.length === 0) {
        values.set(name, null)
      }
    }
  }, [data])

  return children({ data })
}

const DataProvider = ({
  name,
  handler,
  url,
  params = [],
  depends = [],
  children,
}) => {
  const service = useService()
  // Extracts values out of form context.
  const { values } = useForm()

  const defaultParams = depends
    .filter((name) => !isNilOrEmpty(values.get(name)))
    .map((name) => ({ [name]: values.get(name) }))

  // For programming errors we just need to inform the developer. "params"
  // prop is not user provided, so if it is not an array, then programmer
  // has made an error and should fix it.
  console.assert(
    !params || Array.isArray(params),
    'Programming error. "params" prop must be an array. Fix your code.'
  )

  /**
   * Calls item apis and collects data.
   *
   * @returns [any, any, int]
   */
  const fetch = async () =>
    await service.get(url, [...params, ...defaultParams])

  return (
    <DataSource fetch={fetch} handler={handler}>
      {({ data, reload }) => (
        <ChangeMonitor
          name={name}
          data={data}
          reload={reload}
          depends={depends}
        >
          {children}
        </ChangeMonitor>
      )}
    </DataSource>
  )
}

const ItemFreeField = ({
  handler,
  name,
  url,
  params = [],
  depends = [],
  ...rest
}) => {
  return (
    <DataProvider
      name={name}
      url={url}
      handler={handler}
      params={params}
      depends={depends}
    >
      {({ data }) => (
        <FreeChooseField name={name} items={data || []} {...rest} />
      )}
    </DataProvider>
  )
}

export const EquipmentMaincategoryFreeField = ({
  name = 'maincategory',
  ...rest
}) => <ItemFreeField name={name} url="/items/maincategories" {...rest} />

export const EquipmentCategoryFreeField = ({
  name = 'category',
  dependable,
  ...rest
}) => (
  <ItemFreeField
    name={name}
    url="/items/categories"
    depends={dependable ? ['maincategory'] : []}
    {...rest}
  />
)

export const EquipmentSubcategoryFreeField = ({
  name = 'subcategory',
  dependable,
  ...rest
}) => (
  <ItemFreeField
    name={name}
    url="/items/subcategories"
    depends={dependable ? ['category', 'maincategory'] : []}
    {...rest}
  />
)

const ItemSelectField = ({
  handler,
  name,
  url,
  depends = [],
  params = [],
  ...rest
}) => (
  <DataProvider
    name={name}
    url={url}
    handler={handler}
    params={params}
    depends={depends}
  >
    {({ data }) => {
      return (
        <DropDownField
          name={name}
          items={data?.map((e) => ({ key: e, title: e }))}
          {...rest}
        />
      )
    }}
  </DataProvider>
)

export const EquipmentMaincategorySelectField = ({
  name = 'maincategory',
  ...rest
}) => <ItemSelectField name={name} url="/items/maincategories" {...rest} />

export const EquipmentCategorySelectField = ({
  name = 'category',
  ...rest
}) => (
  <ItemSelectField
    name={name}
    url="/items/categories"
    depends={['maincategory']}
    {...rest}
  />
)

export const EquipmentSubcategorySelectField = ({
  name = 'subcategory',
  ...rest
}) => (
  <ItemSelectField
    name={name}
    url="/items/subcategories"
    depends={['category', 'maincategory']}
    {...rest}
  />
)
