import React from 'react'

import { EditViewPage } from 'system/utils/edit'

import { TaskView } from './add'

/**
 * Renders page for editing a task
 *
 * @returns ReactElement
 */
export const TaskEditPage = () => (
  <EditViewPage
    url="/tasks"
    title={(e) => e.title}
    isArchiveEnable={() => false}
  >
    {(data) => {
      return <TaskView task={data} />
    }}
  </EditViewPage>
)
