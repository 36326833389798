import { useTranslation } from 'react-i18next'

import { Select } from 'common/widgets/select'
import { FieldWrapper } from 'common/widgets/form/field'

export const SubsistenceField = ({ ...rest }) => {
  const { t } = useTranslation()

  const items = [
    { key: null, title: 'None' },
    { key: 'SUBSISTENCE_SMALL', title: t('SUBSISTENCE_SMALL') },
    { key: 'SUBSISTENCE_LARGE', title: t('SUBSISTENCE_LARGE') },
  ]

  return (
    <FieldWrapper name="subsistence" {...rest}>
      {(getValue, setValue) => (
        <Select
          selected={getValue()}
          items={items}
          onSelectChange={(item) => setValue(item.key)}
        />
      )}
    </FieldWrapper>
  )
}
