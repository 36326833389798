import { SourceOverlay } from './form'

export const UpdateSourceOverlay = ({
  request,
  source,
  id,
  selectedSources,
  onClose,
  onUpdate,
  onRemove,
}) => {
  // Finds selectd source
  const selected = selectedSources.find((e) => e.id === id)
  // Filters out old source, if there is a source
  const sources = selectedSources.filter((e) => e.id !== id)
  return (
    <SourceOverlay
      request={request}
      source={source}
      id={id}
      sources={sources}
      data={{
        start: selected.start,
        end: selected.end,
        replaceable: selected.replaceable,
        amount: selected.amount,
      }}
      onClose={onClose}
      onUpdate={onUpdate}
      onRemove={onRemove}
    />
  )
}
