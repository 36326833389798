import { useContext, createContext, useEffect, useState } from 'react'

import { useService } from 'common/service/context'
import { useSession } from 'common/session/context'

// Creates instance of context to use in context provider
const NotificationContext = createContext()

/**
 * Provides notification context for child elements.
 *
 * @param {*} children child elements
 * @returns
 */
export const NotificationProvider = ({ children }) => {
  const service = useService()
  const session = useSession()

  const [unreadCount, setUnreadCount] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      // If unread count is null, it means that it is the first time to call.
      const [response, error] = await service.get('notifications/unreads')
      if (!error) {
        setUnreadCount(parseInt(response.data))
      }
    }
    if (unreadCount === null && session.isLoggedIn()) {
      setUnreadCount(0)
      fetchData()
    }
  }, [unreadCount, service])

  return (
    <NotificationContext.Provider value={{ unreadCount }}>
      {children}
    </NotificationContext.Provider>
  )
}

/**
 * Creates an instance of notification context and returns it.
 *
 * @returns NotificationContext
 */
export const useNotification = () => useContext(NotificationContext)
