import { useTranslation } from 'react-i18next'

import {
  TextField,
  EmailField,
  PhoneNumberField,
  CheckBoxField,
} from 'common/widgets/form/field'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { Row, Column } from 'common/widgets/grid'
import { CommentView, SectionView } from 'common/widgets/view'
import { ProfileImageField } from 'common/widgets/image'
import { useForm } from 'common/widgets/form/context'
import { useSession } from 'common/session/context'

import { UserRoleSelectField } from './roles'

/**
 * Renders users's data entry form.
 *
 * @param {Function} onClose closing hook
 * @returns
 */
export const UserFormContent = ({ onAdd, onUpdate, onRemove }) => {
  const { t } = useTranslation(['security'])
  const { values, dirty } = useForm()
  const { currentUser } = useSession()

  return (
    <>
      <SectionView title="Details">
        <Row>
          <Column title="Profile picture" n={12} m={12} s={12}>
            <ProfileImageField
              userId={values.json.id}
              name={values.json.fullname}
              imageRef={values.json.profile_image_ref}
              onUpload={(file) => {
                dirty && onUpdate && onUpdate()
              }}
              onRemove={() => dirty && onUpdate && onUpdate()}
            />
          </Column>
          <Column n={12} m={12} s={12} flex gap="10px">
            <TextField name="first_name" label="First name" mandatory />
            <TextField name="last_name" label="Last name" mandatory />
          </Column>
          <Column n={12} m={12} s={12} flex gap="10px">
            <EmailField name="email" label="Email" mandatory />
            <PhoneNumberField name="phone_number" label="Phone number" />
          </Column>
          {onAdd && (
            <Column n={12} m={12} s={12}>
              <CheckBoxField
                name="send_invitation"
                label="Invitation"
                i18nScope="security"
              >
                Send invitation E-Mail
              </CheckBoxField>
            </Column>
          )}
          {onUpdate && values?.json?.pending_email && (
            <Column n={12} m={12} s={12}>
              <CommentView
                warning
                comment={
                  t('Email change confirmation required for') +
                  ` ${values?.json?.pending_email}`
                }
              />
            </Column>
          )}
        </Row>
        <UserRoleSelectField
          name="roles"
          label="Roles"
          disabled={!currentUser.is_privileged}
          mandatory
        />
      </SectionView>
      <SimpleFormAction onAdd={onAdd} onUpdate={onUpdate} onRemove={onRemove} />
    </>
  )
}
