import React from 'react'

import { buildURI } from './core'
import { defaultProps as commonDefaultProps } from './metaProps'

const defaultProps = { target: '_blank' }

/**
 * Renders a component to automatically download generated csv file.
 * When this component gets mounted, the download will occur.
 * There would not be a rendering.
 */
export class CSVDownload extends React.Component {
  static defaultProps = Object.assign(commonDefaultProps, defaultProps)

  /**
   * Generates the download link.
   *
   * @param {Array} data data to be exported
   * @param {Boolean} uFEFF add microsoft excel compatibility header
   * @param {Array} headers headers to be exported
   * @param {string} separator separator character to be used in generated csv file
   * @param {string} enclosingCharacter character to be used for quoting each column in generated csv file
   * @returns string
   */
  buildURI(data, uFEFF, headers, separator, enclosingCharacter) {
    return buildURI(data, uFEFF, headers, separator, enclosingCharacter)
  }

  /**
   * When the component gets mounted, it will trigger the download.
   */
  componentDidMount() {
    const {
      data,
      headers,
      separator,
      enclosingCharacter,
      uFEFF,
      target,
      specs,
      done,
    } = this.props
    window.open(
      this.buildURI(data, uFEFF, headers, separator, enclosingCharacter),
      target,
      specs
    )
    done()
  }

  render() {
    return null
  }
}
