import React from 'react'
import { useTranslation } from 'react-i18next'

import { Row, Column } from 'common/widgets/grid'
import {
  DateRangeField,
  NumericField,
  TextField,
  TextAreaField,
} from 'common/widgets/form/field'
import { AddressField } from 'common/widgets/form/address'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { SectionView } from 'common/widgets/view'
import { SubsistenceField } from 'modules/projects/widgets/subsistence'
import { useForm } from 'common/widgets/form/context'
import { CostCenterSelectField } from 'modules/master-data/cost-centers/widgets'

import {
  ProjectPhaseIdSelectField,
  ProjectTypeIdSelectField,
} from './widgets/type'
import { ProjectPhasePlan } from './widgets/plan'

/**
 * Renders Project data entry form.
 *
 * @param onAdd
 * @param onUpdate
 * @param onRemove
 * @param data
 * @returns
 */
export const ProjectFormContent = ({ onAdd, onUpdate, onRemove }) => {
  const ctx = useForm()
  const { t } = useTranslation(['projects'])

  return (
    <>
      <Row>
        <Column n={6} flex gap="10px">
          <SectionView title="Master data" flex>
            <TextField name="name" label="Name" mandatory />
            <TextField name="short_name" label="Short name" />
            <NumericField
              name="shortest_distance"
              label="Shortest distance"
              preDecimalScale={3}
            />
            <ProjectTypeIdSelectField
              name="type_id"
              label="Project template"
              disabled={ctx.values.get('type_id') && !ctx.values.dirty?.type_id}
            />
            <ProjectPhaseIdSelectField
              name="phase_id"
              label="Current phase"
              projectTypeId={
                ctx.values.dirty?.type_id || ctx.values.get('type_id')
              }
            />
            <DateRangeField
              label="Project timeline"
              mandatory
              nameStart="start_date"
              nameEnd="end_date"
            />
            <SubsistenceField label="Subsistence" />
          </SectionView>
          <SectionView>
            <h1>{t('project.form.phaseplan.title')}</h1>
            <h4>{t('project.form.phaseplan.help')}</h4>
            <ProjectPhasePlan
              name="phase_plans"
              projectTypeId={ctx.values.get('type_id')}
              phasePlans={ctx.values.get('phase_plans')}
              onChange={(phasePlans) => {
                ctx.values.set('phase_plans', phasePlans)
              }}
            />
          </SectionView>
        </Column>
        <Column n={6} flex vertical gap="5px">
          <SectionView title="Billing" grow="0">
            <CostCenterSelectField name="cost_center_id" removable />
            <NumericField
              name="contract_volume"
              label="Contract volume"
              decimalScale={2}
            />
            <NumericField
              name="rent_rate"
              label="Rent rate"
              preDecimalScale={3}
            />
            <NumericField
              name="load_rate"
              label="Loading rate"
              preDecimalScale={3}
            />
            <TextAreaField
              name="comment"
              label="Comment"
              maxLength={500}
              rows="5"
            />
          </SectionView>
          <SectionView title="Location">
            <AddressField name="address" showMap editGeofence />
          </SectionView>
        </Column>
      </Row>
      <SimpleFormAction onAdd={onAdd} onUpdate={onUpdate} onRemove={onRemove} />
    </>
  )
}
