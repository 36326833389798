import { BaseMap } from 'common/widgets/map/base'
import { useQuery } from 'common/query/context'
import { formatProjectName } from 'modules/projects/utils'

import { formatItemName, getEquipmentNavPath } from '../utils'

export const ResourceLocationMap = ({ items, showYard = false, ...rest }) => {
  const makeMarkers = (items) => {
    const markers = []

    // In order to display project markers and their checked in items
    // (in one single popup, instead of multiple markers)
    const projectItems = {}
    items?.forEach((r) => {
      if (r.project) {
        if (!projectItems[r.project.id]) {
          projectItems[r.project.id] = []
        }
        projectItems[r.project.id].push(r)
      }
    })
    Object.entries(projectItems).forEach(([projectId, items]) => {
      // all items have the same project and there is at least one item
      const proj = items[0].project
      markers.push({
        lat: proj.address.lat,
        lon: proj.address.lon,
        tooltip: (
          <>
            <h4>{proj.name}</h4>
            <br />
            {items.map((i) => (
              <>
                {formatItemName(i)}
                <br />
              </>
            ))}
          </>
        ),
        url: `/projects/${proj.id}`,
      })
    })

    items
      ?.filter(
        (r) =>
          r.last_location ||
          (r?.project?.address?.lat && r?.project?.address?.lon)
      )
      .forEach((r) => {
        const loc = r.last_location || {
          lat: r?.project?.address?.lat,
          lon: r?.project?.address?.lon,
        }
        markers.push({
          lat: loc.lat,
          lon: loc.lon,
          tooltip: (
            <>
              {r?.project && (
                <>
                  {r.project.name}
                  <br />
                </>
              )}
              {formatItemName(r)}
            </>
          ),
          url: getEquipmentNavPath(r),
          isLive: !!r.last_location,
        })
      })
    return markers
  }
  return <BaseMap markers={makeMarkers(items)} showYard={showYard} {...rest} />
}

export const AssetDistributionMap = ({ ...rest }) => {
  const { records } = useQuery()

  return <ResourceLocationMap items={records} {...rest} />
}

export const FormworkDistributionMap = ({ ...rest }) => {
  const { records } = useQuery()

  const makeMarkers = (items) => {
    const markers = []
    for (const item of items) {
      if (item.distributions && item.distributions.length > 0) {
        item.distributions.forEach((dist) => {
          markers.push({
            lat: dist.project.address.lat,
            lon: dist.project.address.lon,
            tooltip: (
              <>
                Project: {dist.project && formatProjectName(dist.project)}
                <br />
                Item: {formatItemName(item)}
                <br />
                Amount: {dist.amount}
              </>
            ),
            url: getEquipmentNavPath(item),
          })
        })
      }
    }
    return markers
  }

  return (
    <ResourceLocationMap
      markers={records ? makeMarkers(records) : []}
      {...rest}
    />
  )
}
