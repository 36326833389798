import React from 'react'
import { useParams } from 'react-router-dom'

import { useService } from 'common/service/context'
import { Form } from 'common/widgets/form/context'
import { EditViewPage } from 'system/utils/edit'

import { SmallScaleEquipmentFormContent } from './form'

/**
 * Renders page for editing a small scale equipment
 *
 * @returns ReactElement
 */
export const SmallScaleEquipmentEditPage = () => {
  const service = useService()
  const { id } = useParams()

  const handleEdit = async (values) => {
    return await service.put(`items/resources/${id}`, values)
  }

  return (
    <EditViewPage url="/items/resources" title={(e) => e.name}>
      {(data, reload) => {
        return (
          <Form data={data}>
            <SmallScaleEquipmentFormContent onUpdate={handleEdit} />
          </Form>
        )
      }}
    </EditViewPage>
  )
}
