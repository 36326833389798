import parsePhoneNumberFromString from 'libphonenumber-js'

import { isNilOrEmpty } from 'common/utils/conditional'

// Internal utility function to be removed
// consider using Nullish coalescing operator (??) instead
// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Nullish_coalescing
const isNil = (value) => value === null || value === undefined

/**
 * Forces to have value.
 *
 * @param {number} value
 * @returns string
 */
export const forceValue = (value) => (isNilOrEmpty(value) ? 'Required' : null)

/**
 * Checks value length.
 *
 * @param {string} value
 * @param {int} minLen
 * @param {int} maxLen
 * @returns string
 */
export const forceLength = (value, minLen, maxLen) => {
  if (!isNil(value)) {
    if (value.length < minLen) {
      return 'Too short'
    } else if (value.length > maxLen) {
      return 'Too long'
    }
  }
  return null
}

/**
 * Checks int value range.
 *
 * @param {int} value
 * @param {int} min
 * @param {int} max
 * @returns
 */
export const forceIntRange = (value, min, max) => {
  if (value) {
    if (min && parseInt(value) < min) {
      return `Value must be between larger than ${min}`
    } else if (max && parseInt(value) > max) {
      return `Value must be between smaller than ${max}`
    } else {
      return null
    }
  }
}

/**
 * Checks that the given value is an email.
 *
 * @param {string} value
 * @returns string
 */
export const forceEmail = (value) => {
  if (!isNil(value)) {
    const pattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i
    const match = value.match(pattern)
    if (!match) {
      return 'Invalid E-Mail address'
    }
  }
  return null
}

/**
 * Checks that the given value is a valid phone number.
 *
 * @param {string} value phone number
 * @returns string
 */
export const validatePhoneNumber = (value) => {
  if (!value) {
    return null
  }
  return isPhoneNumber(value) ? null : 'Invalid phone number.'
}

export const isPhoneNumber = (value) => {
  if (value) {
    const number = parsePhoneNumberFromString(value)
    if (number?.isValid()) {
      return true
    }
  }
  return false
}
