import { useTranslation } from 'react-i18next'

import { DetailViewPage } from 'system/utils/view'
import { Container } from 'common/widgets/container'
import { Row, Column } from 'common/widgets/grid'
import {
  GridFieldView,
  GridLinkFieldView,
  SectionView,
} from 'common/widgets/view'
import { Barcode } from 'common/widgets/barcode'
import { RemoteImage } from 'common/widgets/image'
import { formatDate } from 'common/utils/format'
import { formatDeliveryNumber } from 'modules/yard/utils'
import { OrderDates } from 'modules/orders/view/order-dates'
import { formatOrderNumber } from 'modules/orders/utils'
import { Avatar } from 'common/widgets/avatar'

import { SourceProjectPicker } from '../project/select'

import { DeliveryItems } from './delivery-items'

/**
 * Renders the deliveries view page.
 *
 * @returns ReactElemnt
 */
export const DeliveryDetailViewPage = () => {
  const { t } = useTranslation()

  return (
    <DetailViewPage
      url="/kommission/delivery"
      title={(delivery) =>
        `${t('Delivery number')}: ${formatDeliveryNumber(
          delivery?.order?.number,
          delivery?.number
        )}`
      }
    >
      {(delivery, reload) => (
        <>
          <DeliveryViewDetails delivery={delivery} order={delivery.order} />
          <DeliveryItems
            delivery={delivery}
            onChange={reload}
            commissionedOnly
            readOnly
          />
        </>
      )}
    </DetailViewPage>
  )
}

/**
 * Renders the Delivery detail info.
 *
 * @param {object} delivery delivery note object
 * @param {any} reload call back for reloading the page
 *
 * @returns ReactElement
 */
export const DeliveryViewDetails = ({ delivery, order, reload }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <Row>
        <Column flex n={6}>
          <SectionView>
            <Container flex justify="space-between">
              <Container>
                <h1>{t('Delivery data')}</h1>
                <Row>
                  <GridLinkFieldView
                    n={6}
                    label={t('Project')}
                    value={order.project.short_name || order.project.name}
                    url={`/projects/${order.project.id}`}
                  />
                  <GridFieldView
                    n={6}
                    label={t('Status')}
                    value={t(delivery.state)}
                  />
                  <GridFieldView
                    n={6}
                    label={t('Recipient')}
                    value={delivery.recipient}
                  />
                  <GridFieldView
                    n={6}
                    label={t('Orderer')}
                    value={order?.orderer}
                  />
                  <GridLinkFieldView
                    n={6}
                    label={t('Logisticer')}
                    value={delivery.creator_user.fullname}
                    url={`/security/users/${delivery.creator_user.id}`}
                  />
                </Row>
              </Container>
              <Container flex>
                <Barcode value={`${delivery.id}`} size={192} />
              </Container>
            </Container>
          </SectionView>
        </Column>
        <Column flex n={6}>
          <SectionView>
            <Row>
              <Column n={6}>
                <h1>{t('Order data')}</h1>
                <Row>
                  <GridLinkFieldView
                    n={12}
                    label={t('Order')}
                    value={formatOrderNumber(order?.number)}
                    url={order ? `/orders/${order.id}` : null}
                  />
                  <GridFieldView
                    n={12}
                    label={t('Ordered at')}
                    value={formatDate(order.ordered_at)}
                  />
                  <GridFieldView n={12} label={t('Delivery option')}>
                    <p>
                      {order
                        ? order.requires_delivery
                          ? t('Requires delivery')
                          : t('Self-pickup')
                        : ''}
                    </p>
                  </GridFieldView>
                  <Column n={12}>
                    <SourceProjectPicker delivery={delivery} reload={reload} />
                  </Column>
                </Row>
              </Column>
              <Column n={6}>
                <Container flex grow align="start" justify="end">
                  {delivery.signature_file_id && (
                    <RemoteImage id={delivery.signature_file_id}>
                      {(data) => <Avatar imgSrc={data} size="192px" />}
                    </RemoteImage>
                  )}
                </Container>
              </Column>
              <Column n={12}>
                {order && <OrderDates order={delivery.order} />}
              </Column>
            </Row>
          </SectionView>
        </Column>
      </Row>
    </Container>
  )
}
