import { useState } from 'react'

import { QueryProvider, useQuery } from 'common/query/context'
import { Container } from 'common/widgets/container'
import { SystemPageLoading } from 'system/page/loading'
import { Pagination } from 'common/widgets/pagination'

import { DataTableFilterWidget } from './filter'
import { DataTableProvider } from './context'
import { DataTableHeader } from './header'

/**
 * Creates query and data tabke context and renders the data table.
 *
 * @param {Function} fetch fetch function
 * @param {string} url fetch url, if passed ignores fetch
 * @param {string} title data table title
 * @param {Array} sortables definition of sortable fields
 * @param {bool} archive flag to specify whether data table handles archived records
 * @returns ReactElement
 */
export const DataTable = ({
  // Name property used in filters to distinguish this DataTable from
  // others on the same page
  url,
  name,
  title,
  fetch,
  sortables,
  // shows archive filter widget if set (hidden by default)
  archive,
  limit,
  usePrefix,
  children,
  ...rest
}) => {
  // Reuse existing query provider
  // In case there is already a parent QueryProvider we can reuse it.
  // Why you might ask? For multiple reasons:
  // 1. It should be possible to nest providers, as React allows it
  // 2. It allows us to reuse DataTable data in another widget. This
  // simplifies our desing, because we wouldn't need to pass other
  // components to DataTable in order to have a detail page for example.
  const queryCtx = useQuery()

  // Finds default sort element
  const defaultSortElement = sortables ? sortables.find((e) => e.default) : null
  // Makes default sort object if it is possible
  const defaultSort = defaultSortElement
    ? {
        field: defaultSortElement.key,
        direction: defaultSortElement.default === 'desc' ? 'desc' : 'asc',
      }
    : {
        field: 'id',
        direction: 'desc',
      }

  const tableContents = (ctx) => (
    <DataTableProvider name={name}>
      <DataTableContents title={title} archive={archive} {...rest}>
        {typeof children === 'function' ? children(ctx) : children}
      </DataTableContents>
    </DataTableProvider>
  )
  return queryCtx ? (
    <>{tableContents(queryCtx)}</>
  ) : (
    <QueryProvider
      url={url}
      fetch={fetch}
      name={name}
      defaultSort={defaultSort}
      limit={limit}
      usePrefix={usePrefix}
    >
      {(ctx) => tableContents(ctx)}
    </QueryProvider>
  )
}

const DataTableContents = ({ title, style, filters, children, ...rest }) => {
  const ctx = useQuery()
  const [showFilters, setShowFilters] = useState(false)

  if (ctx?.error) {
    return null
  }
  return (
    <Container style={style} grow>
      <DataTableHeader
        title={title}
        onFilterClick={() => setShowFilters(!showFilters)}
        showFilterBtn={filters?.length > 0}
        {...rest}
      />
      <SystemPageLoading loading={ctx?.loading} />
      <DataTableFilterWidget
        filters={filters}
        open={showFilters}
        onReset={() => setShowFilters(false)}
      />
      {children}
      <Pagination {...ctx} />
    </Container>
  )
}
