import React, { useEffect, useState } from 'react'

import { Overlay, OverlayFooter } from 'common/widgets/overlay'
import { AddressMap } from 'common/widgets/map/address'
import { SliderInput } from 'common/widgets/slider'
import { ConfirmButton, ClearButton } from 'common/widgets/button'
import { useQuery } from 'common/query/context'

/**
 * An overlay to let user select a radious to search for contacts.
 **/
export const MapOverlay = ({ open, onClose }) => {
  const [selectedPin, setSelectedPin] = useState(null)
  const [radius, setRadius] = useState(1)
  const query = useQuery()

  // Fills the selected pin and radius whenever component gets mounted.
  useEffect(() => {
    const { filter_pin, filter_radius } = extractCurrentFilters()
    if (filter_pin) {
      setSelectedPin(filter_pin)
    }

    if (filter_radius) {
      setRadius(filter_radius)
    }
  }, [])

  // TODO: Why? Needs to be fixed
  const extractCurrentFilters = () => {
    let filter_lat = query.parameters.get('source_lat')
    let filter_lon = query.parameters.get('source_lon')
    let filter_radius = query.parameters.get('radius')
    let result = {}
    if (filter_lat?.length > 0 && filter_lon?.length > 0) {
      result.filter_pin = {
        lat: filter_lat,
        lon: filter_lon,
      }
    }

    if (filter_radius?.length > 0) {
      result.filter_radius = filter_radius
    }

    return result
  }

  /**
   * Triggers a new service call with given filters added to it.
   *
   * @param {Object} pin the coordinates of the selected location on the map in the form of: {lat: value1, lon: value2}
   * @param {Number} radius the selected radius
   */
  const confirmFilter = (pin, radius) => {
    query.parameters.setMany({
      source_lat: pin.lat,
      source_lon: pin.lon,
      radius: radius,
    })
  }

  /**
   * Handles the pin location change event
   *
   * @param {Object} value new pin location value
   */
  const onPinChange = (value) => {
    let lat = value['address.lat']
    let lon = value['address.lon']

    // this branch is for an edge case where the user has done the following steps:
    // 1. selected a location on the map and confirmed the filters.
    // 2. opened the map again and searched for a new location using address bar and selected the new address.
    // 3. without confirming the new filters, user has removed the selected address by clicking on `x` button.
    // so we have to keep showing the last confirmed filters to the user to be aware that previous filters are still in effect.
    if (!lat?.value || !lon?.value) {
      let { filter_pin } = extractCurrentFilters()
      lat.value = filter_pin?.lat
      lon.value = filter_pin?.lon
    }

    setSelectedPin({
      lat: lat?.value,
      lon: lon?.value,
    })
  }

  return (
    <Overlay open={open} onClose={onClose} style={{ width: '40%' }}>
      <AddressMap
        name="map"
        radius={radius}
        onChange={onPinChange}
        address={selectedPin}
        style={{ height: '480px' }}
      />
      <SliderInput
        value={radius}
        min={1}
        max={50}
        onChange={setRadius}
        //disabled={!selectedPin?.lat || !selectedPin?.lon}
        showToolTip
        tipFormatter={(value) => `${value} KM`}
        style={{ margin: '20px', width: 'calc(100% - 40px)' }}
      />
      <OverlayFooter repel>
        <ConfirmButton
          disabled={!selectedPin?.lat || !selectedPin?.lon || radius <= 0}
          onClick={() => {
            confirmFilter(selectedPin, radius)
            onClose && onClose()
          }}
        />
        <ClearButton
          onClick={() => {
            query.parameters.setMany({
              source_lat: undefined,
              source_lon: undefined,
              radius: undefined,
            })
            onClose && onClose()
          }}
        />
      </OverlayFooter>
    </Overlay>
  )
}
