import { useState } from 'react'
import L from 'leaflet'
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polygon,
  Tooltip,
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { useTranslation } from 'react-i18next'

import { useSettings } from 'system/settings/context'

import { Controls } from './controls'
import { SearchBox } from './geosearch'

const LiveUpdateIcon = new L.Icon({
  iconUrl: require('assets/telematics_marker.gif'),
  iconSize: [50, 50],
})

const DefaultIcon = new L.Icon({
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
})

/**
 * Base map component to be used by other components.
 *
 * @param {Boolean} interactive make the map interactive. if set to false, all other map controls will be ignored
 * @param {Boolean} searchBox enable search box on the map
 * @param {Array[Object]} markers a list of marker objects to be drawn on the map
 */
export const BaseMap = ({
  markers,
  geofences,
  interactive = false,
  searchBox = false,
  singleMarker,
  onMarkerAdd,
  onMarkerRemove,
  onMarkerChange,
  onGeofenceAdd,
  onGeofenceRemove,
  onGeofenceChange,
  showYard = false,
  style,
}) => {
  const settings = useSettings()
  const { t } = useTranslation()
  const addr = settings.get('company.address')
  const center = addr?.lat && addr?.lon ? [addr.lat, addr.lon] : [0, 0]
  const zoom = addr?.lat && addr?.lon ? 14 : 0

  if (showYard && addr?.lat && addr?.lon) {
    const icon = new L.Icon({
      iconUrl: require('assets/bauhof_marker.png'),
      iconSize: [36, 45],
    })
    markers ??= []
    markers.push({
      lat: addr.lat,
      lon: addr.lon,
      popup: t('Yard'),
      icon: icon,
    })
  }

  // Leaflet map reference (see the ref on the MapContainer below)
  const [map, setMap] = useState(null)

  if (map) {
    // Configure scroll behaviour, which leads to better UX
    map.on('focus', () => {
      map.scrollWheelZoom.enable()
    })
    map.on('click', () => {
      map.scrollWheelZoom.enable()
    })
    map.on('blur', () => {
      map.scrollWheelZoom.disable()
    })
    map.on('mouseout', () => {
      map.scrollWheelZoom.disable()
    })
    // Refit the map
    let bounds = []
    if (markers?.length) {
      bounds = bounds.concat(markers)
    }
    if (geofences?.length) {
      bounds = bounds.concat(geofences)
    }
    if (bounds.length) {
      map.fitBounds(bounds, { padding: [10, 10] })
    }
  }

  const displayMap = (
    <MapContainer
      center={center}
      zoom={zoom}
      scrollWheelZoom={false}
      dragging={!L.Browser.mobile}
      touchZoom={L.Browser.mobile}
      ref={setMap}
      style={{
        // setting height is mandatory according to leaflet docs
        // users of this component can change it of course
        height: '400px',
        borderRadius: '5px',
        ...(style ?? {}),
      }}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.de/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png"
        maxZoom={20}
      />
      {geofences?.map((g, index) => (
        <Polygon key={index} positions={g} />
      ))}
      {markers?.map((item, index) => {
        const props = {}
        if (item?.icon) {
          props.icon = item.icon
        } else if (item?.isLive) {
          props.icon = LiveUpdateIcon
        } else {
          props.icon = DefaultIcon
        }
        return (
          <Marker
            key={index}
            position={[item?.lat, item?.lon]}
            eventHandlers={{
              click: (e) => {
                if (item?.url) {
                  window.open(item?.url, '_self')
                }
              },
            }}
            {...props}
          >
            {item?.popup && <Popup>{item.popup}</Popup>}
            {item.tooltip && <Tooltip>{item.tooltip}</Tooltip>}
          </Marker>
        )
      })}
      {interactive && map && (
        <Controls
          singleMarker={singleMarker}
          onMarkerAdd={(e, latlon) => {
            onMarkerAdd && onMarkerAdd(e, { map, latlon })
          }}
          onMarkerRemove={(e, latlon) => {
            onMarkerRemove && onMarkerRemove(e, { map, latlon })
          }}
          onMarkerChange={(e, latlon) => {
            onMarkerChange && onMarkerChange(e, { map, latlon })
          }}
          onGeofenceAdd={(e, geofence) => {
            onGeofenceAdd && onGeofenceAdd(e, { map, geofence })
          }}
          onGeofenceRemove={(e, geofence) => {
            onGeofenceRemove && onGeofenceRemove(e, { map, geofence })
          }}
          onGeofenceChange={(e, geofence) => {
            onGeofenceChange && onGeofenceChange(e, { map, geofence })
          }}
        />
      )}
      {searchBox && (
        <SearchBox
          locationFound={(e, map) => {
            const location = { ...e.marker.getLatLng() }
            console.debug('location found', location)
          }}
          locationRemoved={(map) => {
            console.debug('locationRemoved', map)
          }}
        />
      )}
    </MapContainer>
  )

  return displayMap
}
