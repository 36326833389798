import { useTranslation } from 'react-i18next'

import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'
import { DropDown } from 'common/widgets/dropdown'
import { FieldWrapper } from 'common/widgets/form/field'

export const CostCenterSelectField = ({
  onSelectChange,
  removable,
  ...rest
}) => {
  return (
    <FieldWrapper name={name} {...rest}>
      {(getValue, setValue, values) => (
        <CostCenterSelect
          removable
          selectedCostCenterId={getValue()}
          onSelectChange={(costCenter) => {
            if (costCenter) {
              setValue(costCenter.id)
            } else {
              setValue(null)
            }
            if (onSelectChange) {
              onSelectChange(costCenter)
            }
          }}
          {...rest}
        />
      )}
    </FieldWrapper>
  )
}

export const CostCenterSelect = ({
  handler,
  onSelectChange,
  hint,
  disabled,
  removable,
  selectedCostCenterId,
  maxItems = 10,
}) => {
  const service = useService()
  const { t } = useTranslation()
  const params = [
    { archived: false, include_cost_center_id: selectedCostCenterId },
  ]

  const fetch = async () =>
    await service.get('billing/accounts/cost-centers', params)

  const render = ({ data }) => {
    var items = data
      ? data.map((costCenter) => ({
          key: costCenter.id,
          title: formatCostCenterTitle(costCenter),
          data: costCenter,
        }))
      : []

    const sIndex =
      selectedCostCenterId &&
      items.findIndex((p) => p.key == selectedCostCenterId)

    return (
      <DropDown
        minWidth="320px"
        maxWidth="320px"
        title={t(hint) || t('Cost center')}
        items={items}
        selectedIndex={sIndex}
        disabled={disabled}
        removable={removable}
        maxItems={maxItems}
        onSelectChange={(e) => {
          if (onSelectChange) {
            onSelectChange(e?.data)
          }
        }}
        searchFilter={(option, searchTerm) => {
          const st = searchTerm.toLowerCase()
          return (
            option?.title.toLowerCase().includes(st) ||
            option?.data?.name.toLowerCase().includes(st) ||
            option?.data?.code.toLowerCase().includes(st)
          )
        }}
      />
    )
  }

  return <DataSource fetch={fetch} handler={handler} render={render} />
}

const formatCostCenterTitle = (costCenter) => {
  if (!costCenter) {
    return '-'
  }

  if (costCenter.code === costCenter.name) {
    return costCenter.code
  }

  return `${costCenter.code} - ${costCenter.name}`
}
