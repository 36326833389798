/**
 * Returns sum of values inside complex or simple array.
 *
 * @param {Array} collection array of objects or simple numbers
 * @param {String | Function} field specifies how to get field inside an object, by name or by function
 * @returns
 */
export const sum = (collection, field) =>
  collection && collection.length > 0
    ? collection
        .map((e) =>
          typeof field === 'string' ? e[field] : field ? field(e) : e
        )
        .reduce((prev, next) => prev + next)
    : 0
