import { register } from 'system/container'

import { SmallScaleEquipmentAddPage } from './add'
import { SmallScaleEquipmentListPage } from './list'
import { SmallScaleEquipmentEditPage } from './edit'
import { SmallScaleEquipmentViewPage } from './view'

// Registers small scale equipments page
register({
  menu: true,
  path: '/master-data/equipments/small-scales',
  title: 'Small scale equipments',
  render: () => <SmallScaleEquipmentListPage />,
})
// Registers projects page inside system container.
register({
  render: () => <SmallScaleEquipmentAddPage />,
  path: '/master-data/equipments/small-scales/add',
  title: 'Neu',
})

// Registers small scale edit page inside system container.
register({
  render: () => <SmallScaleEquipmentEditPage />,
  path: '/master-data/equipments/small-scales/:id/edit',
  title: 'Edit',
})

// Registers projects page inside system container.
register({
  render: () => <SmallScaleEquipmentViewPage />,
  path: '/master-data/equipments/small-scales/:id',
  title: 'Details',
})
