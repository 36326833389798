import { Settings } from 'react-feather'

import { register } from 'system/container'

import { SettingsPage } from './form'

register({
  icon: <Settings />,
  render: () => <SettingsPage />,
  title: 'Settings',
  path: '/settings/',
  menu: true,
  // submenu priority
  priority: 90,
})

register({
  icon: <Settings />,
  title: 'Settings',
  // Subsystem base route, no trailing slash
  path: '/settings',
  bottom: true,
  // mainmenu priority, lower is higher
  priority: 80,
})
