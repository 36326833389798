import ReactFlow, { Controls, ReactFlowProvider } from 'reactflow'
import { useEffect, useState } from 'react'

import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'
import { getLayoutedElements } from 'common/utils/graph'

import styles from '../tag.module.css'

/**
 * Builds reactflow elements out of the given tree structure.
 *
 * @param {Array} nodes tags as parent child nodes
 */

const makeInitialElements = (tags) => {
  const nodes = []
  const edges = []
  for (const tag of tags) {
    let el = {
      id: `${tag.id}`,
      //type: 'tag',
      data: { label: tag.name, tag: tag },
      position: { x: 0, y: 0 },
    }
    nodes.push(el)
    if (tag.parent_id) {
      let el2 = {
        id: `${tag.parent_id}-${tag.id}`,
        //type: 'float',
        //data: tag,
        source: `${tag.parent_id}`,
        target: `${tag.id}`,
        markerEnd: { type: 'arrowclosed' },
        animated: false,
      }
      edges.push(el2)
    }
  }
  return { nodes, edges }
}

const findNode = (nodes, id) => {
  const rFind = (nodes) => {
    for (const node of nodes) {
      if (node.id === id) {
        return node
      } else if (node.children?.length > 0) {
        const result = rFind(node.children)
        if (result) {
          return result
        }
      }
    }
  }
  return rFind(nodes)
}

const ContactsTagTreeDesign = ({ data, onParentChange, ...rest }) => {
  const [tags, setTags] = useState(data)
  const initialElements = tags ? makeInitialElements(tags) : null
  const edges = initialElements?.edges || []
  const nodes = initialElements
    ? getLayoutedElements(initialElements.nodes, initialElements.edges)
    : []

  const getChildren = (id) => {
    const rCollect = (node, list) => {
      for (const c of node.children) {
        list.push(c.id)
        rCollect(c, list)
      }
    }
    const node = findNode(nodes, id)
    const results = []
    if (node) {
      rCollect(node, results)
    }
    return results
  }

  const addConnection = (nsource, ntarget) => {
    const source = parseInt(nsource)
    const target = parseInt(ntarget)
    const children = getChildren(target)
    if (!children.includes(source) && source !== target) {
      const all = tags.filter((e) => e.id !== target)
      const child = tags.find((e) => e.id === target)
      child.parent_id = source
      setTags([...all, child])
      onParentChange(target, source)
    } else {
      console.debug(`${target} is child of ${source}!`)
    }
  }

  useEffect(() => {
    setTags(data)
  }, [data])

  return (
    <div className={styles.designTree} {...rest}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onConnect={(c) => addConnection(c.source, c.target)}
        onInit={(reactFlowInstance) => reactFlowInstance.fitView()}
      >
        <Controls />
      </ReactFlow>
    </div>
  )
}

/**
 * Renders a designer for tags.
 *
 * @returns ReactElement
 */
export const ContactsTagTree = ({ ...rest }) => {
  const service = useService()
  const fetch = async () => await service.get('contacts/defs/tags')
  const handleParentChange = (childId, parentId) => {
    service
      .put(`contacts/defs/tags/${childId}`, { parent_id: parentId })
      .then(console.debug)
  }

  return (
    <DataSource
      fetch={fetch}
      render={({ data }) => (
        <ReactFlowProvider>
          <ContactsTagTreeDesign
            data={data}
            onParentChange={handleParentChange}
            {...rest}
          />
        </ReactFlowProvider>
      )}
    />
  )
}
