import { register } from 'system/container'

import { ContactPresentAddPage } from './add'
import { ContactPresentEditPage } from './edit'

register({
  render: () => <ContactPresentAddPage />,
  title: 'Add',
  path: '/master-data/contacts/:id/presents/add',
  priority: 0.0,
})

register({
  render: () => <ContactPresentEditPage />,
  title: 'Edit',
  path: '/master-data/contacts/:id/presents/:presentId/edit',
  priority: 0.0,
})
