import { register } from 'system/container'
import { SystemPage } from 'system/page/core'

import { RequestsPage } from './shrinks'
import { ExtendRequestsPage } from './extensions'

register({
  menu: true,
  render: () => <SystemPage />,
  title: 'Requests',
  path: '/disposition/requests',
  priority: 0.0,
})

register({
  menu: true,
  render: () => <RequestsPage />,
  title: 'Booking shrinks',
  path: '/disposition/requests/shrink',
  priority: 0.0,
})

register({
  menu: true,
  render: () => <ExtendRequestsPage />,
  title: 'Booking extensions',
  path: '/disposition/requests/extend',
  priority: 0.0,
})
