import { useParams } from 'react-router-dom'

import { Form } from 'common/widgets/form/context'
import { SystemPage } from 'system/page/core'
import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'

import { OrderedItemFormContent } from './form'

export const OrderedItemEditPage = () => {
  const service = useService()
  // Extract ordered item info from url params
  const { orderId, orderedItemId } = useParams()

  /**
   * Calls a put call and updated ordered item info.
   *
   * @param {any} values orderdd item data
   * @returns
   */
  const handleSave = async (values) => {
    const normalized = { ...values }
    return await service.put(
      `orders/${orderId}/items/${orderedItemId}`,
      normalized
    )
  }

  /**
   * Calls a delete call and removes the given ordered item.
   *
   * @param {any} values orderdd item data
   * @returns
   */
  const handleRemove = async () => {
    return await service.delete(`/orders/${orderId}/items/${orderedItemId}`)
  }

  /**
   * Fetches information about baseitem and ordered item.
   *
   * @returns object
   */
  const fetch = async () => {
    const [orderedItemResult, orderedItemError] = await service.get(
      `/orders/items/${orderedItemId}`
    )
    if (orderedItemError) {
      return [orderedItemResult, orderedItemError]
    }
    const [baseItemResult, baseItemError] = await service.get(
      `/orders/${orderId}/base-items/${orderedItemResult.data.item_id}`
    )
    if (baseItemError) {
      return [baseItemResult, baseItemError]
    }
    // Extracts needed data and packs them in an object
    return [
      {
        data: {
          orderedItem: {
            amount: orderedItemResult.data.amount,
            comment: orderedItemResult.data.comment,
            booking_start: orderedItemResult.data.booking_start,
            booking_end: orderedItemResult.data.booking_end,
            booking: orderedItemResult.data.booking,
            fixed_date: orderedItemResult.data.fixed_date,
          },
          baseItemInfo: baseItemResult.data,
          relateds: baseItemResult.data.relateds,
          frequents: baseItemResult.data.frequents,
          baseitem: baseItemResult.data.baseitem,
          order: baseItemResult.data.order,
        },
      },
      null,
    ]
  }

  return (
    <DataSource
      fetch={fetch}
      render={({ data, loading }) => (
        <SystemPage loading={loading}>
          {data && (
            <Form data={data?.orderedItem}>
              <OrderedItemFormContent
                data={data}
                onUpdate={handleSave}
                onRemove={handleRemove}
              />
            </Form>
          )}
        </SystemPage>
      )}
    />
  )
}
