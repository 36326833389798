import { addTranslation } from 'common/i18n/core'

const de = {
  'Current inventory': 'Aktueller Bestand',
  'Total check-in amount': 'Gesamte Check-in-Menge',
  'Total check-out amount': 'Gesamte Check-out-Menge',
  'project.form.phaseplan.title': 'Geplannte Projektstunden und Budget',
  'project.form.phaseplan.help':
    'Diese Werte werden bei Aufgabenerstellung kontrolliert.',
  'Planning hours have been successfully added':
    'Planungsstunden wurden erfolgreich hinzugefügt',
  'Address not available.': 'Keine Adresse.',
}

const en = {
  'project.form.phaseplan.title': 'Planned hours and budget per phase',
  'project.form.phaseplan.help':
    'Orinoco will control these limits during the lifetime ' +
    'of the project and its tasks.',
}

addTranslation('de', 'projects', de)
addTranslation('en', 'projects', en)
