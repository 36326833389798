import { useParams } from 'react-router-dom'

import { Form } from 'common/widgets/form/context'
import { SystemPage } from 'system/page/core'
import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'

import { OrderedItemFormContent } from './form'

export const OrderedItemAddPage = () => {
  const service = useService()
  // Extract ordered item info from url params
  const { orderId, baseItemId } = useParams()

  /**
   * Calls a post call and creates a new ordered item.
   *
   * @param {any} values ordered item data
   * @returns
   */
  const handleAdd = async (values) => {
    const normalized = { ...values, item: baseItemId }
    return await service.post(`orders/${orderId}/items`, normalized)
  }

  /**
   * Fetches information about baseitem and order.
   *
   * @returns object
   */
  const fetch = async () =>
    await service.get(`orders/${orderId}/base-items/${baseItemId}`)

  return (
    <SystemPage>
      <DataSource
        fetch={fetch}
        render={({ data }) => (
          <Form>
            {data && <OrderedItemFormContent data={data} onAdd={handleAdd} />}
          </Form>
        )}
      />
    </SystemPage>
  )
}
