import { DetailViewPage } from 'system/utils/view'

/**
 * Renders booking view page.
 * @returns ReactElement
 */
export const BookingViewPage = () => {
  return (
    <DetailViewPage url="/dispositions/bookings" title="Booking" editable>
      {(data, reload) => <></>}
    </DetailViewPage>
  )
}
