import {
  ColorPickerField,
  TextField,
  TextAreaField,
} from 'common/widgets/form/field'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { SectionView } from 'common/widgets/view'

export const ContactStateFormContent = ({ onUpdate, onRemove, onAdd }) => {
  return (
    <>
      <SectionView title="Master data">
        <TextField name="name" label="Name" mandatory />
        <ColorPickerField name="color" label="Color" mandatory />
        <TextAreaField name="comment" label="Comment" rows="5" />
      </SectionView>
      <SimpleFormAction onUpdate={onUpdate} onRemove={onRemove} onAdd={onAdd} />
    </>
  )
}
