import { EditViewPage } from 'system/utils/edit'

import { CostCenterForm } from './form'

/**
 * Renders cost center edit page.
 *
 */
export const CostCenterEditPage = () => {
  return (
    <EditViewPage url="/billing/accounts/cost-centers" title={(e) => e.name}>
      {(data) => <CostCenterForm data={data} />}
    </EditViewPage>
  )
}
