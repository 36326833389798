import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useService } from 'common/service/context'
import { Form } from 'common/widgets/form/context'
import { SystemPage } from 'system/page/core'

import { ConsumablesFormContent } from './form'

/**
 * Renders page for adding a consumable
 *
 * @returns ReactElement
 */
export const ConsumablesAddPage = () => {
  const service = useService()
  const navigate = useNavigate()

  const handleAdd = async (values) => {
    return await service.post('items/consumables/', values)
  }

  const handleSuccess = async (response) => {
    navigate(`/master-data/equipments/consumables/${response.data.id}`)
  }

  return (
    <SystemPage>
      <Form>
        <ConsumablesFormContent onAdd={handleAdd} onSuccess={handleSuccess} />
      </Form>
    </SystemPage>
  )
}
