.language {
  display: flex;
  margin-right: 20px;
  width: 120px;
  height: 50px;
  border-radius: 5px;
  transition: 0.2s ease-out;
  align-items: center;
  background: #eeeeee;
  border: 1px solid #aaaaaa;
}
.language :hover {
  cursor: pointer;
}

.language svg {
  margin-left: 10px;
  color: #b8b8b8;
  width: 18px;
  height: 18px;
}

.language p {
  margin: auto;
  color: #b8b8b8;
}

.languageSelected {
  composes: language;
  background: #fafafa;
  border: 1px solid #4a7bf5;
  box-shadow: 0px 3px 24px #e0e0e0;
}

.languageSelected svg {
  color: #303030;
}

.languageSelected p {
  color: #303030;
}
