import { useParams } from 'react-router-dom'

import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'
import { Form } from 'common/widgets/form/context'
import { SystemPage } from 'system/page/core'

import { UserFormContent } from './form'

export const UserEditPage = () => {
  const { id } = useParams()
  const service = useService()

  const update = async (values) => {
    values.roles = values?.roles?.map((r) => r.id)
    return await service.put(`/users/${id}`, values)
  }

  return (
    <DataSource
      url={`/users/${id}`}
      render={({ data, error, loading }) => (
        <SystemPage loading={loading}>
          <Form data={data}>
            <UserFormContent onUpdate={update} />
          </Form>
        </SystemPage>
      )}
    />
  )
}
