import { Form } from 'common/widgets/form/context'
import { TextField, TextAreaField, FileField } from 'common/widgets/form/field'
import { CardView } from 'common/widgets/view'

import { CategorySelectField } from './widgets'

export const CategoryForm = ({ ...rest }) => {
  return (
    <Form baseUrl="/pim/categories" showActions auto {...rest}>
      <CardView>
        <TextField name="name" label="Name" mandatory />
        <CategorySelectField name="parent_id" label="Parent group" />
        <FileField
          name="photo"
          label="Photo"
          accept="image/png, image/jpeg"
          preview
        />
        <TextAreaField name="description" label="Description" rows="5" />
      </CardView>
    </Form>
  )
}
