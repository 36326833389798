import styled from 'styled-components'

import { PagedDataSource } from 'common/widgets/data-source'
import { CardView } from 'common/widgets/view'
import { Container } from 'common/widgets/container'
import { SearchInput } from 'common/widgets/search'
import { OverlayForm } from 'common/widgets/overlay'

export const SelectOverlay = ({
  url,
  title,
  open,
  params,
  maxItems = 5,
  onSelect,
  onClose,
  renderRecord,
}) => {
  return (
    <OverlayForm open={open} onClose={onClose} title={title}>
      <PagedDataSource url={url} limit={maxItems} params={params}>
        {({ data, totalCount }) => (
          <>
            <SearchInput />
            <SelectRecords>
              {data?.map((r, i) => (
                <CardView
                  key={i}
                  divide
                  onClick={async () => {
                    if (onSelect) {
                      await onSelect(r)
                    }
                    onClose()
                  }}
                  flex
                  gap="10px"
                  interactive
                >
                  {renderRecord(r, i)}
                </CardView>
              ))}
            </SelectRecords>
          </>
        )}
      </PagedDataSource>
    </OverlayForm>
  )
}

const SelectRecords = styled(Container)`
  width: 300px;
  min-height: 330px;
`
