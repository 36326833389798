.nodeContainer {
  background: #ffffff;
  color: #303030;
  border: 1px solid #303030;
  border-radius: 2px;
}

.nodeContent {
  background: #ffffff;
  color: #303030;
  padding-left: 5px;
  padding-right: 10px;
  padding-top: 5px;
  overflow-x: hidden;
  max-width: 200px;
}
