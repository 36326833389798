import { useTranslation } from 'react-i18next'

import { useForm } from 'common/widgets/form/context'
import { Column, Row } from 'common/widgets/grid'
import {
  FieldView,
  GridFieldView,
  GridLinkFieldView,
  SectionView,
} from 'common/widgets/view'
import { ItemProcessEnum } from 'modules/master-data/equipments/utils'
import {
  EquipmentCategorySelectField,
  EquipmentMaincategorySelectField,
  EquipmentSubcategorySelectField,
} from 'modules/master-data/equipments/widgets/category'
import { formatProjectName } from 'modules/projects/utils'
import { formatOrderNumber } from 'modules/orders/utils'
import { formatDate, formatDateRange } from 'common/utils/format'
import { formatUserName } from 'modules/security/users/utils'

export const PendingRequestData = ({ request }) => {
  const { t } = useTranslation()
  const { values } = useForm()

  let color = '#303030'
  if (
    values.json.maincategory !== request.ordered_item.item.maincategory ||
    values.json.subcategory !== request.ordered_item.item.subcategory ||
    values.json.category !== request.ordered_item.item.category
  ) {
    // red
    color = '#FC4242'
  }

  return (
    <SectionView>
      <Row>
        <Column n={6}>
          <FieldView label={t('Main group')}>
            <EquipmentMaincategorySelectField
              params={[
                { type: request.ordered_item.item.type },
                { process: ItemProcessEnum.DISPOSITION },
              ]}
            />
          </FieldView>
        </Column>
        <GridFieldView n={6} label={t('Ordered main group')}>
          <h4 style={{ color: color }}>
            {request.ordered_item.item.maincategory}
          </h4>
        </GridFieldView>
        <Column n={6}>
          <FieldView label={t('Group')}>
            <EquipmentCategorySelectField
              params={[
                { type: request.ordered_item.item.type },
                { process: ItemProcessEnum.DISPOSITION },
              ]}
            />
          </FieldView>
        </Column>
        <GridFieldView n={6} label={t('Ordered group')}>
          <h4 style={{ color: color }}>{request.ordered_item.item.category}</h4>
        </GridFieldView>
        <Column n={6}>
          <FieldView label={t('Subgroup')}>
            <EquipmentSubcategorySelectField
              params={[
                { type: request.ordered_item.item.type },
                { process: ItemProcessEnum.DISPOSITION },
              ]}
            />
          </FieldView>
        </Column>
        <GridFieldView n={6} label={t('Ordered subgroup')}>
          <h4 style={{ color: color }}>
            {request.ordered_item.item.subcategory}
          </h4>
        </GridFieldView>
      </Row>
    </SectionView>
  )
}

export const PendingRequestOverview = ({ request }) => {
  const { t } = useTranslation()
  return (
    <SectionView>
      <Row>
        <GridLinkFieldView
          n={6}
          s={6}
          label={t('Project')}
          value={formatProjectName(request.project)}
          url={`/projects/${request.project.id}`}
        />
        <GridLinkFieldView
          n={6}
          s={6}
          label={t('Order')}
          value={formatOrderNumber(request.ordered_item.order?.number)}
          url={`/orders/${request.ordered_item.order.id}`}
        />
        <GridFieldView n={6} s={6} label={t('Amount')} value={request.amount} />
        <GridFieldView
          n={6}
          s={6}
          label={t('Required booking period')}
          value={formatDateRange(request.start, request.end)}
        />
        <GridFieldView
          n={6}
          s={6}
          label={t('Earliest')}
          value={formatDate(request.ordered_item.booking_start)}
        />
        <GridFieldView
          n={6}
          s={6}
          label={t('Latest')}
          value={formatDate(request.ordered_item.booking_end, {
            friendly: true,
          })}
        />
        <GridFieldView
          n={6}
          s={6}
          label={t('Fixed date')}
          value={formatDate(request.ordered_item.fixed_date)}
        />
        <GridFieldView
          n={6}
          s={6}
          label={t('Planned delivery date')}
          value={formatDate(request.ordered_item.order.planned_delivery_date)}
        />
        <GridFieldView
          n={6}
          s={6}
          label={t('Orderer')}
          value={formatUserName(request.requester_user)}
        />
        <GridFieldView
          n={6}
          s={6}
          label={t('Ordered at')}
          value={formatDate(request.ordered_item.order.ordered_at)}
        />
      </Row>
    </SectionView>
  )
}
