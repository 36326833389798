.eventContainer {
  overflow: hidden;
}
.eventContainer h5 {
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  color: #000000cc;
}
.eventContainer h4 {
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  color: #000000;
}
.eventContainer p {
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  color: #000000cc;
}

.eventContainer svg {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  color: #000000cc;
}

.eventContainerTooltip {
  position: absolute;
  opacity: 0;
  transition: 0.3s;
  background-color: white;
  border-radius: 5px;
  align-items: center;
  display: flex;
  padding: 2px 2px;
}
