import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { DataSource } from 'common/widgets/data-source'
import { SectionView } from 'common/widgets/view'
import { SystemPage } from 'system/page/core'
import { Column, Row } from 'common/widgets/grid'

import { OrderStateColor } from './utils'
import styles from './orders.module.css'

/**
 * Renders a simple overview on orders.
 * @returns ReactElement
 */
export const OrdersOverviewPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <SystemPage>
      <DataSource url="orders/analytics">
        {({ data }) => (
          <>
            <div>
              <h1>{t('All')}</h1>
              <Row>
                {data?.all.map(({ state, count }, index) => (
                  <Column n={3} key={index}>
                    <SectionView
                      className={styles.statCard}
                      style={{ color: OrderStateColor[state] }}
                      flex
                      vertical
                      grow
                      onClick={() => navigate(`/orders/?state=${state}`)}
                    >
                      <h1>{count}</h1>
                      <h2>{t(state)}</h2>
                    </SectionView>
                  </Column>
                ))}
              </Row>
            </div>
            <div>
              <h1>{t('Today')}</h1>
              <Row>
                {data?.today.map(({ state, count }, index) => (
                  <Column n={3} key={index}>
                    <SectionView
                      className={styles.statCard}
                      style={{ color: OrderStateColor[state] }}
                      flex
                      vertical
                      grow
                      color={OrderStateColor[state]}
                    >
                      <h1>{count}</h1>
                      <h2>{t(state)}</h2>
                    </SectionView>
                  </Column>
                ))}
              </Row>
            </div>
          </>
        )}
      </DataSource>
    </SystemPage>
  )
}
