import { addTranslation } from 'common/i18n/core'

// Please put only big sentences here. Single words go to the main
// translation modules.
const resources = {
  'Min stock should be smaller than max stock':
    'Min. Bestand muss kleiner als max. Bestand sein',
  'Max stock should be larger than min stock':
    'Max. Bestand muss großer als min. Bestand sein',
  'Value should be smaller than': 'Der Wert muss kleiner sein als',
  'Minimum and maximum stock levels': 'Min. und Max. Bestand',
}

addTranslation('de', 'equipments', resources)
