import React from 'react'
import { Handle, Position } from 'reactflow'

import { Container } from 'common/widgets/container'

import styles from './phases.module.css'

const ProjectPhaseNode = ({ data }) => {
  const color = data.phase.color ? data.phase.color : '#ffffff'
  return (
    <Container className={styles.nodeContainer} flex>
      <Handle
        type="target"
        position={Position.Left}
        style={{ borderRadius: 0 }}
      />
      <Handle
        type="source"
        position={Position.Right}
        id="b"
        style={{ borderRadius: 0 }}
      />
      <Container
        className={styles.nodeContent}
        style={{
          borderLeft: `10px solid ${color}`,
        }}
      >
        <h4>{data.label}</h4>
      </Container>
    </Container>
  )
}

export default ProjectPhaseNode
