import styled from 'styled-components'
import { MoreHorizontal } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { Container } from 'common/widgets/container'
import { useMediaQuery } from 'common/utils/hooks'

import styles from './pagination.module.css'

export const Pagination = ({
  limit,
  setLimit,
  offset,
  setOffset,
  totalCount,
}) => {
  const { t } = useTranslation()
  const isSmallScreen = useMediaQuery('(max-width: 768px)')

  // note that we should also consider the last page. so if (totalCount / limit) is not an
  // integer (ex. 65.2), we should take the ceiling of that number as max (66 in this case).
  const maxPageCount = Math.ceil(totalCount / limit)

  // Calculates passed records
  // note that the last page might not have the length of a full size page,
  // so we should calculate the correct value for `passed`.
  const passed =
    totalCount > limit ? Math.min((offset + 1) * limit, totalCount) : totalCount

  const buttonsDesktop = []
  const buttonsMobile = []
  const availableDesktop = [
    0,
    1,
    maxPageCount - 1,
    maxPageCount - 2,
    parseInt(Math.floor(maxPageCount / 2)),
    offset,
    offset - 1,
    offset - 2,
    offset + 1,
    offset + 2,
  ]
  const availableMobile = [0, maxPageCount - 1, offset, offset - 1, offset + 1]
  for (var i = 0; i < maxPageCount; i++) {
    if (
      availableDesktop.includes(i) ||
      buttonsDesktop[buttonsDesktop.length - 1] !== null
    ) {
      buttonsDesktop.push(availableDesktop.includes(i) ? i : null)
    }
    if (
      availableMobile.includes(i) ||
      buttonsMobile[buttonsMobile.length - 1] !== null
    ) {
      buttonsMobile.push(availableMobile.includes(i) ? i : null)
    }
  }

  // No need to display anything if everything fits in one page
  if (maxPageCount <= 1 && passed == totalCount) {
    return null
  }

  const btns = (isSmallScreen ? buttonsMobile : buttonsDesktop).map(
    (i, index) =>
      i !== null ? (
        <PaginationButton
          key={index}
          onClick={() => setOffset(i)}
          active={i === offset}
        >
          <h4>{i + 1}</h4>
        </PaginationButton>
      ) : (
        <MoreHorizontal key={index} />
      )
  )
  return (
    <Container flex repel noprint>
      {btns.length > 0 && (
        <Container>
          <label>{`${t('Showing')}: ${passed} / ${totalCount}`}</label>
          <Container className={styles.btnList}>{btns}</Container>
        </Container>
      )}
      {limit && (
        <div>
          <label className={styles.limitLabel}>{t('Results per page')}</label>
          <Container className={styles.btnList} flex>
            {totalCount > 5 && (
              <PaginationButton active={limit == 5} onClick={() => setLimit(5)}>
                <h4>5</h4>
              </PaginationButton>
            )}
            {totalCount > 10 && (
              <PaginationButton
                active={limit == 10}
                onClick={() => setLimit(10)}
              >
                <h4>10</h4>
              </PaginationButton>
            )}
            {totalCount > 20 && (
              <PaginationButton
                active={limit == 20}
                onClick={() => setLimit(20)}
              >
                <h4>20</h4>
              </PaginationButton>
            )}

            {totalCount > 50 && (
              <PaginationButton
                active={limit == 50}
                onClick={() => setLimit(50)}
              >
                <h4>50</h4>
              </PaginationButton>
            )}
          </Container>
        </div>
      )}
    </Container>
  )
}

const PaginationButton = styled.div`
  border-radius: 5px;
  border: 1px solid #30303010;
  background: ${(props) => (props.active ? '#376DF4' : 'transparent')};
  transition: 0.3s;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;

  h4 {
    margin: auto;
    color: ${(props) => (props.active ? '#ffffff' : '#8B8B8B')};
  }

  :hover {
    background: ${(props) => (props.active ? '#376DF4' : '#E0E0E0')};
    h4 {
      color: ${(props) => (props.active ? '#ffffff' : '#303030')};
    }
  }
`
