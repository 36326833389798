import { useNavigate } from 'react-router'

import { Avatar } from 'common/widgets/avatar'
import { SystemPage } from 'system/page/core'
import { SimpleDataTable, SimpleColumn } from 'common/data-table/simple'
import { formatProjectName } from 'modules/projects/utils'
import { QueryProvider } from 'common/query/context'
import { useMediaQuery } from 'common/utils/hooks'

import { AssetDistributionMap } from '../widgets/map'

export const LargeScaleEquipmentListPage = () => {
  const navigate = useNavigate()
  const isRowBased = useMediaQuery('(max-width: 768px)')

  return (
    <SystemPage
      nopadding
      style={{
        display: 'grid',
        gridTemplateColumns: isRowBased ? '1fr' : '1fr 1fr',
        gap: 0,
      }}
    >
      <QueryProvider
        url="/items/resources?process=DISPOSITION"
        name="large-scales-list-provider"
      >
        <SimpleDataTable
          name="largescale"
          title="Large scale equipments"
          onAddClick={() =>
            navigate('/master-data/equipments/large-scales/add')
          }
          addTooltip="Add large scale"
          navBaseUrl="/master-data/equipments/large-scales"
          archive
          style={{ padding: '10px' }}
        >
          <SimpleColumn fixed>
            {(r) => <Avatar name={r.name} id={r.image?.id} />}
          </SimpleColumn>
          <SimpleColumn sortable n={12} s={12} field="name" header />
          <SimpleColumn
            n={4}
            s={6}
            sortable
            field="maincategory"
            label="Main group"
            filterUrl="items/resources/maincategory?process=DISPOSITION"
          />
          <SimpleColumn
            n={4}
            s={6}
            sortable
            field="category"
            label="Group"
            filterUrl="items/resources/category?process=DISPOSITION"
          />
          <SimpleColumn
            n={4}
            s={6}
            sortable
            field="subcategory"
            label="Subgroup"
            filterUrl="items/resources/subcategory?process=DISPOSITION"
          />
          <SimpleColumn
            n={4}
            s={6}
            sortable
            label="Inventory number"
            field="inventory_number"
          />
          <SimpleColumn
            n={4}
            s={6}
            sortable
            label="Cost center"
            field="cost_center"
          />
          <SimpleColumn
            n={4}
            s={6}
            field="project_id"
            sortKey="project_cost_center"
            label="Location"
            filter={(e) => (e ? e.id : null)}
            title={(e) => (e ? formatProjectName(e) : 'Yard')}
            filterUrl="items/resources/projects"
            value={(r) => (r.project ? r.project.id : null)}
          />
        </SimpleDataTable>
        <AssetDistributionMap
          visible={!useMediaQuery('(max-width: 768px)')}
          style={{
            position: 'sticky',
            top: 0,
            borderRadius: '0',
            height: 'calc(100vh - 60px)',
          }}
        />
      </QueryProvider>
    </SystemPage>
  )
}
