import { Form } from 'common/widgets/form/context'
import { SystemPage } from 'system/page/core'
import { useService } from 'common/service/context'

import { ContactStateFormContent } from './form'

/**
 * Renders contact add page.
 *
 * @returns ReactElemtn
 */
export const ContactStateAddPage = () => {
  const service = useService()

  /**
   * Handles adding a new contact, send a post call and creates a new contact.
   */
  const handleAdd = async (values) =>
    await service.post('contacts/defs/states', values)

  return (
    <SystemPage>
      <Form>
        <ContactStateFormContent onAdd={handleAdd} />
      </Form>
    </SystemPage>
  )
}
