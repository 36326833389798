import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Container } from 'common/widgets/container'
import { Row, Column } from 'common/widgets/grid'
import { SectionView } from 'common/widgets/view'
import { DetailViewPage } from 'system/utils/view'
import { RelatedItemView } from 'modules/master-data/equipments/view/related-items'
import { DocumentsSectionView } from 'common/widgets/document'
import { DamageReportButton } from 'modules/yard/maintenance/damage_report'
import { SingleImageFieldSectionView } from 'common/widgets/image'
import { AdHocDeliveryIconButton } from 'common/widgets/button'

import { BillingView } from '../view/billing'
import { MasterDataView } from '../view/master-data'
import { MasterDataDiverseView } from '../view/master-data-diverse'
import { BookingHistoriesView } from '../view/booking-histories'
import { TelematicsDataView } from '../widgets/telematics'
import { ResourceLocationMap } from '../widgets/map'
import { ItemHistory } from '../view/history'

export const LargeScaleEquipmentDetailsPage = () => {
  const { t } = useTranslation(['equipments'])
  const navigate = useNavigate()
  const { id } = useParams()

  return (
    <DetailViewPage
      url="/items/resources"
      navUrl="/master-data/equipments/large-scales"
      title={(e) => e.name}
      editable
      clonable
      addExtraButtons={(e) => (
        <>
          {e.is_orderable && (
            <AdHocDeliveryIconButton
              tooltip={t('Adhoc commission')}
              onClick={() => {
                navigate(`/yard/commission/deliveries/adhoc?baseitemId=${id}`)
              }}
            />
          )}
          <DamageReportButton baseitemId={id} />
        </>
      )}
    >
      {(data) => (
        <Container flex>
          <Row>
            <Column flex n={6} m={6} s={12}>
              <MasterDataView data={data} />
            </Column>
            <Column flex n={6} m={6} s={12}>
              <SectionView>
                <ResourceLocationMap showYard items={[data]} />
              </SectionView>
            </Column>
            <Column flex n={6} m={6} s={12}>
              <BillingView data={data} />
            </Column>
            <Column flex n={6} m={6} s={12}>
              <MasterDataDiverseView data={data} />
            </Column>
            <Column flex n={6} m={6} s={12}>
              <TelematicsDataView data={data} />
            </Column>
            <Column flex n={6} m={6} s={12}>
              <RelatedItemView data={data} />
            </Column>
            <Column flex n={9} m={9} s={12}>
              <DocumentsSectionView docRef={`baseitem=${data.id}`} />
            </Column>
            <Column flex n={3} m={3} s={12}>
              <SingleImageFieldSectionView
                imageRef={data.image_ref}
                title={t('Equipment Image')}
                section_title={t('Image')}
              />
            </Column>
            <Column flex n={12} m={12} s={12}>
              <SectionView>
                <BookingHistoriesView data={data} />
              </SectionView>
            </Column>
            <Column flex n={12} m={12} s={12}>
              <SectionView>
                <ItemHistory itemID={id} />
              </SectionView>
            </Column>
          </Row>
        </Container>
      )}
    </DetailViewPage>
  )
}
