import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { AddIconButton } from 'common/widgets/button'
import { Avatar } from 'common/widgets/avatar'
import { ItemTypeFilter } from 'modules/orders/form/select/type-filter'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { isNilOrEmpty } from 'common/utils/conditional'
import { QueryConsumer } from 'common/query/context'
import { getEquipmentNavPath } from 'modules/master-data/equipments/utils'
/**
 * Renders orderable item list as a datatable.
 *
 * @returns ReactElement
 */
export const OrderableItemList = ({ order, style, ...rest }) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['orders'])

  return (
    <SimpleDataTable
      name="items"
      white
      border
      url="/orders/base-items"
      disabled={!order?.editable}
      divide
      style={style}
    >
      <QueryConsumer before>
        {(query) => {
          return (
            <ItemTypeFilter
              onChange={(key) => {
                if (key == 'LARGESCALE') {
                  query.parameters.setMany({
                    type: 'RESOURCE',
                    process: 'DISPOSITION',
                  })
                } else if (key == 'SMALLSCALE') {
                  query.parameters.setMany({
                    type: 'RESOURCE',
                    process: 'COMMISSIONING',
                  })
                } else if (key) {
                  query.parameters.setMany({ type: key, process: undefined })
                } else {
                  query.parameters.clear()
                }
              }}
            />
          )
        }}
      </QueryConsumer>
      <SimpleColumn fixed>
        {(r) => <Avatar name={r.name} id={r.image?.id} />}
      </SimpleColumn>
      <SimpleColumn n={12}>
        {(r) => (
          <>
            <h3>
              <a href={getEquipmentNavPath(r)}>{formatItemNameForOrder(r)}</a>
            </h3>
            {r.related_items_count > 0 && (
              <h3>
                {t('[{{related_items_count}}] items are related.', {
                  related_items_count: r.related_items_count,
                })}
              </h3>
            )}
          </>
        )}
      </SimpleColumn>
      <SimpleColumn
        n={4}
        s={12}
        sortable
        label={t('Main group')}
        field="maincategory"
        title={(e) => e}
        filter={(e) => e}
        filterUrl="/items/maincategories"
      />
      <SimpleColumn
        n={4}
        s={12}
        sortable
        label={t('Group')}
        field="category"
        title={(e) => e}
        filter={(e) => e}
        filterUrl="/items/categories"
      />
      <SimpleColumn
        n={4}
        s={12}
        sortable
        label={t('Subgroup')}
        field="subcategory"
        title={(e) => e}
        filter={(e) => e}
        filterUrl="/items/subcategories"
      />
      <SimpleColumn fixed>
        {(r) => (
          <AddIconButton
            disabled={!order?.editable}
            onClick={(e) => {
              navigate(
                `/orders/${order.draft ? 'cart/' : ''}${order.id}/add/${r.id}`
              )
            }}
          />
        )}
      </SimpleColumn>
    </SimpleDataTable>
  )
}

export const formatItemNameForOrder = (item) => {
  var name = item.name
  if (item.is_resource) {
    name = `${item.maincategory}`
    if (!isNilOrEmpty(item.category)) {
      name = `${name} - ${item.category}`
    }
    if (!isNilOrEmpty(item.subcategory)) {
      name = `${name} - ${item.subcategory}`
    }
    return name
  } else {
    return !isNilOrEmpty(item.inventory_number)
      ? `${item.inventory_number} - ${name}`
      : name
  }
}
