import { useTranslation } from 'react-i18next'

import Logo from 'assets/system-logo-mono.svg'

export const AuthFormHeader = () => {
  const { t } = useTranslation(['security'])
  return (
    <>
      <Logo width="120px" style={{ justifySelf: 'center' }} />
      <div style={{ justifySelf: 'center', textAlign: 'center' }}>
        <h1>{t('security.register.welcome')}</h1>
        <h3>{t('security.register.motto')}</h3>
      </div>
    </>
  )
}
