import styles from './debug.module.css'
/**
 * This component is used inside the FormContex to display
 * data used for debugging. Don't use it directly. Just set
 * the `debug` flag on any Form component to see its output.
 */
export const FormDebug = ({ state, dirty, valid }) => {
  const notNullErrors = Object.fromEntries(
    Object.entries(state?.errors).filter((o) => o[1] !== null)
  )
  console.debug('FormContext state', state)
  return (
    <>
      <h1>
        FormContext Debug &nbsp;
        {valid ? (
          <span style={{ color: 'MediumSeaGreen' }}>VALID</span>
        ) : (
          <span style={{ color: 'Tomato' }}>INVALID</span>
        )}
        &nbsp; and&nbsp;
        {dirty ? (
          <span style={{ color: 'Tomato' }}>DIRTY</span>
        ) : (
          <span style={{ color: 'MediumSeaGreen' }}>CLEAN</span>
        )}
      </h1>
      <p>
        Input fields: &nbsp;
        {Object.values(state.fields)
          .map((f) => f.name)
          .join(', ')}
      </p>
      <div className={styles.debug}>
        <label>
          Context values:
          <textarea
            style={{ height: '200px' }}
            readOnly
            value={JSON.stringify(state.values, undefined, 4)}
          />
        </label>
        <label>
          Context dirty values:
          <textarea
            style={{ height: '200px' }}
            readOnly
            value={JSON.stringify(state.dirtyValues, undefined, 4)}
          />
        </label>
        <label>
          Context errors:
          <textarea
            style={{ height: '200px' }}
            readOnly
            value={JSON.stringify(notNullErrors, undefined, 4)}
          />
        </label>
        <label>
          Initial values:
          <textarea
            style={{ height: '200px' }}
            readOnly
            value={JSON.stringify(state.initialValues, undefined, 4)}
          />
        </label>
      </div>
    </>
  )
}
