import styled, { css } from 'styled-components'

export const ColorPickerInput = ({
  value,
  onChange,
  preview = false,
  ...rest
}) => {
  return (
    <>
      <input
        type="color"
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
        {...rest}
      />
      {preview && (
        <ColorPreview background={value}>
          <h1>{value}</h1>
        </ColorPreview>
      )}
    </>
  )
}

export const ColorPreview = styled.div`
  display: flex;
  border-radius: 5px;
  padding: 20px;
  ${(props) =>
    props.background &&
    css`
      background: ${props.background};
    `}
  h1 {
    margin: auto;
  }
`
