import { useTranslation } from 'react-i18next'

import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'
import { DropDownField } from 'common/widgets/form/field'
import { SelectOverlay } from 'common/widgets/select-overlay'
import { Container } from 'common/widgets/container'
import { Avatar } from 'common/widgets/avatar'

export const ContactSelectOverlay = ({
  open,
  params,
  maxItems = 5,
  onSelect,
  onClose,
}) => {
  const { t } = useTranslation()

  return (
    <SelectOverlay
      title={t('Contacts')}
      url="/contacts/internal?archived=false"
      open={open}
      params={params}
      maxItems={maxItems}
      onSelect={onSelect}
      onClose={onClose}
      renderRecord={(r) => (
        <>
          <Avatar name={r.fullname} id={r.picture_file_id} mini />
          <Container flex vertical>
            <h3>{r.name}</h3>
            <h5>{r.fullname}</h5>
          </Container>
        </>
      )}
    />
  )
}

export const SystemUserSelectField = ({
  handler,
  name = 'system_user_id',
  removable = true,
  contactID = null,
  onSelectChange,
  ...rest
}) => {
  const service = useService()

  const params = []
  if (contactID !== null && contactID !== undefined) {
    params.push({ contact_id: contactID })
  }

  const fetch = async () =>
    await service.get('/contacts/assignable-users', params)

  const render = ({ data, error, loading }) => {
    const items =
      data?.map((d) => {
        return {
          key: d.id,
          title: d.first_name + ' ' + d.last_name,
        }
      }) || []

    const handleChange = (id) => {
      if (onSelectChange) {
        let item = null
        if (id) {
          item = items.find((f) => f.key === id)
        }
        onSelectChange(item)
      }
    }

    return (
      <DropDownField
        name={name}
        items={items}
        minWidth="276px"
        removable={removable}
        onSelectChange={handleChange}
        {...rest}
      />
    )
  }

  return <DataSource fetch={fetch} handler={handler} render={render} />
}
