import { useParams } from 'react-router-dom'

import { Form } from 'common/widgets/form/context'
import { SystemPage } from 'system/page/core'
import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'

import { ContactFormContent } from './form'
import { getPrimaryAddress } from './utils'

/**
 * Renders contact edit page.
 *
 * @returns ReactElement
 */
export const ContactEditPage = () => {
  // Extracts tag id from url params
  const { id } = useParams()
  const service = useService()

  /**
   * Calls a put call and saves information.
   *
   * @param {any} values save data.
   * @returns
   */
  const handleSave = async (values) =>
    await service.put(`contacts/${id}`, values)

  /**
   * Gets information of tag using it's id.
   *
   * @returns any
   */
  const fetch = async () => await service.get(`/contacts/${id}`)

  /**
   * Adds primary address fields like address.city to contact object.
   *
   * @param {any} contact contact data
   * @returns
   */
  const normalize = (contact) => {
    const primary = getPrimaryAddress(contact)
    if (primary) {
      Object.keys(primary).forEach(
        (key) => (contact[`address.${key}`] = primary[key])
      )
    }
    return contact
  }

  return (
    <DataSource
      fetch={fetch}
      render={({ data, error, loading }) => (
        <SystemPage loading={loading}>
          {data && (
            <Form data={normalize(data)}>
              <ContactFormContent onUpdate={handleSave} contactID={id} />
            </Form>
          )}
        </SystemPage>
      )}
    />
  )
}
