import { useTranslation } from 'react-i18next'
import { User, Users } from 'react-feather'

import { FieldWrapper } from 'common/widgets/form/field'

import { ContactTypeEnum } from '../utils'
import styles from '../contacts.module.css'

export const ContactTypeField = ({ ...rest }) => {
  const { t } = useTranslation()
  return (
    <FieldWrapper {...rest}>
      {(getValue, setValue) => (
        <div className="flex">
          <div
            className={
              getValue() === ContactTypeEnum.PERSON
                ? styles.contactSelected
                : styles.contact
            }
            onClick={() => setValue(ContactTypeEnum.PERSON)}
          >
            <User /> <p>{t('Person')}</p>
          </div>
          <div
            className={
              getValue() === ContactTypeEnum.COMPANY
                ? styles.contactSelected
                : styles.contact
            }
            onClick={() => setValue(ContactTypeEnum.COMPANY)}
          >
            <Users /> <p>{t('Company')}</p>
          </div>
        </div>
      )}
    </FieldWrapper>
  )
}
