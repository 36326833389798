import { useState } from 'react'

import { useService } from 'common/service/context'
import { AddIconButton, RemoveIconButton } from 'common/widgets/button'
import { Card } from 'common/widgets/card'
import { Container } from 'common/widgets/container'
import { DataSource } from 'common/widgets/data-source'
import { Row, Column } from 'common/widgets/grid'
import { SearchInput } from 'common/widgets/search'
import { CardView, GridFieldView } from 'common/widgets/view'
import { formatDistanceFromNow } from 'common/utils/format'
import { OverlayForm, OverlayFooter } from 'common/widgets/overlay'
import { FreeChooseField } from 'common/widgets/form/field'
import { SimpleFormAction } from 'common/widgets/form/utils'

import { ContactTypeEnum } from '../utils'
import { ContactCompact } from '../list'

import styles from './view.module.css'

/**
 * Renders relation view inside contact tab widget.
 *
 * @param {any} contact contact info
 * @returns ReactElement
 */
export const ContactRelationView = ({ contact, reload }) => {
  const service = useService()

  const handleAdd = async (relatedContactId, relationType) => {
    return service.post(`/contacts/relations`, {
      main_contact_id: contact.id,
      relation_type: relationType,
      related_contact_id: relatedContactId,
    })
  }

  const handleRemove = async (contactRelationId) => {
    return service.delete(`/contacts/relations/${contactRelationId}`)
  }

  return (
    <Row>
      <Column n={6}>
        {contact?.related_contacts.map((r) => (
          <Card
            key={r.id}
            align="center"
            justify="space-between"
            flex
            padding="10px"
            spaceAfter="10px"
          >
            <Container flex vertical grow>
              <h3>{r.related_contact.fullname}</h3>
              <Row>
                <GridFieldView n={5} label="Relation" value={r.relation_type} />
                <GridFieldView
                  n={4}
                  label="Created by"
                  value={r.creator_user.fullname}
                />
                <GridFieldView
                  n={3}
                  label="Created at"
                  value={formatDistanceFromNow(r.created_at)}
                />
              </Row>
            </Container>
            <RemoveIconButton
              danger
              onClick={() => {
                handleRemove(r.id)
                reload()
              }}
            />
          </Card>
        ))}
      </Column>
      <Column n={6}>
        <ContactSelectList onAdd={handleAdd} reload={reload} />
      </Column>
    </Row>
  )
}

/**
 * Renders a widget to select related contacts.
 *
 * @param {any} contact contact info
 * @param {Function} onAdd function to handle add
 * @returns ReactElement
 */
const ContactSelectList = ({ onAdd, reload }) => {
  const service = useService()
  const [selectedContact, setSelectedContact] = useState(null)

  /**
   * Fetches available contacts.
   *
   * @returns Array
   */
  const fetch = async (params) => {
    return await service.get(
      `/contacts`,
      params
        ? [...params, { type: ContactTypeEnum.PERSON }, { ipp: 10 }]
        : [{ type: ContactTypeEnum.PERSON }, { ipp: 10 }]
    )
  }

  return (
    <DataSource
      fetch={fetch}
      render={({ data }) => {
        return (
          <CardView padding="20px">
            {selectedContact && (
              <RelationTypeSelectOverlay
                contact={selectedContact}
                onClose={() => {
                  setSelectedContact(null)
                  reload()
                }}
                onAdd={onAdd}
              />
            )}
            <Container
              title="Contacts"
              flex
              justify="space-between"
              align="center"
            >
              <SearchInput />
            </Container>
            {data?.map((e) => (
              <div className={styles.contactRowView} key={e.id}>
                <Container flex grow>
                  <ContactCompact contact={e} />
                </Container>
                <AddIconButton
                  default
                  onClick={async () => setSelectedContact(e)}
                />
              </div>
            ))}
          </CardView>
        )
      }}
    />
  )
}

const RelationTypeSelectOverlay = ({ contact, onClose, onAdd, reload }) => {
  const service = useService()
  return (
    <DataSource
      fetch={async () => await service.get('contacts/relations/types')}
    >
      {({ data }) => {
        return (
          <OverlayForm
            open={contact !== null}
            title="Add relation"
            onClose={onClose}
          >
            <h3>{contact?.fullname}</h3>
            <h5>Relation type</h5>
            <FreeChooseField name="reltype" items={data ?? []} mandatory />
            <OverlayFooter>
              <SimpleFormAction
                onAdd={async (values) => onAdd(contact.id, values.reltype)}
                onClose={onClose}
                onSuccess={reload}
              />
            </OverlayFooter>
          </OverlayForm>
        )
      }}
    </DataSource>
  )
}
