import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Container } from 'common/widgets/container'
import { AddIconButton } from 'common/widgets/button'
import { OverlayBody, OverlayForm } from 'common/widgets/overlay'
import { Row } from 'common/widgets/grid'
import { CardView, GridFieldView } from 'common/widgets/view'
import { Avatar } from 'common/widgets/avatar'
import { SearchInput } from 'common/widgets/search'
import { useService } from 'common/service/context'
import { formatItemName } from 'modules/master-data/equipments/utils'

/**
 * Search and select items
 *
 * @param {array} igroreItems to be ingored for selecting
 * @param {any} onSelect setter for the selected baseitem
 * @param {any} onClose state of closing the dialog
 *
 * @returns ReactElement
 */
export const ItemSearchOverlay = ({
  open,
  groupId = null,
  ignoreItems,
  onSelect,
  onClose,
}) => {
  const { t } = useTranslation()
  const service = useService()
  const [items, setItems] = useState(null)
  const [count, setCount] = useState(null)

  const [searchParams, setSearchParams] = useState([
    { ipp: 5 },
    { archived: false },
    { is_orderable: true },
    { has_inventory: true },
    { has_damage_report: false },
  ])

  const loadData = async () => {
    const [response, , count] = await await service.get(`/items`, searchParams)
    setItems(response?.data)
    setCount(count)
  }

  useEffect(() => {
    if (open) {
      loadData()
    }
  }, [searchParams])

  useEffect(() => {
    if (open && !items) {
      loadData()
    }
  }, [open])

  useEffect(() => {
    if (groupId) {
      setSearchParams([...searchParams, { group_id: groupId }])
    }
  }, [groupId])

  // Effect to filter ignored items
  useEffect(() => {
    if (ignoreItems && items) {
      // We should call the get service when an item gets selected and passed
      // through as ignored. otherwise, the list will end up having less and
      // less items after each selection, even if there are more items available.
      loadData()
      let filteredResults = items.filter(
        // Keep items which are not in the ignore list
        (i) => !ignoreItems?.find((ii) => ii.baseitem_id === i.id)
      )
      setItems(filteredResults)
    }
  }, [ignoreItems])

  return (
    <OverlayForm
      open={open}
      onClose={onClose}
      title={t('Item')}
      subtitle={`total ${count || 0}`}
    >
      <OverlayBody>
        <SearchInput
          onChange={(input) => {
            setSearchParams([
              ...searchParams.filter((e) => !e.search),
              { search: input },
            ])
          }}
        />
        {!items?.length && <h3>Not enough inventory for this item</h3>}
        {items?.map((record, i) => (
          <CardView key={i} flex divide="true" gap="5px">
            <Avatar name={record.name} />
            <Row title={formatItemName(record)}>
              <GridFieldView
                n={4}
                s={4}
                label={t('Main group')}
                value={record.maincategory}
              />
              <GridFieldView
                n={4}
                s={4}
                label={t('Group')}
                value={record.category}
              />
              <GridFieldView
                n={4}
                s={4}
                label={t('Subgroup')}
                value={record.subcategory}
              />
            </Row>
            <Container flex align="center">
              <AddIconButton
                onClick={(e) => {
                  e.stopPropagation()
                  onSelect && onSelect(record)
                }}
              />
            </Container>
          </CardView>
        ))}
      </OverlayBody>
    </OverlayForm>
  )
}
