import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { SystemPage } from 'system/page/core'
import { Avatar } from 'common/widgets/avatar'
import { Container } from 'common/widgets/container'
import { SimpleDataTable, SimpleColumn } from 'common/data-table/simple'
import { formatDateTime } from 'common/utils/format'

import { UserStateComment } from './view'

export const UsersListPage = () => {
  const { t } = useTranslation(['security'])
  const navigate = useNavigate()

  return (
    <SystemPage>
      <SimpleDataTable
        name="users"
        title="Users"
        archive
        url="/users"
        onAddClick={() => navigate('/security/users/add')}
        navBaseUrl="/security/users"
      >
        <SimpleColumn fixed>
          {(r) => <Avatar name={r.fullname} id={r.profile_image?.id} />}
        </SimpleColumn>
        <SimpleColumn sortable n={12} s={12} header>
          {(r) => (
            <Container flex vertical>
              <h3>{r.fullname}</h3>
              <UserStateComment user={r} />
            </Container>
          )}
        </SimpleColumn>
        <SimpleColumn s={6} n={4} sortable field="email" label={t('E-Mail')} />
        <SimpleColumn
          s={6}
          n={2}
          sortable
          field="phone_number"
          label={t('Telephone')}
        />
        <SimpleColumn
          s={6}
          n={6}
          sortable
          field="last_login"
          label="Last login"
          value={(r) => formatDateTime(r.last_login)}
        />
        <SimpleColumn
          n={6}
          s={12}
          field="role"
          label={t('Roles')}
          filter={(e) => e.id}
          title={(e) => e.name}
          filterUrl="/security/roles"
          value={(r) =>
            r.roles
              .filter((_, i) => i < 4)
              .map((e, i) => (i < 3 ? e.name : '...'))
              .join(', ')
          }
        />
      </SimpleDataTable>
    </SystemPage>
  )
}
