import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { DetailViewPage } from 'system/utils/view'
import { CancelIconButton, SubmitButton } from 'common/widgets/button'
import { useService } from 'common/service/context'
import { formatDeliveryNumber } from 'modules/yard/utils'
import { TripSelectField } from 'modules/logistics/planning/trip/widgets'
import { Form } from 'common/widgets/form/context'

import { DeliveryViewDetails } from './details'
import { DeliveryItems } from './delivery-items'
import { HandoverOverlay } from './overlays/handover'
import {
  CancelDeliveryOverlay,
  RemovePickedItemAmountOverlay,
} from './overlays/cancelation'

/**
 * Renders the deliveries view page.
 *
 * @returns ReactElemnt
 */
export const PickUpDeliveryViewPage = () => {
  const { t } = useTranslation()
  const service = useService()
  const navigate = useNavigate()
  const [deliveryHandover, setDeliveryHandover] = useState(null)
  const [removeDeliveryItem, setRemoveDeliveryItem] = useState(null)
  const [cancelDeliveryObject, setCancelDeliveryObject] = useState(null)

  /**
   * Picks up the delivery note.
   *
   * @returns Array[result, error]
   */
  const handleDeliveryNotePickUp = async (deliveryId, tripId) => {
    const [result, error] = await service.put(
      `kommission/delivery/${deliveryId}/pickup`,
      { trip_id: tripId }
    )
    if (!error) {
      navigate(-1)
    }

    return [result, error]
  }

  return (
    <DetailViewPage
      url="/kommission/delivery"
      title={(delivery) =>
        `${t('Delivery number')}: ${formatDeliveryNumber(
          delivery?.order?.number,
          delivery?.number
        )}`
      }
      addExtraButtons={(delivery, reload) => (
        <CancelIconButton
          onClick={() => {
            setCancelDeliveryObject(delivery)
          }}
        />
      )}
    >
      {(delivery, reload) => {
        return (
          <>
            <DeliveryViewDetails delivery={delivery} order={delivery.order} />
            <DeliveryItems
              delivery={delivery}
              commissionedOnly
              pickup
              onChange={reload}
            />
            <CancelDeliveryOverlay
              delivery={cancelDeliveryObject}
              onClose={() => setCancelDeliveryObject(null)}
              reload={reload}
              onCancel={() => navigate(-1)}
            />
            {removeDeliveryItem && (
              <RemovePickedItemAmountOverlay
                open={removeDeliveryItem}
                onClose={() => setRemoveDeliveryItem(null)}
                orderedItem={removeDeliveryItem.ordered_item}
                deliveryItem={removeDeliveryItem}
                reload={reload}
              />
            )}
            <HandoverOverlay
              onClose={() => setDeliveryHandover(null)}
              delivery={deliveryHandover}
            />
            <Form>
              {delivery.order?.requires_delivery && (
                <>
                  <h3>{t('Planned trips for this order')}</h3>
                  <TripSelectField
                    name="trip"
                    title={t('Choose one trip please')}
                    orderId={delivery.order_id}
                  />
                </>
              )}
              <SubmitButton
                default
                disabled={delivery?.items?.length === 0}
                onClick={(e, ctx) => {
                  if (delivery.order?.requires_delivery) {
                    handleDeliveryNotePickUp(
                      delivery.id,
                      ctx.values.json.trip?.id
                    )
                  } else {
                    setDeliveryHandover(delivery)
                  }
                }}
              />
            </Form>
          </>
        )
      }}
    </DetailViewPage>
  )
}
