import React from 'react'

import { OverlayBody, OverlayFooter, OverlayForm } from 'common/widgets/overlay'
import { DateField, NumericField, TextField } from 'common/widgets/form/field'
import { SimpleFormAction } from 'common/widgets/form/utils'

/**
 * Renders a text input overlay.
 *
 * @param {boolean} open specifies that the overlay is open
 * @param {Function} onSave saving hook
 * @param {Function} onClose closing hook
 * @param {string} initialValue initial text to be displayed
 * @param {string} title field title to be used in the form
 * @returns JSX.Element
 */
export const TextOverlay = ({ open, onSave, onClose, initialValue, title }) => {
  if (!open) {
    return null
  }

  return (
    <OverlayForm
      open={open}
      title={title}
      onClose={onClose}
      onSubmit={onSave}
      data={{ value: initialValue }}
    >
      <OverlayBody>
        <TextField name="value" />
      </OverlayBody>
      <OverlayFooter>
        <SimpleFormAction onUpdate={onSave} onClose={onClose} />
      </OverlayFooter>
    </OverlayForm>
  )
}

/**
 * Renders a numeric input overlay.
 *
 * @param {boolean} open specifies that the overlay is open
 * @param {Function} onSave saving hook
 * @param {Function} onClose closing hook
 * @param {string} initialValue initial number to be displayed
 * @param {string} title field title to be used in the form
 * @param {Function} normalizer normalizer function
 * @returns JSX.Element
 */
export const NumericOverlay = ({
  open,
  onSave,
  onClose,
  initialValue,
  title,
  normalizer,
}) => {
  return (
    <OverlayForm
      open={open}
      title={title}
      onClose={onClose}
      onSubmit={onSave}
      data={{ value: initialValue }}
    >
      <OverlayBody>
        <NumericField name="value" normalize={normalizer} />
      </OverlayBody>
      <OverlayFooter>
        <SimpleFormAction onUpdate={onSave} onClose={onClose} />
      </OverlayFooter>
    </OverlayForm>
  )
}

/**
 * Renders a date input overlay.
 *
 * @param {boolean} open specifies that the overlay is open
 * @param {Function} onSave saving hook
 * @param {Function} onClose closing hook
 * @param {string} initialValue initial date to be displayed
 * @param {string} title field title to be used in the form
 * @returns JSX.Element
 */
export const DateOverlay = ({ open, onSave, onClose, initialValue, title }) => {
  return (
    <OverlayForm
      open={open}
      title={title}
      onClose={onClose}
      onSubmit={onSave}
      data={{ value: initialValue }}
    >
      <OverlayBody>
        <DateField name="value" />
      </OverlayBody>
      <OverlayFooter>
        <SimpleFormAction onUpdate={onSave} onClose={onClose} />
      </OverlayFooter>
    </OverlayForm>
  )
}
