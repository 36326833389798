import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Container } from 'common/widgets/container'
import { PagedDataSource } from 'common/widgets/data-source'
import { Column, Row } from 'common/widgets/grid'
import { SearchInput } from 'common/widgets/search'
import { CardView, FieldView, SectionView } from 'common/widgets/view'
import { register } from 'system/container'
import { SystemPage } from 'system/page/core'
import { formatDistanceFromNow } from 'common/utils/format'

import { EquipmentDetails } from './details'
import styles from './equipments.module.css'

const EquipmentsPage = () => (
  <SystemPage>
    <Row>
      <Column n={6}>
        <OutOfStockConsumables />
      </Column>
      <Column n={6}>
        <LowBatteryResources />
      </Column>
    </Row>
  </SystemPage>
)

/**
 * Lists low battery resources.
 *
 * @returns ReactElement
 */
const LowBatteryResources = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <PagedDataSource url="/telematics/low-batteries" limit={5}>
      {({ data }) => (
        <SectionView>
          <Container title="Low battery" flex repel align="center">
            <SearchInput />
          </Container>
          {data?.map((r, i) => (
            <CardView
              key={i}
              divide
              noline={i === 0 ? 'true' : 'false'}
              onClick={() =>
                navigate(`/master-data/equipments/resources/${r.id}`)
              }
            >
              <Row>
                <Column n={12}>
                  <h3>{r.baseitem?.name}</h3>
                </Column>
                <Column n={4} s={4}>
                  <FieldView
                    label={t('Inventory number')}
                    value={r.baseitem?.inventory_number}
                  />
                </Column>
                <Column n={4} s={4}>
                  <FieldView label={t('Battery level')}>
                    <h4 className={styles.stockLevel}>{r.battery_level}</h4>
                  </FieldView>
                </Column>
                <Column n={4} s={4}>
                  <FieldView label={t('Time')}>
                    <h4>{formatDistanceFromNow(r.recorded_at)}</h4>
                  </FieldView>
                </Column>
              </Row>
            </CardView>
          ))}
        </SectionView>
      )}
    </PagedDataSource>
  )
}

/**
 * Lists out of stock consumables.
 *
 * @returns ReactElement
 */
const OutOfStockConsumables = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <PagedDataSource url="/items/consumables/inferiors" limit={5}>
      {({ data }) => (
        <SectionView>
          <Container title="Out of stock" flex repel align="center">
            <SearchInput />
          </Container>
          {data?.map((r, i) => (
            <CardView
              key={i}
              divide
              noline={i === 0 ? 'true' : 'false'}
              onClick={() =>
                navigate(`/master-data/equipments/consumables/${r.id}`)
              }
            >
              <Row>
                <Column n={12}>
                  <h3>{r.name}</h3>
                </Column>
                <Column n={4} s={4}>
                  <FieldView
                    label={t('Inventory number')}
                    value={r.inventory_number}
                  />
                </Column>
                <Column n={4} s={4}>
                  <FieldView label={t('Min stock')} value={r.min_stock} />
                </Column>
                <Column n={4} s={4}>
                  <FieldView label={t('Stock level')}>
                    <h4 className={styles.stockLevel}>{r.stock_level}</h4>
                  </FieldView>
                </Column>
              </Row>
            </CardView>
          ))}
        </SectionView>
      )}
    </PagedDataSource>
  )
}

register({
  menu: true,
  title: 'Equipments',
  path: '/master-data/equipments',
  render: () => <EquipmentsPage />,
})

register({
  menu: true,
  title: 'Details',
  path: '/master-data/equipments/:id',
  render: () => <EquipmentDetails />,
})
