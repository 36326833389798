import _axios from 'axios'
import React, { createContext, useContext, useState } from 'react'

import { useToast } from 'system/toast/context'

const axios = _axios.create({
  baseURL: `${window.location.origin}/api/`,
})
console.log(`* Base API URL: ${window.location.origin}/api/`)

// Access token key
const LOCAL_STORAGE_TOKEN_KEY = 'access_token'

// Creates an instance of service context
const ServiceContext = createContext()

var errorHandlers = []

/**
 * Provides service context for child elements.
 *
 * @param {*} children Elements as children
 * @returns
 */
export const ServiceProvider = ({ children }) => {
  const savedToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
  const [token, setToken] = useState(savedToken)

  const { toasts } = useToast()

  /**
   * Registers a new error handler.
   *
   * @param {Function} handler error handler function.
   * @returns null
   */
  const addErrorHandler = (handler) => {
    if (!errorHandlers.includes(handler)) {
      errorHandlers = [...errorHandlers, handler]
    }
  }

  /**
   * Removes the given error handler.
   *
   * @param {Function} handler error handler function
   * @returns
   */
  const removeErrorHandler = (handler) =>
    (errorHandlers = errorHandlers.filter((e) => e !== handler))

  /**
   * Creates axios config object and returns it.
   * It contains base url and maybe header with access token.
   *
   * @returns object
   */
  const makeConfig = (headers, path) => {
    // These endpoints do not need authentication
    const ignoredPaths = ['/settings']
    // Creates config with just api url as base url
    const config = {}
    // If token is available and the endpoint is protected, adds authorization to header
    if (token !== null && !ignoredPaths.includes(path)) {
      config.headers = { Authorization: `Bearer ${token}` }
    }
    return headers ? { ...config, ...headers } : config
  }

  /**
   * Wraps http call in a predefined way, also handles error if error happens.
   *
   * @param {Function} f http call function
   * @returns [any, any, int]
   */
  const wrapHttpCall = async (f) => {
    try {
      // Calls the remote api
      const response = await f().catch((error) => {
        // Catch all HTTP errors and report them at console at least
        console.error('error.config', error.config)
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('error.response', error.response)
          console.error('error.response.status', error.response.status)
          console.error('error.response.headers', error.response.headers)
          console.error(error.response.data.trace)
          toasts.error(error.response.data?.message || error.response.data)
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.error('error.request', error.request)
          toasts.error(error?.message || 'API error, check the console.')
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error', error.message)
          toasts.error(error?.message || 'API error, check the console.')
        }
        throw error
      })

      // Extracts total records count
      const totalCount = response.headers['x-total-count']
        ? parseInt(response.headers['x-total-count'])
        : 0
      // Everything is OK
      return [response, null, totalCount]
    } catch (error) {
      // Error happend
      errorHandlers.forEach((handler) =>
        handler(error?.response?.status, error?.response?.data?.message)
      )
      return [null, error, 0]
    }
  }

  /**
   * Makes a get call to the given path using the given params.
   *
   * @param {string} path api path
   * @param {list} params api params
   * @returns
   */
  const get = async (path, params, headers) => {
    // Try to convert non-array parameters into an array of k,v
    if (params && !Array.isArray(params)) {
      params = Object.entries(params).map((q) => ({ [q[0]]: q[1] }))
    }
    let endpoint = path
    const sep = path.includes('?') ? '&' : '?'
    if (params && params.length > 0) {
      let queryComponent = params
        // undefined and NaN are invalid values and should not be sent to the API.
        // If they reache here there is an error in our code. We remove them but print
        // an warning instead.
        .filter(isValidParam)
        .map((p) => convertToString(p))
        .join('&')
      endpoint = `${path}${sep}${queryComponent}`
    }

    return await wrapHttpCall(
      async () => await axios.get(endpoint, makeConfig(headers, path))
    )
  }

  /**
   * Convert to multipart if uploading files.
   *
   * This function will copy form data under a "payload" form field.
   **/
  const prepare = (data, headers) => {
    // Make sure data is not empty
    data = dropInvalidEntries(data)

    // A flag to send multipart POST request if files are present
    let hasFiles = false

    // FormData instance to upload multipart/form-data if necessary
    const requestData = new FormData()

    // Browser provides us with FileList when files are being submitted
    for (const part of Object.keys(data)) {
      if (data[part] instanceof FileList) {
        hasFiles = true
        for (const f of data[part]) {
          // We add files as a list to the FormData
          requestData.append(part, f)
        }
        // We have to remove the FileList from the JSON payload
        delete data[part]
      } else {
        // We add other fields as normal form fields to the request
        requestData.set(part, data[part])
      }
    }
    // Finally, we add everything else as a special `payload' JSON
    // string to the request. This is somewhat a hack not to disturb
    // much on our client in order to submit both data and files.
    requestData.set('payload', JSON.stringify(data))

    const requestHeaders = makeConfig(headers)
    if (hasFiles) {
      // For files we have to use multipart/form-data content type
      requestHeaders.headers['Content-Type'] = 'multipart/form-data'
    }
    return [hasFiles ? requestData : data, requestHeaders]
  }

  /**
   * Makes a post call to server.
   *
   * @param {string} path api path
   * @param {any} data payload
   * @returns
   */
  const post = async (path, data, headers) => {
    // prepare data, mostly for processing files
    const [pData, pHeaders] = prepare(data, headers)
    return await wrapHttpCall(
      async () => await axios.post(path, pData, pHeaders)
    )
  }

  /**
   * Makes a put call to server.
   *
   * @param {string} path api path
   * @param {object} data payload
   * @returns
   */
  const put = async (path, data, headers) => {
    // prepare data, mostly for processing files
    const [pData, pHeaders] = prepare(data, headers)

    return await wrapHttpCall(
      async () => await axios.put(path, pData, pHeaders)
    )
  }

  const remove = async (path) =>
    await wrapHttpCall(async () => await axios.delete(path, makeConfig()))

  // Provides context members.
  return (
    <ServiceContext.Provider
      value={{
        token,
        setToken: (token) => {
          // Saves token in local storage, for next load
          if (token) {
            localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token)
          } else {
            localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
          }
          setToken(token)
        },
        addErrorHandler,
        removeErrorHandler,
        get,
        post,
        put,
        delete: remove,
      }}
    >
      {children}
    </ServiceContext.Provider>
  )
}

/**
 * Returns service context.
 *
 * @returns Context<ServiceContext>
 */
export const useService = () => {
  return useContext(ServiceContext)
}

/**
 * Converts then given object to key, value pair string.
 *
 * @param {object} entry entry object
 * @returns
 */
const convertToString = (entry) => {
  let kv = Object.keys(entry).map((key) => {
    if (key !== 'search') {
      if (Array.isArray(entry[key]) && entry[key].length > 0) {
        return entry[key].map((a) => `${key}=${a}`).join('&')
      }
      return `${key}=${entry[key]}`
    } else {
      return `${key}=${encodeURIComponent(entry[key])}`
    }
  })
  return kv[0]
}

/**
 * Filter out parameters with "undefined" or NaN values.
 **/
const isValidParam = (param) => {
  const values = Object.values(param)
  // An array with a single member (each param is a key/value combo)
  if (values && values.length == 1) {
    // An array with a single undefined member
    if (values[0] === undefined) {
      console.warn('Invalid value (undefined) removed from parameters: ', param)
      return false
      // An array with a single NaN member
    } else if (Number.isNaN(values[0])) {
      console.warn('Invalid value (NaN) removed from parameters: ', param)
      return false
    }
  }
  return true
}

const dropInvalidEntries = (data) => {
  data ||= {}
  for (const [k, v] of Object.entries(data)) {
    if (v === undefined || Number.isNaN(v)) {
      console.warn(`Invalid value [${v}] for [${k}] removed from data.`)
      delete data[k]
    }
  }
  return data
}
