import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { useService } from 'common/service/context'
import { EditIconButton, SubmitButton } from 'common/widgets/button'
import { CheckBox } from 'common/widgets/checkbox'
import { Container } from 'common/widgets/container'
import { DataSource } from 'common/widgets/data-source'
import {
  ExpandableListItem,
  ExpandableListSubItem,
} from 'common/widgets/expandable'
import { TextField } from 'common/widgets/form/field'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { Row, Column } from 'common/widgets/grid'
import { OverlayFooter, OverlayForm } from 'common/widgets/overlay'
import { SearchInput } from 'common/widgets/search'
import { CardView } from 'common/widgets/view'
import { useToast } from 'system/toast/context'

import styles from '../../security.module.css'

/**
 * Renders notification select view inside role tab widget.
 *
 * @param {any} defaultActiveNotifications default active notifications of role
 * @returns ReactElement
 */
export const RoleNotificationsSelectView = ({ defaultActiveNotifications }) => {
  const service = useService()
  // extract role id from url params
  const { id } = useParams()

  const { t } = useTranslation()
  const { toasts } = useToast()

  // create state for keeping active scopes
  const [activeNotifications, setActiveNotifications] = useState(
    defaultActiveNotifications ? defaultActiveNotifications : []
  )
  const [prevActiveNotifications, setPrevActiveNotifications] = useState(
    defaultActiveNotifications ? defaultActiveNotifications : []
  )

  const disableSubmit = () => {
    if (activeNotifications.length !== prevActiveNotifications.length) {
      return false
    }

    return activeNotifications.every(
      (element, index) => element === prevActiveNotifications[index]
    )
  }
  /**
   * Adds or removes notifications from role.
   *
   * @param {int} notificationId notification id
   * @param {boolean} check boolean value which marks whether the scope should be removed from or added to role
   */
  const handleToggle = (notificationId, check) => {
    check
      ? setActiveNotifications(
          Array.from(new Set([...activeNotifications, notificationId]))
        )
      : setActiveNotifications(
          activeNotifications.filter((e) => e != notificationId)
        )
  }

  const handleToggleBulk = (notifications, check) => {
    check
      ? setActiveNotifications(
          Array.from(new Set([...activeNotifications, ...notifications]))
        )
      : setActiveNotifications(
          activeNotifications.filter((e) => !notifications.includes(e))
        )
  }

  const handleSubmit = async () => {
    const [result, error] = await service.post(
      `/notifications/roles/${id}/overwrite`,
      {
        notification_type_ids: activeNotifications,
      }
    )
    if (result && !error) {
      setPrevActiveNotifications(activeNotifications)
      toasts.success(t('Notification permission were edited'))
    }

    return [result, error]
  }

  const isActive = useCallback(
    /**
     * Checks, if scope was granted to role which means if it is activated.
     *
     * @param {int} notification_id scope id
     * @returns boolean. True if active, false if not.
     */
    (notification_id) => {
      return activeNotifications.includes(notification_id)
    },
    [activeNotifications]
  )

  const areAllActive = useCallback(
    /**
     * Checks, if the scopes were granted to role which means they are activated.
     *
     * @param {array} notifications notifications array
     * @returns boolean. True if active, false if not.
     */
    (notifications) => {
      var isFamilyChecked = true
      notifications.filter((e) => {
        if (!activeNotifications.includes(e)) {
          isFamilyChecked = false
          return
        }
      })

      return isFamilyChecked
    },
    [activeNotifications]
  )

  return (
    <Container flex>
      <Row>
        <Column n={12}>
          <RoleNotificationsSelect
            onToggle={handleToggle}
            onTogleBulk={handleToggleBulk}
            handleSubmit={handleSubmit}
            disableSubmit={disableSubmit}
            isActive={isActive}
            areAllActive={areAllActive}
          />
        </Column>
      </Row>
    </Container>
  )
}

/**
 * Renders scope select.
 *
 * @param {Function} isActive gets called to determine scope state
 * @param {Function} onToggle gets called when scope state changes
 * @returns ReactElement
 */
const RoleNotificationsSelect = ({
  isActive,
  areAllActive,
  onToggle,
  onTogleBulk,
  handleSubmit,
  disableSubmit,
}) => {
  const service = useService()

  /**
   * Fetches available scopes.
   *
   * @returns Array
   */
  const fetch = async (params) => {
    return await service.get(`notifications/types`, [...params])
  }

  return (
    <DataSource
      fetch={fetch}
      render={({ data, reload }) => {
        return (
          <CardView padding="20px">
            <Container flex title="Permissions">
              <SearchInput />
              <SubmitButton
                default
                onClick={handleSubmit}
                disabled={disableSubmit}
              />
            </Container>
            {data && (
              <ScopeList
                scopes={data}
                isActive={isActive}
                areAllActive={areAllActive}
                onToggle={onToggle}
                onTogleBulk={onTogleBulk}
                onEdit={reload}
              />
            )}
          </CardView>
        )
      }}
    />
  )
}

const ScopeList = ({
  scopes,
  isActive,
  areAllActive,
  onToggle,
  onTogleBulk,
  onEdit,
}) => {
  const root = {
    id: null,
    children: [],
  }

  scopes.forEach((e) => grouped(scopes, e.id))

  function grouped(records, itemId) {
    const item = findInGrouped(root, itemId)
    if (!item) {
      const filtered = records.filter((e) => e.id === itemId)
      const data = { ...filtered[0], children: [] }
      const splitted = itemId.split('.')
      var parent = root
      if (splitted.length > 1) {
        const parentId = splitted.slice(0, -1).join('.')
        parent = grouped(records, parentId)
      }
      parent.children = [...parent.children, data]
      return data
    }
    return item
  }

  function findInGrouped(item, itemId) {
    if (item.id === itemId) {
      return item
    } else {
      for (let index = 0; index < item.children.length; index++) {
        const element = findInGrouped(item.children[index], itemId)
        if (element != null) {
          return element
        }
      }
    }
    return null
  }

  return (
    <Container flex grow vertical>
      {root.children.map((scopeSection, idx) => (
        <Section
          key={`scope_section_${scopeSection.id}_${idx}`}
          parentKey={idx}
          isActive={isActive}
          areAllActive={areAllActive}
          scopeSection={scopeSection}
          onToggle={onToggle}
          onTogleBulk={onTogleBulk}
          onEdit={onEdit}
          root
        />
      ))}
    </Container>
  )
}

const Section = ({
  root,
  scopeSection,
  isActive,
  areAllActive,
  onTogleBulk,
  onToggle,
  onEdit,
  parentKey,
}) => {
  function createSection(scope, root, isParentLastItem, isLastItem, childKey) {
    const triggerChildren = (notification, checked) => {
      const children = []
      if (!notification.children || notification.children.length === 0) {
        return
      }
      notification.children
        .sort((a, b) => {
          if (a.parent && !b.parent) {
            return 1 // a comes before b
          } else if (!a.parent && b.parent) {
            return -1 // b comes before a
          } else {
            return 0 // a and b remain unchanged
          }
        })
        .filter((e) => {
          if (e.children && e.children.length > 0) {
            triggerChildren(e, checked)
          } else {
            children.push(e.id)
          }
        })
      onTogleBulk(children, checked)
    }

    const checkChildren = (notification) => {
      const children = []
      if (!notification.children || notification.children.length === 0) {
        return
      }
      notification.children
        .sort((a, b) => {
          if (a.parent && !b.parent) {
            return 1 // a comes before b
          } else if (!a.parent && b.parent) {
            return -1 // b comes before a
          } else {
            return 0 // a and b remain unchanged
          }
        })
        .filter((e) => {
          if (e.children && e.children.length > 0) {
            checkChildren(e)
          } else {
            children.push(e.id)
          }
        })
      return children
    }

    return (
      <>
        {scope.parent ? (
          root ? (
            <ExpandableListItem
              key={`${parentKey}_${childKey}`}
              renderHeader={() => <p>{scope.name}</p>}
            >
              {scope.children.map((e, idx) =>
                createSection(
                  e,
                  false,
                  isLastItem,
                  scope.children.length - 1 === idx
                )
              )}
            </ExpandableListItem>
          ) : (
            <ExpandableListSubItem
              key={`${parentKey}_${childKey}`}
              isLastItem={isLastItem}
              renderHeader={() => (
                <Container flex repel>
                  <p>{scope.name}</p>
                  {scope.parent && (
                    <CheckBox
                      onChange={(checked) => triggerChildren(scope, checked)}
                      value={areAllActive(checkChildren(scope))}
                    />
                  )}
                </Container>
              )}
            >
              {scope.children.map((e, idx) =>
                createSection(
                  e,
                  false,
                  isLastItem,
                  scope.children.length - 1 === idx
                )
              )}
            </ExpandableListSubItem>
          )
        ) : (
          <ScopeCard
            isLastItem={isLastItem}
            isParentLastItem={isParentLastItem}
            scope={scope}
            isActive={isActive}
            onToggle={onToggle}
            onEdit={onEdit}
          />
        )}
      </>
    )
  }

  return createSection(scopeSection, root, false, true)
}

const ScopeCard = ({
  scope,
  onToggle,
  isActive,
  onEdit,
  isLastItem,
  isParentLastItem,
}) => {
  const [open, setOpen] = useState(false)
  // get service context
  const service = useService()

  /**
   * Calls a put call and saves information.
   *
   * @param {any} values save data.
   * @returns
   */
  const handleSave = async (values) => {
    const result = await service.put(`notifications/types/${scope.id}`, values)
    setOpen(false)
    onEdit && onEdit()
    return result
  }

  return (
    <Container
      className={styles.scopeContainer}
      divider={!isLastItem || !isParentLastItem}
    >
      <Container flex grow>
        <p>{scope.name}</p>
      </Container>
      <EditIconButton onClick={() => setOpen(true)} />
      <NotificationEditOverlay
        open={open}
        notification={scope}
        onUpdate={handleSave}
        onClose={() => setOpen(false)}
      />
      <CheckBox
        onChange={(checked) => onToggle(scope.id, checked)}
        value={isActive(scope.id)}
      />
    </Container>
  )
}

const NotificationEditOverlay = ({ open, notification, onClose, onUpdate }) => {
  const { t } = useTranslation()

  return (
    <OverlayForm
      data={{ name: notification.name }}
      open={open}
      title={t('Edit')}
      onClose={onClose}
    >
      <NotificationsFormContent />
      <OverlayFooter>
        <SimpleFormAction onUpdate={onUpdate} />
      </OverlayFooter>
    </OverlayForm>
  )
}

/**
 * Renders roles' data entry form.
 *
 * @param {Function} onClose closing hook
 * @returns
 */
export const NotificationsFormContent = () => {
  return (
    <Container padding="inhert 20px">
      <TextField name="name" mandatory />
    </Container>
  )
}
