import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Sun, AlertCircle } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { isNilOrEmpty } from 'common/utils/conditional'
import { useForm } from 'common/widgets/form/context'

import { Card } from './card'
import { Container } from './container'
import { Column } from './grid'

export const CardView = styled(Card).attrs((props) => ({
  borderRadius: props.borderRadius || '5px',
  padding: '10px',
}))``

export const SectionView = ({ title, children, ...rest }) => {
  const { t } = useTranslation()
  return (
    <SectionViewImpl {...rest}>
      {title && <h1>{t(title)}</h1>}
      {children}
    </SectionViewImpl>
  )
}

const SectionViewImpl = styled(CardView).attrs((props) => ({
  flex: 'true',
  direction: 'column',
  grow: props.grow == '0' ? '0' : '1',
  gap: '10px',
}))`
  padding: 15px 15px 15px 15px;
  h1 {
    padding-top: 0px;
  }

  @media (max-width: 1280px) {
    padding: 10px 10px 10px 10px;
  }

  @media (max-width: 768px) {
    padding: 5px 5px 5px 5px;
  }
  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth};
    `}
`

export const RowView = styled(Card).attrs((props) => ({
  border: props.border || null,
}))`
  display: flex;
  background: #ffffff;
  margin-bottom: 5px;
  border-radius: 5px;
  padding: ${(props) => (props.padding ? props.padding : '15px')};
  ${(props) =>
    props.highlight &&
    css`
      box-shadow: 0px 0px 16px #fdfad7;
      background: #fffff9;
    `}
  ${(props) =>
    props.divide &&
    css`
      border-radius: 0;
      border: 0;
      border-top: 1px solid #eeeeee;
    `}
`

export const Divider = styled.div`
  display: flex;
  border-top: 1px solid #eeeeee;
  padding: ${(props) => (props.nopadding ? '0px' : '5px')};
`

/**
 * Redirects the user to the given url in a new tab.
 *
 * @param {label} string label of the field
 * @param {value} string value that is been shown
 * @param {url} string redirect url
 * @returns ReactElement
 */
export const LinkFieldView = ({ label, value, url }) => (
  <FieldView label={label}>
    <a href={url} rel="noreferrer" onClick={(e) => e.stopPropagation()}>
      {value}
    </a>
  </FieldView>
)

/**
 * Redirects the user to the user`s detail page.
 *
 * @param {label} string label of the field
 * @param {value} string value that is been shown
 * @param {id} int user id
 *
 * @returns ReactElement
 */
export const UserFieldView = ({ label, id, value }) => (
  <FieldView label={label}>
    <a
      href={`/security/users/${id}`}
      rel="noreferrer"
      onClick={(e) => e.stopPropagation()}
    >
      {value}
    </a>
  </FieldView>
)

/**
 * Redirects the user to the given url in a new tab.
 *
 * @param {n} int lenght of grid item
 * @param {label} string label of the field
 * @param {value} string value that is been shown
 * @param {url} string redirect url
 *
 * @returns ReactElement
 */
export const GridLinkFieldView = ({ n, label, value, url, ...rest }) => (
  <GridFieldView n={n} label={label} {...rest}>
    <a href={url} rel="noreferrer" onClick={(e) => e.stopPropagation()}>
      {value}
    </a>
  </GridFieldView>
)

export const FieldView = ({ label, value, children, href, ...rest }) => {
  const { t } = useTranslation()
  const Value = () =>
    href && !isNilOrEmpty(value) ? (
      <Link to={href} onClick={(e) => e.stopPropagation()}>
        {value}
      </Link>
    ) : (
      <p>{value || '-'}</p>
    )
  return (
    <FieldViewContainer {...rest} href={href}>
      {label && <h5>{t(label)}</h5>}
      {children ? children : <Value />}
    </FieldViewContainer>
  )
}

export const GridFieldView = ({
  label,
  value,
  render,
  danger,
  success,
  reverse,
  children,
  ...rest
}) => {
  // Sometimes we need the form context in the body of our field,
  // in such cases we can provide a function as the child and use
  // the passed context in the body.
  const ctx = useForm()
  const { t } = useTranslation()
  let effectiveValue = value
  if (typeof value === 'string') {
    effectiveValue = <p>{t(value) ?? '-'}</p>
  }
  return (
    <Column {...rest}>
      <FieldView
        label={label}
        danger={danger}
        success={success}
        reverse={reverse}
      >
        {render && render}
        {!children && !render && effectiveValue}
        {typeof children == 'function' ? children(ctx) : children}
      </FieldView>
    </Column>
  )
}

export const FieldViewGroup = styled(Container)`
  display: flex;
  margin-top: 15px;
`

export const FieldViewContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  ${(props) =>
    props.debug &&
    css`
      border: 1px solid #555555;
    `}
  flex-direction: column;
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${(props) =>
    props.rpadding &&
    css`
      padding-right: ${props.rpadding};
    `}
  ${(props) =>
    props.lpadding &&
    css`
      padding-left: ${props.lpadding};
    `}
  a {
    transition: 0.5s;
    :hover {
      ${(props) =>
        props.href &&
        css`
          background: #f5f5f5;
          border-radius: 2px;
        `}
    }
  }
  svg {
    height: 16px;
    width: 16px;
  }
  p {
    ${(props) =>
      props.danger &&
      css`
        color: #fc4242;
      `}
    ${(props) =>
      props.success &&
      css`
        color: #95e68b;
      `}
    ${(props) =>
      props.cut
        ? css`
            overflow: clip;
            text-overflow: ellipsis;
          `
        : css`
            overflow-wrap: break-word;
          `}
  }
  h4 {
    ${(props) =>
      props.danger &&
      css`
        color: #fc4242;
      `}
    ${(props) =>
      props.success &&
      css`
        color: #95e68b;
      `}
    ${(props) =>
      props.cut
        ? css`
            overflow: clip;
            text-overflow: ellipsis;
          `
        : css`
            overflow-wrap: break-word;
          `}
  }
`

export const CommentView = ({ icon, comment, warning, info, ...rest }) => {
  const { t } = useTranslation()
  let color = '#808080'
  if (warning) {
    // Orange
    color = '#F49537'
  } else if (info) {
    // Blue
    color = '#4A7BF5'
  }
  return (
    comment && (
      <CommentViewContainer flex color={color} {...rest}>
        {icon ? icon : warning ? <AlertCircle /> : <Sun />}
        <h5>{t(comment)}</h5>
      </CommentViewContainer>
    )
  )
}

const CommentViewContainer = styled(Container)`
  display: flex;
  align-items: center;
  margin-top: 5px !important;
  h5 {
    color: ${(props) => props.color} !important;
    width: 100%;
  }
  svg {
    margin-right: 5px !important;
    color: ${(props) => props.color};
    width: 14px;
    height: 14px;
  }
`
