/**
 * Formats address.
 *
 * @param {any} address address info
 * @returns
 */
export const formatAddress = (address) => {
  const lines = []
  if (address) {
    if (address.street && address.street_number) {
      lines.push(`${address.street} ${address.street_number}`)
    }
    if (address.zip_code && address.city) {
      lines.push(`${address.zip_code} ${address.city}`)
    }
    if (address.country) {
      lines.push(`${address.country}`)
    }
  }
  return lines.length > 0 ? lines : ['...']
}

/**
 * Concat project short name with project cost center.
 *
 * @param {any} project project data
 * @returns string
 */
export const formatProjectName = (project) => {
  if (!project) {
    return '-'
  }
  const preferredProjectName = project.short_name || project.name
  if (project.cost_center) {
    return `${project.cost_center} - ${preferredProjectName}`
  }
  return preferredProjectName
}

/**
 * Concat project short name with project cost center.
 *
 * @param {any} project project data
 * @returns string
 */
export const getProjectLink = (project) =>
  project ? `/projects/${project.id}` : null

/**
 * Gets the formatted address in a single line.
 *
 * @param {object} address address info
 */
export const getSingleLineAddress = (address) => {
  let result = ''
  if (address) {
    if (address.street) {
      result += `${address.street} `
    }
    if (address.street_number) {
      result += `${address.street_number} `
    }
    if (address.zip_code) {
      result += `${address.zip_code} `
    }
    if (address.city) {
      result += `${address.city} `
    }
    if (address.country) {
      result += `${address.country}`
    }
  }
  return result
}
