import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useMediaQuery } from 'common/utils/hooks'

import './flexgrid.css'

/**
 * Renders a grid component which positiones it's childs - GridItems - based on the params.
 *
 *
 * @param {object} params
 * @returns
 */
export const Row = ({
  title,
  // will be added to the title line on the opposite side
  titleWidgets,
  align,
  justify = 'flex-start',
  spaceBetweenColumns = '15px',
  spaceBetweenRows = '10px',
  cells = 12,
  space = '0',
  children,
  debug,
  ...rest
}) => {
  const { t } = useTranslation()

  // If you only have one child, children is the child object, but not a list.
  // Convert it to a list, as we need a list. If you have more than one child, children is a list.
  let normalizedChildren = Array.isArray(children) ? children : [children]
  normalizedChildren = normalizedChildren.filter(
    (child) => child?.props && child?.props?.visible !== false
  )

  const min = (a, b) => (a > b ? b : a)
  const large = (child) => min(child.props.n ? child.props.n : cells, cells)
  const small = (child) =>
    min(child.props.s ? child.props.s : 2 * child.props.n, cells)
  const medium = (child) =>
    min(child.props.m ? child.props.m : child.props.n, cells)

  let info = []
  let occupiedCellsN = 0
  let rowIndexN = 0
  let occupiedCellsM = 0
  let rowIndexM = 0
  let occupiedCellsS = 0
  let rowIndexS = 0
  normalizedChildren.forEach((child, index) => {
    info.push({})

    info[index].n = large(child)
    occupiedCellsN += info[index].n
    info[index].lastItemInRowN = info[index].n === cells
    info[index].occupiedCellsN = occupiedCellsN
    if (occupiedCellsN > cells) {
      if (index > 0) {
        info[index - 1].lastItemInRowN = true
      }
      rowIndexN += 1
      occupiedCellsN = info[index].n
      info[index].rowIndexN = rowIndexN
    } else if (occupiedCellsN === cells) {
      info[index].rowIndexN = rowIndexN
      info[index].lastItemInRowN = true
      occupiedCellsN = 0
      rowIndexN += 1
    } else {
      info[index].rowIndexN = rowIndexN
    }

    info[index].m = medium(child)
    occupiedCellsM += info[index].m
    info[index].lastItemInRowM = info[index].m === cells
    info[index].occupiedCellsM = occupiedCellsM
    if (occupiedCellsM > cells) {
      if (index > 0) {
        info[index - 1].lastItemInRowM = true
      }
      rowIndexM += 1
      occupiedCellsM = info[index].m
      info[index].rowIndexM = rowIndexM
    } else if (occupiedCellsM === cells) {
      info[index].rowIndexM = rowIndexM
      info[index].lastItemInRowM = true
      occupiedCellsM = 0
      rowIndexM += 1
    } else {
      info[index].rowIndexM = rowIndexM
    }

    info[index].s = small(child)
    occupiedCellsS += info[index].s
    info[index].lastItemInRowS = info[index].s === cells
    info[index].occupiedCellsS = occupiedCellsS
    if (occupiedCellsS > cells) {
      if (index > 0) {
        info[index - 1].lastItemInRowS = true
      }
      rowIndexS += 1
      occupiedCellsS = info[index].s
      info[index].rowIndexS = rowIndexS
    } else if (occupiedCellsS === cells) {
      info[index].rowIndexS = rowIndexS
      info[index].lastItemInRowS = true
      occupiedCellsS = 0
      rowIndexS += 1
    } else {
      info[index].rowIndexS = rowIndexS
    }
  })

  const columnsCountInRowN = (rowIndex) =>
    info.filter((c) => c.rowIndexN === rowIndex).length
  const columnsCountInRowM = (rowIndex) =>
    info.filter((c) => c.rowIndexM === rowIndex).length
  const columnsCountInRowS = (rowIndex) =>
    info.filter((c) => c.rowIndexS === rowIndex).length

  const isDebugMode = () =>
    debug &&
    (window.location.hostname.toLowerCase() === 'localhost' ||
      window.location.hostname === '127.0.0.1')

  // Collects rendering items
  const items = normalizedChildren.map((child, index) => {
    const data = info[index]
    /**
     * Calculate the width of a GridItem and right margin.
     *
     * @param {int} occupiedCells The number of cells which the GridItem occupies inside the row.
     * @param {int} columnsCountInRow The number of GridItems inisde the row.
     * @param {int} lastRowElement The sum of cells which are occupied by the GridItems inside the row.
     * @returns
     */
    const calculate = (occupiedCells, columnsCountInRow, lastRowElement) => {
      const haveGut =
        spaceBetweenColumns !== '0' && spaceBetweenColumns !== '0px'
      const gut = columnsCountInRow !== 0 ? 1 - 1 / columnsCountInRow : 0
      // Calculate the width
      const width =
        haveGut && gut > 0
          ? `calc(${occupiedCells} / ${cells} * 100% - (${gut} * ${spaceBetweenColumns}))`
          : `calc(${occupiedCells} / ${cells} * 100%)`
      // Calculates the right margin
      const marginRight =
        lastRowElement || columnsCountInRow === 1 ? '0' : spaceBetweenColumns
      return [width, marginRight]
    }

    // Calculate the childs width.
    const [widthN, marginN] = calculate(
      large(child),
      columnsCountInRowN(data.rowIndexN),
      data.lastItemInRowN
    )
    const [widthM, marginM] = calculate(
      medium(child),
      columnsCountInRowM(data.rowIndexM),
      data.lastItemInRowM
    )
    const [widthS, marginS] = calculate(
      small(child),
      columnsCountInRowS(data.rowIndexS),
      data.lastItemInRowS
    )

    // Pass props if the child is not a DOM element
    const childProps =
      typeof child?.type == 'string'
        ? { key: index }
        : {
            key: index,
            marginTopN: data.rowIndexN === 0 ? '0px' : spaceBetweenRows,
            marginTopM: data.rowIndexM === 0 ? '0px' : spaceBetweenRows,
            marginTopS: data.rowIndexS === 0 ? '0px' : spaceBetweenRows,
            marginRightN: marginN,
            marginRightM: marginM,
            marginRightS: marginS,
            widthN: widthN,
            widthM: widthM,
            widthS: widthS,
            n: data.n,
            m: data.m,
            s: data.s,
            spaceBetweenRows: spaceBetweenRows,
            spaceBetweenColumns: spaceBetweenColumns,
            debug: isDebugMode(),
          }

    // Return cloned child with its properties.
    return React.cloneElement(child, childProps)
  })

  return (
    <RowContainer
      justify={justify}
      align={align}
      debug={isDebugMode()}
      space={space}
      {...rest}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        {title && (
          <h3 style={{ marginBottom: '5px', width: '100%' }}>{t(title)}</h3>
        )}
        <span style={{ marginLeft: 'auto' }}>{titleWidgets}</span>
      </div>
      {normalizedChildren && items}
    </RowContainer>
  )
}

const RowContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  background: ${(props) => props.background};
  height: ${(props) => props.height};
  margin-top: ${(props) => props.space};

  ${(props) =>
    props.debug &&
    css`
      background: ${getRandomColor()};
    `}
`

export function Column({ title, children, ...rest }) {
  const { t } = useTranslation()
  return (
    <ColumnInner {...rest}>
      {title && <h5>{t(title)}</h5>}
      {children}
    </ColumnInner>
  )
}

const ColumnInner = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: ${(props) => props.marginTopN};
  margin-right: ${(props) => props.marginRightN};
  width: ${(props) => props.widthN};

  @media (max-width: 1280px) {
    margin-top: ${(props) => props.marginTopM};
    margin-right: ${(props) => props.marginRightM};
    width: ${(props) => props.widthM};
  }

  @media (max-width: 768px) {
    margin-top: ${(props) => props.marginTopS};
    margin-right: ${(props) => props.marginRightS};
    width: ${(props) => props.widthS};
  }

  padding: ${(props) => props.padding};
  background: ${(props) => props.background};
  justify-content: ${(props) => props.justify};
  ${(props) =>
    props.debug &&
    css`
      background: ${getRandomColor()};
    `}
  ${(props) =>
    props.end &&
    css`
      align-items: end;
    `}
  ${(props) =>
    props.grow &&
    css`
      flex-grow: 1;
    `}
  ${(props) =>
    props.reverse &&
    css`
      flex-direction: column-reverse;
    `}
  /*
   * This is a workaround for a Chrome bug:
   * https://bugs.chromium.org/p/chromium/issues/detail?id=1161709
   * Using gap in certain cases causes Chrome to print thousands of pages.
   * Do not use this prop unless absolutely necessary.
   */
  ${(props) =>
    props.gapByMargin &&
    css`
      /* Learn more about "Lobotomized Owls" selector at
       * https://alistapart.com/article/axiomatic-css-and-lobotomized-owls/
       */
      * + * {
        margin-left: ${props.gapByMargin};
      }
    `}
  ${(props) =>
    props.noprint &&
    useMediaQuery('print') &&
    css`
      display: none;
    `}
    ${(props) =>
    props.gap &&
    css`
      gap: ${props.gap};
    }
  `}
`

Column.defaultProps = {
  n: 12,
}

const getRandomColor = () => {
  var letters = '0123456789ABCDEF'
  var color = '#'
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}
