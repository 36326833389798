import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { RemoteImage } from 'common/widgets/image'
import { formatDate } from 'common/utils/format'
import { Container } from 'common/widgets/container'
import { Row, Column } from 'common/widgets/grid'
import {
  FieldView,
  FieldViewGroup,
  GridFieldView,
  GridLinkFieldView,
  SectionView,
} from 'common/widgets/view'
import { ProjectSelectField } from 'modules/projects/widgets/select'
import { useService } from 'common/service/context'
import { Form, useForm } from 'common/widgets/form/context'
import { DataSource } from 'common/widgets/data-source'
import { Select } from 'common/widgets/select'
import { formatProjectName } from 'modules/projects/utils'
import { DetailViewPage } from 'system/utils/view'
import { formatBackDeliveryNumber } from 'modules/yard/utils'
import { CancelIconButton } from 'common/widgets/button'
import { Avatar } from 'common/widgets/avatar'

import { BackDeliveredItems } from './items'
import { CancelBackDeliveryOverlay } from './overlays/cancel'

/**
 * Renders the Back delivery detail info.
 *
 * @param {any} backDelivery object
 * @returns ReactElement
 */
export const BackDeliveryViewDetails = ({ backDelivery }) => {
  const { t } = useTranslation()

  return (
    <Row>
      <Column flex n={6}>
        <SectionView>
          <h1>{t('Back delivery data')}</h1>
          <Row>
            <GridLinkFieldView
              n={6}
              label={t('Project')}
              value={backDelivery?.project?.short_name}
              url={`/projects/${backDelivery?.project?.id}`}
            />
            <GridFieldView
              n={6}
              label={t('Destination')}
              value={
                backDelivery?.dest_project
                  ? backDelivery?.dest_project?.short_name
                  : t('Building yard')
              }
            />
            <GridFieldView
              n={6}
              label={t('State')}
              value={t(backDelivery?.state)}
            />
          </Row>
        </SectionView>
      </Column>
      <Column flex n={6}>
        <SectionView>
          <Container flex justify="space-between">
            <Container flex grow vertical>
              <h1>{t('Back delivery data')}</h1>
              <FieldViewGroup>
                <FieldView label={t('Delivery option')}>
                  <p>
                    {backDelivery?.adhoc
                      ? t('Self-pickup')
                      : t('Requires delivery')}
                  </p>
                </FieldView>
                <FieldView label={t('Creation date')}>
                  <p>{formatDate(backDelivery?.created_at)}</p>
                </FieldView>
              </FieldViewGroup>
            </Container>
            <RemoteImage id={backDelivery?.signature_file_id}>
              {(data) => <Avatar imgSrc={data} />}
            </RemoteImage>
          </Container>
        </SectionView>
      </Column>
    </Row>
  )
}

/**
 * Renders the Create Back Delivery detail info.
 *
 * @param {int} currentProjectId current project id
 * @param {any} setTargetProjectId setter for the selected project id
 * @param {bool} forDelivery is the back delivery set for self pickup or not
 * @param {any} setForDelivery setter for the back delivery delivery state
 *
 * @returns ReactElement
 */
export const CreateBackDeliveryForm = ({ ...rest }) => (
  <Form>
    <CreateBackDeliveryFormFields {...rest} />
  </Form>
)

const CreateBackDeliveryFormFields = ({
  currentProjectId,
  setTargetProjectId,
  forDelivery,
  setForDelivery,
}) => {
  const { t } = useTranslation()
  const service = useService()
  const { values } = useForm()

  const fetchCurrentProject = async () =>
    await service.get(`/projects/${currentProjectId}`)

  useEffect(() => {
    setTargetProjectId(values.json.target_project_id)
  }, values.json.target_project_id)

  return (
    <Row>
      <Column flex n={6}>
        <SectionView>
          <Container flex grow vertical>
            <h1>{t('Back delivery data')}</h1>
            <Row>
              <GridFieldView
                n={6}
                label={t(`Target location`)}
                render={
                  <ProjectSelectField
                    name="target_project_id"
                    hint={t(`Building yard`)}
                    disabled
                    excludeProjectId={currentProjectId}
                  />
                }
              />
            </Row>
          </Container>
        </SectionView>
      </Column>
      <Column flex n={6}>
        <SectionView>
          <Container flex grow vertical>
            <h1>{t('Back delivery data')}</h1>
            <Row>
              <GridFieldView
                n={6}
                label={t(`Project`)}
                render={
                  <DataSource fetch={fetchCurrentProject}>
                    {({ data }) =>
                      data && (
                        <ProjectSelectField
                          name="project_id"
                          disabled
                          hint={formatProjectName(data)}
                        />
                      )
                    }
                  </DataSource>
                }
              />
              <GridFieldView
                n={6}
                label={t('Delivery option')}
                render={
                  <DeliveryOptionField
                    forDelivery={forDelivery}
                    setForDelivery={setForDelivery}
                  />
                }
              />
            </Row>
          </Container>
        </SectionView>
      </Column>
    </Row>
  )
}

/**
 * Renders back delivery options.
 *
 * @returns
 */
const DeliveryOptionField = ({ forDelivery, setForDelivery }) => {
  const { t } = useTranslation()

  const items = {
    'Requires delivery': true,
    'Self pickup': false,
  }
  const mapped = Object.entries(items).map(([title, key]) => {
    return { key, title: t(title) }
  })

  return (
    <Select
      selected={forDelivery}
      items={mapped}
      onSelectChange={(item) => setForDelivery(item.key)}
    />
  )
}

/**
 * Renders the back deliveries view page.
 *
 * @returns ReactElemnt
 */
export const BackDeliveryViewPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  // Back delivery cancelation ovarlay state
  const [cancelBackDeliveryObject, setCancelBackDeliveryObject] = useState(null)

  return (
    <DetailViewPage
      url="/back/delivery"
      archivable={(r) => r.archivable}
      title={(backDelivery) =>
        `${t('Back delivery number')} : ${formatBackDeliveryNumber(
          backDelivery
        )}`
      }
      addExtraButtons={(r) =>
        r?.cancelable && (
          <CancelIconButton
            onClick={(e) => {
              e.stopPropagation()
              setCancelBackDeliveryObject(r)
            }}
          />
        )
      }
    >
      {(backDelivery) => (
        <Container>
          {backDelivery && <BackDeliveryContent backDelivery={backDelivery} />}
          <CancelBackDeliveryOverlay
            backDelivery={cancelBackDeliveryObject}
            onClose={() => setCancelBackDeliveryObject(null)}
            onDeliveryCanceled={() => navigate(-1)}
          />
        </Container>
      )}
    </DetailViewPage>
  )
}

/**
 * Renders the back delivery content
 *
 * @param {any} backDelivery Back delivery object
 *
 * @returns ReactElemnt
 */
const BackDeliveryContent = ({ backDelivery }) => {
  const resources = backDelivery.items?.filter(
    (item) => item.baseitem?.is_resource
  )
  const formworks = backDelivery.items?.filter(
    (item) => item.baseitem?.is_formwork
  )

  return (
    <Container flex vertical gap="10px">
      <BackDeliveryViewDetails backDelivery={backDelivery} />
      {resources?.length > 0 && (
        <BackDeliveredItems backDeliveryItems={resources} title={'RESOURCE'} />
      )}
      {formworks?.length > 0 && (
        <BackDeliveredItems backDeliveryItems={formworks} title={'FORMWORK'} />
      )}
    </Container>
  )
}
