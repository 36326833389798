import { useTranslation } from 'react-i18next'

import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'
import { DropDownField } from 'common/widgets/form/field'
import { useForm } from 'common/widgets/form/context'
import { CommentView } from 'common/widgets/view'

/**
 * Renders a telematics id free choose widget.
 *
 * @param {Array} params filter parameters
 * @param {object} handler handler to control params and loadings
 * @param {Function} onSelect gets called when a project selected
 * @returns
 */
export const TelematicsIdField = ({
  handler,
  name = 'telematics_id',
  assigned,
  ...rest
}) => {
  const service = useService()
  const { values } = useForm()
  const { t } = useTranslation()
  const telematics_id = values.get(name) || null
  let selectedIndex
  return (
    <DataSource
      fetch={async () => await service.get('telematics', [{ assigned }])}
      handler={handler}
    >
      {({ data, error, loading }) => {
        // if we are displaying only free devices, we have to inject the current
        // selected device to the list options (as pre-selected value)
        if (!assigned && telematics_id) {
          data?.push({ id: telematics_id })
          selectedIndex = data?.length - 1
        }

        return (
          <>
            <DropDownField
              name={name}
              items={data?.map((e) => ({ key: e.id, title: e.id }))}
              selectedIndex={selectedIndex}
              removable
            />
            {!data?.length && (
              <CommentView
                warning
                comment={t('There are no available telematics.')}
              />
            )}
          </>
        )
      }}
    </DataSource>
  )
}
