import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { DetailViewPage } from 'system/utils/view'
import { Container } from 'common/widgets/container'
import { formatBackDeliveryNumber } from 'modules/yard/utils'
import { CancelIconButton, SaveButton } from 'common/widgets/button'
import { useService } from 'common/service/context'

import { BackDeliveryViewDetails } from './details'
import { PickBackdeliveryItemOverlay } from './overlays/add'
import { BackDeliveredItems } from './items'
import { CancelBackDeliveryOverlay } from './overlays/cancel'
import { BackDeliverySummaryOverlay } from './overlays/summary'

/**
 * Renders the back delivery confirm view page.
 *
 * @returns ReactElemnt
 */
export const BackDeliveryConfirmViewPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [items, setItems] = useState([])
  const [disableSubmit, setDisableSubmit] = useState(true)
  const [cancelBackDeliveryObject, setCancelBackDeliveryObject] = useState(null)

  return (
    <DetailViewPage
      url="/back/delivery"
      title={(backDelivery) =>
        `${t('Back delivery number')} : ${formatBackDeliveryNumber(
          backDelivery
        )}`
      }
      addExtraButtons={(r) => (
        <CancelIconButton
          onClick={(e) => {
            e.stopPropagation()
            setCancelBackDeliveryObject(r)
          }}
        />
      )}
    >
      {(backDelivery) => (
        <Container>
          {backDelivery && (
            <BackDeliveryContent
              backDelivery={backDelivery}
              items={items}
              setItems={setItems}
              setDisableSubmit={setDisableSubmit}
              disableSubmit={disableSubmit}
            />
          )}
          <CancelBackDeliveryOverlay
            backDelivery={cancelBackDeliveryObject}
            onClose={() => setCancelBackDeliveryObject(null)}
            onDeliveryCanceled={() => navigate(-1)}
          />
        </Container>
      )}
    </DetailViewPage>
  )
}

/**
 * Renders the back delivery content
 *
 * @param {any} backDelivery Back delivery object
 * @param {array} items back delivery items
 * @param {any} setItems setter for the back delivery items
 * @param {any} setDisableSubmit setter for the disabled state of the backdelivery submit button
 *
 * @returns ReactElemnt
 */
const BackDeliveryContent = ({
  backDelivery,
  items,
  setItems,
  setDisableSubmit,
  disableSubmit,
}) => {
  const service = useService()
  const navigate = useNavigate()
  const [addBackdeliveryItem, setAddBackdeliveryItem] = useState(null)
  const [summaryOverlayVisible, setSummaryOverlayVisible] = useState(false)
  setItems(backDelivery.items)
  setDisableSubmit(
    items.filter((item) => item.commissionedAmount > 0).length === 0
  )
  const resources = items?.filter((item) => item.baseitem?.is_resource)
  const formworks = items?.filter((item) => item.baseitem?.is_formwork)

  /**
   * Handles the back delivery item add
   *
   * @param {any} backDeliveryItem back delivery item object
   */
  const handleBackDeliveryItemAdd = (backDeliveryItem) => {
    setAddBackdeliveryItem(backDeliveryItem)
  }

  const calculateFaultAmount = (backdeliveryItem) => {
    var amount = 0
    if (backdeliveryItem.faults) {
      for (var i = 0; i < backdeliveryItem.faults.length; i++) {
        amount = amount + backdeliveryItem.faults[i].amount
      }
    }

    return amount
  }

  const extractFaults = (backdeliveryItem) => {
    if (backdeliveryItem.faults) {
      return backdeliveryItem.faults.map((e) => ({
        amount: e.amount,
        type: e.type,
        comment: e.comment,
      }))
    }

    return []
  }

  const handleBackDeliverySubmit = async (data) => {
    const [createBackDeliveryResult, createBackDeliveryError] =
      await service.put(`back/delivery/${backDelivery.id}/confirm`, data)
    if (!createBackDeliveryError) {
      navigate(-1)
    }

    return [createBackDeliveryResult, createBackDeliveryError]
  }

  return (
    <>
      <BackDeliveryViewDetails backDelivery={backDelivery} />
      <Container flex vertical>
        {resources?.length > 0 && (
          <BackDeliveredItems
            backDeliveryItems={resources}
            title={'RESOURCE'}
            onAdd={handleBackDeliveryItemAdd}
          />
        )}
        {formworks?.length > 0 && (
          <BackDeliveredItems
            backDeliveryItems={formworks}
            title={'FORMWORK'}
            onAdd={handleBackDeliveryItemAdd}
          />
        )}
      </Container>
      <Container>
        <SaveButton
          disabled={disableSubmit}
          default
          onClick={() => {
            setSummaryOverlayVisible(true)
          }}
        />
      </Container>
      {summaryOverlayVisible && (
        <BackDeliverySummaryOverlay
          open={summaryOverlayVisible}
          backDeliveryItems={items}
          projectId={backDelivery.project_id}
          onSubmit={() => {
            const data = {
              items: items.map((e) => ({
                baseitem_id: e.baseitem.id,
                booking_id: e.booking?.id,
                amount: e.commissionedAmount,
                ok: e.commissionedAmount - calculateFaultAmount(e),
                faults: extractFaults(e),
              })),
              signature: null,
            }
            handleBackDeliverySubmit(data)
          }}
          onClose={() => setSummaryOverlayVisible(false)}
        />
      )}
      {addBackdeliveryItem && (
        <PickBackdeliveryItemOverlay
          onClose={() => setAddBackdeliveryItem(null)}
          backdeliveryItem={addBackdeliveryItem}
        />
      )}
    </>
  )
}
