import { useParams } from 'react-router-dom'

import { Form } from 'common/widgets/form/context'
import { SystemPage } from 'system/page/core'
import { useService } from 'common/service/context'

import { ContactPresentFormContent } from './form'

export const ContactPresentAddPage = () => {
  const service = useService()
  const { id } = useParams()

  /**
   * Calls a post call and creates a new contact present.
   *
   * @param {any} values contact present data
   * @returns
   */
  const handleAdd = async (values) =>
    await service.post(`/contacts/presents`, {
      ...values,
      contact_id: parseInt(id),
    })

  return (
    <SystemPage>
      <Form>
        <ContactPresentFormContent onAdd={handleAdd} />
      </Form>
    </SystemPage>
  )
}
