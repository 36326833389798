import styled from 'styled-components'

export const Badge = styled.div`
  position: absolute;
  box-sizing: content-box;
  height: 20px;
  border-radius: 10px;
  margin-left: 22px;
  margin-top: 12px;
  display: flex;
  background: #4b83ff;
  color: #ffffff;
  align-items: center;

  h5 {
    margin: auto;
    color: #ffffff;
    font-size: 9px;
    font-weight: 900;
  }
`
