import { useService } from 'common/service/context'
import { DropDown } from 'common/widgets/dropdown'
import { FieldView, GridFieldView } from 'common/widgets/view'

/**
 * Renders the drop down field for changing the source project of a delivery.
 *
 * @param {any} delivery delivery note object
 * @param {object} reload reloads the parent page
 *
 * @returns ReactElement
 */
export const SourceProjectPicker = ({ delivery, reload }) => {
  const service = useService()

  /**
   * Edits the source project in the delivery note.
   *
   * @param {int} sourceProjectId id of the source project
   *
   * @returns Array[deliveryResult, deliveryError]
   */
  const handleSourceProjectChange = async (sourceProject) => {
    // Consider null which is a valid value for unsetting the Id
    const sourceProjectId = sourceProject?.id || sourceProject
    const [deliveryResult, deliveryError] = await service.put(
      `kommission/delivery/${delivery.id}`,
      {
        source_project_id: sourceProjectId,
      }
    )
    if (!deliveryError && reload) {
      reload()
    }

    return [deliveryResult, deliveryError]
  }

  // Fetches the bookings of the delivery items if any are available
  const sourceProjects = delivery.bookings
    .filter((e) => e.source_project !== null)
    .map((booking) => booking.source_project)
  const sourceProjectDisabled = delivery.state !== 'COMMISSIONING'
  const uniqueIds = []

  const filteredProjects = sourceProjects.filter((element) => {
    const isDuplicate = uniqueIds.includes(element.id)

    if (!isDuplicate) {
      uniqueIds.push(element.id)

      return true
    }

    return false
  })
  return sourceProjectDisabled ? (
    <GridFieldView
      n={6}
      m={6}
      s={12}
      label="Pick-up location"
      value={delivery?.source_project?.name}
    />
  ) : (
    <FieldView label="Pick-up location">
      <SourceProjectSelectField
        projects={filteredProjects}
        hint="Building yard"
        selected={delivery?.source_project?.id}
        onSelectChange={async (project) => {
          handleSourceProjectChange(project)
        }}
        disabled={!sourceProjectDisabled}
      />
    </FieldView>
  )
}

/**
 * Renders a source project select widget.
 *
 * @param {Array} params filter parameters
 * @param {object} handler handler to control params and loadings
 * @param {Function} onSelect gets called when a project selected
 * @param {string} hint hint of the dropdown menu
 * @param {any} icon widget icon.
 * @param {Array} bookings dispo bookings array
 * @param {bool} disabled is the widget disabled or not
 * @returns
 */
const SourceProjectSelectField = ({
  selected,
  projects,
  onSelectChange,
  hint,
  icon,
  disabled,
}) => {
  const items =
    projects && disabled
      ? projects.map((project) => ({
          key: project.id,
          title: project.short_name || project.name,
          data: project,
        }))
      : []

  return !disabled ? (
    <p>{hint}</p>
  ) : (
    <DropDown
      minWidth="320px"
      maxWidth="320px"
      title={hint ? hint : 'Projects'}
      items={items}
      selectedIndex={items?.findIndex((i) => i.key === selected)}
      disabled={!disabled}
      onSelectChange={(e) => {
        onSelectChange && onSelectChange(e?.data || e)
      }}
      removable
    />
  )
}
