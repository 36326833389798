import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'
import { DropDownField } from 'common/widgets/form/field'

export const ResponsibleOrdererField = ({
  handler,
  name = 'responsible_orderer_id',
  removable = true,
  ...rest
}) => {
  // Define service parameters
  const params = [
    { archived: false },
    { role: 'purchasing_department' },
    { role: 'stock_manager' },
    { role: 'stock_employee' },
    { role: 'storeman' },
  ]

  const service = useService()

  /**
   * Calls project apis and collects data.
   *
   * @returns [any, any, int]
   */
  const fetch = async () => await service.get('users', params)

  const render = ({ data, error, loading }) => {
    const items =
      data?.map((d) => {
        return {
          key: d.id,
          title: d.first_name + ' ' + d.last_name,
        }
      }) || []
    return (
      <DropDownField
        name={name}
        items={items}
        minWidth="276px"
        removable={removable}
        {...rest}
      />
    )
  }

  return <DataSource fetch={fetch} handler={handler} render={render} />
}
