import { useTranslation } from 'react-i18next'
import { Calendar } from 'react-feather'

import { Card } from 'common/widgets/card'
import { formatItemName } from 'modules/master-data/equipments/utils'
import { Row } from 'common/widgets/grid'
import { CommentView, GridFieldView } from 'common/widgets/view'
import { formatDate, formatDateRange } from 'common/utils/format'
import { formatOrderedItemAmount } from 'modules/orders/utils'
import { DeleteIconButton } from 'common/widgets/button'
import { Container } from 'common/widgets/container'

export const OrderedItem = ({ data, order, onClick, onDelete }) => {
  const { t } = useTranslation(['orders'])
  const formatBookingPeriod = () => {
    return t('Booked from {{booking_start}} - {{booking_end}}', {
      booking_end: data.booking.end,
      booking_start: data.booking.start,
    })
  }

  return (
    <Card flex vertical padding="10px" onClick={onClick}>
      <h3>{formatItemName(data.item)}</h3>
      <CommentView comment={data.comment} />
      {onDelete && (
        <Container style={{ marginLeft: 'auto' }}>
          <DeleteIconButton onClick={(e) => onDelete(e, data)} />
        </Container>
      )}
      <Row>
        <GridFieldView
          n={3}
          s={6}
          label={t('Amount')}
          value={formatOrderedItemAmount(data?.item, data?.amount)}
        />
        {!data.item.is_consumable && (
          <GridFieldView
            n={9}
            s={6}
            label={t('Booking period')}
            value={formatDateRange(data.booking_start, data.booking_end)}
          />
        )}
        {data.item.is_consumable && (
          <GridFieldView
            n={9}
            s={6}
            label={t('Fixed date')}
            value={formatDate(data.fixed_date)}
          />
        )}
      </Row>
      {!order?.draft && (
        <Row space="10px">
          <GridFieldView
            n={3}
            s={6}
            label={t('Dispositioned amount')}
            value={data?.booked_amount}
          />
          <GridFieldView
            n={3}
            s={6}
            label={t('Commissioned amount')}
            value={data.commission_amount}
          />
          <GridFieldView
            n={3}
            s={6}
            label={t('Delivered amount')}
            value={data.delivered_amount}
          />
          <GridFieldView
            n={3}
            s={6}
            label={t('Canceled amount')}
            value={data.canceled_amount}
          />
        </Row>
      )}
      {!data.item.is_consumable && data?.booked_amount > 0 && (
        <CommentView
          icon={<Calendar color="#808080" />}
          comment={formatBookingPeriod()}
        />
      )}
    </Card>
  )
}
