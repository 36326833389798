import { register } from 'system/container'

import { CostCenterList } from './list'
import { CostCenterAddPage } from './add'
import { CostCenterViewPage } from './view'
import { CostCenterEditPage } from './edit'

register({
  menu: true,
  render: () => <CostCenterList />,
  title: 'Cost Centers',
  path: '/master-data/cost-centers',
  priority: 0.0,
})

register({
  render: () => <CostCenterAddPage />,
  title: 'Add',
  path: '/master-data/cost-centers/add',
  priority: 0.0,
})

register({
  render: () => <CostCenterViewPage />,
  title: 'Details',
  path: '/master-data/cost-centers/:id',
  priority: 0.0,
})

register({
  render: () => <CostCenterEditPage />,
  title: 'Edit',
  path: '/master-data/cost-centers/:id/edit',
  priority: 0.0,
})
