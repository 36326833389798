import { useNavigate } from 'react-router-dom'

import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { SystemPage } from 'system/page/core'
import { SingleImageField } from 'common/widgets/image'
import { Container } from 'common/widgets/container'
import { Avatar } from 'common/widgets/avatar'

export const TeamsList = () => {
  return (
    <SystemPage
      style={{
        display: 'grid',
        gap: 0,
      }}
    >
      <TeamsTable />
    </SystemPage>
  )
}

export const TeamsTable = ({ onAdd, disableAdd, handler }) => {
  const navigate = useNavigate()
  return (
    <SimpleDataTable
      name="teams"
      title="Teams"
      url="/teams"
      handler={handler}
      onAddClick={
        !disableAdd
          ? () =>
              onAdd ? onAdd() : navigate(`/master-data/contacts/teams/add`)
          : null
      }
      navBaseUrl="/master-data/contacts/teams"
      rowColor={(r) => r.color}
    >
      <SimpleColumn s={3} n={3} sortable field="name" label="Name" header />
      <SimpleColumn
        s={3}
        n={3}
        sortable
        field="team_size"
        label={'Team size'}
      />
      <SimpleColumn
        s={12}
        n={6}
        label={'Members'}
        value={(r) => <TeamMembers team={r} shouldNavigate={false} />}
      />
    </SimpleDataTable>
  )
}

export const TeamMembers = ({ team, limit = 10, shouldNavigate = true }) => {
  const navigate = useNavigate()
  const contacts = team?.related_contacts
    ?.filter((e) => e.relation_type === 'TEAM_MEMBER')
    .sort((a, b) => a.id > b.id)
  const members = []
  for (let i = 0; i < contacts?.length; i++) {
    const member = contacts[i]

    if (i < limit) {
      members.push(
        <SingleImageField
          key={i}
          readOnly
          allowDelete={false}
          allowDownload={false}
          name={member.related_contact.fullname}
          imageRef={member.related_contact.system_user.profile_image_ref}
          size="30px"
          onClick={(e) =>
            shouldNavigate &&
            navigate(`/master-data/contacts/${member.related_contact.id}`)
          }
        />
      )
    } else {
      const remainingMembers = `+${team?.team_size - i}`
      members.push(
        <Avatar
          name={remainingMembers}
          color="grey"
          size="30px"
          showExactName
        />
      )

      break
    }
  }

  return (
    <Container flex gap="2px">
      {members}
    </Container>
  )
}
