import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { Container } from 'common/widgets/container'
import { ConfirmButton, ClearButton, CancelButton } from 'common/widgets/button'
import { OverlayBody, OverlayFooter, OverlayForm } from 'common/widgets/overlay'
import { useService } from 'common/service/context'
import { DateField, TextField, SignatureField } from 'common/widgets/form/field'
import { dateToISOString } from 'common/utils/format'
import { useSession } from 'common/session/context'

/**
 * Renders the form for the handover overlay.
 *
 * @param {any} delivery delivery note object\
 * @param {array} faults reported faults for the delivery items
 * @param {any} onClose state of closing the dialog
 *
 * @returns ReactElement
 */
export const HandoverOverlay = ({ delivery, faults, onClose }) => {
  const { t } = useTranslation()
  const { currentUser } = useSession()
  const service = useService()
  const navigate = useNavigate()
  const handler = {}

  const onOverlayClose = () => {
    clearSignature()
    onClose()
  }

  const clearSignature = async () => {
    handler.clear()
  }

  const handleHandover = async (values) => {
    const [result, error] = await service.put(
      `kommission/delivery/${delivery.id}/confirm`,
      {
        ...values.json,
        signature_mime_type: 'image/png',
        failed_items: faults ? faults : [],
      }
    )

    if (!error) {
      onClose()
      navigate(-1)
    }

    return [result, error]
  }

  return (
    <OverlayForm
      title={t('Sign')}
      open={delivery}
      onClose={onOverlayClose}
      data={{
        checking_date: new Date(),
        recipient: currentUser?.fullname,
        signature: null,
      }}
    >
      <OverlayBody>
        <HandoverFormContent
          delivery={delivery}
          onClose={onClose}
          handler={handler}
        />
      </OverlayBody>
      <OverlayFooter repel gap="10px">
        <ConfirmButton
          disabled={(ctx) => !ctx.ready || !ctx.values.json.signature}
          onClick={(e, ctx) => handleHandover(ctx.values)}
        />
        <ClearButton danger onClick={clearSignature} />
        <CancelButton onClick={onClose} />
      </OverlayFooter>
    </OverlayForm>
  )
}

/**
 * Renders the form content of the handover overlay.
 *
 * @param {any} delivery delivery note object
 *
 * @returns ReactElement
 */
const HandoverFormContent = ({ delivery, handler }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <h5>{t('Recipient')}*</h5>
      <Container>
        <TextField autoFocus name="recipient" mandatory />
      </Container>
      <Container>
        <h5>{t('Date')}*</h5>
        <DateField
          name="checking_date"
          normalize={dateToISOString}
          mandatory
          clearable
        />
      </Container>
      <Container flex>
        <Container>
          {delivery && <SignatureField name="signature" handler={handler} />}
        </Container>
      </Container>
    </Container>
  )
}
