.tabbedPane {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  padding-top: 10px;
}
.tabNav {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
}

.tabLink {
  display: flex;
  min-width: 100px;
  flex-direction: column;
  transition: 0.2s ease-out;
  text-decoration: none;
  cursor: pointer;
}

.tabLinkActive {
  composes: tabLink;
}

.tabLinkPassive {
  composes: tabLink;
}

.tabLink h4 {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;

  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-around;
}

.tabStatus {
  transition: 0.2s ease-out;
  height: 5px;
  border-radius: 5px 5px 0px 0px;
  flex: 0 1 auto;
}

.tabLinkPassive:hover .tabStatus {
  background-color: #e0e0e0;
}

.tabLinkActive .tabStatus {
  background-color: #376df4;
}

.dropDownNav {
  margin-bottom: 5px;
}
