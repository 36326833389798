import React, { useState } from 'react'

import { SearchInput } from 'common/widgets/search'

import { MasterSearchOverlay } from './overlay'

export const MasterSearchField = () => {
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false)

  return (
    <>
      <SearchInput
        defaultValue={search}
        onChange={(e) => {
          setSearch(e)
          setOpen(true)
        }}
      />
      <MasterSearchOverlay
        name={name}
        open={open}
        search={search}
        onClose={() => setOpen(false)}
      />
    </>
  )
}
