import { BaseMap } from 'common/widgets/map/base'
import { useQuery } from 'common/query/context'

/**
 * Renders all project location inside the map.
 *
 * @returns ReactElement
 */
export const ProjectMapOverview = ({ visible = true, ...rest }) => {
  const { records } = useQuery()

  const getProjectMarkers = (projects) => {
    let results = []
    if (!projects) {
      return results
    }

    for (const item of projects) {
      if (item.address && item.address.lat && item.address.lon) {
        results.push({
          id: item.id,
          lat: item.address.lat,
          lon: item.address.lon,
          tooltip: item.name,
          url: `/projects/${item.id}`,
        })
      }
    }

    return results
  }

  return <BaseMap markers={getProjectMarkers(records)} {...rest} />
}
