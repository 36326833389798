import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { SystemPage } from 'system/page/core'
import { useService } from 'common/service/context'
import { formatProjectName } from 'modules/projects/utils'
import { formatOrderNumber } from 'modules/orders/utils'
import { formatDate, formatDateRange } from 'common/utils/format'
import { getEquipmentNavPath } from 'modules/master-data/equipments/utils'
import { formatUserName } from 'modules/security/users/utils'

import { BookingRequestTypeEnum } from '../utils'

export const PendingRequestsPage = () => {
  const { t } = useTranslation(['disposition'])
  const navigate = useNavigate()
  const service = useService()

  /**
   * Calls orders get api and loads the data from server side.
   *
   * @param {Array} params query params
   * @returns
   */
  const fetch = async (params) =>
    await service.get('dispositions/requests?audited=false', [
      ...params,
      { type: BookingRequestTypeEnum.NEW, audited: false },
    ])

  return (
    <SystemPage>
      <SimpleDataTable
        name="pendingbookings"
        fetch={fetch}
        title={t('Pending booking requests')}
        onRowClick={(r) =>
          r.ordered_item.item.is_resource
            ? navigate(`/disposition/requests/new/resources/${r.id}/confirm`)
            : navigate(`/disposition/requests/new/formworks/${r.id}/confirm`)
        }
      >
        <SimpleColumn
          n={12}
          sortable
          label={t('Name')}
          field="name"
          value={(r) => r.ordered_item.item.name}
          when={(r) => !r.ordered_item.item.is_resource}
          link={(r) => getEquipmentNavPath(r.ordered_item.item)}
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          filterable
          label={t('Category')}
          value={(r) => t(r.ordered_item.item.type)}
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          field="project_id"
          label={t('Project')}
          value={(r) => formatProjectName(r.project)}
          link={(r) => (r.project_id ? `/projects/${r.project_id}` : null)}
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          field="number"
          label={t('Order number')}
          value={(r) => formatOrderNumber(r.ordered_item.order?.number)}
          link={(r) => `/orders/${r.ordered_item.order.id}`}
        />
        <SimpleColumn
          n={3}
          sortable
          label={t('Booking period')}
          value={(r) => formatDateRange(r.start, r.end)}
        />
        <SimpleColumn
          n={3}
          s={6}
          field="maincategory"
          label={t('Main group')}
          value={(r) => r.ordered_item.item.maincategory}
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          field="category"
          label={t('Group')}
          value={(r) => r.ordered_item.item.category}
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          field="subcategory"
          label={t('Subgroup')}
          value={(r) => r.ordered_item.item.subcategory}
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          field="created_at"
          label={t('Ordered at')}
          value={(r) => formatDate(r.created_at)}
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          field="requires_delivery"
          label={t('Delivery option')}
          value={(r) =>
            r.ordered_item.order.requires_delivery
              ? t('Requires delivery')
              : t('Self-pickup')
          }
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          field="fixed_date"
          label={t('Fixed date')}
          value={(r) => formatDate(r.ordered_item.fixed_date)}
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          field="booking_start"
          label={t('Earliest')}
          value={(r) => formatDate(r.ordered_item.booking_start)}
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          field="booking_end"
          label={t('Latest')}
          value={(r) =>
            formatDate(r.ordered_item.booking_end, { friendly: true })
          }
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          field="planned_delivery_date"
          label={t('Planned delivery date')}
          value={(r) => formatDate(r.ordered_item.order.planned_delivery_date)}
        />
        <SimpleColumn
          n={3}
          s={6}
          filterable
          field="requester_user"
          label={t('Orderer')}
          value={(r) => formatUserName(r.requester_user)}
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          field="ordered_at"
          label={t('Ordered at')}
          value={(r) => formatDate(r?.ordered_item?.order?.ordered_at)}
        />
      </SimpleDataTable>
    </SystemPage>
  )
}
