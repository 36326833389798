import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Row } from 'common/widgets/grid'
import { Container } from 'common/widgets/container'
import { GridFieldView, RowView } from 'common/widgets/view'
import { EquipmentGridLinkFieldView } from 'modules/master-data/equipments/widgets/link'
import {
  getQuantityUnit,
  ItemTypeEnum,
} from 'modules/master-data/equipments/utils'
import { formatDateRange } from 'common/utils/format'
import { DeleteIconButton, EditIconButton } from 'common/widgets/button'

import { AdHocCRUDOverlay } from '../overlays/adhoc'
/**
 * Renders the addhooc delivery items.
 *
 * @param {Array} items addhook items array
 *
 * @returns ReactElement
 */
export const AdHocSelectedItemsList = ({ items, onEdit, onDelete }) => {
  const { t } = useTranslation()

  const [itemForRemove, setItemForRemove] = useState(null)
  const [itemForEdit, setItemForEdit] = useState(null)

  const groups = [
    {
      entries: items?.filter((item) => item.is_resource),
      type: ItemTypeEnum.RESOURCE,
    },
    {
      entries: items?.filter((item) => item.is_formwork),
      type: ItemTypeEnum.FORMWORK,
    },
    {
      entries: items?.filter((item) => item.is_consumable),
      type: ItemTypeEnum.CONSUMABLE,
    },
  ]

  return (
    <Container>
      {groups.map(({ entries, type }, idx) => (
        <Container key={idx}>
          {entries?.length > 0 && <h3>{t(type)}</h3>}
          {entries.map((item, i) => (
            <RowView key={i}>
              <Row>
                <EquipmentGridLinkFieldView n={5} baseitem={item} />
                <GridFieldView
                  n={3}
                  label={t('Ordered amount')}
                  value={`${item.amount} ${getQuantityUnit(item)}`}
                />
                {type === ItemTypeEnum.RESOURCE ||
                type === ItemTypeEnum.FORMWORK ? (
                  <GridFieldView
                    n={2}
                    label={t('Booking period')}
                    value={formatDateRange(new Date(), item?.booking_end)}
                  />
                ) : (
                  <GridFieldView
                    n={2}
                    label={t('Packaging unit')}
                    value={item.packaging_unit}
                  />
                )}
                <Container flex marginLeft="auto" gap="10px">
                  <EditIconButton onClick={() => setItemForEdit(item)} />
                  <DeleteIconButton
                    danger
                    onClick={() => setItemForRemove(item)}
                  />
                </Container>
              </Row>
            </RowView>
          ))}
        </Container>
      ))}
      {itemForEdit && (
        <AdHocCRUDOverlay
          title={t('Edit commissioning item')}
          item={itemForEdit}
          onUpdate={(item) => {
            onEdit(item)
            setItemForEdit(null)
          }}
          onClose={() => setItemForEdit(null)}
        />
      )}
      {itemForRemove && (
        <AdHocCRUDOverlay
          title={t('Remove delivery item')}
          item={itemForRemove}
          onDelete={(item) => {
            onDelete(item)
            setItemForRemove(null)
          }}
          onClose={() => setItemForRemove(null)}
        />
      )}
    </Container>
  )
}
