import { useTranslation } from 'react-i18next'

import { useService } from 'common/service/context'
import { MailIconButton } from 'common/widgets/button'
import { useToast } from 'system/toast/context'

export const UserEmailChangeConfirmation = ({ user }) => {
  const { t } = useTranslation()
  const service = useService()
  const { toasts } = useToast()

  /**
   * Resend user email change confirmation if there is any, otherwise an error will be returned.
   *
   * @param {any} user user data
   * @returns [any, any]
   */
  const resend_confirmation = async (user) => {
    const [, error] = await service.post(
      `/users/${user.id}/email-change/resend-confirmation`
    )
    if (!error) {
      toasts.success(t('Email change confirmation sent.'))
    }
  }

  return (
    <>
      {user?.pending_email && (
        <MailIconButton
          tooltip={
            t('Resend email change confirmation for') +
            ` ${user?.pending_email}`
          }
          onClick={async () => await resend_confirmation(user)}
        />
      )}
    </>
  )
}
