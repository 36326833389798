import { isNilOrEmpty } from 'common/utils/conditional'

export const ItemTypeEnum = {
  RESOURCE: 'RESOURCE',
  LARGESCALE: 'LARGESCALE',
  SMALLSCALE: 'SMALLSCALE',
  FORMWORK: 'FORMWORK',
  CONSUMABLE: 'CONSUMABLE',
}

export const ItemProcessEnum = {
  COMMISSIONING: 'COMMISSIONING',
  DISPOSITION: 'DISPOSITION',
}

// Defines available accounting types for resource (large/small scale equipment)
export const ResourceAccountingTypeEnum = {
  per_day: 'per_day',
  per_month: 'per_month',
  per_hour: 'per_hour',
}

// Defines available accounting types for formwork
export const FormworkAccountingTypeEnum = {
  RENTAL: 'rental',
  BUY: 'buy',
  WITHOUT: 'without',
}

// Defines available condition values
export const ItemConditionEnum = {
  NEW: 'new',
  USED: 'used',
}

// Defines available source of supply values
export const ItemSourceOfSupplyEnum = {
  OWN: 'own',
  FOREIGN: 'shared',
}

/**
 * Formats item name using type.
 *
 * @param {object} item base item data
 * @returns string
 */
export const formatItemName = (item) =>
  !isNilOrEmpty(item.inventory_number)
    ? `${item.inventory_number} - ${item.name}`
    : item.name

/**
 * Get navigation path for resources
 *
 * @param {any} item baseitem object
 * @returns string
 */
export const getEquipmentNavPath = (baseitem) => {
  if (baseitem?.is_largescale) {
    return `/master-data/equipments/large-scales/${baseitem.id}`
  } else if (baseitem?.is_smallscale) {
    return `/master-data/equipments/small-scales/${baseitem.id}`
  } else if (baseitem?.is_formwork) {
    return `/master-data/equipments/formworks/${baseitem.id}`
  } else if (baseitem?.is_consumable) {
    return `/master-data/equipments/consumables/${baseitem.id}`
  } else {
    return null
  }
}

/**
 * Returns quantity unit for the given item.
 *
 * @param {any} baseitem baseitem information
 * @returns String
 */
export const getQuantityUnit = (baseitem) =>
  hasQuantityUnit(baseitem) ? baseitem?.quantity_unit : ''

/**
 * Returns true if the given item has proper quantity unit.
 *
 * @param {any} baseitem baseitem information
 * @returns boolean
 */
export const hasQuantityUnit = (baseitem) =>
  !isNilOrEmpty(baseitem?.quantity_unit)

/**
 * Returns true if the given item has proper packaging unit.
 *
 * @param {any} baseitem baseitem information
 * @returns boolean
 */
export const hasPackagingUnit = (baseitem) =>
  !isNilOrEmpty(baseitem?.packaging_unit)

/**
 * Returns quantity unit for the given item.
 *
 * @param {any} baseitem baseitem information
 * @returns String
 */
export const getPackagingUnit = (baseitem) =>
  hasPackagingUnit(baseitem) ? baseitem?.packaging_unit : '-'

/**
 * Returns human readable name of item type
 * @param {any} baseitem article info
 * @returns string
 */
export const formatItemType = (baseitem) => {
  if (baseitem?.is_smallscale) {
    return 'Small scale equipments'
  }
  if (baseitem?.is_largescale) {
    return 'Large scale equipments'
  }
  if (baseitem?.is_formwork) {
    return 'Formworks'
  }
  if (baseitem?.is_consumable) {
    return 'Consumables'
  }
  return baseitem?.type
}
