import React from 'react'
import { useParams } from 'react-router-dom'

import { useService } from 'common/service/context'
import { Form } from 'common/widgets/form/context'
import { EditViewPage } from 'system/utils/edit'

import { ProjectFormContent } from './form'

export const ProjectEditPage = () => {
  const service = useService()
  const { id } = useParams()

  const handleEdit = async (values) => {
    return await service.put(`projects/${id}`, values)
  }

  return (
    <EditViewPage url="/projects" title={(e) => e.name}>
      {(data) => {
        return (
          <Form data={data}>
            <ProjectFormContent onUpdate={handleEdit} />
          </Form>
        )
      }}
    </EditViewPage>
  )
}
