import { Calendar } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { OverlayBody, OverlayFooter, OverlayForm } from 'common/widgets/overlay'
import { formatItemName } from 'modules/master-data/equipments/utils'
import { CommentView, GridFieldView } from 'common/widgets/view'
import { Column, Row } from 'common/widgets/grid'
import { ConfirmButton, BackButton } from 'common/widgets/button'
import { formatProjectName } from 'modules/projects/utils'
import { DateField } from 'common/widgets/form/field'
import { useForm } from 'common/widgets/form/context'
import { toDate, differenceInDays } from 'common/utils/date'
import { useService } from 'common/service/context'
import { useToast } from 'system/toast/context'
import { formatDate } from 'common/utils/format'

import { BookingRequestTypeEnum } from '../utils'

export const BookingConfirmOverlay = ({ request, open, onClose }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const service = useService()
  const { toasts } = useToast()

  /**
   * Confirms booking request.
   * @param {any} data confirm data contains start and end date
   * @returns
   */
  const handleConfirm = async (data) => {
    const [result, error] = await service.put(
      `dispositions/requests/${request.id}/confirm`,
      {
        start: request.start,
        end: request.end,
      }
    )
    if (!error) {
      toasts.success('Booking is confirmed!')
      navigate('/disposition/requests/shrink')
    }
    return [result, error]
  }

  return (
    <OverlayForm
      open={open}
      onClose={onClose}
      data={{ start: request.start, end: request.end }}
      title="Booking"
    >
      <OverlayBody>
        <Row>
          <Column n={12}>
            <h3>{formatItemName(request.booking.baseitem)}</h3>
          </Column>
          <GridFieldView n={6} s={6} label={t('Project')}>
            <h4>{formatProjectName(request.project)}</h4>
          </GridFieldView>
          <GridFieldView n={6} s={6} label={t('Amount')}>
            <h4>1</h4>
          </GridFieldView>
          <Column n={6} s={6}>
            <h5>{t('Staoooort')}*</h5>
            <DateField
              name="start"
              filterDate={(date, ctx) =>
                date >= toDate(request.start) &&
                date < toDate(ctx.values.get('end'))
              }
              disabled={request.type !== BookingRequestTypeEnum.SHRINK}
              help={
                request.type === BookingRequestTypeEnum.EXTEND
                  ? formatDate(request.booking.start)
                  : ''
              }
            />
          </Column>
          <Column n={6} s={6}>
            <h5>{t('End')}*</h5>
            <DateField
              name="end"
              mandatory
              inclusive
              filterDate={(date, ctx) =>
                request.type === BookingRequestTypeEnum.SHRINK
                  ? date > toDate(ctx.values.get('start')) &&
                    date <= toDate(request.end)
                  : date >= toDate(request.end)
              }
              help={formatDate(request.booking.end, { friendly: true })}
            />
          </Column>
        </Row>
        <Duration request={request} />
      </OverlayBody>
      <OverlayFooter justify>
        <ConfirmButton
          disabled={(ctx) =>
            !ctx.ready || differenceInDays(ctx.end, ctx.start) <= 0
          }
          onClick={(e, ctx) => handleConfirm(ctx.values.json)}
        />
        <BackButton onClick={onClose} />
      </OverlayFooter>
    </OverlayForm>
  )
}

const Duration = ({ request }) => {
  const { t } = useTranslation()
  const { values } = useForm()
  const { start, end } = values.json
  const days = differenceInDays(end, start)
  return (
    <CommentView
      icon={<Calendar />}
      comment={t(
        request.type === BookingRequestTypeEnum.EXTEND
          ? 'Booking extension is for [{{days}}] day/days.'
          : 'Booking shrink is for [{{days}}] day/days.',
        { days }
      )}
    />
  )
}
