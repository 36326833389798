import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Container } from 'common/widgets/container'
import { SectionView } from 'common/widgets/view'
import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'
import { ItemTypeEnum } from 'modules/master-data/equipments/utils'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { formatDate } from 'common/utils/format'
import { BackIconButton, BookingButton } from 'common/widgets/button'
import { SystemPage } from 'system/page/core'
import { createEvent, FullCalendarWrapper } from 'common/widgets/calendar'

/**
 * Renders tab content for Bookings
 *
 * @returns ReactElement
 */
export const BookingTabContent = ({ project }) => {
  const service = useService()

  /**
   * Calls api for fetching the disposition bookings assigned to the project
   *
   * @returns [any, any, int]
   */
  const fetch = async () =>
    await service.get(`dispositions/bookings?project_id=${project.id}`)

  const getItemCountByType = (data, type) =>
    data?.filter((inventory) => inventory.baseitem.type === type).length

  return (
    <DataSource
      fetch={fetch}
      render={({ data }) => {
        return (
          <>
            {getItemCountByType(data, ItemTypeEnum.RESOURCE) > 0 && (
              <BookingDetails
                project={project}
                data={data}
                type={ItemTypeEnum.RESOURCE}
              />
            )}
            {getItemCountByType(data, ItemTypeEnum.FORMWORK) > 0 && (
              <BookingDetails
                project={project}
                data={data}
                type={ItemTypeEnum.FORMWORK}
              />
            )}
          </>
        )
      }}
    />
  )
}

export const BookingDetails = ({ project, data, type }) => {
  const { t } = useTranslation(['projects'])
  const navigate = useNavigate()
  const service = useService()
  /**
   * Add defaults params like type to given params and returns new list
   *
   * @param {Array} params query parameters
   * @returns Array
   */
  const normalize = (params) => {
    return [...params, { type: type, project_id: project.id }]
  }

  /**
   * Gets information of project inventories.
   *
   * @param {*} params query params
   * @returns any
   */
  const fetch = async (params) =>
    await service.get(`dispositions/bookings`, normalize(params))

  return (
    <SectionView>
      <SimpleDataTable
        name="projectbookings"
        nopadding
        nomargin
        white
        border
        fetch={fetch}
        title={t(type)}
      >
        <SimpleColumn n={12} s={12} sortable field="name">
          {(r) => <h3>{r.baseitem.name}</h3>}
        </SimpleColumn>
        <SimpleColumn
          n={4}
          s={6}
          sortable
          field="maincategory"
          label={t('Main group')}
          filter={(e) => e}
          title={(e) => e}
          fetch={async (params) =>
            await service.get('items/resources/maincategory', params)
          }
          value={(r) => r.baseitem.maincategory}
        />
        <SimpleColumn
          n={4}
          s={6}
          sortable
          field="category"
          label={t('Group')}
          filter={(e) => e}
          title={(e) => e}
          fetch={async (params) =>
            await service.get('items/resources/category', params)
          }
          value={(r) => r.baseitem.category}
        />
        <SimpleColumn
          n={4}
          s={6}
          sortable
          field="subcategory"
          label={t('Subgroup')}
          filter={(e) => e}
          title={(e) => e}
          fetch={async (params) =>
            await service.get('items/resources/subcategory', params)
          }
          value={(r) => r.baseitem.maincategory}
        />
        <SimpleColumn
          n={4}
          s={6}
          sortable
          label={t('Inventory number')}
          field="inventory_number"
          value={(r) => r.baseitem.inventory_number}
        />
        <SimpleColumn
          n={4}
          s={6}
          label={t('Booking start')}
          field="start"
          value={(r) => formatDate(r.start)}
        />
        <SimpleColumn
          n={4}
          s={6}
          label={t('Booking end')}
          field="end"
          value={(r) => formatDate(r.end, { friendly: true })}
        />
        <SimpleColumn fixed>
          {(r) => (
            <BookingButton
              default
              text=""
              onClick={(e) => {
                navigate(
                  `/projects/${project.id}/booking/${r.baseitem.id}/${r.start}`
                )
              }}
            />
          )}
        </SimpleColumn>
      </SimpleDataTable>
    </SectionView>
  )
}

export const BookingCalenderPage = () => {
  const service = useService()

  const { projectId, itemId, startDate } = useParams()

  const [state, setState] = useState({
    data: null,
    project: null,
    start: null,
    end: null,
    error: null,
    loading: true,
  })

  /**
   * Recalls fetch function and loads disposition booking data.
   */
  const reload = async () => {
    setState({ ...state, error: null, loading: true })
    const [response, error] = await service.get(
      `/dispositions/bookings?project_id=${projectId}`
    )
    const data = error ? null : response.data
    setState({
      ...state,
      error,
      data,
      project: data[0].project.name,
      loading: false,
    })
  }
  useEffect(() => {
    const safeLoad = async () => await reload()
    safeLoad()
  }, [])

  const getBookingEvent = (ev) => {
    let color =
      ev.baseitem.id === itemId && ev.start === startDate
        ? '#F49537'
        : ev.baseitem?.is_resource
        ? '#95E68B'
        : null
    return createEvent({
      title: ev.baseitem.name,
      start: ev.start,
      end: ev.end,
      color: color,
      className: 'generic-event',
    })
  }

  const getProjectName = (data) => {
    if (data.baseitem.id === itemId && data.start === startDate) {
      return data.project.short_name || data.project.name
    }
  }

  return (
    <SystemPage style={{ gap: '10px' }}>
      <Container flex align="center" gap="10px">
        <BackIconButton />
        <h1>{state.data?.map(getProjectName)}</h1>
      </Container>
      <SectionView>
        <FullCalendarWrapper
          initialDate={new Date(startDate)}
          events={state.data?.map(getBookingEvent)}
        />
      </SectionView>
    </SystemPage>
  )
}
