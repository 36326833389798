import { Layers, Package, Tool, Trello } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { CommentView } from 'common/widgets/view'

import { formatItemType } from '../utils'

export const ItemTypeCommentView = ({ equipment }) => {
  const { t } = useTranslation(['equipments'])
  const icon = equipment?.is_resource ? (
    equipment.is_largescale ? (
      <Trello />
    ) : (
      <Tool />
    )
  ) : equipment?.is_formwork ? (
    <Layers />
  ) : (
    <Package />
  )
  return <CommentView icon={icon} comment={t(formatItemType(equipment))} />
}
