import { useTranslation } from 'react-i18next'

import { TextField, TextAreaField } from 'common/widgets/form/field'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { Row, Column } from 'common/widgets/grid'
import { SectionView } from 'common/widgets/view'

/**
 * Renders teams present's data entry form.
 *
 * @param {Function} onClose closing hook
 * @returns
 */
export const TeamsFormContent = ({ onAdd, onUpdate, onRemove }) => {
  const { t } = useTranslation()

  return (
    <>
      <Row>
        <Column flex n={6} s={12}>
          <SectionView>
            <h2>{t('Team')}</h2>
            <TextField name="fullname" mandatory label="Team name" />
            <TextAreaField name="comment" rows="4" label="Description" />
          </SectionView>
        </Column>
      </Row>
      <SimpleFormAction onAdd={onAdd} onUpdate={onUpdate} onRemove={onRemove} />
    </>
  )
}
