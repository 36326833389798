import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Row } from 'common/widgets/grid'
import { GridFieldView, CommentView } from 'common/widgets/view'
import { Card } from 'common/widgets/card'
import { Container } from 'common/widgets/container'
import { EquipmentGridLinkFieldView } from 'modules/master-data/equipments/widgets/link'
import { getQuantityUnit } from 'modules/master-data/equipments/utils'
import { formatDateRange } from 'common/utils/format'

import { DeliveryItemTripleDotMenu } from './action-menus'

/**
 * Display and edit delivery items
 * */
export const DeliveryItems = ({
  title = 'Delivery items',
  delivery,
  commissionedOnly,
  readOnly,
  pickup,
  sign,
  onChange,
  onItemConfirm,
  onItemFault,
}) => {
  const { t } = useTranslation(['yard'])

  const [normalizedData, setNormalizedData] = useState({
    largescale: [],
    smallscale: [],
    formworks: [],
    consumables: [],
  })

  const normalizeData = (delivery) => {
    let orderItemsLookup = new Map()
    let largescale = []
    let smallscale = []
    let formworks = []
    let consumables = []

    // Group ordered items
    delivery.order.ordered_items.forEach((oitem) => {
      // Add a placeholder for adding delivery items
      oitem['delivery_items'] = []

      if (oitem.item.is_largescale) {
        largescale.push(oitem)
      } else if (oitem.item.is_smallscale) {
        smallscale.push(oitem)
      } else if (oitem.item.is_formwork) {
        formworks.push(oitem)
      } else if (oitem.item.is_consumable) {
        consumables.push(oitem)
      } else {
        console.assert(`Item is of unknown type ${oitem.item.type}`)
      }
      orderItemsLookup.set(oitem.id, oitem)
    })

    // Group delivery items
    delivery.items?.forEach((ditem) => {
      // Add the delivery item to its oredered item
      if (ditem.ordered_item_id) {
        let oitem = orderItemsLookup.get(ditem.ordered_item_id)
        oitem.delivery_items.push(ditem)
      } else {
        // Item is adhoc, since it does not have ordered item.
        // Construct an adhoc order item for it and attach it to that
        let oitem = {
          id: ditem.id * -1,
          adhoc: true,
          item: ditem.baseitem,
          amount: ditem.amount,
          open_amount: ditem.amount,
          commission_amount: ditem.amount,
          booking_start: ditem.tmp_start,
          booking_end: ditem.tmp_end,
          delivery_items: [ditem],
          //...ditem,
        }
        orderItemsLookup.set(oitem.id, oitem)
        if (ditem.baseitem?.is_largescale) {
          //largescale.push(ditem)
          largescale.push(oitem)
        } else if (ditem.baseitem?.is_smallscale) {
          //smallscale.push(ditem)
          smallscale.push(oitem)
        } else if (ditem.baseitem?.is_formwork) {
          //formworks.push(ditem)
          formworks.push(oitem)
        } else if (ditem.baseitem?.is_consumable) {
          //consumables.push(ditem)
          consumables.push(oitem)
        } else {
          console.assert(`Item is of unkown type ${ditem.baseitem.type}`)
        }
      }
    })

    if (commissionedOnly) {
      // Keep only ordered items which have commissioned items
      largescale = largescale.filter((oitem) => oitem.delivery_items.length)
      smallscale = smallscale.filter((oitem) => oitem.delivery_items.length)
      formworks = formworks.filter((oitem) => oitem.delivery_items.length)
      consumables = consumables.filter((oitem) => oitem.delivery_items.length)
    }

    setNormalizedData({ largescale, smallscale, formworks, consumables })
  }

  useEffect(() => {
    normalizeData(delivery)
  }, [delivery])

  // Just leave a message if there is nothing else to display
  if (
    !(
      !normalizedData ||
      normalizedData.largescale.length ||
      normalizedData.smallscale.length ||
      normalizedData.formworks.length ||
      normalizedData.consumables.length
    )
  ) {
    // Formulate a friendy message that user should switch source to see bookings
    const onSiteBookings = delivery?.bookings?.find(
      (b) => !!b.source_project_id
    )
    if (onSiteBookings) {
      return t(
        'Switch source project to {{projectName}} to see more bookings.',
        {
          projectName:
            onSiteBookings.source_project.short_name ||
            onSiteBookings.source_project.name,
        }
      )
    } else {
      const location = delivery?.source_project_id
        ? delivery?.source_project?.name || delivery?.source_project_id
        : 'yard'
      return t('Nothing to commissioned for this order from {{location}}.', {
        location,
      })
    }
  }

  //TODO: actions should be refactored out in order ot maintain the number
  // of passed in parameters

  return (
    <Card
      title={title}
      flex
      vertical
      background="#d3d3d375"
      padding="10px"
      gap="10px"
    >
      <DeliveryItemsGroup
        title={t('LARGESCALE')}
        delivery={delivery}
        items={normalizedData.largescale}
        onChange={onChange}
        readOnly={readOnly}
        pickup={pickup}
        sign={sign}
        onItemConfirm={onItemConfirm}
        onItemFault={onItemFault}
      />
      <DeliveryItemsGroup
        title={t('SMALLSCALE')}
        delivery={delivery}
        items={normalizedData.smallscale}
        onChange={onChange}
        readOnly={readOnly}
        pickup={pickup}
        sign={sign}
        onItemConfirm={onItemConfirm}
        onItemFault={onItemFault}
        group
      />
      <DeliveryItemsGroup
        title={t('FORMWORK')}
        delivery={delivery}
        items={normalizedData.formworks}
        onChange={onChange}
        readOnly={readOnly}
        pickup={pickup}
        sign={sign}
        onItemConfirm={onItemConfirm}
        onItemFault={onItemFault}
      />
      <DeliveryItemsGroup
        title={t('CONSUMABLE')}
        delivery={delivery}
        items={normalizedData.consumables}
        onChange={onChange}
        readOnly={readOnly}
        pickup={pickup}
        sign={sign}
        onItemConfirm={onItemConfirm}
        onItemFault={onItemFault}
      />
    </Card>
  )
}

/**
 * Renders a group of items of specific type in one row.
 *
 * @param {Array} items list of ordered items each with delivery_items
 * @param {object} group group resources and add a summary
 * */
const DeliveryItemsGroup = ({
  title,
  delivery,
  items,
  group,
  readOnly,
  pickup,
  sign,
  onChange,
  onItemConfirm,
  onItemFault,
}) => {
  // Return nothing if there is nothing to do
  if (!items.length) {
    return null
  }

  const getActions = ({ oitem, ditem, group }) => {
    return (
      <DeliveryItemActions
        readOnly={readOnly}
        delivery={delivery}
        orderedItem={oitem}
        deliveryItem={ditem}
        groupHeader={group}
        onChange={onChange}
        pickup={pickup}
        sign={sign}
        onItemConfirm={onItemConfirm}
        onItemFault={onItemFault}
      />
    )
  }

  // Render a set of header and child rows depending on the group flag
  return (
    <Container flex vertical gap="5px">
      <h3>{title}</h3>
      {items.map((item, idx) => (
        <Card key={idx} padding="10px">
          <DeliveryItemRow
            orderedItem={item}
            deliveryItem={
              !group && item.delivery_items.length
                ? item.delivery_items[0]
                : null
            }
            onChange={onChange}
            actions={getActions({
              oitem: item,
              ditem:
                !group && item.delivery_items.length
                  ? item.delivery_items[0]
                  : null,
              group: group,
            })}
            group={group}
          />
          {group &&
            item.delivery_items.map((subitem, idx) => (
              <DeliveryItemRow
                key={idx}
                orderedItem={item}
                deliveryItem={subitem}
                onChange={onChange}
                actions={getActions({ oitem: item, ditem: subitem })}
                isChildRow
              />
            ))}
        </Card>
      ))}
    </Container>
  )
}

const DeliveryItemRow = ({
  orderedItem,
  deliveryItem,
  group,
  onChange,
  isChildRow,
  actions,
}) => {
  const { t } = useTranslation()

  const inventoryNumber = isChildRow
    ? deliveryItem.baseitem.inventory_number
    : orderedItem.item.inventory_number

  const totalCommissioningAmount = orderedItem.delivery_items.reduce(
    (prev, curr) => prev + curr.amount,
    0
  )

  return (
    <Row style={{ minHeight: '50px' }}>
      {group ? (
        <GridFieldView
          n={3}
          label={t('Group')}
          value={joinCategories(orderedItem?.item)}
        />
      ) : (
        <EquipmentGridLinkFieldView n={3} baseitem={orderedItem.item} />
      )}
      <GridFieldView
        n={2}
        label={t('Inventory number')}
        value={inventoryNumber}
      />
      <GridFieldView
        n={2}
        label={t('Ordered amount')}
        value={`${
          isChildRow ? deliveryItem.amount : orderedItem.original_amount
        } ${getQuantityUnit(orderedItem.item)}`}
      />
      <GridFieldView
        n={2}
        label={
          isChildRow ? t('Commissioned') : `${t('Commissioned')} / ${t('Open')}`
        }
        value={
          orderedItem.adhoc
            ? '-'
            : isChildRow
            ? deliveryItem.amount
            : `${totalCommissioningAmount} / ${
                orderedItem?.open_amount
              } ${getQuantityUnit(orderedItem)}`
        }
      />
      {/* add bookable flag to the API */}
      {!orderedItem.item.is_consumable ? (
        <GridFieldView
          n={2}
          label={t('Booking period')}
          value={
            deliveryItem
              ? formatDateRange(deliveryItem.tmp_start, deliveryItem.tmp_end)
              : formatDateRange(
                  orderedItem?.booking_start,
                  orderedItem?.booking_end
                )
          }
        />
      ) : (
        <GridFieldView
          n={2}
          label={t('Packaging unit')}
          value={orderedItem.item.packaging_unit}
        />
      )}
      {orderedItem.adhoc && <CommentView n={0} info comment={t('adhoc')} />}
      {!orderedItem.adhoc &&
        orderedItem.requires_disposition &&
        orderedItem.disposition_amount <= 0 && (
          <CommentView n={0} warning comment={t('Not dispositioned yet.')} />
        )}
      {actions && (
        <Container style={{ marginLeft: 'auto' }}>{actions}</Container>
      )}
    </Row>
  )
}

/**
 * Combine categories into one string.
 *
 * */
const joinCategories = (baseitem) =>
  [baseitem?.maincategory, baseitem?.category, baseitem?.subcategory]
    .filter((i) => i)
    .join(' - ')

const DeliveryItemActions = ({
  readOnly,
  delivery,
  orderedItem,
  deliveryItem,
  groupHeader,
  onChange,
  pickup,
  sign,
  onItemFault,
  onItemConfirm,
}) => {
  // Set default menu options
  let trippleDotMenuOptions = {
    delivery,
    orderedItem,
    deliveryItem,
    showAddBtn:
      !readOnly &&
      !(pickup || sign) &&
      (deliveryItem?.amount || 0) < orderedItem.open_amount,
    showDelBtn:
      !readOnly &&
      !sign &&
      (deliveryItem?.amount || 0) >= orderedItem.open_amount,
    //showRstBtn:groupHeader && orderedItem.delivery_items.length < orderedItem.amount
    showFaultBtn: sign || (readOnly && deliveryItem?.fault),
    showConfirmBtn: !readOnly && sign,
    onChange,
    onItemFault,
    onItemConfirm,
  }

  if (orderedItem.item.is_largescale) {
    // Set largescale options
    if (groupHeader) {
      // For largescale group header there is no actions
      // Add is only allowed for dispositioned child elements
      trippleDotMenuOptions['showAddBtn'] = false
    } else {
      trippleDotMenuOptions['showAddBtn'] =
        !readOnly &&
        !(pickup || sign) &&
        orderedItem.disposition_item_id &&
        !deliveryItem
      //trippleDotMenuOptions['showDelBtn'] =
      //  orderedItem.disposition_item_id && deliveryItem?.amount > 0
    }
  } else if (orderedItem.item.is_smallscale) {
    if (groupHeader) {
      trippleDotMenuOptions['showFaultBtn'] = false
      trippleDotMenuOptions['showConfirmBtn'] = false
    }
    // Set smallscale options
    trippleDotMenuOptions['showAddBtn'] =
      !readOnly &&
      !(pickup || sign) &&
      groupHeader &&
      orderedItem.delivery_items.length < orderedItem.amount
    trippleDotMenuOptions['showDelBtn'] =
      !readOnly &&
      !sign &&
      !groupHeader &&
      orderedItem.delivery_items.length > 0
  } else if (orderedItem.item.is_formwork) {
    // Set formwork options
    trippleDotMenuOptions['showAddBtn'] =
      !readOnly && !(pickup || sign) && orderedItem.disposition_item_id
    //trippleDotMenuOptions['showDelBtn'] = orderedItem.disposition_item_id
  }

  return <DeliveryItemTripleDotMenu {...trippleDotMenuOptions} />
}
