.roles {
  display: flex;
  background: #c2d2fb;
  border-radius: 5px;
  padding: 2px;
  padding-left: 10px;
  align-items: center;
  gap: 10px;
}

.roles p {
  flex: 1;
}

.roles svg {
  color: #303030;
}

.attachedRole {
  border-radius: 5px;
  display: flex;
  height: 32px;
  justify-content: space-between;
  background: #c2d2fb !important;
  align-items: center;
  width: 125px;
  height: 50px;
  font-size: small !important;
  text-align: center;
}
.attachedRole p {
  flex: 1;
  font-size: small;
}

.attachedRole svg {
  cursor: pointer;
}

.plus {
  cursor: pointer;
  color: #303030;
}

.plus :hover {
  color: #808080;
}
