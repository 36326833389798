import { useTranslation } from 'react-i18next'

import { formatDistanceFromNow } from 'common/utils/format'
import { Container } from 'common/widgets/container'
import { Row, Column } from 'common/widgets/grid'
import { Tab } from 'common/widgets/tab'
import { GridFieldView, SectionView } from 'common/widgets/view'
import { register } from 'system/container'
import { DetailViewPage } from 'system/utils/view'

import { RoleNotificationsSelectView } from './notifications'
import { RoleScopesSelectView } from './scopes'

const RoleViewPage = () => {
  const { t } = useTranslation(['security'])

  return (
    <DetailViewPage url="/security/roles" title={(e) => e.name} editable>
      {(data, reload) => (
        <>
          <Container flex>
            <Row>
              <Column flex n={6}>
                <SectionView>
                  <GridFieldView
                    label={t('Last updated')}
                    value={formatDistanceFromNow(data.updated_at)}
                  />
                </SectionView>
              </Column>
            </Row>
          </Container>
          <Tab
            items={[
              {
                key: 'permission',
                title: 'Permissions',
                render: () => (
                  <RoleScopesSelectView defaultActiveScopes={data.scopes} />
                ),
              },
              {
                key: 'notification',
                title: 'Notifications',
                render: () => (
                  <RoleNotificationsSelectView
                    defaultActiveNotifications={data.notification_types}
                  />
                ),
              },
            ]}
          />
        </>
      )}
    </DetailViewPage>
  )
}

register({
  render: () => <RoleViewPage />,
  title: 'Details',
  path: '/security/roles/:id',
  priority: 0.0,
})
