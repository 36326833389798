import { createContext, useContext } from 'react'

const RecordContext = createContext()

/**
 * Provide data table record context for each dto
 *
 * @param {children} list  list of react elements
 * @returns
 */
export const RecordProvider = ({ r, children }) => {
  return <RecordContext.Provider value={r}>{children}</RecordContext.Provider>
}

/**
 * Returns the provided data table record context.
 *
 * @returns RecordContext
 */
export const useRecord = () => useContext(RecordContext)
