import { EditViewPage } from 'system/utils/edit'

import { DeviceForm } from './form'

export const DeviceEdit = () => {
  return (
    <EditViewPage url="/telematics" title={(e) => e.name}>
      {(data) => <DeviceForm data={data} />}
    </EditViewPage>
  )
}
