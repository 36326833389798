import { register } from 'system/container'
import { SystemPage } from 'system/page/core'

import { TripsList } from './list'

register({
  render: () => (
    <SystemPage>
      <TripsList />
    </SystemPage>
  ),
  menu: true,
  title: 'Trips',
  path: '/logistics/planning/trips',
})
