.debug {
  display: grid;
  grid-gap: 5px;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: minmax(150px, auto);
}

.debug label {
}

.debug textarea {
  white-space: pre-wrap;
  font-family: monospace;
  resize: both !important;
}
