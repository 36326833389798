import { Trello } from 'react-feather'

import { register } from 'system/container'

import { ProjectAddPage } from './add'
import { ProjectEditPage } from './edit'
import { ProjectsPage } from './list'
import { BookingCalenderPage } from './view/bookings'
import { BookingChangePage } from './view/inventory/shrink'
import { BookingExtendPage } from './view/inventory/extend'
import { ProjectDetailsPage } from './view/details'
import { ProjectTimelinePage } from './timeline'

register({
  icon: <Trello />,
  title: 'Projects',
  // Subsystem base route, no trailing slash
  path: '/projects',
  // mainmenu priority, lower is higher
  priority: 70,
})

// Registers large scale edit page inside system container.
register({
  render: () => <ProjectEditPage />,
  path: '/projects/:id/edit',
  title: 'Edit',
})

// Registers projects page inside system container.
register({
  render: () => <ProjectAddPage />,
  path: '/projects/add',
  title: 'New',
})

// Registers the page as a child of project sections
register({
  menu: true,
  title: 'Projects',
  path: '/projects/',
  render: () => <ProjectsPage />,
})

register({
  render: () => <ProjectDetailsPage />,
  title: 'Details',
  path: '/projects/:id',
})

register({
  render: () => <BookingCalenderPage />,
  title: 'Booking',
  path: '/projects/:projectId/booking/:itemId/:startDate',
})

// Registers booking extend page in system container, with it's url
register({
  render: () => <BookingChangePage />,
  title: 'Booking shrink',
  path: '/projects/:projectId/shrink/:itemId',
})

// Registers booking extend page in system container, with it's url
register({
  render: () => <BookingExtendPage />,
  title: 'Booking extension',
  path: '/projects/:projectId/extend/:itemId',
})

// Register the project hours plan page
register({
  render: () => <ProjectTimelinePage />,
  menu: true,
  title: 'Project timeline',
  path: '/projects/timeline',
  priority: 1,
})
