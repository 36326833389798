import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { SystemPage } from 'system/page/core'
import { formatDistanceFromNow } from 'common/utils/format'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'

/**
 *  Renders the Delivery page
 *
 * @returns ReactElement
 */
export const StatesListPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <SystemPage>
      <SimpleDataTable
        name="states"
        title={t('States')}
        url="contacts/defs/states"
        onAddClick={() => navigate('/master-data/contacts/states/add')}
        addTooltip="Add state"
        navBaseUrl="/master-data/contacts/states"
        rowColor={(r) => r.color}
      >
        <SimpleColumn s={12} n={12} sortable header field="name" />
        <SimpleColumn
          s={6}
          n={6}
          sortable
          field="comment"
          label={t('Comment')}
        />
        <SimpleColumn
          s={6}
          n={6}
          sortable
          field="created_at"
          label={t('Creation date')}
          value={(r) => formatDistanceFromNow(r.created_at)}
        />
      </SimpleDataTable>
    </SystemPage>
  )
}
