import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import L from 'leaflet'
import { useMapEvents } from 'react-leaflet'
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch'

export const SearchBox = ({ locationFound, locationRemoved }) => {
  const { t } = useTranslation()

  const searchControl = new GeoSearchControl({
    provider: new OpenStreetMapProvider(),
    marker: {
      draggable: false,
    },
    style: 'bar',
    searchLabel: t('Enter address'),
  })

  const map = useMapEvents({
    layerremove: (e) => {
      if (locationRemoved && e.layer instanceof L.Marker) {
        locationRemoved(map)
      }
    },
  })

  const onLocationFound = (e) => locationFound && locationFound(e, map)

  useEffect(() => {
    map.addControl(searchControl)
    map.on('geosearch/showlocation', onLocationFound)

    return () => {
      map.removeControl(searchControl)
      map.off('geosearch/showlocation', onLocationFound)
    }
  }, [map, onLocationFound])

  return null
}
