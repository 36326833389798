import { useTranslation } from 'react-i18next'

import { FieldView } from 'common/widgets/view'

import { formatAddress } from '../utils'

/**
 * Renders address field inside a data table.
 */
export const AddressFieldView = ({ address }) => {
  const { t } = useTranslation(['projects'])
  return (
    <FieldView label="Address">
      {address
        ? formatAddress(address).map((line, i) => <p key={i}>{line}</p>)
        : t('Address not available.')}
    </FieldView>
  )
}
