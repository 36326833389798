import { register } from 'system/container'

import { TeamsList } from './list'
import { TeamDetailViewPage } from './view'
import { TeamsAddPage } from './add'
import { TeamsEditPage } from './edit'

register({
  menu: true,
  render: () => <TeamsList />,
  title: 'Teams',
  path: '/master-data/contacts/teams',
  priority: 0.0,
})

register({
  render: () => <TeamDetailViewPage />,
  title: 'Details',
  path: '/master-data/contacts/teams/:id',
  priority: 0.0,
})

register({
  render: () => <TeamsAddPage />,
  title: 'Add',
  path: '/master-data/contacts/teams/add',
  priority: 0.0,
})

register({
  render: () => <TeamsEditPage />,
  title: 'Edit',
  path: '/master-data/contacts/teams/:id/edit',
  priority: 0.0,
})
