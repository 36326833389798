import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

import { Container } from 'common/widgets/container'
import { ConfirmButton, ClearButton, CancelButton } from 'common/widgets/button'
import { OverlayBody, OverlayFooter, OverlayForm } from 'common/widgets/overlay'
import { useService } from 'common/service/context'
import { DateField, SignatureField, TextField } from 'common/widgets/form/field'
import { dateToISOString } from 'common/utils/format'
import { useSession } from 'common/session/context'

/**
 * Renders the form for the back delivery handover overlay.
 *
 * @param {any} backDelivery back delivery note object
 * @param {any} onClose state of closing the dialog
 *
 * @returns ReactElement
 */
export const BackDeliveryHandoverOverlay = ({ backDelivery, onClose }) => {
  const { t } = useTranslation()
  const service = useService()
  const navigate = useNavigate()
  const { currentUser } = useSession()
  const handler = {}

  const onOverlayClose = () => {
    clearSignature()
    onClose()
  }

  const clearSignature = async () => {
    handler.clear()
  }

  const handleHandover = async (values) => {
    const [result, error] = await service.put(
      `back/delivery/${backDelivery?.id}/deliver`,
      {
        ...values.json,
      }
    )

    if (!error) {
      onClose()
      navigate(-1)
    }

    return [result, error]
  }

  return (
    <OverlayForm
      title={t('Handover')}
      open={backDelivery}
      onClose={onOverlayClose}
      data={{
        checkout_date: new Date(),
        recipient: currentUser?.fullname,
        signature: null,
      }}
    >
      <OverlayBody>
        <HandoverFormContent backDelivery={backDelivery} handler={handler} />
      </OverlayBody>
      <OverlayFooter repel gap="10px">
        <ConfirmButton
          disabled={(ctx) => !ctx.ready || !ctx.values.json.signature}
          onClick={(e, ctx) => handleHandover(ctx.values)}
        />
        <ClearButton danger onClick={clearSignature} />
        <CancelButton onClick={onClose} />
      </OverlayFooter>
    </OverlayForm>
  )
}

/**
 * Renders the form content of the handover overlay.
 *
 * @param {any} backDelivery back delivery note object
 * @param {any} handler handler object for the signature field
 *
 * @returns ReactElement
 */
const HandoverFormContent = ({ backDelivery, handler }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <h5>{t('Recipient')}*</h5>
      <Container>
        <TextField autoFocus name="recipient" mandatory />
      </Container>
      <Container>
        <h5>{t('Date')}*</h5>
        <DateField
          name="checkout_date"
          normalize={dateToISOString}
          mandatory
          clearable
        />
      </Container>
      <Container flex>
        <Container>
          {backDelivery && (
            <SignatureField name="signature" handler={handler} />
          )}
        </Container>
      </Container>
    </Container>
  )
}
