import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  NumericField,
  SelectField,
  TextField,
  TextAreaField,
} from 'common/widgets/form/field'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { Row, Column } from 'common/widgets/grid'
import { SectionView } from 'common/widgets/view'
import {
  EquipmentCategoryFreeField,
  EquipmentMaincategoryFreeField,
  EquipmentSubcategoryFreeField,
} from 'modules/master-data/equipments/widgets/category'
import { CostCenterSelectField } from 'modules/master-data/cost-centers/widgets'

import { OrderableField } from '../widgets/orderable'
import { AccountingGroupField } from '../widgets/accounting-group'
import { AccountingTypeField } from '../widgets/accounting-type'
import { QuantityUnitField } from '../widgets/quantity-unit'
import { ItemTypeEnum, FormworkAccountingTypeEnum } from '../utils'

import { SourceOfSupplyField } from './widgets/source-of-supply'
import { EquipmentConditionField } from './widgets/condition'

/**
 * Renders formwork's data entry form.
 *
 * @param {Function} onClose closing hook
 * @returns
 */
export const FormworksFormContent = ({
  onAdd,
  onUpdate,
  onRemove,
  onSuccess,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Row>
        <Column n={6}>
          <SectionView>
            <h1>{t('Master data')}</h1>
            <h5>{t('Label')}*</h5>
            <TextField name="name" mandatory />
            <h5>{t('Inventory number')}</h5>
            <TextField name="inventory_number" />
            <h5>{t('Main group')}*</h5>
            <EquipmentMaincategoryFreeField
              mandatory
              params={[{ type: ItemTypeEnum.FORMWORK }]}
            />
            <h5>{t('Group')}*</h5>
            <EquipmentCategoryFreeField
              mandatory
              dependable={!onAdd}
              params={[{ type: ItemTypeEnum.FORMWORK }]}
            />
            <h5>{t('Subgroup')}</h5>
            <EquipmentSubcategoryFreeField
              dependable={!onAdd}
              params={[{ type: ItemTypeEnum.FORMWORK }]}
            />
            <h5>{t('Commission type')}*</h5>
            <SelectField
              name="commission_type"
              mandatory
              items={[
                { key: 'COMMISSIONER', title: t('COMMISSIONER') },
                { key: 'SELF', title: t('SELF') },
              ]}
            />
            <h5>{t('Supplier')}</h5>
            <TextField name="supplier" />
            <h5>{t('Supplier')}*</h5>
            <SourceOfSupplyField mandatory />
            <h5>{t('Orderable')}*</h5>
            <OrderableField mandatory selected={false} />
          </SectionView>
        </Column>
        <Column n={6}>
          <SectionView>
            <h1>{t('Accounting and stock')}</h1>
            <h5>{t('Cost center')}</h5>
            <CostCenterSelectField name="cost_center_id" removable />
            <h5>{t('Accounting type')}*</h5>
            <AccountingTypeField
              typeEnum={FormworkAccountingTypeEnum}
              mandatory
            />
            <h5>{t('Price when new')}*</h5>
            <NumericField
              name="cost_rate"
              preDecimalScale={4}
              decimalScale={2}
              mandatory
            />
            <QuantityUnitField mandatory label="Quantity unit" />
            <h5>{t('Total stock')}</h5>
            <NumericField name="total_amount" preDecimalScale={4} />
            <h5>{t('Storage')}</h5>
            <TextField name="storage" />
            <h5>{t('Condition')}*</h5>
            <EquipmentConditionField />
            <h5>{t('Accounting group')}</h5>
            <AccountingGroupField params={[{ type: 'FORMWORK' }]} />
          </SectionView>
        </Column>
        <Column n={6}>
          <SectionView>
            <h1>{t('Dimensions and cargo')}</h1>
            <h5>{t('Weight')} (kg)</h5>
            <NumericField name="weight" preDecimalScale={4} />
            <h5>{t('Packaging unit')}</h5>
            <TextField name="packaging_unit" />
            <h5>{t('Transportation')}</h5>
            <TextField name="transportation" />
            <h5>{t('Clear inner dimensions')} (L/B/H)</h5>
            <TextField name="dimensions_1" />
            <h5>{t('Outer dimensions')} (L/B/H)</h5>
            <TextField name="dimensions_2" />
            <h5>{t('Maximum outer dimensions')} (L/B/H)</h5>
            <TextField name="dimensions_3" />
            <h5>{t('Comment')}</h5>
            <TextAreaField name="comment" rows="4" />
          </SectionView>
        </Column>
      </Row>
      <SimpleFormAction
        onAdd={onAdd}
        onUpdate={onUpdate}
        onRemove={onRemove}
        onSuccess={onSuccess}
      />
    </>
  )
}
