import React from 'react'
import { useTranslation } from 'react-i18next'

import { formatDate } from 'common/utils/format'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { formatProjectName } from 'modules/projects/utils'
import {
  formatDeliveryNumber,
  formatBackDeliveryNumber,
} from 'modules/yard/utils'

/**
 * Renders item history.
 *
 * @param itemID the item id to get its history
 * @param type item type
 *
 * @returns {JSX.Element}
 */
export const ItemHistory = ({ itemID }) => {
  const { t } = useTranslation(['projects'])

  return (
    <SimpleDataTable
      name="inouthistory"
      url={`/items/${itemID}/history`}
      title={t('Check-in/Check-out History')}
      limit={5}
      rowNumbers
      divide
      white
      nomargin
      nopadding
    >
      <SimpleColumn color={(e) => (e.amount > 0 ? 'green' : 'red')} />
      <SimpleColumn
        n={4}
        s={6}
        field={'delivery_date'}
        label={t('Date')}
        value={(e) => formatDate(e.delivery_date)}
      />
      <SimpleColumn
        n={4}
        s={6}
        field={'amount'}
        label={t('Amount')}
        value={(e) => e.amount}
      />
      <SimpleColumn
        n={4}
        s={6}
        field={'project_id'}
        label={t('Project')}
        value={(e) =>
          formatProjectName({
            cost_center: e.cost_center,
            short_name: e.short_name,
          })
        }
        link={(e) => `/projects/${e.project_id}`}
      />
      <SimpleColumn
        n={4}
        s={12}
        field={'delivery_number'}
        label={t('Delivery/Back Delivery Number')}
        value={(e) =>
          e.amount > 0
            ? formatDeliveryNumber(e.order_number, e.delivery_number)
            : formatBackDeliveryNumber({ id: e.back_delivery_id })
        }
        link={(e) =>
          e.amount > 0
            ? `/yard/commission/deliveries/${e.delivery_id}`
            : `/yard/retour/deliveries/${e.back_delivery_id}`
        }
      />
    </SimpleDataTable>
  )
}
