import React, { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import jwtDecode from 'jwt-decode'

import { useService } from 'common/service/context'
import { getPages } from 'system/container'
import { useToast } from 'system/toast/context'

// Creates an instance of service context
const SessionContext = createContext({
  pages: null,
  getPage: null,
})

/**
 * Provides session context for child elements.
 *
 * @param {*} children Elements as children
 * @returns
 */
export const SessionProvider = ({ children }) => {
  const service = useService()
  const [currentUser, setCurrentUser] = useState(null)
  const navigate = useNavigate()
  const { toasts } = useToast()

  // Current user profile image as Data URL
  const [currentProfileImage, setCurrentProfileImage] = useState()

  useEffect(() => {
    const handleInvalidToken = (statusCode, message) => {
      // Logout on HTTP 401 Unauthorized status code
      // (will cause a redirect to the login page in our routes)
      if (statusCode === 401) {
        logout()
      }
    }

    // Registers invalid token handler in service context.
    service.addErrorHandler(handleInvalidToken)

    // Returns deregister hook.
    return () => service.removeErrorHandler(handleInvalidToken)
  })

  useEffect(() => {
    if (service.token) {
      service.get('users/current').then(([result, error]) => {
        if (!error) {
          setCurrentUser(result.data)
          reloadProfileImage(result.data)
        }
      })
    }
  }, [service.token])

  const reloadProfileImage = async (user, pictureFileId) => {
    user ??= currentUser
    pictureFileId ??= user.picture_file_id
    if (!user.has_profile_image) {
      setCurrentProfileImage(null)
      return
    }
    const [response, error] = await service.get(
      `/files/${pictureFileId}/thumbnail/dataurl`
    )
    if (!error) {
      setCurrentProfileImage(response.data)
    }
  }

  /**
   * Authenticates the user using the given credentials.
   *
   * @param {string} email User email
   * @param {string} password User password
   * @returns
   */
  const login = async ({ email, password, next = '/' }) => {
    const [response, error] = await service.post('security/auth', {
      email,
      password,
    })
    service.setToken(response?.data?.access_token ?? null)
    if (!error) {
      toasts.clear()
      navigate(next)
    }
  }

  /**
   * Runs logout and redirects user to login page.
   */
  const logout = () => {
    // Notifies service for token change
    service.setToken(null)
    setCurrentProfileImage(null)
  }

  /**
   * Updates the current user data.
   *
   * @returns array[object]
   */
  const update = async (params) => {
    const [result, error] = await service.put('users/current', params)
    if (!error) {
      setCurrentUser(result.data)
      reloadProfileImage(result.data)
    }
    return [result, error]
  }

  /**
   * Refreshes the current user data.
   *
   * @returns array[object]
   */
  const refresh = async () => {
    const [result, error] = await service.get('users/current')
    if (!error) {
      setCurrentUser(result.data)
      reloadProfileImage(result.data)
    }
    return [result, error]
  }

  /**
   * Returns login status, true means user is logged in.
   *
   * @returns boolean
   */
  const isLoggedIn = () =>
    service.token && service.token !== 'null' ? true : false

  const getPage = (path) => {
    // Finds first matching page
    return getPages()
      .reverse()
      .find((e) => e.path.startsWith(path))
  }

  const completeRegistration = async ({ token, password }) => {
    const [result, error] = await service.post(
      `/security/auth/complete-registration/${token}`,
      {
        password: password,
      }
    )
    if (!error) {
      service.setToken(result.data.token)
    }
    return [result, error]
  }

  const resetPassword = async ({ token, password }) => {
    const [result, error] = await service.post(
      `/security/auth/reset-password/${token}`,
      {
        password,
      }
    )
    return [result, error]
  }

  // Calculate a rudimentary ready flag to avoid double rendering children
  let isReady = false
  if (isLoggedIn()) {
    if (currentUser) {
      isReady = true
    }
  } else {
    isReady = true
  }

  // Provides context members.
  return (
    <SessionContext.Provider
      value={{
        token: service.token,
        login,
        logout,
        isLoggedIn,
        update,
        refresh,
        data: service.token && jwtDecode(service.token),
        currentUser: service.token ? currentUser : null,
        pages: getPages(),
        getPage,
        completeRegistration,
        resetPassword,
        currentProfileImage,
      }}
    >
      {/* Wait until current user is loaded to avoid unnecessary renders */}
      {isReady ? (
        children
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <LoadingDots />
        </div>
      )}
    </SessionContext.Provider>
  )
}

/**
 * Returns session context.
 *
 * @returns Context<SessionContext>
 */
export const useSession = () => {
  return useContext(SessionContext)
}

const LoadingDots = () => {
  const [step, setStep] = useState(0)
  useEffect(() => {
    const timer = setTimeout(() => {
      setStep((step) => {
        if (step > 0 && step % 3 === 0) {
          return 0
        }
        return step + 1
      })
    }, 300)
    return () => clearTimeout(timer)
  })
  let dots = ''
  for (let i = 0; i < step; i++) {
    dots += '.'
  }
  return `${dots}`
}
