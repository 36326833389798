import React from 'react'
import { useTranslation } from 'react-i18next'

import { DropDown } from 'common/widgets/dropdown'

// ISO 3166 country codes
import countries_de from './countries_de.json'

export const CountryDropDown = ({
  name,
  selected,
  disabled,
  onChange,
  ...rest
}) => {
  const { t } = useTranslation()
  const items = countries_de.map((c) => ({
    key: c.alpha2,
    title: c.name,
  }))
  const deIndex = items.findIndex((i) => i.key == 'de')
  const inIndex = items.findIndex((i) => i.title == selected)
  return (
    <DropDown
      name={name}
      title={t('Country')}
      items={items}
      selectedIndex={selected ? inIndex : deIndex}
      disabled={disabled}
      onSelectChange={(e) => {
        onChange && onChange(e)
      }}
      {...rest}
    />
  )
}
