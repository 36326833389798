/**
 * Renders when the given condition is true.
 *
 * @param {criteria} bool render condition
 * @param {children} list react child elements
 * @returns
 */
export const When = ({ criteria, children }) =>
  criteria ? <>{children}</> : null

/**
 * Returns true if the given string value is empty.
 *
 * @param {String} value string value
 * @returns bool
 */
export const isNilOrEmpty = (value) =>
  value === null ||
  value === undefined ||
  (typeof value === 'string' && value.trim().length === 0)
