import { Form } from 'common/widgets/form/context'
import { SystemPage } from 'system/page/core'
import { useService } from 'common/service/context'

import { UserFormContent } from './form'

export const UserAddPage = () => {
  const service = useService()

  const handleAdd = async (values) => {
    const normalized = { ...values, roles: values?.roles?.map((e) => e.id) }
    return await service.post('users', normalized)
  }

  return (
    <SystemPage>
      <Form>
        <UserFormContent onAdd={handleAdd} />
      </Form>
    </SystemPage>
  )
}
