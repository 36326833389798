import { Container } from 'common/widgets/container'
import { SingleImageView } from 'common/widgets/image'
import { FieldView } from 'common/widgets/view'
import { formatUserName } from 'modules/security/users/utils'

export const ContactView = ({ contact }) => (
  <Container flex gap="10px">
    <SingleImageView
      imageRef={contact.system_user?.profile_image_ref}
      name={contact.system_user?.fullname}
      size="24px"
    />
    <FieldView
      href={`/master-data/contacts/${contact.id}`}
      value={formatUserName(contact.system_user)}
    />
  </Container>
)
