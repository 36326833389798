import { register } from 'system/container'
import { SystemPage } from 'system/page/core'

import { TaskDetailViewPage } from './view'
import { TasksPage } from './list'
import { TaskAddPage } from './add'
import { TaskEditPage } from './edit'

register({
  when: () => true,
  render: () => (
    <SystemPage>
      <TasksPage />
    </SystemPage>
  ),
  menu: () => true,
  title: 'Tasks',
  path: '/projects/tasks',
})

register({
  when: () => true,
  render: () => <TaskEditPage />,
  title: 'Edit',
  path: '/projects/tasks/:id/edit',
})

register({
  when: () => true,
  render: () => <TaskDetailViewPage />,
  title: 'Details',
  path: '/projects/tasks/:id',
})

register({
  when: () => true,
  render: () => <TaskAddPage />,
  title: 'Add task',
  path: '/projects/tasks/add',
})
