import { register } from 'system/container'

import { ConsumablesAddPage } from './add'
import { ConsumablesListPage } from './list'
import { ConsumablesEditPage } from './edit'
import { ConsumableViewPage } from './view'

// Registers projects page inside system container.
register({
  render: () => <ConsumablesAddPage />,
  path: '/master-data/equipments/consumables/add',
  title: 'Neu',
})

// Registers consumables page.
register({
  menu: true,
  path: '/master-data/equipments/consumables',
  title: 'Consumables',
  render: () => <ConsumablesListPage />,
})

// Registers formworks edit page inside system container.
register({
  render: () => <ConsumablesEditPage />,
  path: '/master-data/equipments/consumables/:id/edit',
  title: 'Edit',
})

register({
  render: () => <ConsumableViewPage />,
  title: 'Details',
  path: '/master-data/equipments/consumables/:id',
  priority: 0.0,
})
