import dagre from 'dagre'

/**
 * Reposition graph nodes on a canvas.
 */
export const getLayoutedElements = (nodes, edges) => {
  const direction = 'LR'
  const isHorizontal = true
  const nodeWidth = 172
  const nodeHeight = 72

  const dagreGraph = new dagre.graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({}))
  dagreGraph.setGraph({ rankdir: direction })

  nodes.forEach((el) => {
    dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight })
  })

  edges.forEach((el) => {
    dagreGraph.setEdge(el.source, el.target)
  })

  dagre.layout(dagreGraph)

  return nodes.map((e) => {
    const nodeWithPosition = dagreGraph.node(e.id)
    e.targetPosition = isHorizontal ? 'left' : 'top'
    e.sourcePosition = isHorizontal ? 'right' : 'bottom'
    e.position = nodeWithPosition
    return e
  })
}
