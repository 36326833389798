import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Calendar } from 'react-feather'

import { formatDate, formatDateRange } from 'common/utils/format'
import { Container } from 'common/widgets/container'
import { Row } from 'common/widgets/grid'
import { CommentView, GridFieldView, RowView } from 'common/widgets/view'
import { CloseIconButton } from 'common/widgets/button'
import { formatOrderedItemAmount } from 'modules/orders/utils'
import { EquipmentLink } from 'modules/master-data/equipments/widgets/link'
import { formatItemNameForOrder } from 'modules/orders/form/select/list'
import { ItemTypeCommentView } from 'modules/master-data/equipments/widgets/type'
import { getEquipmentNavPath } from 'modules/master-data/equipments/utils'

import { ConfirmItemCancelationOverlay } from './overlays'

/**
 * Renders the Order`s ordered items based on the baseitem type.
 *
 * @param {any} orderedItems ordered items array
 * @param {string} title title of the component
 * @param {object} reload reloads the parent page
 *
 * @returns ReactElement
 */
export const OrderedItems = ({ reload, orderedItems, title }) => {
  const { t } = useTranslation(['orders'])
  // State for opening and closing of the overlays
  const [cancelItemVisibleOverlay, setCancelItemVisibleOverlay] =
    useState(false)
  // State for the item subject to cancellation
  const [cancelItem, setCancelItem] = useState(null)

  return (
    <>
      <h3>{t(title)}</h3>
      {orderedItems?.map((e, idx) => (
        <RowView key={idx}>
          <Container flex vertical grow>
            {e.commission_amount > 0 ||
            e.delivered_amount > 0 ||
            e.canceled_amount > 0 ||
            e.disposition_amount > 0 ? (
              <EquipmentLink equipment={e.item} />
            ) : (
              <>
                <h4>
                  <a href={getEquipmentNavPath(e.item)}>
                    {formatItemNameForOrder(e.item)}
                  </a>
                </h4>
                <ItemTypeCommentView equipment={e.item} />
              </>
            )}
            <Row>
              <GridFieldView
                n={2}
                s={6}
                label={t('Ordered amount')}
                value={formatOrderedItemAmount(e.item, e.original_amount)}
              />
              <GridFieldView
                n={2}
                s={6}
                label={t('Open amount')}
                value={e.open_amount}
              />
              <GridFieldView
                n={2}
                s={6}
                label={t('Commissioned amount')}
                value={e.commission_amount}
              />
              <GridFieldView
                success={e.delivered_amount > 0}
                n={2}
                s={6}
                label={t('Delivered amount')}
                value={e.delivered_amount}
              />
              <GridFieldView
                danger={e.canceled_amount > 0}
                n={2}
                s={6}
                label={t('Canceled amount')}
                value={e.canceled_amount}
              />
              {(e.item.is_largescale || e.item.is_formwork) && (
                <GridFieldView
                  danger={e.disposition_amount === 0}
                  n={2}
                  s={6}
                  label={t('Disposition amount')}
                  value={e.disposition_amount}
                />
              )}
            </Row>
            {e.comment && <CommentView comment={e.comment} />}
            {!e.item.is_consumable && e.booking_start && e.booking_end && (
              <CommentView
                icon={<Calendar />}
                comment={formatDateRange(e?.booking_start, e?.booking_end)}
              />
            )}
            {e.item.is_consumable && e.fixed_date && (
              <CommentView
                icon={<Calendar />}
                comment={formatDate(e.fixed_date)}
              />
            )}
          </Container>
          {e.amount > 0 && (
            <CloseIconButton
              danger
              onClick={() => {
                setCancelItemVisibleOverlay(true)
                setCancelItem(e)
              }}
            />
          )}
        </RowView>
      ))}
      <ConfirmItemCancelationOverlay
        open={cancelItemVisibleOverlay}
        onClose={() => {
          setCancelItemVisibleOverlay(false)
          setCancelItem(null)
        }}
        reload={reload}
        item={cancelItem}
      />
    </>
  )
}
