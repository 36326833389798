/**
 * Returns true if the given value is type of string.
 *
 * @param {any} value any value
 * @returns boolean
 */
const isString = (value) =>
  Object.prototype.toString.call(value) === '[object String]'

/**
 * Returns true if the given value is a number.
 *
 * @param {any} value any value
 * @returns boolean
 */
const isNumber = (value) =>
  Object.prototype.toString.call(value) === '[object Number]' && !isNaN(value)

/**
 * Returns true if the given value is an Array.
 *
 * @param {any} value any value
 * @returns boolean
 */
export const isArray = (value) => Array.isArray(value)

/**
 * Returns true if the given value is actually a dictionary.
 *
 * @param {any} value any value
 * @returns boolean
 */
export const isObject = (value) =>
  Object.prototype.toString.call(value) === '[object Object]'

/**
 * Returns true if the given value is a valid date or a valid string representation of a date.
 *
 * @param {any} value any value
 * @returns boolean
 */
const isDate = (value) => {
  if (!value || isBoolean(value) || isNumber(value)) {
    return false
  }

  let possibleDate = new Date(value)
  if (Object.prototype.toString.call(possibleDate) === '[object Date]') {
    return !isNaN(possibleDate.getTime())
  }
  return false
}

/**
 * Returns true if the given value is boolean.
 *
 * @param {any} value any value
 * @returns boolean
 */
const isBoolean = (value) =>
  Object.prototype.toString.call(value) === '[object Boolean]'

/**
 * Gets the type name of given value.
 *
 * @param {any} value any value
 * @returns string
 */
export const getType = (value) => {
  if (value == null || value == undefined) {
    return 'string'
  }
  if (isNumber(value)) {
    return 'number'
  }
  if (isArray(value)) {
    return 'array'
  }
  if (isObject(value)) {
    return 'object'
  }
  if (isDate(value)) {
    return 'date'
  }
  if (isString(value)) {
    return 'string'
  }
  return typeof value
}
