import { Square } from 'react-feather'

import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'
import { DropDownField } from 'common/widgets/form/field'

/**
 * Renders state select field.
 *
 * @param {string} name field name
 * @returns
 */
export const ContactStateField = ({ ...rest }) => {
  const service = useService()
  const fetch = async () => await service.get(`/contacts/defs/states`)
  return (
    <DataSource
      fetch={fetch}
      render={({ data }) => (
        <DropDownField
          items={
            data
              ? data.map((e) => ({
                  key: e.id,
                  title: e.name,
                  data: e,
                  icon: <Square fill={e.color} color={e.color} />,
                }))
              : []
          }
          minWidth="276px"
          {...rest}
        />
      )}
    />
  )
}
