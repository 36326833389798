import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FieldWrapper } from 'common/widgets/form/field'
import { Select } from 'common/widgets/select'
import { GridFieldView } from 'common/widgets/view'

/**
 * Renders field for indicating orderable item.
 *
 * @returns ReactElement
 */
export const OrderableField = ({ mandatory, selected }) => {
  // Get translation context.
  const { t } = useTranslation()
  const [selection, setSelection] = useState(null)
  const items = [
    { key: true, title: t('Yes') },
    { key: false, title: t('No') },
  ]

  return (
    <FieldWrapper name="is_orderable" mandatory={mandatory}>
      {(getValue, setValue) => (
        <Select
          selected={selected ? getValue() : selection}
          items={items}
          onSelectChange={(item) => {
            if (!selected) {
              setSelection(item.key)
            }
            setValue(item.key)
          }}
        />
      )}
    </FieldWrapper>
  )
}

/**
 * Renders field view for showing webshop value
 *
 * @returns ReactElement
 */
export const OrderableColumnFieldView = ({ value, ...rest }) => {
  const { t } = useTranslation()

  return (
    <GridFieldView
      {...rest}
      label={t('Orderable')}
      value={value ? t('Yes') : t('No')}
    />
  )
}
