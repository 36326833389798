import { useTranslation } from 'react-i18next'
import { Users } from 'react-feather'
import chroma from 'chroma-js'

import { stringToColor } from 'common/utils/color'
import { useService } from 'common/service/context'
import { AddIconButton, RemoveIconButton } from 'common/widgets/button'
import { Container } from 'common/widgets/container'
import { DataSource } from 'common/widgets/data-source'
import { Row, Column } from 'common/widgets/grid'
import { SearchInput } from 'common/widgets/search'
import { CardView, CommentView } from 'common/widgets/view'

import styles from './view.module.css'

export const ContactTagsView = ({ contact, reload }) => {
  const service = useService()
  const existingTagIds = contact?.tags?.map((t) => t.id)
  return (
    <DataSource url="/contacts/defs/tags/minimal">
      {({ data, totalCount }) => {
        return (
          <Row>
            <Column n={6}>
              <ContactTagsSelect
                tags={contact?.tags}
                onRemove={async (tagId) => {
                  const [, error] = await service.delete(
                    `/contacts/${contact.id}/tags/${tagId}`
                  )
                  if (!error) {
                    reload()
                  }
                }}
              />
            </Column>
            <Column n={6}>
              <ContactTagsSelect
                tags={data?.filter((t) => !existingTagIds.includes(t.id))}
                onAdd={async (tagId) => {
                  const [, error] = await service.post(
                    `/contacts/${contact.id}/tags/${tagId}`
                  )
                  if (!error) {
                    reload()
                  }
                }}
              />
            </Column>
          </Row>
        )
      }}
    </DataSource>
  )
}

/**
 * Renders a widget to select tags for contacts.
 *
 * @param {any} contact contact info
 * @param {Function} reload data reload function
 * @returns ReactElement
 */
const ContactTagsSelect = ({ tags, totalCount, onAdd, onRemove }) => {
  const { t } = useTranslation('contacts')

  const formatOverflowContacts = (overflow) => {
    return t('There are [{{overflow}}] more results...', { overflow })
  }

  return (
    <CardView>
      <Container flex vertical>
        <Container flex justify="space-between">
          <Container flex vertical title="Tags">
            {totalCount > 10 && (
              <h5>{formatOverflowContacts(totalCount - 10)}</h5>
            )}
          </Container>
          <SearchInput />
        </Container>
        {tags?.map((e, idx) => {
          const color = e.color ?? chroma(stringToColor(e.name)).brighten(1)
          return (
            <div className={styles.tagCard} key={idx}>
              <div
                className={styles.contactTagColor}
                style={{
                  background: color,
                  boxShadow: `0px 0px 8px ${color}`,
                }}
              />
              <Container
                flex
                padding="15px"
                align="center"
                grow
                justify="space-between"
              >
                <Container flex vertical>
                  <h4>{e.name}</h4>
                  <CommentView
                    icon={<Users />}
                    comment={`[${e.contact_count}] ${t('Contacts')}`}
                  />
                </Container>
                {onAdd && (
                  <AddIconButton
                    default
                    onClick={() => {
                      onAdd(e.id)
                    }}
                  />
                )}
                {onRemove && (
                  <RemoveIconButton
                    danger
                    onClick={() => {
                      onRemove(e.id)
                    }}
                  />
                )}
              </Container>
            </div>
          )
        })}
      </Container>
    </CardView>
  )
}
