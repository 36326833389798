import { FreeChooseField } from 'common/widgets/form/field'
import { forceLength } from 'common/widgets/form/validate'

export const QuantityUnitField = ({ name = 'quantity_unit', ...rest }) => {
  const items = [
    'Stück',
    'Eimer',
    'Paar',
    'Liter',
    'lfm',
    'm',
    'm2',
    'm3',
    'Dose',
    'kg',
    'Rolle',
    'Kartusche',
    'Schlauchbeutel',
    'Kanister',
    'Tube',
    'Sack',
  ]

  return (
    <FreeChooseField
      validate={(value) => forceLength(value, 1, 64)}
      name={name}
      items={items}
      {...rest}
    />
  )
}
