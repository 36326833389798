import { register } from 'system/container'
import { SystemPage } from 'system/page/core'

// Overview of the commission submodule page.
const CommissionPage = () => (
  <SystemPage>
    <h1>You are in the Commission module.</h1>
  </SystemPage>
)

register({
  menu: true,
  render: () => <CommissionPage />,
  title: 'Commission',
  path: '/yard/commission',
  priority: 1.0,
})
