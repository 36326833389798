import { useState } from 'react'
import { ArrowLeft, ArrowRight } from 'react-feather'
import { Document, Page, pdfjs } from 'react-pdf'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { RemoteImage, RemoteFileProvider } from 'common/widgets/image'
import { Button } from 'common/widgets/button'
import { Container } from 'common/widgets/container'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

// register components which render a specific file extension
const previewComponent = {
  pdf: (props) => <PDFPreview {...props} />,
  jpg: (props) => <ImagePreview {...props} />,
  png: (props) => <ImagePreview {...props} />,
  svg: (props) => <ImagePreview {...props} />,
}

/**
 * Extract and render component which is used for specific file extension
 *
 *
 * @param {object} param0 contains file extension and the rest parameters which are passed to the rendered component
 * @returns
 */
const renderPreview = ({ extension, ...rest }) => {
  const component = previewComponent[extension]
  return component ? component(rest) : <h3>No previewer available</h3>
}

/**
 * Renders file preview
 *
 * @returns ReactElement
 */
export const Preview = ({ file }) => {
  const extension = file.name.split('.').pop()
  return (
    <FilePreviewContainer>
      {renderPreview({ extension, file_id: file.id })}
    </FilePreviewContainer>
  )
}

/**
 * Renders image preview
 *
 * @returns ReactElement
 */
const ImagePreview = ({ file_id }) => {
  return (
    <RemoteImage id={file_id}>
      {(data, loading) => <StyledImage src={data} $loading={loading} />}
    </RemoteImage>
  )
}

/**
 * Renders pdf preview
 *
 * @returns ReactElement
 */
const PDFPreview = ({ file_id }) => {
  const { t } = useTranslation()
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  return (
    <>
      <RemoteFileProvider id={file_id}>
        {(data, loading) => (
          <Document file={data} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
        )}
      </RemoteFileProvider>
      {numPages && (
        <>
          <Container flex>
            <Button
              disabled={pageNumber === 1}
              default
              onClick={() => setPageNumber(pageNumber - 1)}
            >
              <p>{t('Previous')}</p>
              <ArrowLeft />
            </Button>
            <Button
              disabled={pageNumber === numPages}
              default
              onClick={() => setPageNumber(pageNumber + 1)}
            >
              <p>{t('Next')}</p>
              <ArrowRight />
            </Button>
          </Container>
          <p>
            Page {pageNumber} of {numPages}
          </p>
        </>
      )}
    </>
  )
}

const FilePreviewContainer = styled.div`
  background: #efefef;
  border-radius: 5px;
`

const StyledImage = styled.img`
  margin: auto;
  width: 100%;
  height: auto;
`
