.stockLevel {
  color: #fc4242;
}

.accessoriesListItem {
  display: flex;
  background: #ffffff;
  margin-top: 10px;
  border-top: 1px solid #eeeeee;
  padding-top: 10px;
}

.comment {
  white-space: pre-wrap;
  font-family: monospace;
}
