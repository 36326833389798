import { useParams } from 'react-router-dom'

import { Form } from 'common/widgets/form/context'
import { SystemPage } from 'system/page/core'
import { DataSource } from 'common/widgets/data-source'
import { useService } from 'common/service/context'

import { ContactTagFormContent } from './form'

/**
 * Renders contact add page.
 *
 * @returns ReactElemtn
 */
export const ContactTagEditPage = () => {
  // Extracts tag id from url params
  const { id } = useParams()
  const service = useService()

  /**
   * Handles saving a tag, send a put call and updates the current tag.
   */
  const handleSave = async (values) =>
    await service.put(`contacts/defs/tags/${id}`, values)

  /**
   * Gets information of tag using it's id.
   *
   * @returns any
   */
  const fetch = async () => await service.get(`/contacts/defs/tags/${id}`)

  return (
    <DataSource
      fetch={fetch}
      render={({ data, error, loading }) => (
        <SystemPage loading={loading}>
          {data && (
            <Form data={data}>
              <ContactTagFormContent id={id} onUpdate={handleSave} />
            </Form>
          )}
        </SystemPage>
      )}
    />
  )
}
