import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Container } from 'common/widgets/container'
import {
  IconButton,
  BackIconButton,
  ForwardIconButton,
} from 'common/widgets/button'
import { formatDate, dateToISOString } from 'common/utils/format'
import { addDays } from 'common/utils/date'

// A simple navigation combo button for internal use in this module
const CalendarNavigation = ({ onNavigate }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { date } = useParams()
  return (
    <Container
      flex
      style={{
        borderRadius: '5px',
      }}
    >
      <BackIconButton
        color="white"
        background="#303030"
        onClick={() => {
          navigate(
            `/logistics/planning/transports/${dateToISOString(
              addDays(date, -1),
              true
            )}`
          )
          onNavigate && onNavigate()
        }}
      />
      <IconButton
        width="60px"
        color="white"
        background="#303030"
        onClick={() => {
          navigate(
            `/logistics/planning/transports/${dateToISOString(
              new Date(),
              true
            )}`
          )
          onNavigate && onNavigate()
        }}
      >
        {t('Today')}
      </IconButton>
      <ForwardIconButton
        color="white"
        background="#303030"
        onClick={() => {
          navigate(
            `/logistics/planning/transports/${dateToISOString(
              addDays(date, 1),
              true
            )}`
          )
          onNavigate && onNavigate()
        }}
      />
    </Container>
  )
}

export const TransportsPageHeader = () => {
  const navigate = useNavigate()
  const { date } = useParams()

  const dateFormatted = formatDate(date, {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  })

  return (
    <Container flex repel gap="10px">
      <BackIconButton onClick={() => navigate(-1)} />
      <h1
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginRight: 'auto',
        }}
      >{`${dateFormatted}`}</h1>
      <CalendarNavigation />
    </Container>
  )
}
