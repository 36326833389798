const _temp0 = require("../disposition/module.js");
const _temp1 = require("../landing/module.js");
const _temp2 = require("../logistics/module.js");
const _temp3 = require("../master-data/module.js");
const _temp4 = require("../orders/module.js");
const _temp5 = require("../projects/module.js");
const _temp6 = require("../security/module.js");
const _temp7 = require("../settings/module.js");
const _temp8 = require("../yard/module.js");
module.exports = {
  "disposition": _temp0,
  "landing": _temp1,
  "logistics": _temp2,
  "master-data": _temp3,
  "orders": _temp4,
  "projects": _temp5,
  "security": _temp6,
  "settings": _temp7,
  "yard": _temp8
}