import * as dfns from 'date-fns'

/****************************************************************
 * IMPORTANT: this is a date-fns wrapper. Don't add anything else.
 * Every wrapped function must have the exact same arguements.
 *
 * Note: unfortunately I had to contradict myself and make modifications.
 * Non-date-fns functions are defined at the end.
 ****************************************************************/

/**
 * Converts the given value to date instance.
 *
 * TODO: rename to "toDateOrNull"
 * @param {any} value date value as string or date
 * @returns Moment
 */
export const toDate = (value) => {
  // Numbers are valued values for most date-fns functions (timestamps).
  // Note: unix timestamps are in seconds, JavaScript's date functions
  // except them in milliseconds, so either they should be multiplied
  // by 1000 or date-fns function should be used.
  if (Number.isFinite(value)) {
    return dfns.fromUnixTime(value)
  }
  if (typeof value === 'string') {
    value = dfns.parseISO(value)
  }
  if (dfns.isValid(value)) {
    return value
  }
  if (value !== undefined) {
    // ignore this warning for undefined values which are common
    console.debug('Invalid date converted to null', value)
  }
  return null
}

// internal utility funcation
const stripTime = (date) => {
  return new Date(date.toDateString())
}

// All of the following functions are date-fns wrapper with exact same
// params, the only difference is use of our toDate conversion function.
// date-fns does not accept string parameters any more (for good reasons).
export const startOfWeek = (date) => dfns.startOfWeek(toDate(date))
export const startOfMonth = (date) => dfns.startOfMonth(toDate(date))
export const endOfMonth = (date) => dfns.endOfMonth(toDate(date))
export const addDays = (date, number) => dfns.addDays(toDate(date), number)
export const startOfDay = (date) => dfns.startOfDay(toDate(date))
export const endOfDay = (date) => dfns.endOfDay(toDate(date))
export const isPast = (date) => dfns.isPast(toDate(date))
export const isFuture = (date) => dfns.isFuture(toDate(date))
export const differenceInDays = (laterDate, earlierDate) =>
  dfns.differenceInDays(toDate(laterDate), toDate(earlierDate))

// See https://date-fns.org/v2.28.0/docs/add
export const add = (date, duration) => dfns.add(toDate(date), duration)

/******************************************
 * Extra date functions on top of date-fns
 *
 ******************************************/

// Check whether given date is yesterday or earlier
export const isPastDate = (date) =>
  dfns.compareAsc(stripTime(toDate(date)), stripTime(new Date())) == -1
