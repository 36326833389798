import { useState, useEffect, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { Container } from 'common/widgets/container'
import {
  AddButton,
  SaveButton,
  CancelButton,
  AddIconButton,
  ConfirmButton,
  YesButton,
  NoButton,
} from 'common/widgets/button'
import { OverlayBody, OverlayForm, OverlayFooter } from 'common/widgets/overlay'
import { Row } from 'common/widgets/grid'
import { CardView, GridFieldView } from 'common/widgets/view'
import { formatItemName } from 'modules/master-data/equipments/utils'
import { useForm } from 'common/widgets/form/context'
import {
  NumericField,
  DateField,
  TextField,
  SignatureField,
} from 'common/widgets/form/field'
import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'
import { SearchInput } from 'common/widgets/search'
import { Avatar } from 'common/widgets/avatar'
import { useToast } from 'system/toast/context'

/**
 * Renders the add items for addhooc delivery overlay.
 *
 * @param {Array} items selected items for addhook delivery
 * @param {object} setItems setter for the selected items for addhook delivery
 * @param {any} open state of opening the dialog
 * @param {any} close state of closing the dialog
 * @param {any} baseitemId preselected baseitem (usually would come from the master data module)
 *
 * @returns ReactElement
 */
export const AdHocItemSearchOverlay = ({
  onClose,
  onSelect,
  sourceProject,
  baseitemId,
}) => {
  const { t } = useTranslation()
  const [selectedItem, setSelectedItem] = useState(null)
  const service = useService()

  useLayoutEffect(() => {
    const fetchBaseitem = async () => {
      const [result, error] = await service.get(`/items/${baseitemId}`)
      if (!error) {
        setSelectedItem(result.data)
      }
    }
    if (baseitemId && baseitemId !== selectedItem?.id) {
      fetchBaseitem()
    }
  }, [baseitemId])

  return (
    <>
      {selectedItem ? (
        <AdHocCRUDOverlay
          title={'Commissioned amount'}
          item={selectedItem}
          onCreate={(item, waitForMore) => {
            onSelect(item, waitForMore)
            setSelectedItem(null)
          }}
          onClose={() => {
            setSelectedItem(null)
            onClose && onClose()
          }}
        />
      ) : (
        <OverlayForm open={true} onClose={onClose} title={t('Item')}>
          <ItemSearch
            sourceProject={sourceProject}
            onSelect={setSelectedItem}
            onClose={() => {
              setSelectedItem(null)
              onClose && onClose()
            }}
          />
        </OverlayForm>
      )}
    </>
  )
}

/**
 * Renders the add item for addhooc delivery overlay.
 *
 * @param {any} setItem sets an selected base item
 * @param {int} sourceProjectId id of the selected source project
 *
 * @returns ReactElement
 */
export const ItemSearch = ({ onClose, onSelect, sourceProject }) => {
  const service = useService()

  const { t } = useTranslation()

  const [params, setParams] = useState([{ project_id: sourceProject?.id }])

  const { toasts } = useToast()

  const fetch = async (params) => {
    const normalizedParams = [
      ...params,
      { archived: false },
      { is_orderable: true },
      { ipp: 5 },
      { has_damage_report: false },
    ]
    if (sourceProject) {
      const [result, error] = await service.get(
        `/projects/base-items?type=FORMWORK&type=RESOURCE`,
        normalizedParams
      )
      if (result) {
        result.data = result?.data?.map((e) => e.baseitem)
      }
      if (!result || !result.data.length) {
        toasts.warn(
          t('No items availabe at project [{{name}}]', {
            name: `${sourceProject?.cost_center} - ${sourceProject?.short_name}`,
          })
        )
      }

      return [result, error]
    }
    return await service.get(`/items`, normalizedParams)
  }

  useEffect(() => {
    setParams([{ project_id: sourceProject?.id }])
  }, [sourceProject])

  return (
    <DataSource params={params} fetch={fetch}>
      {({ data, loading }) => {
        const searchResults = data?.map((record, i) => (
          <CardView key={i} flex gap="10px">
            <Avatar name={record.name} id={record.image?.id} />
            <Container grow>
              <h3>{formatItemName(record)}</h3>
              <Row>
                <GridFieldView
                  n={4}
                  s={4}
                  label={t('Main group')}
                  value={record.maincategory}
                />
                <GridFieldView
                  n={4}
                  s={4}
                  label={t('Group')}
                  value={record.category}
                />
                <GridFieldView
                  n={4}
                  s={4}
                  label={t('Subgroup')}
                  value={record.subcategory}
                />
              </Row>
            </Container>
            <AddIconButton
              onClick={(e) => {
                e.stopPropagation()
                onSelect(record)
              }}
            />
          </CardView>
        ))
        return (
          <>
            <SearchInput />
            {data?.length > 0 ? (
              <>{searchResults}</>
            ) : loading ? (
              'Searching'
            ) : (
              `No articles are available at ${sourceProject?.name || 'Yard'}`
            )}
          </>
        )
      }}
    </DataSource>
  )
}

export const AdHocCRUDOverlay = ({
  title,
  item,
  onCreate,
  onUpdate,
  onDelete,
  onClose,
}) => {
  const { t } = useTranslation()

  // Local state in order to update item only when user presses action buttons
  const [state, setState] = useState({
    amount: item.amount,
    booking_end: item.booking_end,
  })

  const [childFormReady, setChildFormReady] = useState(false)

  // Keep values until user presses save/update buttons
  const storeChanges = ({ amount, booking_end }) => {
    if (amount || booking_end) {
      setState({ amount: amount, booking_end: booking_end })
    }
  }

  // Finally update the item when needed
  const updateItem = (item) => {
    item['amount'] = state.amount
    item['booking_end'] = state.booking_end
  }

  return (
    <OverlayForm
      data={{
        amount: state.amount >= 0 ? state.amount : 1,
        booking_end: state.booking_end,
      }}
      onClose={onClose}
      open={true}
      title={title}
    >
      {/* Load form fields and fill the form context accordingly */}
      <ItemSelectFormFields
        item={item}
        onChange={storeChanges}
        remove={onDelete}
        onReadyChanged={(ready) => setChildFormReady(ready)}
      />
      <OverlayFooter repel vertical={false} gap="10px">
        {onCreate && (
          <AddButton
            default
            disabled={!childFormReady}
            onClick={() => {
              updateItem(item)
              onCreate(item, false)
            }}
          />
        )}
        {onCreate && (
          <AddButton
            text={t('Add more')}
            disabled={!childFormReady}
            onClick={() => {
              updateItem(item)
              onCreate(item, true)
            }}
          />
        )}
        {onUpdate && (
          <SaveButton
            default
            disabled={!childFormReady}
            onClick={() => {
              updateItem(item)
              onUpdate(item)
            }}
          />
        )}
        {onDelete && <YesButton default onClick={() => onDelete(item)} />}
        {onDelete && <NoButton onClick={onClose} marginLeft="auto" />}
        {!onDelete && <CancelButton onClick={onClose} marginLeft="auto" />}
      </OverlayFooter>
    </OverlayForm>
  )
}

/**
 * Renders the pickup item overlay in the addhooc delivery.
 *
 * @param {any} item base item
 * @param {any} item to fill form fields
 *
 * @returns ReactElement
 */
const ItemSelectFormFields = ({
  item,
  onChange,
  onReadyChanged,
  edit = false,
  remove = false,
}) => {
  const { t } = useTranslation()
  const { ready, values } = useForm()

  // Flag for mandatory booking periods
  const bookingPeriodMandatory = item.is_largescale || item.is_formwork

  useEffect(() => {
    // Inform parent about change in booking_end and amount
    onChange({
      booking_end: values.json.booking_end,
      amount: values.json.amount,
    })
  }, [values.json.booking_end, values.json.amount])

  useEffect(() => {
    onReadyChanged(ready)
  }, [ready])

  return (
    <Row>
      <GridFieldView n={12} s={12} label={t('Item')} value={item?.name} />
      <GridFieldView
        n={6}
        s={6}
        label={t('Quantity unit')}
        value={item?.quantity_unit}
      />
      <GridFieldView
        n={6}
        s={6}
        label={t('Main group')}
        value={item?.maincategory}
      />
      <GridFieldView n={6} s={6} label={t('Group')} value={item?.category} />
      <GridFieldView
        n={6}
        s={6}
        label={t('Subgroup')}
        value={item?.subcategory}
      />
      <GridFieldView
        n={12}
        s={12}
        label={t('Amount') + (item?.is_resource ? '' : '*')}
      >
        <NumericField
          name="amount"
          disabled={item?.is_resource}
          mandatory={!edit}
        />
      </GridFieldView>
      {item && !item.is_consumable && (
        <GridFieldView n={12} s={12} label={t('Booking start')}>
          <DateField value={new Date()} disabled />
        </GridFieldView>
      )}
      {item && !item.is_consumable && (
        <GridFieldView n={12} s={12} label={t('Booking end')}>
          <DateField
            name="booking_end"
            forbidPast
            mandatory={bookingPeriodMandatory}
            disabled={remove}
          />
        </GridFieldView>
      )}
    </Row>
  )
}

/**
 * Renders the signature form overlay.
 *
 * @param {any} values form values from the addhook details page
 * @param {Array} items selected items for addhook delivery
 * @param {any} open state of opening the dialog
 * @param {any} onClose state of closing the dialog
 *
 * @returns ReactElement
 */
export const AdHocSignAndSubmitOverlay = ({
  formValues,
  items,
  open,
  onClose,
}) => {
  const { t } = useTranslation()
  const service = useService()
  const navigate = useNavigate()
  const handler = {}

  const handleSubmit = async (values) => {
    const requestBody = {
      orderer: values.json.recipient,
      signature: values.json.signature,
      project_id: formValues.json.project_id,
      items: items.map((i) => {
        let entry = {
          item_id: i.id,
          amount: i.amount,
        }
        if (i.booking_end) {
          entry['booking_end'] = i.booking_end
        }
        return entry
      }),
    }
    if (formValues.json.source_project_id) {
      requestBody['source_project_id'] = formValues.json.source_project_id
    }

    const [result, error] = await service.post(
      `kommission/delivery/adhoc`,
      requestBody
    )

    if (!error) {
      onClose()
      navigate(-1)
    }

    return [result, error]
  }

  const onOverlayClose = () => {
    clearSignature()
    onClose()
  }

  const clearSignature = async () => {
    handler.clear()
  }

  return (
    <OverlayForm
      open={open}
      title={t('Ad Hoc Delivery')}
      onClose={onOverlayClose}
      data={{
        signature: null,
        recipient: formValues.json.recipient,
        delivery_date: formValues.json.delivery_date,
      }}
    >
      <OverlayBody>
        <AdHocSignatureFormContent open={open} handler={handler} />
      </OverlayBody>
      <OverlayFooter grow repel>
        <ConfirmButton
          disabled={(ctx) => !ctx.ready || !ctx.values.json.signature}
          onClick={(e, ctx) => handleSubmit(ctx.values)}
        />
        <CancelButton text={t('Clear')} danger onClick={clearSignature} />
        <CancelButton onClick={onClose} />
      </OverlayFooter>
    </OverlayForm>
  )
}

/**
 * Renders the form content of the signature overlay.
 *
 * @param {bool} open is the dialog opened or not
 * @param {any} handler handler object for the signature field
 *
 * @returns ReactElement
 */
const AdHocSignatureFormContent = ({ open, handler }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <h5>{t('Recipient')}*</h5>
      <Container>
        <TextField autoFocus name="recipient" mandatory />
      </Container>
      <Container>
        <h5>{t('Date')}*</h5>
        <TextField name="delivery_date" disabled />
      </Container>
      {open && <SignatureField name="signature" handler={handler} />}
    </Container>
  )
}
