import { register } from 'system/container'
import { SystemPage } from 'system/page/core'

// Overview of the commission submodule page.
const RetourPage = () => (
  <SystemPage>
    <h1>You are in Retour module.</h1>
  </SystemPage>
)

register({
  menu: true,
  render: () => <RetourPage />,
  title: 'Retours',
  path: '/yard/retour',
  priority: 1.0,
})
