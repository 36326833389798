const _temp0 = require("../../../disposition/requests/change/module.js");
const _temp1 = require("../../../disposition/requests/new/module.js");


const _temp2 = require("../../../logistics/planning/transport/module.js");
const _temp3 = require("../../../logistics/planning/trip/module.js");


const _temp4 = require("../../../master-data/contacts/absence/module.js");
const _temp5 = require("../../../master-data/contacts/presents/module.js");
const _temp6 = require("../../../master-data/contacts/qualifications/module.js");
const _temp7 = require("../../../master-data/contacts/state/module.js");
const _temp8 = require("../../../master-data/contacts/tag/module.js");
const _temp9 = require("../../../master-data/contacts/teams/module.js");
const _temp10 = require("../../../master-data/contacts/view/module.js");

const _temp11 = require("../../../master-data/equipments/consumables/module.js");
const _temp12 = require("../../../master-data/equipments/formworks/module.js");
const _temp13 = require("../../../master-data/equipments/large-scale-equipment/module.js");
const _temp14 = require("../../../master-data/equipments/small-scale-equipment/module.js");


const _temp15 = require("../../../security/roles/view/module.js");


const _temp16 = require("../../../yard/commission/deliveries/module.js");

const _temp17 = require("../../../yard/retour/deliveries/module.js");


module.exports = {
  "disposition":   {
    "requests":     {
      "change": _temp0,
      "new": _temp1
    }
  },
  "logistics":   {
    "planning":     {
      "transport": _temp2,
      "trip": _temp3
    }
  },
  "master-data":   {
    "contacts":     {
      "absence": _temp4,
      "presents": _temp5,
      "qualifications": _temp6,
      "state": _temp7,
      "tag": _temp8,
      "teams": _temp9,
      "view": _temp10
    },
    "equipments":     {
      "consumables": _temp11,
      "formworks": _temp12,
      "large-scale-equipment": _temp13,
      "small-scale-equipment": _temp14
    }
  },
  "security":   {
    "roles":     {
      "view": _temp15
    }
  },
  "yard":   {
    "commission":     {
      "deliveries": _temp16
    },
    "retour":     {
      "deliveries": _temp17
    }
  }
}