import React from 'react'

import { Barcode } from 'common/widgets/barcode'
import { PrintIconButton } from 'common/widgets/button'
import { PrintContainer } from 'common/utils/print'

/**
 * Renders tab content for identification data
 *
 * @returns ReactElement
 */
export const EquipmentIdentificationView = ({ data, size }) => {
  const handler = {}
  return (
    <PrintContainer handler={handler}>
      <Barcode value={data.id} />
      <PrintIconButton onClick={() => handler.print()} />
    </PrintContainer>
  )
}
