import styled, { keyframes } from 'styled-components'
import fileDownload from 'js-file-download'
import { useTranslation } from 'react-i18next'
import { Download, Loader, Trash } from 'react-feather'
import { useState } from 'react'
import { FileIcon } from 'react-file-icon'

import { formatDate } from 'common/utils/format'
import { useService } from 'common/service/context'

import { AddIconButton, IconButton } from './button'
import { Container } from './container'
import { DataSource } from './data-source'
import { FileUploadOverlay, humanizeFileSize } from './file/upload'
import { SectionView } from './view'

/**
 * Renders document view widget
 * @param {string} docRef documents ref value
 * @param {bool} readonly is the in readonly state or not
 * @param {Function} onFileClick a callback function to be called when a file is clicked. it should accept file object as input.
 * @param {Function} onFileRemoved a callback function to be called when a file is removed. it should accept file id as input.
 * @returns ReactElement
 */
export const DocumentsView = ({
  docRef,
  readonly = false,
  onFileClick = null,
  onFileRemoved = null,
  ...rest
}) => {
  // Keeps a state for file overlay visible check
  const [fileOverlayVisible, setFileOverlayVisible] = useState(false)
  const service = useService()

  return (
    <DataSource url={`files/by-ref/${docRef}`}>
      {({ data, reload }) => (
        <DocumentsContainer flex wrap gap="20px" {...rest}>
          {data?.map((file) => (
            <FilePreview
              key={file.id}
              file={file}
              reload={reload}
              onClick={onFileClick}
              onRemoved={onFileRemoved}
            />
          ))}
          {!readonly && (
            <AddIconButton onClick={() => setFileOverlayVisible(true)} />
          )}
          <FileUploadOverlay
            open={fileOverlayVisible}
            onUpload={async (file, onUploadProgress) =>
              await handleUpload(
                service,
                docRef,
                file,
                reload,
                onUploadProgress
              )
            }
            onClose={() => setFileOverlayVisible(false)}
          />
        </DocumentsContainer>
      )}
    </DataSource>
  )
}

/**
 * Renders document view widget
 * @param {string} docRef documents ref value
 * @param {bool} readonly is the in readonly state or not
 * @param {Function} onFileClick a callback function to be called when a file is clicked. it should accept file object as input.
 * @param {Function} onFileRemoved a callback function to be called when a file is removed. it should accept file id as input.
 * @returns ReactElement
 */
export const DocumentsSectionView = ({
  docRef,
  readonly = false,
  onFileClick = null,
  onFileRemoved = null,
  ...rest
}) => {
  // Keeps a state for file overlay visible check
  const [fileOverlayVisible, setFileOverlayVisible] = useState(false)
  const service = useService()
  const { t } = useTranslation()

  return (
    <SectionView>
      <Container flex repel>
        <h1>{t('Documents')}</h1>
        {!readonly && (
          <AddIconButton
            tooltip={t('Add')}
            onClick={() => setFileOverlayVisible(true)}
          />
        )}
      </Container>
      <DataSource url={`files/by-ref/${docRef}`}>
        {({ data, reload }) => (
          <DocumentsContainer flex wrap gap="10px" {...rest}>
            {data?.map((file) => (
              <FilePreview
                key={file.id}
                file={file}
                reload={reload}
                onClick={onFileClick}
                onRemoved={onFileRemoved}
              />
            ))}
            <FileUploadOverlay
              open={fileOverlayVisible}
              onUpload={async (file, onUploadProgress) =>
                await handleUpload(
                  service,
                  docRef,
                  file,
                  reload,
                  onUploadProgress
                )
              }
              onClose={() => setFileOverlayVisible(false)}
            />
          </DocumentsContainer>
        )}
      </DataSource>
    </SectionView>
  )
}

/**
 * Uploads the given file as a document for contact.
 *
 * @param {any} file file object
 * @returns
 */
const handleUpload = async (
  service,
  docRef,
  file,
  reload,
  onUploadProgress
) => {
  var formData = new FormData()
  formData.append('file', file)
  formData.append('ref', docRef)
  formData.append('file_type', file.type)
  const [result, error] = await service.post(`files`, formData, {
    'Content-Type': 'multipart/form-data',
    onUploadProgress: onUploadProgress,
  })
  if (!error) {
    reload()
  }
  return [result, error]
}

/**
 * Returns file extension.
 * @param {string} file filename
 * @returns
 */
const getExtension = (file) => {
  const tokens = file.name.split('.')
  return tokens.length > 1 ? tokens.pop() : file.file_type
}

const getType = (file) => {
  const map = {
    jpg: 'image',
    png: 'image',
    bmp: 'image',
    jpeg: 'image',
    pdf: 'acrobat',
    blend: '3d',
    zip: 'compressed',
    mp3: 'audio',
    wav: 'audio',
    js: 'code',
    c: 'code',
    ttf: 'font',
    xls: 'spreadsheet',
    xlsx: 'spreadsheet',
    csv: 'spreadsheet',
    tsv: 'spreadsheet',
    txt: 'document',
  }
  const types = [
    '3d',
    'acrobat',
    'audio',
    'binary',
    'code',
    'code2',
    'compressed',
    'document',
    'drive',
    'font',
    'image',
    'presentation',
    'settings',
    'spreadsheet',
    'vector',
    'video',
  ]
  const extension = getExtension(file)
  const file_type = file.file_type === 'files' ? 'document' : file.file_type
  if (file_type === 'signature') {
    return 'image'
  }
  const type = map[extension]
  return types.includes(type) ? type : 'document'
}

const FilePreview = ({ file, reload, onClick, onRemoved }) => {
  // Keeps an state for busy flag
  const [busy, setBusy] = useState(false)
  const service = useService()

  /**
   * Calls a delete call and updates files.
   *
   * @returns
   */
  const handleDelete = async (e) => {
    e.stopPropagation()
    setBusy(true)
    await service.delete(`files/${file.id}`)
    await reload()
    if (onRemoved) {
      onRemoved(file.id)
    }
  }

  /**
   * Calls a download file.
   *
   * @returns
   */
  const handleDownload = async (e) => {
    e.stopPropagation()
    setBusy(true)
    const [result, error] = await service.get(`files/${file.id}`, null, {
      responseType: 'blob',
    })
    if (!error) {
      fileDownload(result.data, file.name)
    }
    setBusy(false)
  }
  return (
    <FilePreviewContainer onClick={() => (onClick ? onClick(file) : null)}>
      <FileDataContainer absolute flex vertical>
        <h4>{file.name}</h4>
        <h4>{humanizeFileSize(file.size)}</h4>
        <h5>{formatDate(file.uploaded_at)}</h5>
        {busy && <Spinner />}
      </FileDataContainer>
      {!busy && (
        <FileActionsContainer
          absolute
          flex
          gap="10px"
          justify="center"
          align="center"
        >
          <IconButton danger onClick={handleDelete}>
            <Trash />
          </IconButton>
          <IconButton default onClick={handleDownload}>
            <Download />
          </IconButton>
        </FileActionsContainer>
      )}
      <FileIcon extension={getExtension(file)} type={getType(file)} />
    </FilePreviewContainer>
  )
}

const FilePreviewContainer = styled(Container)`
  overflow: hidden;
  box-sizing: border-box;
  width: 96px;
  height: 120px;

  @media (max-width: 768px) {
    width: 84px;
    height: 100px;

    h5 {
      font-size: 10px;
    }
    h4 {
      font-size: 10px;
    }
  }
`
const FileDataContainer = styled(Container)`
  box-sizing: border-box;
  padding-left: 20px;
  box-sizing: border-box;
  width: 100px;
  height: 120px;
  overflow: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  @media (max-width: 768px) {
    width: 54px;
    height: 80px;
  }
`

const FileActionsContainer = styled(Container)`
  z-index: 1;
  padding: 10px;
  height: 150px;
  transition: 0.5s;

  div {
    width: 32px;
    height: 32px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  @media (max-width: 768px) {
    padding: 5px;
    height: 130px;
  }
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled(Loader)`
  color: #8b8b8b;
  margin: auto;
  animation: ${rotate} 1s linear infinite;
`

const DocumentsContainer = styled(Container)``
