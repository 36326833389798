import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import {
  Clipboard,
  Layers,
  Package,
  ShoppingCart,
  Star,
  Tool,
  Trello,
  Truck,
  Users,
} from 'react-feather'
import { useNavigate } from 'react-router-dom'

import { CommentView, GridFieldView } from 'common/widgets/view'
import { Container } from 'common/widgets/container'
import { useService } from 'common/service/context'
import { formatOrderNumber } from 'modules/orders/utils'
import {
  formatDeliveryNumber,
  formatBackDeliveryNumber,
} from 'modules/yard/utils'
import { Row } from 'common/widgets/grid'
import {
  formatItemType,
  getEquipmentNavPath,
} from 'modules/master-data/equipments/utils'
import { DropDown } from 'common/widgets/dropdown'
import { formatDate } from 'common/utils/format'
import { SystemOverlay } from 'common/widgets/overlay'

import styles from '../../system.module.css'

export const MasterSearchOverlay = ({ search, open, onClose }) => {
  const { t } = useTranslation()
  const service = useService()
  const [data, setData] = useState([])
  const [type, setType] = useState('all')
  const searchTypes = [
    { key: 'all', title: t('All') },
    { key: 'project', title: t('Projects') },
    { key: 'order', title: t('Orders') },
    { key: 'deliveryNote', title: t('Delivery notes') },
    { key: 'backDeliveryNote', title: t('Back delivery notes') },
    { key: 'user', title: t('Users') },
    { key: 'baseitem', title: t('Resources') },
    { key: 'task', title: t('Tasks') },
    { key: 'team', title: t('Teams') },
    { key: 'qualification', title: t('Qualification') },
  ]

  const reload = async () => {
    const fetch = async (key, url) => {
      const [result, error] = await service.get(url, [
        { search: search, ipp: 10 },
      ])
      return [key, result, error]
    }

    const apis = [
      { key: 'project', url: '/projects' },
      { key: 'order', url: '/orders' },
      { key: 'deliveryNote', url: '/kommission/delivery' },
      { key: 'backDeliveryNote', url: '/back/delivery' },
      { key: 'user', url: '/users' },
      { key: 'baseitem', url: '/items' },
      { key: 'task', url: '/tasks' },
      { key: 'team', url: '/teams' },
      { key: 'qualification', url: '/contacts/defs/qualification-categories' },
    ]

    const filterApi = () => {
      if (type !== 'all') {
        return apis.filter((e) => e.key === type)
      }

      return apis
    }

    // Runs request in parallel to fetch all required results
    const results = await Promise.all(
      filterApi().map((e) => fetch(e.key, e.url))
    )

    var mappedDataLength = 0

    const mappedData = results?.map((e) => {
      const [key, result, error] = e
      const data = error ? [] : result.data
      mappedDataLength += data.length
      return [key, data]
    })

    const normalizedData = []
    const maxItemsAllowed = Math.min(10, mappedDataLength)

    while (normalizedData.length < maxItemsAllowed) {
      for (const item of mappedData) {
        const [key, data] = item
        if (data.length > 0) {
          normalizedData.push([key, data.shift()])
        }
      }
    }
    setData(normalizedData)
  }

  // Registers an effect so when the dates or category changes it reloads data
  useEffect(() => {
    if (search?.length > 0) {
      reload()
    } else {
      setData(null)
    }
  }, [search, type])
  // Registers an effect so when the dialog is closed the filter is set to default value
  useEffect(() => {
    if (!open) setType('all')
  }, [open])

  return (
    <SystemOverlay
      name="search"
      open={open}
      onClose={onClose}
      title="Search results"
    >
      <DropDown
        title={t('All')}
        disabled={false}
        items={searchTypes}
        selectedIndex={searchTypes.findIndex((s) => s.key === type)}
        onSelectChange={(e) => setType(e.key)}
      />
      {data?.map((e, i) => {
        return (
          <Container key={i} divider>
            {e[0] === 'project' && (
              <ProjectView
                open={open}
                onClose={onClose}
                project={e[1]}
                search={search}
              />
            )}
            {e[0] === 'order' && (
              <OrderView
                open={open}
                onClose={onClose}
                order={e[1]}
                search={search}
              />
            )}
            {e[0] === 'deliveryNote' && (
              <DeliveryNoteView
                open={open}
                onClose={onClose}
                delivery={e[1]}
                search={search}
              />
            )}
            {e[0] === 'backDeliveryNote' && (
              <BackDeliveryNoteView
                open={open}
                onClose={onClose}
                backDelivery={e[1]}
                search={search}
              />
            )}
            {e[0] === 'user' && (
              <UserView
                open={open}
                onClose={onClose}
                user={e[1]}
                search={search}
              />
            )}
            {e[0] === 'baseitem' && (
              <BaseitemView
                open={open}
                onClose={onClose}
                baseitem={e[1]}
                search={search}
              />
            )}
            {e[0] === 'task' && (
              <TaskView
                open={open}
                onClose={onClose}
                task={e[1]}
                search={search}
              />
            )}
            {e[0] === 'team' && (
              <TeamView
                open={open}
                onClose={onClose}
                team={e[1]}
                search={search}
              />
            )}
            {e[0] === 'qualification' && (
              <QualificationView
                open={open}
                onClose={onClose}
                qualification={e[1]}
                search={search}
              />
            )}
          </Container>
        )
      })}
    </SystemOverlay>
  )
}

const ProjectView = ({ project, search, open, onClose }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const icon = <Star />
  return (
    <Container
      className={styles.searchResult}
      onClick={() => {
        navigate(`/projects/${project.id}`)
        onClose && onClose()
      }}
    >
      <Row>
        <GridFieldView n={12} s={12}>
          <Container>
            <CommentView icon={icon} comment={t('Project')} />
            <HighligtedText
              searchResults={[{ title: project.name }]}
              search={search}
            />
          </Container>
        </GridFieldView>
        {project.start_date && (
          <GridFieldView
            n={6}
            s={6}
            label="Start date"
            value={formatDate(project.start_date)}
          />
        )}
        {project.status && (
          <GridFieldView n={6} s={6} label="Status">
            <HighligtedText
              searchResults={[{ title: project.status }]}
              search={search}
            />
          </GridFieldView>
        )}
      </Row>
    </Container>
  )
}

const OrderView = ({ order, search, open, onClose }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Container
      className={styles.searchResult}
      onClick={() => {
        navigate(`/orders/${order.id}`)
        onClose && onClose()
      }}
    >
      <Row>
        <GridFieldView n={12} s={12}>
          <Container>
            <CommentView icon={<ShoppingCart />} comment={t('Order number')} />
            <HighligtedText
              searchResults={[{ title: formatOrderNumber(order?.number) }]}
              search={search}
            />
          </Container>
        </GridFieldView>
        <GridFieldView
          n={6}
          s={6}
          label="Ordered at"
          value={formatDate(order.ordered_at)}
        />
        {order.state && (
          <GridFieldView n={6} s={6} label="Status">
            <HighligtedText
              searchResults={[{ title: t(order.state) }]}
              search={search}
            />
          </GridFieldView>
        )}
      </Row>
    </Container>
  )
}

const DeliveryNoteView = ({ delivery, search, open, onClose }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <Container
      className={styles.searchResult}
      onClick={() => {
        navigate(`/yard/commission/deliveries/${delivery.id}`)
        onClose && onClose()
      }}
    >
      <Row>
        <GridFieldView n={12} s={12}>
          <Container>
            <CommentView icon={<Clipboard />} comment={t('Delivery number')} />
            <HighligtedText
              searchResults={[
                {
                  title: formatDeliveryNumber(
                    delivery?.order?.number,
                    delivery?.number
                  ),
                },
              ]}
              search={search}
            />
          </Container>
        </GridFieldView>
        <GridFieldView
          n={6}
          s={6}
          label="Created at"
          value={formatDate(delivery.created_at)}
        />
        {delivery.state && (
          <GridFieldView n={6} s={6} label="Status">
            <HighligtedText
              searchResults={[{ title: t(delivery.state) }]}
              search={search}
            />
          </GridFieldView>
        )}
      </Row>
    </Container>
  )
}

const BackDeliveryNoteView = ({ backDelivery, search, open, onClose }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <Container
      className={styles.searchResult}
      onClick={() => {
        navigate(`/yard/retour/deliveries/${backDelivery?.id}`)
        onClose && onClose()
      }}
    >
      <Row>
        <GridFieldView n={12} s={12}>
          <Container>
            <CommentView icon={<Truck />} comment={t('Delivery number')} />
            <HighligtedText
              searchResults={[
                { title: formatBackDeliveryNumber(backDelivery) },
              ]}
              search={search}
            />
          </Container>
        </GridFieldView>
        <GridFieldView
          n={6}
          s={6}
          label="Created at"
          value={formatDate(backDelivery.created_at)}
        />
        {backDelivery.state && (
          <GridFieldView n={6} s={6} label="Status">
            <HighligtedText
              searchResults={[{ title: t(backDelivery.state) }]}
              search={search}
            />
          </GridFieldView>
        )}
      </Row>
    </Container>
  )
}

const UserView = ({ user, search, open, onClose }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <Container
      className={styles.searchResult}
      onClick={() => {
        navigate(`/security/users/${user.id}`)
        onClose && onClose()
      }}
    >
      <Row>
        <GridFieldView n={12} s={12} label={''}>
          <Container>
            <CommentView icon={<Users />} comment={t('Contacts')} />
            <HighligtedText
              searchResults={[{ title: user.fullname }]}
              search={search}
            />
          </Container>
        </GridFieldView>
        <GridFieldView n={6} s={6} label="Created at" value={user.created_on} />
        {user.email && (
          <GridFieldView n={6} s={6} label="E-Mail">
            <HighligtedText
              searchResults={[{ title: user.email }]}
              search={search}
            />
          </GridFieldView>
        )}
      </Row>
    </Container>
  )
}

const BaseitemView = ({ baseitem, search, open, onClose }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const baseitemIcon = (equipment) =>
    equipment?.is_resource ? (
      equipment.is_largescale ? (
        <Trello />
      ) : (
        <Tool />
      )
    ) : equipment?.is_formwork ? (
      <Layers />
    ) : (
      <Package />
    )

  return (
    <Container
      className={styles.searchResult}
      onClick={() => {
        navigate(getEquipmentNavPath(baseitem))
        onClose && onClose()
      }}
    >
      <Row>
        <GridFieldView n={12} s={12} label="Item">
          <Container>
            <CommentView icon={baseitemIcon(baseitem)} comment={t('Item')} />
            <HighligtedText
              searchResults={[{ title: baseitem?.name }]}
              search={search}
            />
          </Container>
        </GridFieldView>
        {baseitem?.inventory_number && (
          <GridFieldView n={6} s={6} label="Inventory number">
            <HighligtedText
              searchResults={[{ title: baseitem?.inventory_number }]}
              search={search}
            />
          </GridFieldView>
        )}
        <GridFieldView n={6} s={6} label="Type">
          <HighligtedText
            searchResults={[{ title: t(formatItemType(baseitem)) }]}
            search={search}
          />
        </GridFieldView>
      </Row>
    </Container>
  )
}

const TaskView = ({ task, search, open, onClose }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Container
      className={styles.searchResult}
      onClick={() => {
        navigate(`/projects/tasks/${task.id}`)
        onClose && onClose()
      }}
    >
      <Row>
        <GridFieldView n={12} s={12} label="Task">
          <Container>
            <HighligtedText
              searchResults={[{ title: task?.title }]}
              search={search}
            />
          </Container>
        </GridFieldView>
        <GridFieldView n={6} s={12} label="Created by">
          <Container>
            <HighligtedText
              searchResults={[{ title: task?.creator_user?.fullname }]}
              search={search}
            />
          </Container>
        </GridFieldView>
        <GridFieldView n={6} s={12} label="Status">
          <Container>
            <HighligtedText
              searchResults={[{ title: t(task?.status) }]}
              search={search}
            />
          </Container>
        </GridFieldView>
        <GridFieldView n={6} s={12} label="Project">
          <Container>
            <HighligtedText
              searchResults={[{ title: task?.project?.name ?? '' }]}
              search={search}
            />
          </Container>
        </GridFieldView>
        <GridFieldView n={6} s={12} label="Phase">
          <Container>
            <HighligtedText
              searchResults={[{ title: task?.phase?.name ?? '' }]}
              search={search}
            />
          </Container>
        </GridFieldView>
      </Row>
    </Container>
  )
}

const TeamView = ({ team, search, open, onClose }) => {
  const navigate = useNavigate()

  return (
    <Container
      className={styles.searchResult}
      onClick={() => {
        navigate(`/master-data/contacts/teams/${team.id}`)
        onClose && onClose()
      }}
    >
      <Row>
        <GridFieldView n={12} s={12} label="Team">
          <Container>
            <HighligtedText
              searchResults={[{ title: team?.fullname ?? '' }]}
              search={search}
            />
          </Container>
        </GridFieldView>
        <GridFieldView
          n={12}
          s={12}
          label="Team size"
          value={
            team?.related_contacts.filter(
              (e) => e.relation_type === 'TEAM_MEMBER'
            )?.length
          }
        />
        <GridFieldView n={12} s={12} label="Comment">
          <Container>
            <HighligtedText
              searchResults={[{ title: team?.description ?? '' }]}
              search={search}
            />
          </Container>
        </GridFieldView>
      </Row>
    </Container>
  )
}

const QualificationView = ({ qualification, search, open, onClose }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Container
      className={styles.searchResult}
      onClick={() => {
        navigate(`/master-data/contacts/qualification/${qualification.id}`)
        onClose && onClose()
      }}
    >
      <Row>
        <GridFieldView n={12} s={12} label="Qualification">
          <Container>
            <HighligtedText
              searchResults={[{ title: qualification?.name ?? '' }]}
              search={search}
            />
          </Container>
        </GridFieldView>
        <GridFieldView
          n={6}
          s={12}
          label="Created by"
          value={qualification?.creator_user.fullname}
        />
        <GridFieldView
          n={6}
          s={12}
          label="Created at"
          value={formatDate(qualification?.created_at)}
        />
        <GridFieldView n={12} s={12} label="Comment">
          <Container>
            <HighligtedText
              searchResults={[{ title: t(qualification?.comment) ?? '' }]}
              search={search}
            />
          </Container>
        </GridFieldView>
      </Row>
    </Container>
  )
}

/**
 * Renders a text component which highlights a text which is included in it
 * @param {*} props properties object
 *
 * @returns ReactElement
 */
const HighligtedText = (props) => {
  return (
    <div>
      {props?.searchResults.length && (
        <div>
          {props.searchResults.slice(0, 15).map((value) => {
            const coloredResultPart = (result, query) => {
              let regex = new RegExp(query, 'ig')
              let results = result
                .replace(/- /g, '')
                .replace(/\./g, '')
                .split(regex)
              return [results, [...result.matchAll(regex)].map((x) => x[0])]
            }
            const [resultSplit, resultFind] = coloredResultPart(
              value.title,
              props.search
            )
            const makeColored = (text) => (
              <h4 style={{ color: '#376df4' }}>{text}</h4>
            )
            const colored = resultSplit.map((i, index) =>
              index ? (
                <span key={index}>
                  {makeColored(resultFind[index - 1])}
                  {i}
                </span>
              ) : (
                i
              )
            )

            return <h4 key={value.title}>{colored}</h4>
          })}
        </div>
      )}
    </div>
  )
}
