import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import { OverlayBody, OverlayFooter, OverlayForm } from 'common/widgets/overlay'
import { GridFieldView } from 'common/widgets/view'
import { Column, Row } from 'common/widgets/grid'
import {
  DateField,
  NumericField,
  TextAreaField,
} from 'common/widgets/form/field'
import { useService } from 'common/service/context'
import { useToast } from 'system/toast/context'
import { UserViewById } from 'modules/security/users/widgets'
import { dateToISOString } from 'common/utils/format'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { MemberSelect } from 'common/widgets/contacts'
import { DataSource } from 'common/widgets/data-source'
import { SearchInput } from 'common/widgets/search'
import { Box, Container } from 'common/widgets/container'

/**
 * Renders the Task hourly booking overlay
 * @param {any} task task object
 * @param {bool} open should the overlay be opened or not
 * @param {any} onClose closes the overlay
 * @param {any} onSubmit submits the booking
 *
 * @returns ReactElement
 */
export const BookingHoursOverlay = ({ task, open, onClose, onSubmit }) => {
  const { t } = useTranslation()
  const service = useService()
  const { toasts } = useToast()

  const handleConfirm = async (data) => {
    const [result, error] = await service.post(`tasks/${task.id}/time/`, {
      spent_time: data.spent_time,
      spent_at: dateToISOString(data.spent_at),
    })
    if (!error) {
      toasts.success(t('Time booking is confirmed!'))
      onClose()
      onSubmit()
    }
    return [result, error]
  }

  return (
    <OverlayForm
      open={open}
      onClose={onClose}
      title="Add hours"
      data={{ spent_time: 0, spent_at: new Date() }}
    >
      <Row>
        <GridFieldView n={12} s={12} label="Task" value={task?.title} />
        <GridFieldView n={12} s={12} label="Add hours to">
          <UserViewById userId={task.creator_user_id} />
        </GridFieldView>
        <GridFieldView n={12} s={12} label="Hours">
          <NumericField name="spent_time" mandatory />
        </GridFieldView>
        <GridFieldView n={12} s={12} label="Spent at">
          <DateField name="spent_at" mandatory excludeTime forbidFuture />
        </GridFieldView>
      </Row>
      <OverlayFooter>
        <SimpleFormAction onAdd={(e, ctx) => handleConfirm(ctx.values.json)} />
      </OverlayFooter>
    </OverlayForm>
  )
}

/**
 * Renders the creation of a task booking overlay.
 *
 * @param {any} booking booking object
 * @param {any} task task object
 * @param {any} project_id project id
 * @param {any} phase_id project phase id
 * @param {any} open state of opening the overlay
 * @param {any} onClose state of closing the dialog
 * @param {any} onSubmit submits the form
 * @param {any} onEdit
 *
 * @returns ReactElement
 */
export const CreateTaskBookingOverlay = ({
  booking,
  task,
  open,
  onClose,
  onSubmit,
  onEdit,
}) => {
  const [selectedMember, setSelectedMember] = useState(booking?.contact)
  const service = useService()
  const title = booking ? 'Edit personal booking' : 'Add personal booking'

  const fetch = async (params) =>
    await service.get(
      `/projects/bookings/contacts/assignable?task_id=${task.id}`,
      params
    )

  return (
    <OverlayForm
      title={title}
      data={{
        contact_id: booking?.contact.id,
        task_id: task?.id,
        booking_id: booking?.id,
        comment: booking?.booking?.comment,
      }}
      open={open}
      onClose={() => onClose()}
    >
      <OverlayBody style={{ padding: '0px', minWidth: '800px' }}>
        <Box nopadding top>
          <Row>
            {!booking && (
              <Column n={6} s={12}>
                <Box>
                  <Container style={{ paddingBottom: '5px' }}>
                    <SearchInput />
                  </Container>
                  <DataSource
                    fetch={fetch}
                    params={[{ archived: false }]}
                    limit={5}
                  >
                    {({ data }) =>
                      data?.map((e, i) => (
                        <MemberSelect
                          key={i}
                          disabled={booking}
                          selectedMember={selectedMember}
                          onSelectChange={() => setSelectedMember(e)}
                          contact={{
                            id: e.id,
                            system_user: e.system_user,
                            job_title: e.job_title,
                          }}
                        />
                      ))
                    }
                  </DataSource>
                </Box>
              </Column>
            )}
            <Column n={!booking ? 6 : 12} s={12}>
              <Box left>
                <TextAreaField name="comment" label="Description" rows={15} />
              </Box>
            </Column>
          </Row>
        </Box>
        <Box nopadding top>
          <BookingActions
            onAdd={!booking && onSubmit}
            onUpdate={booking && onEdit}
            selectedMember={selectedMember}
          />
        </Box>
      </OverlayBody>
    </OverlayForm>
  )
}

// This inner component is added so that the client could check if there is already assigned team leader to the team
// using the form values and the team members. If there is already assigned team leader the client will not allow
// the user to add more team leaders since one team can have only one or zero team leaders
const BookingActions = ({ onAdd, onUpdate, selectedMember }) => {
  // const ctx = useForm()

  return (
    <SimpleFormAction
      onAdd={onAdd}
      onUpdate={onUpdate}
      disabled={selectedMember === null}
      style={{ padding: '15px' }}
    />
  )
}
