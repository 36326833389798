import {
  ColorPickerField,
  TextField,
  TextAreaField,
} from 'common/widgets/form/field'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { SectionView } from 'common/widgets/view'

/**
 * Renders contact present's data entry form.
 *
 * @param {Function} onClose closing hook
 * @returns
 */
export const ContactQualificationFormContent = ({
  onAdd,
  onUpdate,
  onRemove,
}) => {
  return (
    <>
      <SectionView title="Qualification">
        <TextField name="name" mandatory label="Name" />
        <ColorPickerField name="color" label="Color" />
        <TextAreaField name="comment" rows="4" label="Description" />
      </SectionView>
      <SimpleFormAction onAdd={onAdd} onUpdate={onUpdate} onRemove={onRemove} />
    </>
  )
}
