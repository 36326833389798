import { useTranslation } from 'react-i18next'

import { DetailViewPage } from 'system/utils/view'
import { FieldView, FieldViewGroup, SectionView } from 'common/widgets/view'
import { Row, Column } from 'common/widgets/grid'
import { formatDate } from 'common/utils/format'
import { ColorPreview } from 'common/widgets/color'

export const ContactStateViewPage = () => {
  const { t } = useTranslation()

  return (
    <DetailViewPage
      url="/contacts/defs/states"
      navUrl="/master-data/contacts/states"
      title={(e) => e.name}
      removable
      editable
    >
      {(data, reload) => (
        <Row>
          <Column flex n={6}>
            <SectionView>
              <h2>{t('Master data')}</h2>
              <FieldViewGroup>
                <FieldView label={t('Name')} value={data?.name} />
                <FieldView label={t('Created at')}>
                  <p>{formatDate(data?.created_at)}</p>
                </FieldView>
              </FieldViewGroup>
              <FieldView label={t('Comment')}>
                <p>{data?.comment}</p>
              </FieldView>
            </SectionView>
          </Column>
          <Column flex n={6}>
            <SectionView>
              <h2>{t('Color')}</h2>
              {data?.color ? (
                <ColorPreview background={data?.color}>
                  <h1>{data?.color}</h1>
                </ColorPreview>
              ) : (
                '-'
              )}
            </SectionView>
          </Column>
        </Row>
      )}
    </DetailViewPage>
  )
}
