import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { de, enUS } from 'date-fns/locale'
//import * as Locales from 'date-fns/locale'
import { registerLocale, setDefaultLocale } from 'react-datepicker'

import { resources } from 'common/i18n/translation'

registerLocale('de', de)
registerLocale('en', enUS)

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
      lookupQuerystring: 'lng',
      lookupLocalStorage: 'lng',
      caches: ['localStorage'],
    },
    resources,
    defaultNS: 'common',
    fallbackNS: 'common',
    keySeparator: false, // we do not use keys in form messages.welcome
    // Use doulbe colons as namespace separator since colons are common in messages
    nsSeparator: '::',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

/**
 *
 * @param {string} language language code
 * @param {string} namespace namespace
 * @param {object} translation resource
 * @returns
 */
export const addTranslation = (language, namespace, translation) =>
  i18n.addResourceBundle(language, namespace, translation)

if (['de', 'en'].includes(i18n.language)) {
  setDefaultLocale(i18n.language)
} else {
  setDefaultLocale('de')
}

export default i18n
