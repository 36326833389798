import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Menu, ArrowDownRight, ArrowUpRight, X, Filter } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'common/query/context'
import { isNilOrEmpty } from 'common/utils/conditional'
import { IconButton } from 'common/widgets/button'
import { FieldViewContainer } from 'common/widgets/view'
import { Container } from 'common/widgets/container'

/**
 * Renders filterable field inside a data table.
 *
 * @param {string} fieldKey field key, used for sorting or filtering
 * @param {string} fieldValue field value, used for sorting or filtering
 * @param {string} label field label
 * @param {string} value field value
 * @param {Array} children react element children
 * @returns ReactElement
 */
export const FilterFieldView = ({
  fieldKey,
  fieldValue,
  sortKey,
  label,
  value,
  href,
  children,
  ...rest
}) => {
  const { t } = useTranslation()
  const query = useQuery()
  const filterable = fieldValue !== undefined ? true : false
  const direction = query.parameters.get(sortKey, true)?.direction
  const ValueWidget = () => {
    return href && !isNilOrEmpty(value) ? (
      <Link to={href} onClick={(e) => e.stopPropagation()}>
        {value}
      </Link>
    ) : (
      <p>{value || '-'}</p>
    )
  }
  return (
    <FieldViewContainer flex grow shrink href={href} {...rest}>
      <Container flex grow>
        <Container flex shrink>
          <LabelContainer align="center" grow>
            {label && <h5>{t(label)}</h5>}
            {filterable && query.sortOrders.get(fieldKey) && <Filter />}
            {query.sortOrders.get(fieldKey) == 'asc' && <ArrowDownRight />}
            {query.sortOrders.get(fieldKey) == 'desc' && <ArrowUpRight />}
            <ActionContainer flex justify="flex-end" top={label ? false : true}>
              {filterable && (
                <FilterIconButton
                  push
                  onClick={(e) => {
                    e.stopPropagation()
                    query.parameters.toggle(fieldKey, fieldValue)
                  }}
                >
                  <Filter
                    color={
                      query.parameters.get(fieldKey) ? '#12BF69' : '#808080'
                    }
                  />
                </FilterIconButton>
              )}
              {sortKey && (
                <FilterIconButton
                  push
                  onClick={(e) => {
                    e.stopPropagation()
                    query.sortOrders.toggle(sortKey)
                  }}
                >
                  {!direction && <ArrowDownRight />}
                  {direction === 'asc' && <ArrowUpRight />}
                  {direction === 'desc' && <Menu />}
                </FilterIconButton>
              )}
              {query.sortOrders.size > 0 && (
                <>
                  <FilterIconButton
                    onClick={(e) => {
                      e.stopPropagation()
                      query.sortOrders.clear()
                    }}
                  >
                    <X />
                  </FilterIconButton>
                </>
              )}
            </ActionContainer>
          </LabelContainer>
        </Container>
        <Container grow />
      </Container>
      {!children && <ValueWidget />}
      {children}
    </FieldViewContainer>
  )
}

const ActionContainer = styled(Container)`
  display: none;
  position: absolute;
  z-index: 1;
  ${(props) =>
    props.top
      ? css`
          margin-top: 24px;
          margin-left: 24px;
        `
      : css`
          margin-top: -48px;
          margin-left: -8px;
        `}
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  box-shadow: 0px 3px 24px #e0e0e0;
`

const LabelContainer = styled(Container)`
  transition: all 0.5s ease-out;

  svg {
    margin-left: 5px;
    width: 12px;
    height: 12px;
    color: #808080;
  }

  :hover {
    ${ActionContainer} {
      display: flex;
    }
    h5 {
      color: #303030;
    }
  }
`

const FilterIconButton = styled(IconButton)`
  border: 0;
  width: 32px;
  height: 32px;

  svg {
    margin: auto;
    width: 16px;
    height: 16px;
  }
`
