import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { App } from './app'
import { ErrorBoundary } from './error'
import reportWebVitals from './reportWebVitals'

// Import sub-modules by globbing. See
// [glob-primer](https://github.com/isaacs/node-glob#glob-primer) to learn more
// Dependenccies are loaded only from files named `module.js` up to 3 layers
// This is important to increase readability.
import './modules/*/module.js'
import './modules/*/*/module.js'
import './modules/*/*/*/module.js'

// Do the same for translations
import './modules/*/translation*.js'
import './modules/*/*/translation*.js'
import './modules/*/*/*/translation*.js'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </StrictMode>
)

// Registers for handling before install event for installing app on desktop or mobile
window.addEventListener('beforeinstallprompt', function (e) {
  e.userChoice.then(
    (choiceResult) => console.debug(choiceResult.outcome), // either "accepted" or "dismissed"
    (error) => console.error(error) // Just prints error
  )
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.debug)

/* Only register a service worker if it's supported */
/* navigator is only availabe in secureContext mode, see below MDN docs
https://developer.mozilla.org/en-US/docs/Web/Security/Secure_Contexts */
if (window.isSecureContext && 'serviceWorker' in navigator) {
  navigator.serviceWorker.register(
    new URL('service-worker.js', import.meta.url),
    { type: 'module' }
  )
}
