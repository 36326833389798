import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { useService } from 'common/service/context'
import { Form } from 'common/widgets/form/context'
import { SystemPage } from 'system/page/core'

import { LargeScaleEquipmentFormContent } from './form'

/**
 * Renders page for adding a large scale equipment
 *
 * @returns ReactElement
 */
export const LargeScaleEquipmentAddPage = () => {
  const service = useService()
  const { id } = useParams()
  const navigate = useNavigate()

  // state to update form after the service call
  const [formData, setFormData] = useState(null)
  /**
   * Calls a post call and creates a new large scale equipment.
   *
   * @param {any} values user data
   * @returns
   */
  const handleAdd = async (values) => {
    // add DISPOSITION value of field process to values
    values['process'] = 'DISPOSITION'
    // call api
    return await service.post('items/resources/', values)
  }

  const handleClone = async (values) => {
    return await service.post(`items/resources/${id}/clone`, values)
  }

  const handleSuccess = async (response) => {
    navigate(`/master-data/equipments/large-scales/${response.data.id}`)
  }

  if (id && !formData) {
    service.get(`items/resources/${id}`).then(([result, error]) => {
      if (!error) {
        const data = result.data
        data.id = null
        data.telematics_id = null
        setFormData(data)
      }
    })
  }

  return (
    <SystemPage>
      <Form
        data={{
          engine_power: null,
          avg_fuel_consumption: null,
          calculated_depreciation: null,
          balance_sheet_depreciation: null,
          cost_rate: 0,
          ...formData,
        }}
      >
        <LargeScaleEquipmentFormContent
          onAdd={id ? null : handleAdd}
          onClone={id ? handleClone : null}
          onSuccess={handleSuccess}
        />
      </Form>
    </SystemPage>
  )
}
