import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'
import { FreeChooseField } from 'common/widgets/form/field'

/**
 * Renders a telematics id free choose widget.
 *
 * @param {Array} params filter parameters
 * @param {object} handler handler to control params and loadings
 * @param {Function} onSelect gets called when a project selected
 * @returns
 */
export const AccountingGroupField = ({
  handler,
  name = 'accounting_group',
  params,
  ...rest
}) => {
  const service = useService()

  /**
   * Calls project apis and collects data.
   *
   * @returns [any, any, int]
   */
  const fetch = async () => await service.get('items/accounting_groups', params)

  const render = ({ data, error, loading }) => {
    const items = data || []
    return <FreeChooseField name={name} items={items} {...rest} />
  }

  return <DataSource fetch={fetch} handler={handler} render={render} />
}
