.rateLabelBack {
  display: flex;
  width: 100px;
  height: 20px;
  position: absolute;
  overflow: hidden;
}

.rateLabelFront {
  height: 20px;
  position: absolute;
  overflow: hidden;
}

.contactRowView {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-right: 10px;
}

.tagCard {
  display: flex;
  background: #ffffff;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
}
.tagCard h4 {
  flex: 1;
}

.contactTagColor {
  border-radius: 5px 0px 0px 5px;
  width: 10px;
}

.star {
  width: 20px !important;
  height: 20px !important;
}
