import { useTranslation } from 'react-i18next'

import { CommentView, GridFieldView, SectionView } from 'common/widgets/view'
import { Column, Row } from 'common/widgets/grid'
import { formatDate } from 'common/utils/format'
import { UserView } from 'modules/security/users/widgets'
import { SingleImageView } from 'common/widgets/image'

export const ProjectMasterDataView = ({ data }) => {
  const { t } = useTranslation()
  return (
    <SectionView title="Master data">
      <Row>
        <Column n={3} m={12} s={12}>
          <SingleImageView imageRef={data.image_ref} name={data.name} />
        </Column>
        <Column n={9} m={12} s={12}>
          <Row>
            <GridFieldView n={6} s={6} label="Name" value={data.name} />
            <GridFieldView
              n={6}
              s={6}
              label="Short name"
              value={data.short_name}
            />
            <GridFieldView
              n={6}
              s={6}
              label="Start"
              value={formatDate(data.start_date)}
            />
            <GridFieldView
              n={6}
              s={6}
              label="End"
              value={formatDate(data.end_date, { friendly: true })}
            />
            <GridFieldView
              n={6}
              s={6}
              label="Shortest distance"
              value={data.shortest_distance}
            />
            <GridFieldView
              n={6}
              s={6}
              label="Subsistence"
              value={t(data.subsistence)}
            />
            <GridFieldView
              n={6}
              s={6}
              label="Created at"
              value={formatDate(data.created_at)}
            />
            <GridFieldView
              n={6}
              s={6}
              label="Created by"
              value={<UserView user={data.creator_user} />}
            />
          </Row>
        </Column>
      </Row>
      <CommentView comment={data.comment} />
    </SectionView>
  )
}

export const ProjectBillingView = ({ data }) => {
  return (
    <SectionView title="Billing data">
      <Row>
        <GridFieldView
          n={6}
          s={6}
          label="Cost center"
          value={data.cost_center}
        />
        <GridFieldView
          n={6}
          s={6}
          label="Contract volume"
          value={data.contract_volume}
        />
        <GridFieldView n={6} s={6} label="Rent rate" value={data.rent_rate} />
        <GridFieldView
          n={6}
          s={6}
          label="Loading rate"
          value={data.loading_rate}
        />
      </Row>
    </SectionView>
  )
}
