import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

export const Select = ({
  items,
  selected,
  onSelectChange,
  disabled,
  toggle,
}) => {
  const { t } = useTranslation()
  // Maps available items to dropdown menu items
  const children = items.map((e, i) => {
    /**
     * Handles click on the dropdown item.
     */
    const handleClick = () => {
      if (selected !== e.key) {
        if (onSelectChange) {
          onSelectChange(e, i)
        }
        if (e.onSelect) {
          e.onSelect(e)
        }
      } else if (toggle) {
        if (onSelectChange) {
          onSelectChange({ key: null }, i)
        }
        if (e.onSelect) {
          e.onSelect({ key: null })
        }
      }
    }

    return (
      <SelectItem
        // TODO: how will this work for multiple selects?
        selected={selected == e.key}
        onClick={!disabled ? handleClick : undefined}
        key={i}
      >
        {
          // Encapsulates render function of dropdown element,
          // if there is no render function inside element, renders text and icon.
          e.render ? e.render(e) : <p>{t(e.title)}</p>
        }
      </SelectItem>
    )
  })

  return <SelectContainer>{children}</SelectContainer>
}

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;

  @media print {
    display: none;
  }
`

const SelectItem = styled.div`
  display: flex;
  box-sizing: border-box;
  min-width: 120px;
  height: 40px;
  border-radius: 5px;
  transition: 0.2s ease-out;
  align-items: center;
  padding: 0 10px 0 10px;
  user-select: none;

  background: ${(props) => (props.selected ? '#E8EEFD' : '#FFFFFF')};
  border: 1px solid ${(props) => (props.selected ? '#4A7BF5' : '#BABABA')};

  svg {
    margin-left: 10px;
    color: ${(props) => (props.selected ? '#376DF4' : '#8B8B8B')};
    width: 18px;
    height: 18px;
  }

  p {
    margin: auto;
    color: ${(props) => (props.selected ? '#376DF4' : '#8B8B8B')};
  }

  :hover {
    cursor: pointer;
    background: #4a7bf5;
    p {
      color: #ffffff;
    }
    svg {
      color: #ffffff;
    }
  }
`
