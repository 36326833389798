import { useState } from 'react'
import { Minus, Plus } from 'react-feather'
import styled, { css } from 'styled-components'

import { Container } from 'common/widgets/container'

export const ExpandableListItem = ({
  renderHeader,
  children,
  defaultValue = false,
}) => {
  const [expanded, setExpanded] = useState(defaultValue)

  return (
    <ListItemContainer vertical>
      <Header flex onClick={() => setExpanded(!expanded)}>
        <IconContainer>{expanded ? <MinusIcon /> : <PlusIcon />}</IconContainer>
        <ListItemHeaderContainer expanded={expanded}>
          {renderHeader && renderHeader()}
        </ListItemHeaderContainer>
      </Header>
      {expanded && <Body>{children}</Body>}
    </ListItemContainer>
  )
}

export const ExpandableListSubItem = ({
  renderHeader,
  children,
  defaultValue = false,
  isLastItem,
}) => {
  const [expanded, setExpanded] = useState(defaultValue)

  return (
    <ListSubItemContainer expanded={expanded} isLastItem={isLastItem} vertical>
      <Header flex onClick={() => setExpanded(!expanded)}>
        <IconContainer>{expanded ? <MinusIcon /> : <PlusIcon />}</IconContainer>
        <ListSubItemHeaderContainer expanded={expanded} isLastItem={isLastItem}>
          {renderHeader && renderHeader()}
        </ListSubItemHeaderContainer>
      </Header>
      {expanded && <Body>{children}</Body>}
    </ListSubItemContainer>
  )
}

export const Expandable = ({
  renderHeader,
  children,
  defaultValue = false,
}) => {
  const [expanded, setExpanded] = useState(defaultValue)
  return (
    <MainContainer vertical>
      <Header flex onClick={() => setExpanded(!expanded)}>
        {expanded ? <MinusIcon /> : <PlusIcon />}
        {renderHeader ? renderHeader() : <p>header</p>}
      </Header>
      {expanded && <Body>{children}</Body>}
    </MainContainer>
  )
}

const MinusIcon = styled(Minus)`
  color: #8b8b8b;
`

const PlusIcon = styled(Plus)`
  color: #8b8b8b;
`

const ListItemContainer = styled(Container)`
  border-top: 1px solid #e0e0e0;
  :last-child {
    border-bottom: 1px solid #e0e0e0;
  }
`

const IconContainer = styled.div`
  padding: 15px;
`

const ListItemHeaderContainer = styled.div`
  padding-top: 15px;
  ${(props) =>
    props.expanded &&
    css`
      border-bottom: 1px solid #e0e0e0;
    `}
  flex: 1;
`

const ListSubItemHeaderContainer = styled.div`
  padding-top: 15px;
  ${(props) =>
    props.expanded &&
    css`
      border-bottom: 1px solid #e0e0e0;
    `}
  flex: 1;
`

const ListSubItemContainer = styled(Container)`
  ${(props) =>
    !props.isLastItem &&
    !props.expanded &&
    css`
      border-bottom: 1px solid #e0e0e0;
    `}
`

const MainContainer = styled(Container)``

const Header = styled(Container)`
  display: flex;
  flex: 1;
  cursor: pointer;
  :hover {
    background: #e5e8eb;
    color: #303030;
  }
`

const Body = styled(Container)`
  padding-left: 54px;
`
