.CART {
  color: #888888;
}
.OPEN {
  color: #aec4fa;
}
.IN_PROGRESS {
  color: #f9f29a;
}
.COMPLETE {
  color: #95e68b;
}
.CANCELED {
  color: #fc4242;
}

.statCard {
  border: 1px solid #ffffff;
  border-radius: 10px;
  height: 120px;
  padding: 10px;
}

.statCard h1 {
  font-size: 72px;
  line-height: 72px;
  color: inherit;
}
.statCard h2 {
  font-size: 24px;
  line-height: 24px;
}

@media (max-width: 768px) {
  .statCard {
    flex: 1 1 auto;
  }
  .statCard h1 {
    font-size: 48px;
    line-height: 48px;
  }
  .statCard h2 {
    font-size: 21px;
    line-height: 21px;
  }
}

.orderRelatedItems > div {
  margin: 0;
  padding: 0;
  overflow: scroll;
}
