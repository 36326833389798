import { OverlayBody, OverlayForm } from 'common/widgets/overlay'
import { Row } from 'common/widgets/grid'
import { GridFieldView } from 'common/widgets/view'
import { DateField, FileField, TextAreaField } from 'common/widgets/form/field'
import { formatDate } from 'common/utils/format'
import { SimpleFormAction } from 'common/widgets/form/utils'

/**
 * Renders the assign qualification overlay.
 *
 * @param {any} open state of opening the overlay
 * @param {any} qualification qualification object
 * @param {any} contact contact object
 * @param {any} onClose state of closing the dialog
 * @param {any} onSubmit submits the form
 *
 * @returns ReactElement
 */
export const AssignQualificationOverlay = ({
  open,
  qualification,
  contact,
  onClose,
  onSubmit,
}) => {
  return (
    <OverlayForm
      title="Assign Qualification"
      data={{
        contact_id: contact.id,
        category_id: qualification.id,
        name: qualification.name,
        comment: qualification?.comment,
        expires_on: qualification?.expires_on,
      }}
      open={open}
      onClose={() => onClose()}
    >
      <OverlayBody>
        <Row style={{ paddingBottom: '10px' }}>
          <GridFieldView
            n={12}
            s={6}
            label="Qualification"
            value={qualification?.name}
          />
          <GridFieldView
            n={6}
            s={6}
            label="Created at"
            value={formatDate(qualification?.created_at)}
          />
          <GridFieldView
            n={6}
            s={6}
            label="Created by"
            value={qualification.creator_user.fullname}
          />
          <GridFieldView n={12} s={12}>
            <DateField name="expires_on" label="Expiration date" mandatory />
          </GridFieldView>
          <GridFieldView n={12} s={12}>
            <TextAreaField name="comment" rows="4" label="Comment" />
          </GridFieldView>
          <GridFieldView n={12} s={12}>
            <FileField name="attachments" />
          </GridFieldView>
        </Row>
        <SimpleFormAction onAdd={onSubmit} />
      </OverlayBody>
    </OverlayForm>
  )
}
