import { useTranslation } from 'react-i18next'

import styles from './legend.module.css'

export const Legend = ({ text, color, selected, onClick, ...rest }) => {
  const { t } = useTranslation()
  return (
    <div
      className={styles.legend}
      onClick={onClick}
      style={{
        cursor: onClick ? 'pointer' : 'inherit',
      }}
      {...rest}
    >
      <div className={styles.colorBox} style={{ background: color }}></div>
      <h4 style={{ fontWeight: selected ? '800' : 'inherit' }}>{t(text)}</h4>
    </div>
  )
}
