import React from 'react'
import { useTranslation } from 'react-i18next'

import { DataSource } from 'common/widgets/data-source'
import { formatDate } from 'common/utils/format'
import { Box, Container } from 'common/widgets/container'
import { Card } from 'common/widgets/card'
import Rain from 'assets/weather/rain1.svg'
import Fog from 'assets/weather/fog_clouds.svg'
import Snow from 'assets/weather/snow.svg'
import Thunderstorm from 'assets/weather/storm_rain_snow_light.svg'
import Sunny from 'assets/weather/sunny.svg'
import Hail from 'assets/weather/hail.svg'
import SunClouds from 'assets/weather/sun_clouds.svg'
import Clouds from 'assets/weather/clouds.svg'
import Sleet from 'assets/weather/sleet.svg'

export const ProjectForecast = ({ project }) => {
  const { t } = useTranslation()

  const handleWeatherConditionAsset = (icon) => {
    const style = { marginTop: '10px', marginBottom: '10px', width: '50px' }
    if (icon === 'clear-day' || icon === 'clear-night') {
      return <Sunny style={style} />
    } else if (icon === 'fog') {
      return <Fog style={style} />
    } else if (icon === 'rain') {
      return <Rain style={style} />
    } else if (icon === 'sleet') {
      return <Sleet style={style} />
    } else if (icon === 'snow') {
      return <Snow style={style} />
    } else if (icon === 'hail') {
      return <Hail style={style} />
    } else if (icon === 'thunderstorm') {
      return <Thunderstorm style={style} />
    } else if (icon === 'partly-cloudy-day' || icon === 'partly-cloudy-night') {
      return <SunClouds style={style} />
    } else if (icon === 'cloudy') {
      return <Clouds style={style} />
    }

    return null
  }

  return (
    <DataSource url={`/projects/${project.id}/weather/forecast/summary`}>
      {({ data }) => {
        return (
          <Card flex grow gap="10px" style={{ background: '#376DF4' }}>
            {!data['weather'] && (
              <Container
                flex
                grow
                style={{ alignItems: 'center', justifyContent: 'center' }}
              >
                <h1 style={{ color: 'white' }}>
                  {t('No weather data available')}
                </h1>
              </Container>
            )}
            {data?.weather
              ?.filter((r, i) => i !== data.weather.length - 1)
              ?.map((e, i) => {
                return (
                  <Container
                    key={i}
                    flex
                    style={{ minWidth: '105px', alignItems: 'start' }}
                  >
                    <Box
                      nowidth
                      nopadding
                      right
                      color="#FFFFFF"
                      style={{ opacity: '0.5' }}
                    />
                    <Container
                      style={{ alignItems: 'center', padding: '10px' }}
                    >
                      <h4 style={{ color: '#FFFFFF' }}>
                        {i === 0 ? t('Today') : formatDate(e.date)}
                      </h4>
                      {handleWeatherConditionAsset(e.icon)}
                      <h5
                        style={{ color: '#FFFFFF', opacity: '0.5' }}
                      >{`${e.temperature.min}°C - ${e.temperature.max}°C`}</h5>
                    </Container>
                  </Container>
                )
              })}
          </Card>
        )
      }}
    </DataSource>
  )
}
