import {
  ColorPickerField,
  TextField,
  TextAreaField,
} from 'common/widgets/form/field'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { SectionView } from 'common/widgets/view'

import { ContactTagDropDownField } from './widgets/dropdown'

export const ContactTagFormContent = ({ id, onAdd, onUpdate, onRemove }) => {
  return (
    <>
      <SectionView title="Master data">
        <TextField name="name" label="Name" mandatory />
        <ContactTagDropDownField
          name="parent_id"
          label="Parent"
          minWidth="300px"
          excludes={[id ? parseInt(id) : null]}
        />
        <ColorPickerField name="color" label="Color" />
        <TextAreaField name="comment" label="Comment" rows="5" />
      </SectionView>
      <SimpleFormAction onUpdate={onUpdate} onRemove={onRemove} onAdd={onAdd} />
    </>
  )
}
