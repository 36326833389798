import React, { useState, useEffect } from 'react'

import { useForm } from 'common/widgets/form/context'
import { useService } from 'common/service/context'
import { NumericField } from 'common/widgets/form/field'

export const ProjectPhasePlan = ({
  name,
  projectTypeId,
  phasePlans = [],
  onChange,
}) => {
  const [phases, setPhases] = useState([])
  const service = useService()

  const fetch = async () => {
    const [response, error] = await service.get('projects/phases', [
      { project_type_id: projectTypeId },
    ])
    if (!error) {
      setPhases(response.data)
    }
  }

  useEffect(() => {
    if (projectTypeId) {
      fetch()
    }
  }, [projectTypeId])

  return (
    <>
      {phases
        ?.sort((a, b) => a.name >= b.name)
        ?.map((phase, index) => {
          return (
            <SinglePhasePlan
              key={index}
              index={index}
              phase={phase}
              phasePlan={phasePlans?.find((i) => i.phase_id == phase.id)}
              onChange={(newPhasePlan) => {
                const prevPlanIndex = phasePlans.findIndex(
                  (i) => i.phase_id == newPhasePlan.phase_id
                )
                if (prevPlanIndex >= 0) {
                  phasePlans[prevPlanIndex] = newPhasePlan
                } else {
                  phasePlans.push(newPhasePlan)
                }
                if (onChange) {
                  return onChange(phasePlans)
                }
              }}
            />
          )
        })}
    </>
  )
}

const SinglePhasePlan = ({ index, phase, phasePlan, onChange }) => {
  const ctx = useForm()
  return (
    <fieldset>
      <legend>{phase?.name}</legend>
      <NumericField
        name={`${index}_planned_hours`}
        label="Planned hours"
        defaultValue={phasePlan?.planned_hours}
        onChange={(planned_hours) => {
          if (!onChange) {
            return
          }
          onChange({
            ...phasePlan,
            phase_id: phase?.id,
            planned_hours,
            planned_budget: ctx.values.get(`${index}_planned_budget`),
          })
        }}
        ignore
      />
      <NumericField
        name={`${index}_planned_budget`}
        label="Planned budget"
        defaultValue={phasePlan?.planned_budget}
        onChange={(planned_budget) => {
          if (!onChange) {
            return
          }
          onChange({
            ...phasePlan,
            phase_id: phase?.id,
            planned_hours: ctx.values.get(`${index}_planned_hours`),
            planned_budget,
          })
        }}
        ignore
      />
    </fieldset>
  )
}
