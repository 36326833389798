import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { CancelButton, SubmitButton, SaveButton } from 'common/widgets/button'
import { Container } from 'common/widgets/container'
import { ProjectSelectField } from 'modules/projects/widgets/select'
import { OrderStateEnum, formatOrderNumber } from 'modules/orders/utils'
import { Row, Column } from 'common/widgets/grid'
import { useService } from 'common/service/context'
import { SelectField, TextAreaField } from 'common/widgets/form/field'
import { Form, useForm } from 'common/widgets/form/context'
import { SectionView, CommentView } from 'common/widgets/view'

import { OrderedItem } from './item'

// delay in miliseconds for update api call debouncing
const TIMEOUT = 1000

export const BasketForm = ({ order, reload, onNewItemClick, ...rest }) => {
  const service = useService()

  if (!order) {
    return 'Loading cart...'
  }

  return (
    <Container flex vertical gap="10px" {...rest}>
      <Form
        data={{
          requires_delivery: order?.requires_delivery,
          // null is a valid value accepted by the form context, we replace it
          // with undefined, because we want to enforce validation for it.
          project_id: order?.project_id ? order.project_id : undefined,
          comment: order?.comment,
        }}
      >
        <BasketFormFields
          order={order}
          reload={reload}
          update={async (data) => await service.put(`orders/${order.id}`, data)}
          deleteItem={async (item) =>
            await service.delete(`orders/${order.id}/items/${item.id}`)
          }
          clear={async () => await service.put(`orders/${order.id}/clear`)}
          onNewItemClick={onNewItemClick}
        />
      </Form>
    </Container>
  )
}

/**
 * Renders current basket and items inside.
 *
 * @returns ReactElement
 */
const BasketFormFields = ({
  order,
  reload,
  update,
  deleteItem,
  clear,
  onNewItemClick,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['orders'])
  const { values, ready, dirty } = useForm()
  const [timeout, setTimeoutState] = useState(null)

  // Exclude irrelevant fields from being sent to the API
  const excludeValues = (values) => {
    if (!values.get('requires_delivery')) {
      return values.exclude(['items'])
    }
    return values.exclude(['items'])
  }

  const handleSubmit = async () => {
    const [, error] = await update({
      ...excludeValues(values),
      state: OrderStateEnum.OPEN,
    })
    if (!error) {
      navigate('/orders/')
    }
  }

  const handleSave = async () => {
    const [, error] = await update({ ...excludeValues(values) })
    if (!error) {
      navigate('/orders/overview')
    }
  }

  const handleClear = async () => {
    const [response, error] = await clear()
    if (!error) {
      await reload()
      return [response, error]
    }
  }

  const handleDelete = async (props) => {
    const [response, error] = await deleteItem(props)
    if (!error) {
      await reload()
      return [response, error]
    }
  }

  // Update cart info anytime user inputs or changes any of these fields.
  // The update always occurs after a delay to prevent continues service calls
  // when user changes values rapidly.
  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout)
    }
    setTimeoutState(
      setTimeout(() => (dirty ? update(values.dirty) : null), TIMEOUT)
    )

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [
    values.get('project_id'),
    values.get('requires_delivery'),
    values.get('comment'),
  ])

  return (
    <>
      <OrderData
        order={order}
        onNewItemClick={onNewItemClick}
        reload={reload}
      />
      <OrderItemList order={order} deleteItem={handleDelete} />
      <Container flex gap="10px">
        {order?.draft && (
          <>
            <SubmitButton
              default
              disabled={(ctx) =>
                !(ctx.ready && order?.ordered_items?.length > 0)
              }
              onClick={handleSubmit}
              text={t('Place order')}
            />
            <CancelButton
              danger
              disabled={order?.ordered_items?.length === 0}
              onClick={handleClear}
              text={t('Clear cart')}
              animate
            />
          </>
        )}
        {!order?.draft && order?.editable && (
          <SaveButton default disabled={!ready} onClick={handleSave} animate />
        )}
      </Container>
    </>
  )
}

const OrderItemList = ({ order, deleteItem }) => {
  const navigate = useNavigate()
  // Get translation context.
  const { t } = useTranslation(['orders'])
  // Determines that order is editable or not
  const editable = order?.editable
  // Groups items by their type
  const consumables = order?.ordered_items.filter((e) => e.item.is_consumable)
  const resources = order?.ordered_items.filter((e) => e.item.is_resource)
  const formworks = order?.ordered_items.filter((e) => e.item.is_formwork)

  return (
    <>
      {consumables?.length > 0 && (
        <Container flex vertical gap="5px">
          <h2>{t('Consumables')}</h2>
          {consumables.map((e) => (
            <OrderedItem
              key={e.id}
              order={order}
              data={e}
              onClick={
                editable
                  ? () =>
                      navigate(
                        `/orders/${order.draft ? 'cart/' : ''}${
                          order.id
                        }/edit/${e.id}`
                      )
                  : undefined
              }
              onDelete={
                editable
                  ? (e, item) => {
                      e.stopPropagation()
                      deleteItem(item)
                    }
                  : undefined
              }
            />
          ))}
        </Container>
      )}
      {resources?.length > 0 && (
        <Container flex vertical gap="5px">
          <h2>{t('Resources')}</h2>
          {resources.map((e) => (
            <OrderedItem
              key={e.id}
              order={order}
              data={e}
              onClick={
                editable
                  ? () =>
                      navigate(
                        `/orders/${order.draft ? 'cart/' : ''}${
                          order.id
                        }/edit/${e.id}`
                      )
                  : undefined
              }
              onDelete={
                editable
                  ? (e, item) => {
                      e.stopPropagation()
                      deleteItem(item)
                    }
                  : undefined
              }
            />
          ))}
        </Container>
      )}
      {formworks?.length > 0 && (
        <Container>
          <h2>{t('Formworks')}</h2>
          {formworks.map((e) => (
            <OrderedItem
              key={e.id}
              order={order}
              data={e}
              onClick={
                editable
                  ? () =>
                      navigate(
                        `/orders/${order.draft ? 'cart/' : ''}${
                          order.id
                        }/edit/${e.id}`
                      )
                  : undefined
              }
              onDelete={
                editable
                  ? (e, item) => {
                      e.stopPropagation()
                      deleteItem(item)
                    }
                  : undefined
              }
            />
          ))}
        </Container>
      )}
    </>
  )
}

const OrderData = ({ order, reload, onNewItemClick }) => {
  // Get translation context.
  const { t } = useTranslation(['orders'])

  return (
    <SectionView>
      <Container flex repel align="center">
        <h1 style={{ margin: 0, padding: 0 }}>
          {order?.draft ? t('Cart') : formatOrderNumber(order?.number)}
        </h1>
      </Container>
      <Row>
        <Column n={12} m={12} s={12}>
          <h5>{t('Project')}</h5>
          <ProjectSelectField
            name="project_id"
            disabled={!order?.editable || order?.has_booking}
            mandatory
          />
        </Column>
        <Column n={12} m={12} s={12}>
          <h5>{t('Picking Method')}</h5>
          <SelectField
            disabled={!order?.editable}
            name="requires_delivery"
            items={[
              { key: false, title: t('Selfpick') },
              { key: true, title: t('Delivery') },
            ]}
          />
        </Column>
        <Column n={12}>
          <h5>{t('Comment')}</h5>
          <TextAreaField name="comment" rows="4" disabled={!order?.editable} />
        </Column>
      </Row>
      {!order?.editable && (
        <CommentView
          warning
          comment={t('Order is not editable [{{state}}].', {
            state: t(order.state),
          })}
        />
      )}
    </SectionView>
  )
}
