const _temp0 = require("../../disposition/requests/module.js");

const _temp1 = require("../../logistics/iot/module.js");
const _temp2 = require("../../logistics/planning/module.js");

const _temp3 = require("../../master-data/category/module.js");
const _temp4 = require("../../master-data/contacts/module.js");
const _temp5 = require("../../master-data/cost-centers/module.js");
const _temp6 = require("../../master-data/equipments/module.js");
const _temp7 = require("../../master-data/projects/module.js");

const _temp8 = require("../../orders/form/module.js");
const _temp9 = require("../../orders/view/module.js");

const _temp10 = require("../../projects/tasks/module.js");

const _temp11 = require("../../security/roles/module.js");
const _temp12 = require("../../security/users/module.js");

const _temp13 = require("../../yard/commission/module.js");
const _temp14 = require("../../yard/maintenance/module.js");
const _temp15 = require("../../yard/retour/module.js");

module.exports = {
  "disposition":   {
    "requests": _temp0
  },
  "logistics":   {
    "iot": _temp1,
    "planning": _temp2
  },
  "master-data":   {
    "category": _temp3,
    "contacts": _temp4,
    "cost-centers": _temp5,
    "equipments": _temp6,
    "projects": _temp7
  },
  "orders":   {
    "form": _temp8,
    "view": _temp9
  },
  "projects":   {
    "tasks": _temp10
  },
  "security":   {
    "roles": _temp11,
    "users": _temp12
  },
  "yard":   {
    "commission": _temp13,
    "maintenance": _temp14,
    "retour": _temp15
  }
}