.toolbox {
  border-left: 1px solid #eeeeee;
  background: #ffffff;
}

.section {
  border-bottom: 1px solid #eeeeee;
  padding: 10px 10px 5px 10px;
}

.nextPhase {
  display: flex;
  min-height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-between;
  align-items: center;
}
.nextPhase p {
  flex: 1;
  padding-right: 20px;
}

.nextPhase svg {
  cursor: pointer;
}
