/**
 * Gets a value indicating that the current browser is safari.
 *
 * @returns Boolean
 */
const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

/**
 * Gets a value indicating that given value is a list of objects.
 *
 * @param {Object} array
 * @returns Boolean
 */
const isJsons = (array) =>
  Array.isArray(array) &&
  (array.length <= 0 ||
    (typeof array[0] === 'object' && !(array[0] instanceof Array)))

/**
 * Gets a value indicating that given value is a list of lists.
 *
 * @param {Object} array
 * @returns Boolean
 */
const isArrays = (array) =>
  Array.isArray(array) && array.length > 0 && Array.isArray(array[0])

/**
 * Extracts header names from given object keys.
 *
 * @param {Array<Object>} array
 * @returns Array<String>
 */
const jsonsHeaders = (array) => {
  return Array.from(
    array
      .map((json) => Object.keys(json))
      .reduce((a, b) => new Set([...a, ...b]), [])
  )
}

/**
 * Converts the given list of objects to list of arrays.
 *
 * @param {Array<Object>} jsons
 * @param {Array<Object> | Array<String>} headers
 * @returns Array<Array>
 */
const jsons2arrays = (jsons, headers) => {
  headers = headers || jsonsHeaders(jsons)

  // allow headers to have custom labels, defaulting to have the header data key as the label.
  let headerLabels = headers
  let headerKeys = headers
  if (isJsons(headers)) {
    headerLabels = headers.map((header) => header.label)
    headerKeys = headers.map((header) => header.key)
  }

  const data = jsons.map((object) =>
    headerKeys.map((header) => getHeaderValue(header, object))
  )
  return [headerLabels, ...data]
}

/**
 * Gets the corresponding value of given key from the given record.
 * It also normalizes the value if it has any conflicting characters for csv generation.
 *
 * @param {String} key
 * @param {Object} record
 * @returns Object
 */
const getHeaderValue = (key, record) => {
  let value = record[key]
  if (value && typeof value === 'string') {
    value = value.replaceAll('"', '""')
  }

  return value === undefined ? '' : value
}

/**
 * Gets the same input if it is not null or undefined. otherwise, it gets an empty string.
 *
 * @param {String} element
 * @returns {String}
 */
const elementOrEmpty = (element) =>
  typeof element === 'undefined' || element === null ? '' : element

/**
 * Gets a single string from given array of data.
 *
 * @param {Array<Object>} data
 * @param {String} separator
 * @param {String} enclosingCharacter
 * @returns {String}
 */
const joiner = (data, separator = ';', enclosingCharacter = '"') => {
  return data
    .filter((e) => e)
    .map((row) =>
      row
        .map((element) => elementOrEmpty(element))
        .map((column) => `${enclosingCharacter}${column}${enclosingCharacter}`)
        .join(separator)
    )
    .join(`\n`)
}

/**
 * Gets a single csv compatible string from given array of data arrays.
 *
 * @param {Array<Array<String>>} data
 * @param {Array<String>} headers
 * @param {String} separator
 * @param {String} enclosingCharacter
 * @returns {String}
 */
const arrays2csv = (data, headers, separator, enclosingCharacter) => {
  return joiner(
    headers ? [headers, ...data] : data,
    separator,
    enclosingCharacter
  )
}

/**
 * Gets a single csv compatible string from given array of data objects.
 *
 * @param {Array<Object>} data
 * @param {Array<Object> | Array<String>} headers
 * @param {String} separator
 * @param {String} enclosingCharacter
 * @returns {String}
 */
const jsons2csv = (data, headers, separator, enclosingCharacter) => {
  return joiner(jsons2arrays(data, headers), separator, enclosingCharacter)
}

/**
 * Gets a single csv compatible string from given string of data.
 *
 * @param {String} data
 * @param {Array<String>} headers
 * @param {String} separator
 * @returns {String}
 */
const string2csv = (data, headers, separator) => {
  return headers
    ? `${headers.join(separator)}\n${data}`
    : data.replace(/"/g, '""')
}

/**
 * Gets a csv string representation for given data and headers.
 *
 * @param {Array<Object> | Array<Array<String>> | String} data
 * @param {Array<Object> | Array<String>} headers
 * @param {String} separator
 * @param {String} enclosingCharacter
 * @returns {String}
 */
const toCSV = (data, headers, separator, enclosingCharacter) => {
  if (isJsons(data)) {
    return jsons2csv(data, headers, separator, enclosingCharacter)
  }
  if (isArrays(data)) {
    return arrays2csv(data, headers, separator, enclosingCharacter)
  }
  if (typeof data === 'string') {
    return string2csv(data, headers, separator)
  }
  throw new TypeError(
    `Data should be a "String", "Array of arrays" or "Array of objects"`
  )
}

/**
 * Generates the csv download link.
 *
 * @param {Array} data data to be exported
 * @param {Boolean} uFEFF add microsoft excel compatibility header
 * @param {Array} headers headers to be exported
 * @param {String} separator separator character to be used in generated csv file
 * @param {String} enclosingCharacter character to be used for quoting each column in generated csv file
 * @returns String
 */
export const buildURI = (
  data,
  uFEFF,
  headers,
  separator,
  enclosingCharacter
) => {
  const csv = toCSV(data, headers, separator, enclosingCharacter)
  const type = isSafari() ? 'application/csv' : 'text/csv'
  const blob = new Blob([uFEFF ? '\uFEFF' : '', csv], { type })
  const dataURI = `data:${type};charset=utf-8,${uFEFF ? '\uFEFF' : ''}${csv}`
  const URL = window.URL || window.webkitURL

  return typeof URL.createObjectURL === 'undefined'
    ? dataURI
    : URL.createObjectURL(blob)
}
