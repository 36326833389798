import styled from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'

import { add, toDate } from 'common/utils/date'

export const Input = styled.input.attrs((props) => ({ name: props.name }))`
  display: inline-block;
  border: 1px solid #eaeaea;
  background: ${(props) => (props.background ? props.background : '#fcfcfc')};
  width: ${(props) => (props.width ? props.width : 'inherit')};
  height: ${(props) => (props.height ? props.height : '36px')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1.0)};
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  filter: none;

  :focus {
    outline: none;
    border: 1px solid #d7d7d7;
    box-shadow: 0px 0px 16px #00000009;
    border-radius: 5px;
  }
`

export const PasswordInput = (props) => <Input type={'password'} {...props} />

export const TextAreaInput = styled.textarea.attrs((props) => ({
  name: props.name,
  maxLength: props.maxLength ?? 255,
}))`
  display: inline-block;
  border: 1px solid #eaeaea;
  background: ${(props) => (props.background ? props.background : '#fcfcfc')};
  padding: 10px 0 0 10px;
  border-radius: 5px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1.0)};
  resize: vertical;

  :focus {
    outline: none;
    border: 1px solid #d7d7d7;
    box-shadow: 0px 0px 16px #00000009;
    border-radius: 5px;
  }
`

export const NumericInput = styled(Input).attrs((props) => ({
  type: 'number',
  onInput: (e) =>
    onInputNumericField(e, props.preDecimalScale, props.decimalScale),
}))``

export const DateInput = ({
  value,
  onChange,
  // include the whole day
  inclusive,
  excludeTime = true,
  selectsRange,
  showTimeSelect,
  ...rest
}) => {
  const { t, i18n } = useTranslation()

  const normalizeDate = (date, negate = 1) => {
    // Return the same value if there is nothing to normalize
    if (!date) {
      return date
    }
    // Ranges are handled in DateRangeInput onChange
    if (selectsRange) {
      return date
    }
    // If inclusive flag is set, add one day to the selected date to make
    // it inclusive the whole day (by setting it to the next day midnight)
    // Do this only if time selection is disabled, otherwise remain accurate
    if (inclusive && excludeTime) {
      date = add(date, { days: 1 * negate })
    } else {
      // Otherwise make sure the input is a date
      date = toDate(date)
    }
    return date
  }

  return (
    <DatePicker
      locale={i18n.language}
      // See https://date-fns.org/v2.28.0/docs/format
      // and https://github.com/Hacker0x01/react-datepicker/issues/3375
      dateFormat={excludeTime ? 'P' : 'Pp'}
      // We drop time in date pickers by default. Based on "inclusive" flag
      // however, we might add 23 hours and 59 minutes to the date. Somewhat
      // a hack until we learn better how to deal with hourly bookings.
      excludeTime={excludeTime}
      showTimeSelect={showTimeSelect ?? !excludeTime}
      selected={normalizeDate(value, -1)}
      endDate={value ? add(toDate(value), { days: -1 }) : value}
      // Triggers depend on the date, date range, and time selection
      onChange={(date, e) => {
        // the onClick event is a hack to make reactdatepicker work.
        // See the bug report from 2017:
        // https://github.com/Hacker0x01/react-datepicker/issues/1012
        if (e && typeof e.preventDefault === 'function') {
          e.preventDefault()
        }
        if (onChange) {
          onChange(normalizeDate(date))
        }
      }}
      customInput={<Input />}
      showPopperArrow={false}
      // Calendar starts with Monday.
      calendarStartDay={1}
      align="center"
      isClearable
      selectsRange={selectsRange}
      {...rest}
      todayButton={selectsRange ? null : t('Today')}
    />
  )
}

/**
 * This component shows a widget to choose a range of days. Since it does
 * not display a widget to endter time and without it the reactdatepicker
 * returns zero (midnight) for time, it manually adds one day to the value
 * returned by reactdatepicker to make it include the last selected day.
 * That is what the user of this widget expects and also gives us the correct
 * value to use with fullcalendar and database queries.
 *
 * Note: this component expects and returns an exclusive end date
 **/
export const DateRangeInput = ({ startDate, endDate, onChange }) => {
  return (
    <DateInput
      selectsRange
      // DateRangeInput does not work well with time. If choosing time is
      // required, it's best to use two distinct DateInputs.
      showTimeSelect={false}
      // However we display the selected time to avoid confusion
      excludeTime={true}
      value={toDate(startDate)}
      startDate={toDate(startDate)}
      endDate={endDate ? add(toDate(endDate), { days: -1 }) : endDate}
      onChange={(dates) => {
        if (!dates) {
          return
        }
        const [start, end] = dates
        // Add 24 hours to the end date, yet another hack.
        // The datetime-picker component does not include the second day
        // hours, so in order to make it into a range which includes the
        // end date, we have to add one minute less than 24 hours to it.
        onChange && onChange([start, end ? add(end, { days: 1 }) : null])
      }}
    />
  )
}

/**************************
 * Implementation details.
 **************************/

/**
 * Preprocesses the input of the numeric field
 *
 * @param {Event} e Event which containins the value.
 * @param {int} predecimalScale  Max count of predecimal places.
 * @param {int} decimalScale Max count of decimal places.
 * @param {string} sep The separator which should be shown on the input.
 */
const onInputNumericField = (e, predecimalScale, decimalScale, sep = ',') => {
  let value = e.target.value
  value = limitFloatNumber(value)
  value = prefillPredecimalZero(value)
  value = removeRedundantPredecimalZeros(value)
  if (decimalScale) {
    value = limitDecimalPlaces(value, decimalScale)
  }
  if (predecimalScale) {
    value = limitPredecimalScale(value, predecimalScale)
  }
  e.target.value = value
}

/**
 * Filters none float number characters.
 *
 * @param {string} value Data
 * @returns String. The processed string.
 */
const limitFloatNumber = (value) => {
  // only allow float specific chars ==> 0 1 2 3 4 5 6 7 8 9 .
  return value.replace(/[^0-9.]/g, '')
}

/**
 * Adds "0" at start of value, if first char is the separator.
 *
 * Example: ".12" => "0.12"
 *
 * @param {string} value data
 * @returns String. Edited value
 */
const prefillPredecimalZero = (value) => {
  // Check if separator "." is at the beginning of the string.
  if (value.charAt(0) === '.') {
    // Add "0" to the beginning. Example: "." => "0."; ".1" => "0.1"
    return '0' + value
  }
  // No need to prefill. Return value.
  return value
}

/**
 * Splits the value into its components which are decimal, separator and predecimal
 *
 * Example: "23.12" => predecimal="23", sep=".", decimal="12"
 *
 * @param {string} value Data
 * @returns object: Object containing the components.
 */
const splitIntoItsComponents = (value) => {
  // Split value into predecimal and decimal part. Example: "2.0" => [2, 0]
  const [predecimal, decimal] = value.split('.')
  // Return object containing predecimal, decimal and separator
  return {
    predecimal: predecimal || '',
    sep: value.indexOf('.') !== -1 ? '.' : '',
    decimal: decimal || '',
  }
}

/**
 * Removes redundant predecimal zeros
 *
 * Example: "001.1" => "1.1"
 *
 * @param {string} value Data
 * @returns String. The processed value.
 */
const removeRedundantPredecimalZeros = (value) => {
  if (value === '') {
    return value
  }
  // Define regex for all "0" char at the start of string.
  const regex = /^0*/gm
  // Check if value matches the regex. Example: value="001" => zeros="00"; value="1" => zeros=""
  const zeros = value.match(regex)[0]
  // Split the value into predecimal, decimal and separator string.
  const { predecimal, sep, decimal } = splitIntoItsComponents(value)
  // Check if the predecimal string only contains "0". Example: predecimal="00"
  if (predecimal.length === zeros.length) {
    // Cut the string only having one "0" as predecimal string. Example: "00" => "0", "00." => "0.", "00.1" => "0.1"
    return '0' + sep + decimal
  }
  // Check if there are other chars than "0" inside predecimal string. Example: predecimal="01"
  if (predecimal.length > zeros.length) {
    // Remove all "0" chars from the beginning of the predecimal string. Example: predecimal="001" => "1", predecimal="12" => "12"
    return predecimal.substring(zeros.length, predecimal.length) + sep + decimal
  }
  // Return value.
  return value
}

/**
 * Limits the number of decimal places
 *
 * Example: maxCount=2, value "456.123" => "456.12"
 *
 * @param {string} value Data
 * @param {int} maxCount Maximum count of decimal places
 * @returns
 */
const limitDecimalPlaces = (value, maxCount) => {
  // Check if value contains no separator "." There are no decimal places. No limit needed.
  if (value.indexOf('.') === -1) {
    // Return value.
    return value
  }
  // Check if decimal places exceed the max count.
  if (value.length - value.indexOf('.') > maxCount) {
    // Limit the decimal places to max count.
    return parseFloat(value).toFixed(maxCount)
  }
  // Value does not exceed the max count. Return value.
  return value
}

/**
 * Limits the number of predecimal places
 *
 * Example: maxCount=2, value "456.123" => "45.123"
 *
 * @param {string} value Data
 * @param {int} maxCount Maximum count of decimal places
 * @returns
 */
const limitPredecimalScale = (value, maxCount) => {
  // Check if there is no separator ".". Predecimal places only
  if (value.indexOf('.') === -1) {
    // Limit predecimal to max count. Example: count = 2, value = "123" -> "12"
    return value.substring(0, maxCount)
  } else {
    // Split value into its components.
    let { predecimal, sep, decimal } = splitIntoItsComponents(value)
    // Limit the predecimal string to max count. Example: maxCount = 2, predecimal = "123" -> "12"
    predecimal = predecimal.substring(0, maxCount)
    // Build and return the value. Example: "12" + "." +  "12" = "12.12"
    return predecimal + sep + decimal
  }
}

/**
 * Standard HTML file input.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
 * and https://react.dev/reference/react-dom/components/input.
 **/
export const FileInput = ({ ...rest }) => {
  return <Input type="file" height="inherit" {...rest} />
}
