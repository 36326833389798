import styled, { css } from 'styled-components'
import { useEffect, useState } from 'react'

import { Input } from './input'

/**
 * Renders a drop down element.
 *
 * @param {string} title drop down title
 * @param {ReactElement} icon drop down icon
 * @param {any} selected selected item key
 * @param {function} onSelectChange selected item key
 * @param {string} minWidth min width of dropdown
 * @param {string} maxWidth max width of dropdown
 * @returns ReactElement
 */
export const FreeChoose = ({
  items,
  value = '',
  onChange,
  minWidth,
  maxWidth,
  maxItems,
  name,
}) => {
  const [state, setState] = useState({
    open: false,
  })
  const open = state.open
  const setOpen = (open) => {
    setState({ open })
  }

  /**
   * Handle click on window and closes dropdown menu.
   */
  const handleWindowClick = () => {
    if (open) {
      setOpen(false)
    }
  }

  /**
   * Registers a listener for window click event, also removes the listener when element gets unmounted.
   */
  useEffect(() => {
    window.addEventListener('click', handleWindowClick)
    return () => window.removeEventListener('click', handleWindowClick)
  })

  /**
   * Encapsulates render function of dropdown element, if there is no render function inside element, renders text and icon.
   *
   * @param {object} e dropdown element
   * @returns
   */
  const render = (e) =>
    e.render ? (
      e.render(e)
    ) : (
      <Label minWidth={minWidth} maxWidth={maxWidth} title={e} />
    )

  // For programming errors we just need to inform the developer. "items"
  // prop is not user provided, so if it is not an array, then programmer
  // has made an error and should fix it.
  console.assert(
    !items || Array.isArray(items),
    'Programming error. "items" prop must be an array. Fix your code.'
  )

  // If max items is set, tries to filter it with search string
  const filtered = value
    ? items?.filter((e) => e.toLowerCase().includes(value.toLowerCase()))
    : items
  // Splits the extra items
  const available = filtered?.slice(0, maxItems)

  // Maps available items to dropdown menu items
  const children = available?.map((e, i) => {
    /**
     * Handles click on the dropdown item.
     */
    const handleClick = () => {
      if (onChange) {
        onChange(e)
      }
    }

    // This is for solving border radius issues.
    return i === 0 && i === available.length - 1 ? (
      <DropDownItemContainer onClick={handleClick} key={i} top bottom>
        {render(e)}
      </DropDownItemContainer>
    ) : i === 0 ? (
      <DropDownItemContainer onClick={handleClick} key={i} top>
        {render(e)}
      </DropDownItemContainer>
    ) : i === available.length - 1 ? (
      <DropDownItemContainer onClick={handleClick} key={i} bottom>
        {render(e)}
      </DropDownItemContainer>
    ) : (
      <DropDownItemContainer onClick={handleClick} key={i}>
        {render(e)}
      </DropDownItemContainer>
    )
  })

  return (
    <DropDownContainer style={{ minWidth, maxWidth }}>
      <Input
        style={{ minWidth, maxWidth }}
        onClick={() => setOpen(!open)}
        onChange={(event) => onChange(event.target.value)}
        value={value}
        name={name}
      />
      <DropDownContent open={open} style={{ minWidth, maxWidth }}>
        {children}
      </DropDownContent>
    </DropDownContainer>
  )
}

const Label = ({ title, icon }) => {
  if (icon) {
    return (
      <LabelContainer>
        <p>{title}</p>
        {icon}
      </LabelContainer>
    )
  } else {
    return (
      <LabelContainer>
        <p>{title}</p>
      </LabelContainer>
    )
  }
}

const LabelContainer = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
  align-items: center;

  p {
    flex: 1 1 auto;
    text-align: left;
  }

  svg {
    width: 16px;
    height: 16px;
    color: #808080;
    flex: 0 1 auto;
  }

  h5 {
    flex: 0 1 auto;
    text-align: right;
  }
`

const DropDownContainer = styled.div`
  position: relative;
  display: flex;

  textarea {
    flex: 1;
    height: 36px;
    box-sizing: border-box;
  }
`

/* Dropdown Content (Hidden by Default) */
const DropDownContent = styled.div`
  display: ${(props) => (props.open ? 'block' : 'none')};
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 3px 24px #e0e0e0;
  border-radius: 5px;
  z-index: 1;
  margin-top: 42px;
`

const DropDownItemContainer = styled.div`
  padding: 6px 15px;
  text-decoration: none;
  display: block;
  transition: 0.3s;
  background: #ffffff;
  cursor: pointer;
  ${(props) =>
    props.top && props.bottom
      ? css`
          border-radius: 5px 5px 5px 5px;
        `
      : props.top
      ? css`
          border-radius: 5px 5px 0px 0px;
        `
      : props.bottom
      ? css`
          border-radius: 0px 0px 5px 5px;
        `
      : css`
          border-radius: 0px 0px 0px 0px;
        `};

  :hover {
    background: #e2e2e2;
  }
`
