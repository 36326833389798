import { useTranslation } from 'react-i18next'

import { NumericField } from 'common/widgets/form/field'

export const StockField = ({ name = 'stock_level' }) => {
  const { t } = useTranslation()
  return (
    <NumericField
      name={name}
      nullable={false}
      preDecimalScale={4}
      decimalScale={0}
      validate={(value, ctxValues) => {
        const maxStock = ctxValues?.max_stock
        if (value && maxStock) {
          if (value > maxStock)
            return t('Stock should be smaller than maximum stock.')
        }
        // 2147483647 is the max integer value in our API (postgres)
        if (value > 2147483647) {
          return `${t('Value should be smaller than')} 2147483648`
        }
        const minStock = ctxValues?.min_stock
        if (value && minStock) {
          if (value < minStock)
            return t('Stock should be larger than minimum stock.')
        }

        return null
      }}
    />
  )
}

export const MinStockField = ({ name = 'min_stock' }) => {
  const { t } = useTranslation('equipments')

  const validate = (value, ctxValues) => {
    const maxStock = ctxValues?.max_stock
    if (parseInt(value) > maxStock)
      return t('Min stock should be smaller than max stock')
    // 2147483647 is the max integer value in our API (postgres)
    if (parseInt(value) > 2147483647) {
      return `${t('Value should be smaller than')} 2147483648`
    }
    return null
  }

  return (
    <NumericField
      name={name}
      nullable={false}
      decimalScale={0}
      validate={validate}
    />
  )
}

export const MaxStockField = ({ name = 'max_stock' }) => {
  const { t } = useTranslation('equipments')

  const validate = (value, ctxValues) => {
    const minStock = ctxValues?.min_stock
    if (parseInt(value) < minStock)
      return t('Max stock should be larger than min stock')
    // 2147483647 is the max integer value in our API (postgres)
    if (parseInt(value) > 2147483647) {
      return `${t('Value should be smaller than')} 2147483648`
    }
    return null
  }

  return (
    <NumericField
      name={name}
      nullable={false}
      decimalScale={0}
      validate={validate}
    />
  )
}
