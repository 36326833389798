import { BookOpen } from 'react-feather'

import { register } from 'system/container'

register({
  icon: <BookOpen />,
  title: 'Master data',
  // Subsystem base route, no trailing slash
  path: '/master-data',
  // mainmenu priority, lower is higher
  priority: 60,
})
