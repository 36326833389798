import { createContext, useContext } from 'react'
import { useTranslation } from 'react-i18next'

// Create a context object for page.
const PageContext = createContext()

/**
 * Provides page context.
 *
 * @param {object} data holds information about the current page
 * @param {ReactElement} children child elements
 * @returns
 */
export const PageProvider = ({ data, title, children }) => {
  const { t } = useTranslation()
  const t_title = t(title)
  if (t_title) {
    document.title = `${t_title} - Orinoco`
  } else {
    document.title = 'Orinoco'
  }
  return (
    <PageContext.Provider
      value={{
        ...data,
      }}
    >
      {children}
    </PageContext.Provider>
  )
}

/**
 * Returns the active page context.
 *
 * @returns PageContex
 */
export const usePage = () => useContext(PageContext)
