import { register } from 'system/container'

import { LargeScaleEquipmentAddPage } from './add'
import { LargeScaleEquipmentListPage } from './list'
import { LargeScaleEquipmentEditPage } from './edit'
import { LargeScaleEquipmentDetailsPage } from './view'

// Registers projects page inside system container.
register({
  render: () => <LargeScaleEquipmentAddPage />,
  path: '/master-data/equipments/large-scales/add',
  title: 'New',
})

// Registers projects page inside system container.
register({
  render: () => <LargeScaleEquipmentAddPage />,
  path: '/master-data/equipments/large-scales/:id/clone',
  title: 'Clone',
})

// Registers large scale edit page inside system container.
register({
  render: () => <LargeScaleEquipmentEditPage />,
  path: '/master-data/equipments/large-scales/:id/edit',
  title: 'Edit',
})

// Registers the page as a child of equipment sections
register({
  menu: true,
  path: '/master-data/equipments/large-scales',
  title: 'Large scale equipments',
  render: () => <LargeScaleEquipmentListPage />,
})

// Registers projects page inside system container.
register({
  render: () => <LargeScaleEquipmentDetailsPage />,
  path: '/master-data/equipments/large-scales/:id',
  title: 'Details',
})
