import React from 'react'
import { useTranslation } from 'react-i18next'

import { useService } from 'common/service/context'
import { Row, Column } from 'common/widgets/grid'
import { SaveButton, AddButton } from 'common/widgets/button'
import { TextField } from 'common/widgets/form/field'
import { OverlayBody, OverlayFooter, OverlayForm } from 'common/widgets/overlay'
import { useQuery } from 'common/query/context'

export const TripCRUDOverlay = ({
  open,
  trip,
  data,
  onAdd,
  onUpdate,
  onClose,
  source,
}) => {
  const { t } = useTranslation(['logistics'])
  const service = useService()
  const queryCtx = useQuery()

  return (
    <OverlayForm
      open={open}
      onClose={onClose}
      data={data}
      title={data ? t('Edit trip') : t('Add a new trip')}
    >
      <OverlayBody>
        <Row>
          <Column n={6}>
            <h5>{t('Destination')}</h5>
            <TextField name="location" mandatory />
          </Column>
          <Column n={6}>
            <h5>{t('Cost center')}</h5>
            <TextField name="const_center" />
          </Column>
          <Column n={6}>
            <h5>{t('Description')}</h5>
            <TextField name="into" />
          </Column>
          <Column n={6}>
            <h5>{t('Info')}</h5>
            <TextField name="info" />
          </Column>
        </Row>
      </OverlayBody>
      <OverlayFooter repel>
        {!data && (
          <AddButton
            default
            disabled={(ctx) => !ctx.ready}
            onClick={async (e, ctx) => {
              const [response, error] = await service.post(
                `plannings/trips/`,
                ctx.values.json
              )
              if (!error) {
                onAdd && onAdd(response.data, queryCtx)
              }
            }}
          />
        )}
        {data && (
          <SaveButton
            default
            text={t('Update')}
            onClick={async (e, ctx) => {
              const [, error] = await service.put(
                `plannings/trips/${data.id}`,
                ctx.values.dirty
              )
              if (!error) {
                queryCtx.reload()
                onUpdate && onUpdate()
              }
            }}
          />
        )}
      </OverlayFooter>
    </OverlayForm>
  )
}
