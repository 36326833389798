import chroma from 'chroma-js'

/**
 * Returns default color palette.
 *
 * @param {int} count color count
 * @returns Array
 */
export const getDefaultColorPalette = (count) =>
  chroma
    .scale([
      'AntiqueWhite',
      'Aqua',
      'Black',
      'Bisque',
      'Blue',
      'Brown',
      'CadetBlue',
      'GREEN',
      'Chocolate',
      'Gold',
      'RED',
    ])
    .mode('lch')
    .colors(count ? count : 48)

/**
 * Common application state colors.
 */
export const StateColor = {
  READY: '#AEC4FA',
  OPEN: '#AEC4FA',
  IN_PROGRESS: '#F9F29A',
  COMPLETE: '#95E68B',
  CANCELED: '#FC4242',
}

export const stringToColor = (text) => {
  var hash = 0
  for (const i in text) {
    hash = text.charCodeAt(i) + ((hash << 5) - hash)
  }
  var color = '#'
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff
    color += ('00' + value.toString(16)).substr(-2)
  }
  return color
}
