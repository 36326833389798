.mainMenuItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60px;
  height: 55px;
  transition: 0.1s;
  color: #efefef;
}

.mainMenuItem svg {
  width: 20px;
  margin: 0 auto;
}

.mainMenuItemActive {
  composes: mainMenuItem;
  border-radius: 15px 0px 0px 15px;
  background-color: #efefef;
}

.mainMenuItemActive {
  color: #376df4;
}

.mainMenuItemActive > svg {
  color: #376df4;
}

.mainMenuItemText {
  text-align: center;
  font-size: x-small;
}

.menuItemText {
  margin: 0px;
  text-align: left;
  font-size: 14px;
  font-weight: 700;
}

.menuItemContainer {
  width: 199px;
  height: 36px;
  background: #ffffff;
  display: flex;
  align-items: center;
  transition: 0.5s;
}

.subMenuItem :hover {
  background: linear-gradient(
    90deg,
    #ffffff 0%,
    #f9f9f9 25%,
    #f5f5f5 100%
  ) !important;
}

.subMenuTitle {
  width: 200px;
  height: 60px;
  padding-left: 10px;
  border-bottom: 1px solid #e7e8ea;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

.subMenuTitle h4 {
  margin-left: 10px;
}

.subMenuTitle svg {
  width: 20px;
  height: 20px;
}

.sideMenu {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

@media print {
  .SideMenu {
    display: none;
  }
}

.sideMenuRight {
  position: fixed;
  width: 200px;
  height: 100%;
  background: #ffffff;
  border-right: 1px solid #e7e8ea;
  display: flex;
  flex-direction: column;
  z-index: 199;
  transition: 0.4s ease-out;
}

.pageLink {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
}

.menuItem {
  width: 199px;
  height: 36px;
  background: #ffffff;
  display: flex;
  align-items: center;
  transition: 0.5s;
}

.menuItem :hover {
  background: linear-gradient(90deg, #ffffff 0%, #f9f9f9 25%, #f5f5f5 100%);
}

.menuList {
  position: fixed;
  width: 200px;
  height: 100%;
  background: #ffffff;
  border-right: 1px solid #e7e8ea;
  display: flex;
  flex-direction: column;
  z-index: 199;
  transition: 0.4s ease-out;
}

.right {
  position: absolute;
  top: 0px;
  width: 200px;
  height: 100%;
  background: #ffffff;
  border-right: 1px solid #e7e8ea;
  display: flex;
  flex-direction: column;
  z-index: 199;
  transition: 0.4s ease-out;
}

.cover {
  left: 60px;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ffffff33;
  z-index: 1;
  transition: 0.4s ease-in;
}

.moduleListContainer {
  display: flex;
  width: 60px;
  height: calc(100% - 60px);
  background: #376df4;
  z-index: 201;
  padding-bottom: 10px;
  flex-direction: column;
  flex: 1;
  transition: 0.3s;
}

.moduleListContainerVisible {
  composes: moduleListContainer;
  left: 0;
}

.pageLink {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
}

.menuItemListContainer {
  padding-top: 15px;
  padding-bottom: 15px;
  box-sizing: border-box;
  background: #ffffff;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}
