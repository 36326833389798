// The range of `zIndex` which actually works is `±9999`. bigger or lower numbers do not have
// effect when rendered based on some testings in our application, despite the offcial limit which
// is `±2147483647`.
export const ZIndexEnum = {
  BELOW_ALL: -9999,
  BELOW_MOST: -300,
  BELOW_SOME: -200,
  BELOW_FEW: -100,
  ABOVE_FEW: 100,
  ABOVE_SOME: 200,
  ABOVE_MOST: 300,
  ABOVE_ALL: 9999,
}

Object.freeze(ZIndexEnum)
