import { useNavigate } from 'react-router-dom'

import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { formatDistanceFromNow } from 'common/utils/format'
import { SystemPage } from 'system/page/core'

export const RolesListPage = () => {
  const navigate = useNavigate()

  return (
    <SystemPage>
      <SimpleDataTable
        name="roles"
        title="Roles"
        url="security/roles"
        onAddClick={() => navigate('/security/roles/add')}
        navBaseUrl="/security/roles"
      >
        <SimpleColumn n={6} sortable label="Name" field="name" header />
        <SimpleColumn
          n={6}
          sortable
          label="Last updated"
          field="updated_at"
          value={(r) => formatDistanceFromNow(r.updated_at)}
        />
      </SimpleDataTable>
    </SystemPage>
  )
}
