import React from 'react'

import { CSVDownload } from 'common/data-table/export/lib/download'

import { defaultProps as commonDefaultProps } from './metaProps'

/**
 * Renders a csv download link.
 *
 * @returns JSX.Element
 */
export class CSVLink extends React.Component {
  static defaultProps = commonDefaultProps

  // Keeps a state indicating that the data is loaded and can be downloaded as csv.
  // loaded: specifies that the query result is resolved.
  // download: specifies that csv download must be triggered.
  state = {
    loaded: false,
    download: false,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  /**
   * This method will prepare the data to be exported and will trigger the download afterwards.
   */
  handleClick(event) {
    if (!this.state.loaded) {
      this.prepareData()
    } else {
      this.setState({ download: true })
    }
  }

  /**
   * Prepares the data for exporting and sets it into state
   */
  prepareData = () => {
    this.props
      .dataloader()
      .then(() =>
        this.setState({
          loaded: true,
          download: true,
        })
      )
      .catch((error) =>
        this.setState({
          loaded: false,
          download: false,
        })
      )
  }

  /**
   * Download callback to unmount the csv download component after each download.
   */
  downloadDone = () => {
    this.setState({
      download: false,
    })
  }

  render() {
    const {
      data,
      headers,
      separator,
      filename,
      uFEFF,
      children,
      enclosingCharacter,
    } = this.props
    return (
      <>
        {this.state.download && (
          <CSVDownload
            done={this.downloadDone}
            data={data() || []}
            headers={headers}
            uFEFF={uFEFF}
            enclosingCharacter={enclosingCharacter}
            filename={filename}
            separator={separator}
          >
            {children}
          </CSVDownload>
        )}
        <a target="_self" onClick={this.handleClick}>
          {children}
        </a>
      </>
    )
  }
}
