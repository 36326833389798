import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useService } from 'common/service/context'
import { AddIconButton, RemoveIconButton } from 'common/widgets/button'
import { Container } from 'common/widgets/container'
import { DataSource } from 'common/widgets/data-source'
import { Row, Column } from 'common/widgets/grid'
import { CardView } from 'common/widgets/view'
import { stringToColor } from 'common/utils/color'
import { Avatar } from 'common/widgets/avatar'
import { formatDate, formatDistanceFromNow } from 'common/utils/format'
import { DocumentsView } from 'common/widgets/document'

import { QualificationsTable } from '../qualifications/list'
import { AssignQualificationOverlay } from '../qualifications/overlay'

/**
 * Renders tag view inside contact tab widget.
 *
 * @param {any} contact contact info
 * @returns ReactElement
 */
export const ContactQualificationsView = ({ contact }) => {
  const service = useService()
  const qualificationsHandler = {}
  const categoriesHandler = {}

  /**
   * Removes the attached qualification.
   *
   * @param {any} link link
   */
  const handleRemove = async (qualificationId) => {
    const [result, error] = await service.delete(
      `/contacts/qualifications/${qualificationId}`
    )
    qualificationsHandler.reload()

    return [result, error]
  }

  return (
    <DataSource
      fetch={async () =>
        await service.get(`/contacts/qualifications`, [
          { contact_id: contact.id },
        ])
      }
      handler={qualificationsHandler}
      render={({ data }) => {
        return (
          data && (
            <Row>
              <Column n={6}>
                <AttachedQualifications
                  qualifications={data}
                  contact={contact}
                  onRemove={handleRemove}
                />
              </Column>
              <Column n={6}>
                <ContactQualificationsSelect
                  qualifications={data}
                  contact={contact}
                  qualificationsHandler={qualificationsHandler}
                  categoriesHandler={categoriesHandler}
                />
              </Column>
            </Row>
          )
        )
      }}
    />
  )
}

const AttachedQualifications = ({ qualifications, onRemove }) => {
  const { t } = useTranslation()

  return qualifications?.map((e, idx) => (
    <CardView key={idx} style={{ marginBottom: '5px' }}>
      <Container flex justify="space-between" align="center">
        <Container flex>
          <Avatar
            size="48px"
            color={e.color ? e.color : stringToColor(e.name)}
          />
          <Container width="10px" />
          <Container flex vertical justify="space-between">
            <h2>{e.name}</h2>
            {e.expires_on && (
              <h4>
                {t('Expires on:')} {formatDate(e.expires_on)}
              </h4>
            )}
            <label>{e.comment}</label>
            <label>{formatDistanceFromNow(e.created_at)}</label>
          </Container>
          <Container width="10px" />
          {e.files_ref && <DocumentsView docRef={e.files_ref} readonly />}
        </Container>
        <RemoveIconButton danger onClick={async () => await onRemove(e.id)} />
      </Container>
    </CardView>
  ))
}

/**
 * Renders a widget to select tags for contacts.
 *
 * @param {any} contact contact info
 * @param {Function} reload data reload function
 * @returns ReactElement
 */
const ContactQualificationsSelect = ({
  qualifications,
  contact,
  qualificationsHandler,
  categoriesHandler,
}) => {
  // State of the assign qualification overlay
  const [selectedQualification, setSelectedQualification] = useState(null)
  const service = useService()

  useEffect(() => {
    categoriesHandler.reload()
  }, [qualifications])

  /**
   * Fetches available tags and excludes the selected ones.
   *
   * @returns Array
   */
  const fetch = async (params) => {
    const [result, error] = await service.get(
      `/contacts/defs/qualification-categories`,
      [...params]
    )
    result.data = result?.data?.filter(
      (item) => !qualifications.some((obj) => obj.category_id === item.id)
    )

    return [result, error]
  }

  const onQualificationAssign = async (values) => {
    //TODO: fix this by creating a flag stripTime to the datepicker
    values['expires_on'] = values['expires_on']?.substring(0, 11)
    // We are doing this because when we submit a file without a comment the null value gets stringied somehow and its been
    // shown as a 'null' when later we list the assigned qualifications. Probably a bug in the file field.
    values['comment'] = values['comment'] ?? ''
    const [result, error] = await service.post(
      `/contacts/qualifications`,
      values
    )
    qualificationsHandler.reload()
    setSelectedQualification(null)
    return [result, error]
  }

  return (
    <CardView>
      <QualificationsTable
        handler={categoriesHandler}
        fetch={fetch}
        disableAdd
        minimal
        extraButtons={(r) => (
          <AddIconButton
            default
            onClick={(e) => {
              e.stopPropagation()
              setSelectedQualification(r)
            }}
          />
        )}
      />
      {selectedQualification && (
        <AssignQualificationOverlay
          open={selectedQualification}
          qualification={selectedQualification}
          contact={contact}
          onClose={() => setSelectedQualification(null)}
          onSubmit={onQualificationAssign}
        />
      )}
    </CardView>
  )
}
