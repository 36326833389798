import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'

import { Container } from 'common/widgets/container'
import { Row, Column } from 'common/widgets/grid'
import { GridFieldView, SectionView } from 'common/widgets/view'
import { DetailViewPage } from 'system/utils/view'
import { RelatedItemView } from 'modules/master-data/equipments/view/related-items'
import { DocumentsSectionView } from 'common/widgets/document'
import {
  AdHocDeliveryIconButton,
  AddIconButton,
  RemoveIconButton,
} from 'common/widgets/button'
import { SingleImageFieldSectionView } from 'common/widgets/image'

import { OrderableColumnFieldView } from '../widgets/orderable'
import { ItemHistory } from '../view/history'
import { EditConsumableStockOverlay } from '../overlays/edit'
import { MasterDataView } from '../view/master-data'

/**
 * Renders page for viewing a consumable
 *
 * @returns ReactElement
 */
export const ConsumableViewPage = () => {
  // Extracts id from url params.
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation(['equipments'])
  // Consumable stock state
  const [decreaseStock, setDecreaseStock] = useState(null)

  return (
    <DetailViewPage
      url="/items/consumables"
      navUrl="/master-data/equipments/consumables"
      title={(e) => e.name}
      editable
      addExtraButtons={() => (
        <>
          <AddIconButton
            tooltip={t('Increase stock')}
            onClick={() => {
              setDecreaseStock(false)
            }}
          />
          <RemoveIconButton
            tooltip={t('Decrease stock')}
            onClick={() => {
              setDecreaseStock(true)
            }}
          />
          <AdHocDeliveryIconButton
            tooltip={t('Adhoc commission')}
            onClick={() => {
              navigate(`/yard/commission/deliveries/adhoc?baseitemId=${id}`)
            }}
          />
        </>
      )}
    >
      {(data, reload) => (
        <>
          <Row>
            <Column flex n={6} m={6} s={12}>
              <MasterDataView data={data}>
                <Row>
                  <GridFieldView
                    n={6}
                    label={t('Commission type')}
                    value={t(data.commission_type)}
                  />
                  <GridFieldView
                    n={6}
                    label={t('Packaging unit')}
                    value={data.packaging_unit}
                  />
                  <GridFieldView
                    n={6}
                    label={t('Quantity unit')}
                    value={data.quantity_unit}
                  />
                  <GridFieldView
                    n={6}
                    label={t('Responsible orderer')}
                    value={
                      data.responsible_orderer &&
                      `${data.responsible_orderer.first_name} ${data.responsible_orderer.last_name}`
                    }
                  />
                </Row>
              </MasterDataView>
            </Column>
            <Column flex n={6} m={6} s={12}>
              <ConsumableInventorySection consumable={data} />
            </Column>
            <Column flex n={5} m={12} s={12}>
              <RelatedItemView data={data} />
            </Column>
            <Column flex n={5} m={9} s={12}>
              <DocumentsSectionView docRef={`baseitem=${data.id}`} />
            </Column>
            <Column flex n={2} m={3} s={12}>
              <SingleImageFieldSectionView
                imageRef={data.image_ref}
                title={t('Equipment Image')}
                section_title={t('Image')}
              />
            </Column>
            <Column flex n={12} m={12} s={12}>
              <SectionView>
                <ItemHistory itemID={id} />
              </SectionView>
            </Column>
          </Row>
          <EditConsumableStockOverlay
            baseitem={data}
            reload={reload}
            decrease={decreaseStock}
            onClose={() => setDecreaseStock(null)}
          />
        </>
      )}
    </DetailViewPage>
  )
}

export const ConsumableInventorySection = ({ consumable }) => {
  const { t } = useTranslation(['equipments'])

  return (
    <SectionView>
      <h1>{t('Inventory')}</h1>
      <Container flex vertical shrink>
        <Row>
          <GridFieldView
            n={6}
            label={t('Stock level')}
            value={consumable.stock_level}
          />
          <GridFieldView
            n={6}
            label={t('Min stock level')}
            value={consumable.min_stock}
          />
          <GridFieldView
            n={6}
            label={t('Max stock level')}
            value={consumable.max_stock}
          />
          <GridFieldView
            n={6}
            label={t('Reserved amount')}
            value={consumable.reserved}
          />
          <GridFieldView
            n={6}
            label={t('Storage')}
            value={consumable.storage}
          />
          <GridFieldView
            n={6}
            label={t('Supplier')}
            value={consumable.supplier}
          />
          <GridFieldView
            n={6}
            label={t('Internal billing rate')}
            value={consumable.internal_billing_rate}
          />
          <OrderableColumnFieldView n={6} value={consumable.is_orderable} />
          <GridFieldView
            n={6}
            label={t('Accounting group')}
            value={consumable.accounting_group}
          />
        </Row>
      </Container>
    </SectionView>
  )
}
