import styles from './loading.module.css'

/**
 * Renders a loading line
 *
 * @returns ReactElement
 */
export const SystemPageLoading = ({ loading }) => {
  return (
    <div className={loading ? styles.loadingActive : styles.loadingPassive} />
  )
}
