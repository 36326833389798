import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { formatItemName } from 'modules/master-data/equipments/utils'
import { formatProjectName } from 'modules/projects/utils'
import { BackButton } from 'common/widgets/button'
import { OverlayBody, OverlayFooter, OverlayForm } from 'common/widgets/overlay'
import { GridFieldView } from 'common/widgets/view'
import { Row } from 'common/widgets/grid'

export const BookingRequestTypeEnum = {
  NEW: 'NEW',
  EXTEND: 'EXTEND',
  SHRINK: 'SHRINK',
}

export const AuditedRequestOverlay = ({ request }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  /**
   * Handles click on back or close.
   */
  const handleBack = () => {
    if (request.type === BookingRequestTypeEnum.SHRINK) {
      navigate('/disposition/requests/shrink')
    } else if (request.type === BookingRequestTypeEnum.EXTEND) {
      navigate('/disposition/requests/extend')
    } else {
      navigate('/disposition/requests/new')
    }
  }

  const project =
    request.type === BookingRequestTypeEnum.NEW
      ? request.project
      : request.booking.project
  const baseitem =
    request.type === BookingRequestTypeEnum.NEW
      ? request.ordered_item.item
      : request.booking.ordered_item.item
  return (
    <OverlayForm
      open={request.audited_at}
      title={t('Invalid request')}
      onClose={handleBack}
    >
      <OverlayBody>
        <Row>
          <GridFieldView
            n={6}
            label={t('Project')}
            value={formatProjectName(project)}
          />
          <GridFieldView
            n={6}
            label={t('Item')}
            value={formatItemName(baseitem)}
          />
        </Row>
        <p>{t('Request is already audited!')}</p>
      </OverlayBody>
      <OverlayFooter>
        <BackButton onClick={handleBack} />
      </OverlayFooter>
    </OverlayForm>
  )
}
