import { EditViewPage } from 'system/utils/edit'

import { CategoryForm } from './form'

export const CategoryEdit = () => {
  return (
    <EditViewPage url="/pim/categories" title={(e) => e.name}>
      {(data) => <CategoryForm data={data} />}
    </EditViewPage>
  )
}
