import styled, { css } from 'styled-components'
import { AlertCircle, ThumbsUp, AlertTriangle } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { Card } from './card'

export const WarningMessage = ({ title, message, flat }) => {
  const { t } = useTranslation()
  return (
    <AlertContainer backgroundColor="#FDEAD7" color="#F49537" flat={flat}>
      <AlertTriangle />
      <p>
        {title && <strong>{` ${t(title)}: `}</strong>}
        {t(message)}
      </p>
    </AlertContainer>
  )
}

export const SuccessMessage = ({ title, message, flat }) => {
  const { t } = useTranslation()
  return (
    <AlertContainer backgroundColor="#D5F5D0" color="#2DCE18" flat={flat}>
      <ThumbsUp />
      <p>
        {title && <strong>{` ${t(title)}: `}</strong>}
        {t(message)}
      </p>
    </AlertContainer>
  )
}

export const SimpleErrorMessage = ({ error, flat }) => {
  const { t } = useTranslation()
  const { type, message } = error

  return (
    <AlertContainer backgroundColor="#FFD9D9" color="#FC4242" flat={flat}>
      <AlertCircle />
      <p>{message ? t(message) : t(type)}</p>
    </AlertContainer>
  )
}

const AlertContainer = styled(Card)`
  display: flex;
  align-items: center;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  border: 1px solid ${(props) => props.foreground};
  border-radius: 10px;
  padding: 10px;

  ${(props) =>
    props.flat &&
    css`
      flex: 1 1 auto;
      border-radius: 0;
      border: 0;
      border-top: 1px solid ${(props) => props.foreground};
    `}

  h3 {
    margin-left: 15px;
    color: ${(props) => props.color};
    font-weight: 900;
  }

  p {
    margin-left: 10px;
    color: ${(props) => props.color};
  }

  h4 {
    margin-left: 15px;
    color: ${(props) => props.color};
    font-weight: 900;
    text-decoration: underline;
  }

  svg {
    width: 24px;
    color: ${(props) => props.color};
  }
`
