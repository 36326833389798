import { SystemPage } from 'system/page/core'

import { CostCenterForm } from './form'

export const CostCenterAddPage = () => {
  return (
    <SystemPage>
      <CostCenterForm />
    </SystemPage>
  )
}
