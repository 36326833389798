import { useTranslation } from 'react-i18next'

import { EquipmentGridLinkFieldView } from 'modules/master-data/equipments/widgets/link'
import { CancelButton, ConfirmButton } from 'common/widgets/button'
import { OverlayBody, OverlayFooter, OverlayForm } from 'common/widgets/overlay'
import { Divider, GridFieldView } from 'common/widgets/view'
import { Row } from 'common/widgets/grid'
import { formatProjectName } from 'modules/projects/utils'
import { DataSource } from 'common/widgets/data-source'

/**
 * Renders the back delivery summary overlay.
 *
 * @param {any} open flag for opening the overlay
 * @param {any} backDeliveryItems backdelivery items array
 * @param {int} projectId selected project id
 * @param {any} onClose state of closing the dialog
 *
 * @returns ReactElement
 */
export const BackDeliverySummaryOverlay = ({
  open,
  backDeliveryItems,
  projectId,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation()

  return (
    <OverlayForm title={t('Summary')} open={open} onClose={onClose}>
      <OverlayBody>
        <SummaryContent
          backDeliveryItems={backDeliveryItems}
          projectId={projectId}
        />
      </OverlayBody>
      <OverlayFooter repel gap="10px">
        <ConfirmButton
          onClick={() => {
            onClose()
            onSubmit()
          }}
        />
        <CancelButton onClick={onClose} />
      </OverlayFooter>
    </OverlayForm>
  )
}

/**
 * Renders the content of the summary overlay
 *
 * @param {any} backDeliveryItems backdelivery items array
 * @param {int} projectId selected project id
 *
 * @returns ReactElement
 */
const SummaryContent = ({ backDeliveryItems, projectId }) => {
  const { t } = useTranslation()

  return (
    <Row>
      <GridFieldView
        n={6}
        m={6}
        s={6}
        label={t('Target location')}
        value={t('Yard')}
      />
      <DataSource url={`/projects/${projectId}`}>
        {({ data }) =>
          data && (
            <GridFieldView
              n={6}
              m={6}
              s={6}
              label={t('Project')}
              value={formatProjectName(data)}
            />
          )
        }
      </DataSource>
      <GridFieldView n={12} m={12} s={12}>
        {backDeliveryItems?.map((e, i) => (
          <SummaryItem
            key={i}
            item={e}
            index={i}
            itemsLenght={backDeliveryItems.length}
          />
        ))}
      </GridFieldView>
    </Row>
  )
}

/**
 * Renders the picked up back delivery item
 *
 * @param {any} item back delivery item
 *
 * @returns ReactElement
 */
const SummaryItem = ({ item, index, itemsLenght }) => {
  const { t } = useTranslation()

  return (
    <Row>
      <EquipmentGridLinkFieldView n={8} m={8} s={8} baseitem={item.baseitem} />
      <GridFieldView
        n={4}
        m={4}
        s={4}
        label={t('For order')}
        value={`${item?.okAmount ?? 0}`}
      />
      <GridFieldView
        n={4}
        m={4}
        s={4}
        label={t('For testing')}
        value={`${
          item.faults.find((e) => e.type === 'inspection')?.amount ?? 0
        }`}
      />
      <GridFieldView
        n={4}
        m={4}
        s={4}
        label={t('For repair')}
        value={`${item.faults.find((e) => e.type === 'repair')?.amount ?? 0}`}
      />
      <GridFieldView
        n={4}
        m={4}
        s={4}
        label={t('For removal')}
        value={`${item.faults.find((e) => e.type === 'disposal')?.amount ?? 0}`}
      />
      {index !== itemsLenght - 1 && (
        <GridFieldView n={12} m={12} s={12}>
          <Divider />
        </GridFieldView>
      )}
    </Row>
  )
}
