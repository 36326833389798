import { useNavigate } from 'react-router-dom'

import { useService } from 'common/service/context'
import {
  TextField,
  CheckBoxField,
  TextAreaField,
} from 'common/widgets/form/field'
import { OverlayFooter, OverlayForm } from 'common/widgets/overlay'
import { SimpleFormAction } from 'common/widgets/form/utils'

export const ProjectTypeFormOverlay = ({ open, onClose, data }) => {
  const service = useService()
  const navigate = useNavigate()

  const handleAdd = async (formData) => {
    const [result, error] = await service.post('projects/types', formData)
    if (!error) {
      finalize(formData, result?.data)
    }
    return [result, error]
  }

  const handleUpdate = async (formData) => {
    const [result, error] = await service.put(
      `projects/types/${data.id}`,
      formData
    )
    if (!error) {
      finalize(formData, result?.data)
    }
    return [result, error]
  }

  const finalize = async (formData, result) => {
    if (formData?.goto_design) {
      navigate(`/master-data/projects/types/${result.id}`)
    } else {
      onClose(result)
    }
  }

  return (
    <OverlayForm
      name="project_type"
      open={open}
      title={data ? 'Edit project template' : 'New project template'}
      onClose={onClose}
      data={data}
    >
      <TextField name="name" label="Name" mandatory />
      <TextAreaField name="description" label="Description" rows="4" />
      <CheckBoxField
        name="goto_design"
        text="Continue to design page"
        defaultValue={true}
      />
      <OverlayFooter>
        <SimpleFormAction
          onAdd={data ? undefined : handleAdd}
          onUpdate={data ? handleUpdate : undefined}
        />
      </OverlayFooter>
    </OverlayForm>
  )
}
