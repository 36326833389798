import { SourceOverlay } from './form'

export const AddSourceOverlay = ({
  request,
  source,
  selectedSources,
  start,
  end,
  onClose,
  onAdd,
}) => {
  return (
    <SourceOverlay
      request={request}
      source={source}
      sources={selectedSources}
      data={{
        start,
        end,
        replaceable: 'false',
        amount: 0,
      }}
      onClose={onClose}
      onAdd={onAdd}
    />
  )
}
