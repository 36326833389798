import { useTranslation } from 'react-i18next'

import { SubmitButton, CancelButton } from 'common/widgets/button'
import { OverlayBody, OverlayFooter, OverlayForm } from 'common/widgets/overlay'
import { useService } from 'common/service/context'
import { Row } from 'common/widgets/grid'
import { GridFieldView } from 'common/widgets/view'
import { useForm } from 'common/widgets/form/context'
import { TextField } from 'common/widgets/form/field'

/**
 * Renders incrase/decrease stock of a consumable.
 *
 * @param {any} baseitem baseitem object
 * @param {bool} decrease should the amount of the consumable be increased or decreased
 * @param {any} reload reload call back
 * @param {any} onClose state of closing the dialog
 *
 * @returns ReactElement
 */
export const EditConsumableStockOverlay = ({
  baseitem,
  decrease,
  onClose,
  reload,
}) => {
  const { t } = useTranslation()
  const service = useService()

  /**
   * Submits the edited stock amount of a consumable.
   *
   * @returns Array[result, error]
   */
  const handleStockEdit = async (values) => {
    var amount = parseInt(values.json.amount)
    if (decrease) {
      amount = amount * -1
    }
    const [result, error] = await service.put(
      `/items/${baseitem.id}/increase`,
      { amount: amount }
    )
    if (!error && result) {
      reload()
      onClose()
    }

    return [result, error]
  }

  /**
   * Should show the overlay or not
   *
   * @returns bool
   */
  const shouldShowOverlay = () => {
    if (decrease !== null) {
      return true
    }
    return null
  }

  /**
   * Returns the actualStock level of the consumable
   *
   * @param {any} values form values object
   *
   * @returns int
   */
  const getActualStockLevel = (values) => {
    const addedStockLevel = values.json.amount
      ? parseInt(values.json.amount)
      : 0

    var acutalStockLevel
    if (decrease) {
      acutalStockLevel = parseInt(values.json.stock_level) - addedStockLevel
    } else {
      acutalStockLevel = parseInt(values.json.stock_level) + addedStockLevel
    }

    return acutalStockLevel
  }

  return (
    <OverlayForm
      open={shouldShowOverlay()}
      onClose={() => onClose()}
      data={{
        amount: null,
        stock_level: `${baseitem?.stock_level} ${baseitem?.quantity_unit}`,
      }}
      title={
        decrease ? t('Decrease item quantity') : t('Increase item quantity')
      }
    >
      <OverlayBody>
        <EditConsumableStockForm
          baseitem={baseitem}
          stockLevel={getActualStockLevel}
        />
      </OverlayBody>
      <OverlayFooter repel gap="10px">
        <SubmitButton
          default
          disabled={(ctx) => !ctx.ready || getActualStockLevel(ctx.values) < 0}
          onClick={(e, ctx) => handleStockEdit(ctx.values)}
        />
        <CancelButton onClick={() => onClose()} />
      </OverlayFooter>
    </OverlayForm>
  )
}

/**
 * Renders the consumable stock amount form
 *
 * @param {any} baseitem baseitem object
 * @param {any} stockLevel function that returns calculated actual stock amount
 *
 * @returns ReactElement
 */
const EditConsumableStockForm = ({ baseitem, stockLevel }) => {
  const { t } = useTranslation()
  const { values } = useForm()

  return (
    <Row>
      <GridFieldView n={12} s={12} label={t('Item')} value={baseitem?.name} />
      <GridFieldView
        n={6}
        s={6}
        label={t('Current stock')}
        value={values.json.stock_level}
      />
      <GridFieldView
        n={6}
        s={6}
        label={t('New stock')}
        value={`${stockLevel(values)} ${baseitem?.quantity_unit}`}
      />
      <GridFieldView n={12} s={12} label={t('Added stock')}>
        <TextField name="amount" mandatory />
      </GridFieldView>
    </Row>
  )
}
