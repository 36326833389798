import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import { DetailViewPage } from 'system/utils/view'
import { SubmitButton } from 'common/widgets/button'
import { formatDeliveryNumber } from 'modules/yard/utils'

import { DeliveryViewDetails } from './details'
import { DeliveryItems } from './delivery-items'
import { HandoverOverlay } from './overlays/handover'

export const SignDeliveryViewPage = () => {
  const { t } = useTranslation()
  const [deliveryHandover, setDeliveryHandover] = useState(null)
  const [confirmedItems, setConfirmedItems] = useState(new Map())
  const [faultyItems, setFaultyItems] = useState(new Map())
  const [disabled, setDisabled] = useState(true)

  return (
    <DetailViewPage
      url="/kommission/delivery"
      title={(delivery) =>
        `${t('Delivery number')}: ${formatDeliveryNumber(
          delivery?.order?.number,
          delivery?.number
        )}`
      }
    >
      {(delivery, reload) => {
        return (
          <>
            <DeliveryViewDetails delivery={delivery} order={delivery.order} />
            <DeliveryItems
              delivery={delivery}
              onChange={reload}
              sign
              commissionedOnly
              onItemConfirm={(item, confirmed) => {
                if (confirmed) {
                  if (!confirmedItems.has(item.id)) {
                    confirmedItems.set(item.id, item)
                  }
                } else if (confirmedItems.has(item.id)) {
                  confirmedItems.delete(item.id)
                }
                setConfirmedItems(confirmedItems)
                setDisabled(
                  confirmedItems.size + faultyItems.size < delivery.items.length
                )
              }}
              onItemFault={(item, { amount, type, comment }) => {
                faultyItems.set(item.id, [
                  item,
                  { id: item.id, amount, type, comment },
                ])
                setFaultyItems(faultyItems)
                setDisabled(
                  confirmedItems.size + faultyItems.size < delivery.items.length
                )
              }}
            />
            {deliveryHandover && (
              <HandoverOverlay
                faults={Array.from(faultyItems, ([name, value]) => {
                  return value[1]
                })}
                onClose={() => setDeliveryHandover(null)}
                delivery={delivery}
              />
            )}
            <SubmitButton
              default
              disabled={disabled}
              onClick={() => {
                setDeliveryHandover(delivery)
              }}
            />
          </>
        )
      }}
    </DetailViewPage>
  )
}
