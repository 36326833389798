import { Calendar } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { SystemPage } from 'system/page/core'
import { useService } from 'common/service/context'
import { formatProjectName } from 'modules/projects/utils'
import { formatDateRange } from 'common/utils/format'
import { Container } from 'common/widgets/container'
import { EquipmentLink } from 'modules/master-data/equipments/widgets/link'
import { CommentView } from 'common/widgets/view'

import { BookingRequestTypeEnum } from './utils'

export const RequestsPage = () => {
  const { t } = useTranslation(['disposition'])
  const navigate = useNavigate()
  const service = useService()

  /**
   * Calls orders get api and loads the data from server side.
   *
   * @param {Array} params query params
   * @returns
   */
  const fetch = async (params) =>
    await service.get('dispositions/requests', [
      ...params,
      { type: BookingRequestTypeEnum.SHRINK, audited: false },
    ])

  return (
    <SystemPage>
      <SimpleDataTable
        name="pendingshrinks"
        fetch={fetch}
        title={t('Pending shrink request')}
        onRowClick={(r) =>
          navigate(`/disposition/requests/shrink/${r.id}/confirm`)
        }
      >
        <SimpleColumn n={12} s={12} sortable filterable>
          {(r) => (
            <Container flex vertical>
              <EquipmentLink equipment={r.booking.baseitem} />
              <CommentView
                icon={<Calendar />}
                comment={formatDateRange(r.booking.start, r.booking.end)}
              />
            </Container>
          )}
        </SimpleColumn>
        <SimpleColumn
          n={3}
          s={6}
          sortable
          field="project_id"
          label={t('Project')}
          value={(r) => formatProjectName(r.project)}
          link={(r) => (r.project_id ? `/projects/${r.project_id}` : null)}
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          label={t('Shrink')}
          value={(r) => formatDateRange(r.start, r.end)}
        />
        <SimpleColumn
          n={2}
          s={6}
          sortable
          field="maincategory"
          label={t('Main group')}
          value={(r) => r.booking.baseitem.maincategory}
        />
        <SimpleColumn
          n={2}
          s={6}
          sortable
          field="category"
          label={t('Group')}
          value={(r) => r.booking.baseitem.category}
        />
        <SimpleColumn
          n={2}
          s={6}
          sortable
          field="subcategory"
          label={t('Subgroup')}
          value={(r) => r.booking.baseitem.subcategory}
        />
      </SimpleDataTable>
    </SystemPage>
  )
}
