import { SystemPage } from 'system/page/core'
import { register } from 'system/container'

import { ProjectPhaseDesignPage } from './phases/canvas'
import { ProjectTypesListPage } from './types/list'

register({
  menu: true,
  render: () => <SystemPage />,
  title: 'Projects',
  path: '/master-data/projects',
  priority: 0.0,
})

register({
  menu: true,
  render: () => <ProjectTypesListPage />,
  title: 'Project templates',
  path: '/master-data/projects/types',
  priority: 0.0,
})

register({
  menu: true,
  render: () => <ProjectPhaseDesignPage />,
  title: 'Design',
  path: '/master-data/projects/types/:id',
  priority: 0,
})
