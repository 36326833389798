import { FieldWrapper } from 'common/widgets/form/field'
import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'
import { DropDown } from 'common/widgets/dropdown'

/**
 * Renders project type select widget.
 * @param {Function} onSelectChange calls when project type select changes
 * @returns ReactElement
 */
export const ProjectTypeSelect = ({
  selectedProjectyTypeId,
  onSelectChange,
  disabled,
  ...rest
}) => {
  const service = useService()

  const fetch = async (params) => {
    if (disabled) {
      if (selectedProjectyTypeId) {
        return await service.get(`projects/types/${selectedProjectyTypeId}`)
      }
    } else {
      return await service.get('projects/types', [
        ...params,
        { archived: false },
      ])
    }
  }

  return (
    <DataSource fetch={fetch}>
      {({ data }) => {
        if (data && !Array.isArray(data)) {
          data = [data]
        }
        return (
          <DropDown
            minWidth="320px"
            maxWidth="320px"
            items={data?.map((type) => ({
              key: type.id,
              title: type.name,
              data: type,
            }))}
            onSelectChange={(e) => onSelectChange && onSelectChange(e.data)}
            {...rest}
            // user should not override selected, since we manage it
            // hence it appears after passing ...rest
            selectedIndex={data?.findIndex(
              (i) => i.id === selectedProjectyTypeId
            )}
            disabled={disabled}
          />
        )
      }}
    </DataSource>
  )
}

/**
 * Renders project type select field.
 * @returns ReactElement
 */
export const ProjectTypeIdSelectField = ({ onSelectChange, ...rest }) => (
  <FieldWrapper {...rest}>
    {(getValue, setValue, values) => (
      <ProjectTypeSelect
        selectedProjectyTypeId={getValue()}
        onSelectChange={(type) => {
          setValue(type.id)
          if (onSelectChange) {
            onSelectChange(type)
          }
        }}
        {...rest}
      />
    )}
  </FieldWrapper>
)

/**
 * Renders project phase select widget.
 * @param {int} projectTypeId project type id
 * @param {Function} onSelectChange calls when project phase select changes
 * @returns ReactElement
 */
const ProjectPhaseSelect = ({
  projectTypeId,
  onSelectChange,
  selectedPhaseId,
  ...rest
}) => {
  if (!projectTypeId) {
    return '...'
  }

  return (
    <DataSource
      url="projects/phases"
      params={[
        {
          project_type_id: projectTypeId,
        },
      ]}
    >
      {({ data }) => {
        return (
          <DropDown
            minWidth="320px"
            maxWidth="320px"
            items={data
              ?.sort((a, b) => a.name >= b.name)
              ?.map((type) => ({
                key: type.id,
                title: type.name,
                data: type,
              }))}
            onSelectChange={(e) => onSelectChange && onSelectChange(e?.data)}
            {...rest}
            // user can should not override selected, since we manage it
            // hence it appears after passing ...rest
            selectedIndex={data?.findIndex((i) => i.id === selectedPhaseId)}
          />
        )
      }}
    </DataSource>
  )
}

/**
 * Renders project phase select field.
 * @param {Function} onSelectChange calls when project phase select changes
 * @returns ReactElement
 */
export const ProjectPhaseIdSelectField = ({ onSelectChange, ...rest }) => (
  <FieldWrapper {...rest}>
    {(getValue, setValue, values) => {
      return (
        <ProjectPhaseSelect
          selectedPhaseId={getValue()}
          onSelectChange={(phase) => {
            if (phase) {
              setValue(phase.id)
            } else {
              setValue(null)
            }
            if (onSelectChange) {
              onSelectChange(phase)
            }
          }}
          {...rest}
        />
      )
    }}
  </FieldWrapper>
)
