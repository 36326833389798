import { Calendar } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { OverlayBody, OverlayFooter, OverlayForm } from 'common/widgets/overlay'
import { formatItemName } from 'modules/master-data/equipments/utils'
import { CommentView, GridFieldView } from 'common/widgets/view'
import { Column, Row } from 'common/widgets/grid'
import { ConfirmButton, BackButton } from 'common/widgets/button'
import { formatProjectName } from 'modules/projects/utils'
import { DateField, SelectField } from 'common/widgets/form/field'
import { useForm } from 'common/widgets/form/context'
import { differenceInDays } from 'common/utils/date'
import { useService } from 'common/service/context'
import { useToast } from 'system/toast/context'

export const BookingConfirmOverlay = ({
  request,
  resource,
  start,
  end,
  onClose,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const service = useService()
  const { toasts } = useToast()

  /**
   * Confirms booking request.
   * @param {any} data confirm data contains start and end date
   * @returns
   */
  const handleConfirm = async (data) => {
    const [result, error] = await service.put(
      `dispositions/requests/${request.id}/resource/confirm`,
      {
        ...data,
        baseitem_id: resource.id,
        cost_rate: resource.cost_rate,
        accounting_type: resource.accounting_type,
        replaceable: data.replaceable === 'true',
      }
    )
    if (!error) {
      toasts.success('Booking is confirmed!')
      navigate('/disposition/requests/new')
    }
    return [result, error]
  }

  return (
    <OverlayForm
      open={resource !== null && start !== null && end !== null}
      onClose={onClose}
      data={{ start, end, replaceable: 'false' }}
      title="Booking"
    >
      <OverlayBody>
        <Row>
          <Column n={12}>
            <h3>{formatItemName(resource)}</h3>
          </Column>
          <GridFieldView n={6} s={6} label={t('Project')}>
            <h4>{formatProjectName(request.project)}</h4>
          </GridFieldView>
          <GridFieldView n={6} s={6} label={t('Amount')}>
            <h4>1</h4>
          </GridFieldView>
          <Column n={12}>
            <Duration />
          </Column>
          <GridFieldView n={6} s={6} label={t('Start*')}>
            <DateField name="start" mandatory />
          </GridFieldView>
          <GridFieldView n={6} s={6} label={t('End')}>
            <DateField name="end" inclusive mandatory />
          </GridFieldView>
          <GridFieldView n={12} label={t('Can be replaced')}>
            <SelectField
              name="replaceable"
              items={[
                { key: 'true', title: t('Replaceable') },
                { key: 'false', title: t('Not replaceable') },
              ]}
            />
          </GridFieldView>
        </Row>
      </OverlayBody>
      <OverlayFooter repel>
        <ConfirmButton
          disabled={(ctx) =>
            !ctx.ready ||
            differenceInDays(ctx.values.json.end, ctx.values.json.start) <= 0
          }
          onClick={(e, ctx) => handleConfirm(ctx.values.json)}
        />
        <BackButton onClick={onClose} />
      </OverlayFooter>
    </OverlayForm>
  )
}

const Duration = () => {
  const { t } = useTranslation()
  const { values } = useForm()
  const { start, end } = values.json
  const days = differenceInDays(end, start)
  return (
    <CommentView
      icon={<Calendar />}
      comment={t('Booking is for [{{days}}] day/days.', { days })}
    />
  )
}
