import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Container } from 'common/widgets/container'
import { Row } from 'common/widgets/grid'
import { GridFieldView, RowView } from 'common/widgets/view'
import { EquipmentGridLinkFieldView } from 'modules/master-data/equipments/widgets/link'
import {
  AddIconButton,
  FlagIconButton,
  FoldButtons,
} from 'common/widgets/button'
import { formatDateRange } from 'common/utils/format'
import { getQuantityUnit } from 'modules/master-data/equipments/utils'

import { PickBackdeliveryItemOverlay } from './overlays/add'

/**
 * Renders the Back Delivery`s items based on the baseitem type.
 *
 * @param {any} backDeliveryItems back delivery items array
 * @param {any} onAdd onClick callback when backdelivery item is added
 * @param {string} title title of the component
 * @param {any} onAdd registers adding items to back delivery
 *
 * @returns ReactElement
 */
export const BackDeliveredItems = ({ backDeliveryItems, title, onAdd }) => {
  const { t } = useTranslation()
  const [backDeliveryFaults, setBackDeliveryFaults] = useState(null)
  return (
    <Container flex vertical gap="5px">
      <h3>{t(title)}</h3>
      {backDeliveryItems?.map((e, idx) => {
        var commissionedAmount = 0
        if (e.commissionedAmount) {
          commissionedAmount = e.commissionedAmount
        }
        var menge
        if (onAdd) {
          menge = `${commissionedAmount} / ${e.amount}`
        } else {
          menge = `${e.amount}`
        }

        return (
          <RowView key={idx}>
            <Row>
              <EquipmentGridLinkFieldView
                n={3}
                label={t('Item')}
                baseitem={e?.baseitem}
              />
              <GridFieldView
                n={3}
                label={t('Menge')}
                value={`${menge} ${getQuantityUnit(e.baseitem)}`}
              />
              <GridFieldView
                n={3}
                label={t('Booking period')}
                value={`${formatDateRange(e?.booking?.start, e?.booking?.end)}`}
              />
            </Row>
            <FoldButtons>
              {onAdd && <AddIconButton onClick={() => onAdd(e)} />}
              {e.faults?.length > 0 && (
                <FlagIconButton onClick={() => setBackDeliveryFaults(e)} />
              )}
            </FoldButtons>
            {backDeliveryFaults && (
              <PickBackdeliveryItemOverlay
                disabled
                backdeliveryItem={backDeliveryFaults}
                onClose={() => setBackDeliveryFaults(null)}
              />
            )}
          </RowView>
        )
      })}
    </Container>
  )
}
