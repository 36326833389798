import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { OverlayBody, OverlayFooter, OverlayForm } from 'common/widgets/overlay'
import { Column, Row } from 'common/widgets/grid'
import { Container } from 'common/widgets/container'
import { useForm } from 'common/widgets/form/context'
import { CancelButton, DownloadButton } from 'common/widgets/button'
import { CheckBoxField } from 'common/widgets/form/field'
import { getType, isArray, isObject } from 'common/data-table/export/utils'
import { getMaxHeight } from 'common/utils/size'
import { useQuery } from 'common/query/context'
import { CSVLink } from 'common/data-table/export/lib/link'

/**
 * Render an export button on the parent form.
 *
 * @param {Function} onClose closing hook
 * @returns JSX.Element
 */
const ExportAction = ({ onClose }) => {
  const { t } = useTranslation()
  const form = useForm()
  // Keeps the state of generating the csv file
  const [generating, setGenerating] = useState(false)
  // Keeps the records that should be exported to csv file
  const [records, setRecords] = useState([])
  const query = useQuery()
  // Keeps the current stream
  const stream = query.stream()

  /**
   * Get the field label for given field name.
   *
   * @param {string} name field name
   * @returns string
   */
  const getFieldLabel = (name) =>
    `${t(toTitle(name)).replaceAll('"', "'")} [${name}]`

  /**
   * Get the headers that should be included in csv file.
   *
   * @param {Object} headerData header form data
   * @returns Array<object>
   */
  const getHeaders = (headerData) => {
    let headers = []
    for (let name in headerData) {
      if (headerData[name] === true) {
        let fieldInfo = { key: name, label: getFieldLabel(name) }
        headers.push(fieldInfo)
      }
    }
    return headers
  }

  /**
   * Fetch all records to be exported.
   */
  const fetchAll = async () => {
    let result = []
    if (!generating) {
      setGenerating(true)
      while (stream.hasNext()) {
        let item = await stream.next()
        result.push(item)
      }
      setGenerating(false)
      setRecords(result)
    }
  }

  /**
   * Get all ready for export records.
   *
   * @returns Array<object>
   */
  const getRecords = () => {
    return records
  }

  return (
    <>
      <CSVLink
        data={getRecords}
        headers={getHeaders(form.values.json)}
        dataloader={fetchAll}
      >
        <DownloadButton
          default
          disabled={!form.ready || generating}
          text={generating ? `${t('Exporting')}...` : t('Export')}
        />
      </CSVLink>
      <CancelButton onClick={onClose} />
    </>
  )
}

/**
 * Render an overlay to select columns for export.
 *
 * @param {boolean} open specifies that the overlay is open
 * @param {Function} onClose closing hook
 * @returns JSX.Element
 */
export const ExportOverlay = ({ open, onClose }) => {
  const { t } = useTranslation()
  const query = useQuery()
  // Keeps the current stream
  const stream = query.stream()

  /**
   * Get a value indicating that given value is exportable to csv.
   *
   * @param {Object} value the value to be checked
   * @returns Boolean
   */
  const isExportable = (value) => {
    return !isObject(value) && !isArray(value)
  }

  /**
   * Get the initial data of the form.
   *
   * @returns Object
   */
  const getFormData = () => {
    let result = {}
    if (stream.count() > 0) {
      let record = stream.first()
      for (let name in record) {
        if (isExportable(record[name])) {
          result[name] = true
        }
      }
    }
    return result
  }

  return (
    <OverlayForm
      open={open}
      title={t('Fields')}
      onClose={onClose}
      data={getFormData()}
    >
      <OverlayBody>
        <ScrollableContainer>
          <Row>
            <Column n={12} s={12}>
              <h5>
                {t('Total Records')}
                {t(` ${stream.count()}`)}
              </h5>
            </Column>
          </Row>
          <Row>
            {stream.count() > 0 &&
              Object.keys(stream.first())
                .filter((name, index) => {
                  return isExportable(stream.first()[name])
                })
                .map((name, index) => {
                  return (
                    <Column n={12} s={12} key={name}>
                      <CheckBoxCard
                        name={name}
                        firstRowValue={stream.first()[name]}
                      />
                    </Column>
                  )
                })}
          </Row>
        </ScrollableContainer>
      </OverlayBody>
      <OverlayFooter repel>
        <ExportAction onClose={onClose} />
      </OverlayFooter>
    </OverlayForm>
  )
}

/**
 * Render a checkbox card info for datasource field.
 *
 * @param {string} name Name of the field
 * @param {object} firstRowValue The value of the related field in the first record
 * @returns JSX.Element
 */
const CheckBoxCard = ({ name, firstRowValue }) => {
  const { t } = useTranslation()

  return (
    <FieldView>
      <CheckBoxField name={name} />
      <Row>
        <Column n={9} s={9}>
          <p>{t(toTitle(name))}</p>
        </Column>
        <Column n={3} s={3}>
          <p>{getType(firstRowValue)}</p>
        </Column>
      </Row>
    </FieldView>
  )
}

const ScrollableContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: ${() => getMaxHeight(0.35)};
  max-width: 600px;
`

const FieldView = styled(Container)`
  background: #ffffff;
  margin: 0;
  border-radius: 0;
  border-top: 1px solid #eeeeee;
  display: flex;
  padding-top: 5px;
`

/**
 * Capitalizes the first letter of the given string.
 *
 * @param {string} value string value
 * @returns string
 */
const capitalizeFirst = (value) =>
  value !== null && value !== undefined
    ? value.charAt(0).toUpperCase() + value.slice(1)
    : null

/**
 * Capitalizes the first letter of the given string and replaces all underscores with space.
 *
 * @param {string} value string value
 * @returns string
 */
const toTitle = (value) =>
  value !== null && value !== undefined
    ? capitalizeFirst(value).replaceAll('_', ' ')
    : null
