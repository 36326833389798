import { register } from 'system/container'
import { SystemPage } from 'system/page/core'

import { DeliveryAddPage } from './add'
import { AdHocDeliveryPage } from './adhoc/add'
import { DeliveryDetailViewPage } from './details'
import { DeliveryDataTable } from './list'
import { OrderList } from './order-list'
import { PickUpDeliveryViewPage } from './pickup'
import { SignDeliveryViewPage } from './sign'

register({
  render: () => <DeliveryAddPage />,
  title: 'Create',
  path: '/yard/commission/deliveries/:id/add',
})

register({
  render: () => <DeliveryDetailViewPage />,
  title: 'Details',
  path: '/yard/commission/deliveries/:id',
})

register({
  menu: true,
  render: () => (
    <SystemPage>
      <DeliveryDataTable />
    </SystemPage>
  ),
  title: 'Deliveries',
  path: '/yard/commission/deliveries',
  priority: 0.0,
})

register({
  menu: true,
  render: () => <OrderList />,
  title: 'Ordered items',
  path: '/yard/commission/orders',
  priority: -1,
})

register({
  render: () => <PickUpDeliveryViewPage />,
  title: 'Load',
  path: '/yard/commission/deliveries/:id/pickup',
})

register({
  render: () => <SignDeliveryViewPage />,
  title: 'Handover',
  path: '/yard/commission/deliveries/:id/sign',
})

register({
  render: () => <AdHocDeliveryPage />,
  title: 'Ad Hoc Delivery',
  path: '/yard/commission/deliveries/adhoc',
})
