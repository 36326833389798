import { register } from 'system/container'
import { SystemPage } from 'system/page/core'

import { CategoryList } from './list'
import { CategoryAdd } from './add'
import { CategoryEdit } from './edit'
import { CategoryView } from './view'

register({
  menu: true,
  render: () => (
    <SystemPage>
      <CategoryList />
    </SystemPage>
  ),
  title: 'Categories',
  path: '/master-data/categories',
})

register({
  render: () => (
    <SystemPage>
      <CategoryAdd />
    </SystemPage>
  ),
  title: 'New',
  path: '/master-data/categories/add',
})

register({
  render: () => <CategoryEdit />,
  title: 'Edit',
  path: '/master-data/categories/:id/edit',
})

register({
  render: () => <CategoryView />,
  title: 'View',
  path: '/master-data/categories/:id',
})
