import React from 'react'
import styled, { css } from 'styled-components'

import { stringToColor } from 'common/utils/color'
import { RemoteImage } from 'common/widgets/image'
import { useSession } from 'common/session/context'

const DEFAULT_AVATAR_SIZE = '72px'

/**
 * Shortens the given string name.
 *
 * @param {string} value name or anything
 * @returns string
 */
const makeShort = (value) => {
  // Removing all spaces. It will be used if the string has only special characters.
  let fallbackText = value?.replace(/ +/g, '')
  // Keeping only alphabets (including German), numbers and space characters.
  let text = value?.toLowerCase().replace(/[^a-zA-Z0-9 äöüÄÖÜß]+/g, '')
  // Reducing consecutive spaces into a single space character.
  text = text?.replace(/  +/g, ' ')
  text =
    text?.length > 0 && text?.trim() != ''
      ? text
      : fallbackText?.length > 0
      ? fallbackText
      : '??'
  const texts = text
    .trim()
    .split(' ')
    .filter((e) => e.length > 0)

  const word1 = texts.length > 0 ? texts[0] : '??'
  const word2 =
    texts.length > 1 ? texts[1] : word1.length > 1 ? word1[1] : word1[0]
  return `${word1[0]}${word2[0]}`.toUpperCase()
}

/**
 * Renders avatar box.
 *
 * @param {number} id remote image file id
 * @param {string} imgSrc any valid img source, such as Data URL
 * @param {string} name avatar name
 * @returns ReactElement
 */
export const Avatar = ({
  id,
  name,
  imgSrc,
  color,
  mini,
  size,
  showExactName,
  ...rest
}) => {
  if (id && imgSrc) {
    console.assert('Either "id" or "imgSrc" should be provided, not both.')
    return null
  }
  return (
    <AvatarContainer
      background={
        color ? color : !(id || imgSrc) ? stringToColor(name) : 'white'
      }
      size={mini ? '32px' : size}
      {...rest}
    >
      {id && (
        <RemoteImage id={id} thumbnail>
          {(data) => <AvatarImage src={data} />}
        </RemoteImage>
      )}
      {imgSrc && <AvatarImage src={imgSrc} />}
      {!id && !imgSrc && (
        <StyledH1 mini={mini || parseInt(size) <= 32}>
          {showExactName ? name : name ? makeShort(name) : '?'}
        </StyledH1>
      )}
    </AvatarContainer>
  )
}

export const ProfileAvatar = ({ userId, ...rest }) => {
  const { currentProfileImage, currentUser } = useSession()
  if (!userId) {
    userId = currentUser.id
  }
  return <Avatar imgSrc={currentProfileImage} {...rest} />
}

const AvatarImage = styled.img`
  flex: 1;
  margin: auto;
  display: block;
  max-width: ${(props) => props.size || DEFAULT_AVATAR_SIZE};
  max-height: ${(props) => props.size || DEFAULT_AVATAR_SIZE};
  width: auto;
  height: auto;
  border-radius: ${(props) => props.borderRadius || '5px'};
  cursor: pointer;
  object-fit: contain;
`

const StyledH1 = styled.h1`
  margin: auto;
  color: #ffffff;
  cursor: pointer;
  ${(props) =>
    props.mini &&
    css`
      font-size: 16px;
      line-height: 18px;
    `}
`

export const AvatarContainer = styled.div`
  background: ${(props) => props.background};
  border-radius: ${(props) => props.borderRadius || '5px'};
  display: flex;
  width: ${(props) => props.size || DEFAULT_AVATAR_SIZE};
  height: ${(props) => props.size || DEFAULT_AVATAR_SIZE};
  img {
    display: block;
    object-fit: contain;
    max-width: ${(props) => props.size || DEFAULT_AVATAR_SIZE};
    max-height: ${(props) => props.size || DEFAULT_AVATAR_SIZE};
    width: auto;
    height: auto;
  }
  align-items: center;
  flex: 0 0 auto;
  cursor: ${(props) => props.cursor};
`
