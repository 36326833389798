import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useMediaQuery } from 'common/utils/hooks'

/**
 * A glorified wrapper for html div.
 *
 * Note: using forwardRef is necessary to pass the ref to the child
 * component (ContainerInner).
 **/
export const Container = forwardRef(function ContainerOuter(
  { title, subtitle, titleDivider, children, before, ...rest },
  ref
) {
  const { t } = useTranslation()
  return (
    <ContainerInner ref={ref} {...rest}>
      {before && before}
      {title && (
        <h1
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginRight: 'auto',
            marginBottom: titleDivider ? '10px' : 'inherit',
            borderBottom: titleDivider ? '1px solid #e9e9e9' : 'inherit',
          }}
        >
          {t(title)}
          {subtitle && <small>{t(subtitle)}</small>}
        </h1>
      )}
      {!title && subtitle && (
        <h3
          style={{
            alignSelf: 'center',
            textAlign: 'center',
            marginRight: 'auto',
          }}
        >
          {t(subtitle)}
        </h3>
      )}
      {children}
    </ContainerInner>
  )
})

const ContainerInner = styled.div.attrs((props) => ({
  wrap: props.wrap ? 'true' : 'false',
}))`
  ${(props) =>
    props.background &&
    css`
      background: ${props.background};
    `}
  ${(props) =>
    props.marginLeft &&
    css`
      margin-left: ${props.marginLeft};
    `}
  ${(props) =>
    props.marginRight &&
    css`
      margin-right: ${props.marginRight};
    `}
  ${(props) =>
    props.relative &&
    css`
      position: relative;
    `}
  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
    `}
  ${(props) =>
    props.flex &&
    props.visible !== false &&
    css`
      display: flex;
    `}
  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${(props) =>
    props.direction &&
    css`
      flex-direction: ${props.direction};
    `}
  ${(props) =>
    props.vertical &&
    css`
      flex-direction: ${(props) =>
        props.reverse ? 'column-reverse' : 'column'};
    `}
  ${(props) =>
    props.flex &&
    !props.vertical &&
    css`
      flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${(props) =>
    props.align &&
    css`
      align-items: ${props.align};
    `}
  ${(props) =>
    props.repel &&
    css`
      justify-content: space-between;
    `}
  ${(props) =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}
  ${(props) =>
    props.border &&
    css`
      border: ${props.border};
    `}
  ${(props) =>
    props.grow &&
    css`
      flex-grow: 1;
    `}
  ${(props) =>
    props.fixed &&
    css`
      flex: 0 0 auto;
    `}
  ${(props) =>
    props.visible === false &&
    css`
      display: none;
    `}
  ${(props) =>
    props.wrap !== 'false' &&
    css`
      flex-wrap: wrap;
    `}
  ${(props) =>
    props.separate &&
    css`
      border-top: 1px solid #eeeeee;
    `}
  ${(props) =>
    props.gap &&
    css`
      gap: ${props.gap};
    `}
  ${(props) =>
    props.interactive ||
    (props.hover &&
      css`
        cursor: pointer;
        transition: 0.5s;
        :hover {
          background: ${props.hover ? props.hover : '#fbfbfb'};
          padding-left: 5px;
        }
      `)}
  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `}
  ${(props) =>
    props.divider &&
    `
      > * {
        padding-top: 5px;
        border-top: 1px solid #e0e0e0;
      }
    `}

  @media (max-width: 1280px) {
    ${(props) =>
      props.large &&
      css`
        display: none;
      `}
  }

  @media (max-width: 768px) {
    ${(props) =>
      props.desktop &&
      css`
        display: none;
      `}
  }

  @media (min-width: 768px) {
    ${(props) =>
      props.mobile &&
      css`
        display: none;
      `}
  }
  ${(props) =>
    props.noprint &&
    useMediaQuery('print') &&
    css`
      display: none;
    `}
`

export const Box = styled.div`
  width: ${(props) => props.nowidth ?? '100%'};
  height: 100%;
  border: 1px solid ${(props) => props.color ?? '#e9e9e9'};
  border-radius: 0px;
  padding: ${(props) => (props.nopadding ? '0px' : '15px')};
  border-top: ${(props) => (props.top ? null : 'none')};
  border-bottom: ${(props) => (props.bottom ? null : 'none')};
  border-left: ${(props) => (props.left ? null : 'none')};
  border-right: ${(props) => (props.right ? null : 'none')};
`
