import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'
import { DropDownField } from 'common/widgets/form/field'

/**
 * Renders a dropdown field containing contacts for selecting parent.
 *
 * @returns ReactElement
 */
export const ContactTagDropDownField = ({ excludes, ...rest }) => {
  const service = useService()

  /**
   * Fetches tags and returns it as an array.
   *
   * @returns Array
   */
  const fetch = async () => await service.get('contacts/defs/tags')

  return (
    <DataSource
      fetch={fetch}
      render={({ data }) => (
        <DropDownField
          items={
            data
              ? [
                  { key: null, title: '-' },
                  ...data
                    .filter((e) => !excludes?.includes(e.id))
                    .map((e) => ({ key: e.id, title: e.name })),
                ]
              : []
          }
          {...rest}
        />
      )}
    ></DataSource>
  )
}
