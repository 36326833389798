import React from 'react'

import { useNotification } from 'system/toolbar/notification/context'
import { NotificationIconButton } from 'common/widgets/button'

import { NotificationOverlay } from './overlay'

export const NotificationIcon = ({ name, active, onClose }) => {
  const { unreadCount } = useNotification()
  return (
    <>
      <NotificationIconButton badge={unreadCount <= 99 ? unreadCount : '99+'} />
      <NotificationOverlay name={name} open={active} onClose={onClose} />
    </>
  )
}
