import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { DetailViewPage } from 'system/utils/view'
import { useService } from 'common/service/context'
import { AddIconButton, SubmitButton } from 'common/widgets/button'
import { formatDeliveryNumber } from 'modules/yard/utils'

import { DeliveryViewDetails } from './details'
import { DeliveryItems } from './delivery-items'
import { PickUpSelectedItem } from './overlays/add'
import { ItemSearchOverlay } from './overlays/search'

export const DeliveryAddPage = () => {
  const { t } = useTranslation()
  const service = useService()
  const navigate = useNavigate()

  const [adHocOverlayVisible, setAdHocOverlayVisible] = useState(false)
  const [adHocItem, setAdHocItem] = useState(null)

  return (
    <DetailViewPage
      url="/kommission/delivery"
      title={(delivery) =>
        `${t('Delivery number')}: ${formatDeliveryNumber(
          delivery?.order?.number,
          delivery?.number
        )}`
      }
      addExtraButtons={() => (
        <AddIconButton
          onClick={() => {
            setAdHocOverlayVisible(true)
          }}
        />
      )}
    >
      {(delivery, reload) => (
        <>
          <DeliveryViewDetails
            delivery={delivery}
            order={delivery.order}
            reload={reload}
          />
          <DeliveryItems delivery={delivery} onChange={reload} />
          <SubmitButton
            default
            text={t('Complete')}
            disabled={delivery?.items?.length === 0}
            onClick={async () => {
              const [, error] = await service.put(
                `kommission/delivery/${delivery.id}/submit`
              )
              if (!error) {
                navigate(-1)
              }
            }}
          />
          {/* adHoc stuff here */}
          <ItemSearchOverlay
            open={adHocOverlayVisible}
            ignoreItems={delivery?.items}
            onClose={() => setAdHocOverlayVisible(false)}
            onSelect={(item) => {
              setAdHocItem(item)
              setAdHocOverlayVisible(false)
            }}
          />
          <PickUpSelectedItem
            open={adHocItem}
            item={adHocItem}
            delivery={delivery}
            onClose={() => setAdHocItem(null)}
            reload={reload}
          />
        </>
      )}
    </DetailViewPage>
  )
}
