import {
  getPackagingUnit,
  getQuantityUnit,
  hasPackagingUnit,
  hasQuantityUnit,
} from 'modules/master-data/equipments/utils'

// Defines order states
export const OrderStateEnum = {
  CART: 'CART',
  OPEN: 'OPEN',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE',
  CANCELED: 'CANCELED',
}

export const OrderStateColor = {
  CART: '#888888',
  OPEN: '#AEC4FA',
  IN_PROGRESS: '#F9F29A',
  COMPLETE: '#95E68B',
  CANCELED: '#FC4242',
}

/**
 * Formats the order number based on the order data.
 *
 * @param {any} order order object
 * @returns string
 */
export const formatOrderNumber = (orderNumber) =>
  orderNumber ? String(orderNumber).padStart(12, '0') : '-'

/**
 * Formats ordered item amount using equipment data.
 *
 * @param {any} orderedItem ordered item data
 * @returns String
 */
export const formatOrderedItemAmount = (item, amount) =>
  item
    ? hasQuantityUnit(item)
      ? `${amount} (${getQuantityUnit(item)})`
      : hasPackagingUnit(item)
      ? `${amount} (${getPackagingUnit(item)})`
      : `${amount}`
    : '-'
