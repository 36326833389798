import React from 'react'
import { useLocation } from 'react-router-dom'

/**
 * Return query string parameters as a JSON object
 * @returns any
 */
export const useQueryParams = () => {
  const { search } = useLocation()
  // Recalculate only when the query string changes
  const queryParams = React.useMemo(() => new URLSearchParams(search), [search])

  return Object.fromEntries(queryParams.entries())
}
