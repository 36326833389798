import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { usePage } from '../page/context'

import { NotificationIcon } from './notification/icon'
import { ProfileIcon } from './user/icon'
import { MasterSearchField } from './search/icon'
import styles from './toolbar.module.css'

export const SystemToolbar = () => {
  const [active, setActive] = useState()
  return (
    <div className={styles.toolbarWrapper}>
      <PageBreadcrumb />
      <div className={styles.toolbarMenu}>
        <MasterSearchField
          onMouseUp={(e) => {
            e.stopPropagation()
            setActive((prev) => (prev == 'search' ? null : 'search'))
          }}
          name="search"
          active={active === 'search'}
          onClose={() => setActive(null)}
        />
        <div
          className={styles.toolbarMenuItem}
          onMouseUp={(e) => {
            e.stopPropagation()
            setActive((prev) =>
              prev == 'notifications' ? null : 'notifications'
            )
          }}
        >
          <NotificationIcon
            name="notifications"
            active={active === 'notifications'}
            onClose={() => setActive(null)}
          />
        </div>
        <div
          className={styles.toolbarMenuItem}
          onMouseUp={(e) => {
            e.stopPropagation()
            setActive((prev) => (prev == 'profile' ? null : 'profile'))
          }}
        >
          <ProfileIcon
            name="profile"
            active={active === 'profile'}
            onClose={() => setActive(null)}
          />
        </div>
      </div>
    </div>
  )
}

/**
 * Render a breadcrumb for a list of pages.
 **/
const PageBreadcrumb = ({ separator = '/', children }) => {
  const { t } = useTranslation()
  const currentPage = usePage()
  const pages = currentPage?.getBreadCrumbPages() ?? []

  return (
    <div className={styles.breadCrumb}>
      {pages.map((page, idx) => (
        <Link
          className={
            page?.path == currentPage.path
              ? styles.breadCrumbLinkActive
              : styles.breadCrumbLink
          }
          key={idx}
          to={page?.path == currentPage.path ? '#' : page?.path}
        >
          {t(page?.title)}
          {pages.length - idx > 1 && (
            <h5
              style={{
                display: 'inline',
                padding: '0 5px',
              }}
            >
              {separator}
            </h5>
          )}
        </Link>
      ))}
    </div>
  )
}
