import { register } from 'system/container'

import { RoleAddPage } from './add'
import { RoleEditPage } from './edit'
import { RolesListPage } from './list'

register({
  render: () => <RoleAddPage />,
  title: 'New',
  path: '/security/roles/add',
  priority: 0.0,
})

register({
  render: () => <RoleEditPage />,
  title: 'Edit',
  path: '/security/roles/:id/edit',
  priority: 0.0,
})

register({
  menu: true,
  render: () => <RolesListPage />,
  title: 'Roles',
  path: '/security/roles',
  priority: 0,
})
