import './phase-plan.css'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PagedDataSource } from 'common/widgets/data-source'
import {
  dateToISOString,
  formatDate,
  formatDateRange,
} from 'common/utils/format'
import { addDays } from 'common/utils/date'
import { CardView, GridFieldView } from 'common/widgets/view'
import { Container } from 'common/widgets/container'
import {
  CalendarSearch,
  FullCalendarWrapper,
  createEvent,
} from 'common/widgets/calendar'
import { Row } from 'common/widgets/grid'
import { OverlayForm } from 'common/widgets/overlay'
import { DateField, NumericField } from 'common/widgets/form/field'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { useService } from 'common/service/context'
import { AddIconButton } from 'common/widgets/button'
import { NumericInput } from 'common/widgets/input'
import { useToast } from 'system/toast/context'

const PAGE_SIZE = 10

/**
 * Renders the project plan calendar.
 *
 * @param {any} project project object
 *
 * @returns ReactElemnt
 */
export const PhasePlanCalendar = ({ project }) => {
  const { t } = useTranslation()
  const [selectedPlanData, setSelectedPlanData] = useState(null)
  // Keep the start and end date of the calendar's current view
  const [fromDate, setFromDate] = useState(addDays(new Date(), -14))
  const [toDate, setToDate] = useState(addDays(new Date(), 14))
  const service = useService()
  // Handler for the data source
  const handler = {}

  /**
   * Handles the selection of absence in the calendar
   *
   * @param {any} selection calendar`s event object
   */
  const handleEventClick = (selection) => {
    const event = selection?.event?._def.extendedProps
    if (event?.ui?.display === 'background') {
      return
    }

    setSelectedPlanData({
      project: project,
      phase: event,
      start_date: event.start_date,
      end_date: event.end_date,
      planned_hours: event.planned_hours ?? 0,
      planned_budget: event.planned_budget ?? 0,
    })
  }

  const handleSubmit = async (values) => {
    const requestBody = {}
    if (values['start_date']) {
      requestBody['start_date'] = values['start_date']
    }
    if (values['end_date']) {
      requestBody['end_date'] = values['end_date']
    }
    if (values['planned_hours']) {
      requestBody['planned_hours'] = values['planned_hours']
    }
    if (values['planned_budget']) {
      requestBody['planned_budget'] = values['planned_budget']
    }
    const [result, error] = await service.put(
      `/projects/${project.id}/phases/${values['phase_id']}`,
      requestBody
    )
    setSelectedPlanData(null)
    handler.reload()

    return [result, error]
  }

  return (
    <PagedDataSource
      url={`/projects/${project.id}/phases`}
      params={[
        { start: dateToISOString(fromDate, true) },
        { end: dateToISOString(toDate, true) },
      ]}
      limit={PAGE_SIZE}
      handler={handler}
    >
      {({ data }) => {
        const phases = data?.map((e) => ({
          id: e.id,
          title: e.name,
          data: e,
        }))
        const events = data?.map((e, i) =>
          createEvent({
            id: i,
            start: e.start_date,
            end: e.end_date,
            resourceId: e?.id,
            className: 'phase-plan-event',
            extendedProps: {
              ...e,
            },
          })
        )

        return (
          <>
            <CardView>
              <FullCalendarWrapper
                datesSet={(data) => {
                  setFromDate(addDays(data.start, -14))
                  setToDate(addDays(data.end, 14))
                }}
                initialView={'customTimeline'}
                eventResourceEditable={false}
                resourceAreaHeaderContent={() => (
                  <CalendarSearch title="Phases" />
                )}
                resourceLabelContent={(resource) => (
                  <PhaseInfo data={resource} onSubmit={handleSubmit} />
                )}
                eventMouseEnter={(info) => {
                  let phase = info.event?.extendedProps
                  if (info.event?.display === 'background' || !phase) {
                    return
                  }
                  info.el.title = `${formatDateRange(
                    phase.start_date,
                    phase.end_date
                  )} - ${phase.name}`
                }}
                titleFormat={{ week: 'short' }}
                resources={phases}
                events={events}
                eventContent={({ event }) => <PhaseEvent event={event} />}
                selectable
                select={(e) =>
                  setSelectedPlanData({
                    project: project,
                    phase: e.resource._resource.extendedProps.data,
                    start_date: e.start,
                    end_date: e.end,
                    planned_hours: 0,
                    planned_budget: 0,
                  })
                }
                eventClick={handleEventClick}
                unselectAuto
                resourceAreaWidth="15%"
                views={{
                  customTimeline: {
                    type: 'resourceTimeline',
                    buttonText: t('Main view'),
                    duration: { weeks: 2 },
                    slotDuration: { day: 1 },
                    slotLabelFormat: [
                      { month: 'long', year: 'numeric' },
                      { day: 'numeric' },
                    ],
                  },
                  customMonthTimeline: {
                    type: 'resourceTimeline',
                    duration: { weeks: 2 * 6 },
                    slotDuration: { day: 1 },
                    slotLabelInterval: { week: 1 },
                    slotLabelFormat: [
                      { year: 'numeric' },
                      { month: 'long' },
                      { week: 'short' },
                    ],
                    slotMinWidth: 45,
                  },
                }}
                nextDayThreshold={'00:00:00'}
                defaultAllDay={false}
                scrollTime={{ weeks: 4 }}
                headerToolbar={{
                  left: 'customTimeline',
                }}
              />
              {selectedPlanData && (
                <CreatePlanOverlay
                  planData={selectedPlanData}
                  handleSubmit={handleSubmit}
                  onClose={() => setSelectedPlanData(null)}
                />
              )}
            </CardView>
          </>
        )
      }}
    </PagedDataSource>
  )
}

const CreatePlanOverlay = ({ planData, handleSubmit, onClose }) => {
  const formData = {
    phase_id: planData['phase'].id,
    start_date: planData['start_date'],
    end_date: planData['end_date'],
    planned_hours: planData['planned_hours'] ?? 0,
    planned_budget: planData['planned_budget'] ?? 0,
  }
  return (
    <OverlayForm
      title="Project phase planning"
      data={formData}
      open={planData}
      onClose={() => onClose()}
    >
      <Row>
        <GridFieldView
          n={12}
          s={12}
          label="Project"
          value={planData['project']?.name}
        />
        <GridFieldView
          n={12}
          s={12}
          label="Project type"
          value={planData['project']?.type?.name}
        />
        <GridFieldView
          n={12}
          s={12}
          label="Project phase"
          value={planData['phase'].name}
        />
        <GridFieldView n={6} s={6}>
          <DateField label="Start date" name="start_date" mandatory />
        </GridFieldView>
        <GridFieldView n={6} s={6}>
          <DateField label="End date" name="end_date" mandatory />
        </GridFieldView>
        <GridFieldView n={6} s={6}>
          <NumericField label="Planned hours" name="planned_hours" mandatory />
        </GridFieldView>
        <GridFieldView n={6} s={6}>
          <NumericField label="Planned budget" name="planned_budget" />
        </GridFieldView>
      </Row>
      <SimpleFormAction onAdd={handleSubmit} style={{ paddingTop: '20px' }} />
    </OverlayForm>
  )
}

/**
 * Renders the phase section of the calendar.
 *
 * @param {any} data represents a project phase
 *
 * @returns ReactElemnt
 */
const PhaseInfo = ({ data, onSubmit }) => {
  const { toasts } = useToast()
  const { t } = useTranslation(['projects'])
  const [plannedHours, setPlannedHours] = useState(null)
  const resource = data?.resource?._resource?.extendedProps?.data

  return (
    <Container padding="15px" title={resource.name}>
      <Container
        flex
        gap="10px"
        style={{ alignItems: 'center', paddingTop: '10px' }}
      >
        <AddIconButton
          default
          style={{ width: '25px', height: '25px' }}
          disabled={!plannedHours > 0}
          onClick={async () => {
            const [result, error] = await onSubmit({
              planned_hours: plannedHours,
              phase_id: resource.id,
            })
            if (result && !error) {
              toasts.success(t('Planning hours have been successfully added'))
              setPlannedHours(null)
            }
          }}
        />
        <NumericInput
          name="planned_hours"
          onChange={(event) => setPlannedHours(parseInt(event.target.value))}
          style={{ width: '80px' }}
        />
      </Container>
    </Container>
  )
}

const PhaseEvent = ({ event }) => {
  const phase = event.extendedProps
  return (
    <h3 style={{ color: 'white' }}>
      {formatDate(phase.start_date)} - {formatDate(phase.end_date)}
    </h3>
  )
}
