import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useService } from 'common/service/context'
import { Form, useForm } from 'common/widgets/form/context'
import { Container } from 'common/widgets/container'
import { Column, Row } from 'common/widgets/grid'
import {
  DateField,
  NumericField,
  TextAreaField,
} from 'common/widgets/form/field'
import { formatDate } from 'common/utils/format'
import { toDate } from 'common/utils/date'
import { EquipmentSimpleView } from 'modules/master-data/equipments/widgets/simple-view'
import { CommentView, GridFieldView, SectionView } from 'common/widgets/view'
import { Card } from 'common/widgets/card'
import { AddButton, BackButton } from 'common/widgets/button'
import { useToast } from 'system/toast/context'
import { DataSource } from 'common/widgets/data-source'
import { SystemPage } from 'system/page/core'

/**
 * Renders booking page in project.
 *
 * @returns ReactElement
 */
export const BookingChangePage = () => {
  // Extract ordered item info from url params
  const { projectId, itemId } = useParams()
  const service = useService()

  return (
    <SystemPage>
      <DataSource
        fetch={async () =>
          await service.get(
            `dispositions/bookings/${projectId}/status?baseitem_id=${itemId}`
          )
        }
      >
        {({ data }) => (
          <>{data && data.length > 0 && <Layout data={data[0]} />}</>
        )}
      </DataSource>
    </SystemPage>
  )
}

/**
 * Renders booking extension and shrink entry form.
 *
 * @param {any} data form data
 * @returns
 */
const Layout = ({ data }) => {
  const service = useService()
  const navigate = useNavigate()
  // Extract ordered item info from url params
  const { projectId, itemId } = useParams()
  const { toasts } = useToast()
  const { t } = useTranslation()

  /**
   * Calls a post call and creates a new booking extension.
   *
   * @param {any} values
   * @returns
   */
  const shrink = async ({ start, end, amount, comment }) => {
    const [result, error] = await service.post(
      `dispositions/requests/shrink/`,
      {
        amount,
        comment,
        start,
        end,
        project_id: projectId,
        baseitem_id: itemId,
      }
    )
    if (!error) {
      toasts.success(t('Booking request change successfully sent.'))
      navigate(-1)
    }
    return [result, error]
  }

  // Finds earliest booking start
  const minStart = new Date(
    Math.min(...data.bookings.map((e) => toDate(e.start)))
  )
  // Finds latest booking end
  const maxEnd = new Date(Math.max(...data.bookings.map((e) => toDate(e.end))))

  return (
    <Form
      title="Booking shrinks"
      data={{
        start: minStart,
        end: maxEnd,
        amount: data.total_remained_amount,
      }}
    >
      <>
        <Row>
          <Column n={6} flex>
            <FormContent data={data} minStart={minStart} maxEnd={maxEnd} />
          </Column>
          <Column n={6}>
            <Container flex vertical gap="10px">
              {data.baseitem && (
                <EquipmentSimpleView equipment={data.baseitem} />
              )}
              {data.bookings && <Bookings bookings={data.bookings} />}
            </Container>
          </Column>
        </Row>
        <Actions onAdd={shrink} maxEnd={maxEnd} minStart={minStart} />
      </>
    </Form>
  )
}

const Actions = ({ maxEnd, minStart, onAdd }) => {
  const { values } = useForm()
  const { start, end, comment, amount } = values.json
  const ready = (toDate(start) > minStart || toDate(end) < maxEnd) && amount > 0
  return (
    <Container flex gap="10px">
      <AddButton
        animate
        default
        onClick={async () => await onAdd({ start, end, comment, amount })}
        disabled={!ready}
      />
      <BackButton />
    </Container>
  )
}

const FormContent = ({ data, minStart, maxEnd }) => {
  const { t } = useTranslation()

  return (
    <SectionView>
      <Container>
        <ShrinkInputs minStart={minStart} maxEnd={maxEnd} />
        <h5>{t('Amount') + (data.baseitem.type === 'FORMWORK' ? '*' : '')}</h5>
        <NumericField
          disabled={!data.baseitem.is_formwork}
          mandatory
          name="amount"
        />
        <h5>{t('Comment')}</h5>
        <TextAreaField name="comment" rows={4} />
      </Container>
    </SectionView>
  )
}

const ShrinkInputs = ({ minStart, maxEnd }) => {
  const { values } = useForm()
  // Deconstruct values out of form container
  const { start, end } = values.json

  return (
    <Row>
      <Column n={6} s={6}>
        <DateField
          name="start"
          label="Start"
          mandatory
          filterDate={(date) => date >= minStart && date < toDate(end)}
        />
      </Column>
      <Column n={6} s={6}>
        <DateField
          name="end"
          label="End"
          inclusive
          mandatory
          filterDate={(date) => date <= maxEnd && date > toDate(start)}
        />
      </Column>
    </Row>
  )
}

const Bookings = ({ bookings }) => {
  const { t } = useTranslation(['projects'])
  return (
    <SectionView>
      <h3>{t('Bookings')}</h3>
      {bookings
        .filter((_, i) => i < 5)
        .map((booking, i) => (
          <Card key={i} divide>
            <Row>
              <GridFieldView
                n={3}
                s={3}
                label={t('Start')}
                value={formatDate(booking.start)}
              />
              <GridFieldView
                n={3}
                s={3}
                label={t('End')}
                value={formatDate(booking.end, { friendly: true })}
              />
              <GridFieldView
                n={3}
                s={3}
                label={t('Booked')}
                value={booking?.amount}
              />
              <GridFieldView
                n={3}
                s={3}
                label={t('Remained amount')}
                value={booking?.remained_amount}
              />
            </Row>
          </Card>
        ))}
      {bookings.length > 5 && (
        <CommentView
          comment={t('There are [{{overflow}}] more results...', {
            overflow: bookings.length - 5,
          })}
        />
      )}
    </SectionView>
  )
}
