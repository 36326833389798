import { useTranslation } from 'react-i18next'

import { DateField, TextField, TextAreaField } from 'common/widgets/form/field'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { Row, Column } from 'common/widgets/grid'
import { SectionView } from 'common/widgets/view'

/**
 * Renders contact present's data entry form.
 *
 * @param {Function} onClose closing hook
 * @returns
 */
export const ContactPresentFormContent = ({ onAdd, onUpdate, onRemove }) => {
  const { t } = useTranslation()

  return (
    <>
      <Row>
        <Column flex n={6}>
          <SectionView>
            <h5>{t('Event')}*</h5>
            <TextField name="event" mandatory />
            <h5>{t('Date')}*</h5>
            <DateField name="date" />
            <h5>{t('Description')}</h5>
            <TextAreaField name="description" rows="4" />
          </SectionView>
        </Column>
      </Row>
      <SimpleFormAction onAdd={onAdd} onUpdate={onUpdate} onRemove={onRemove} />
    </>
  )
}
