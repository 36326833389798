import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  dateToISOString,
  formatDate,
  formatDateRange,
} from 'common/utils/format'
import { Column, Row } from 'common/widgets/grid'
import { CommentView, GridFieldView, SectionView } from 'common/widgets/view'
import { formatProjectName } from 'modules/projects/utils'
import { DetailViewPage } from 'system/utils/view'
import { useService } from 'common/service/context'
import { Container } from 'common/widgets/container'
import { ConfirmButton, CancelButton } from 'common/widgets/button'
import { ConfirmOverlay } from 'common/widgets/overlay'
import { EquipmentLink } from 'modules/master-data/equipments/widgets/link'
import { Card } from 'common/widgets/card'
import {
  startOfWeek,
  addDays,
  startOfMonth,
  endOfMonth,
} from 'common/utils/date'
import { StyledText } from 'common/widgets/text'
import { BookingRequestTypeEnum } from 'modules/disposition/requests/utils'

import { BookingChangeLegends, BookingChangeCalendar } from './calendar'
import { BookingConfirmOverlay } from './overlay'

const BookingData = ({ request }) => {
  const { booking } = request
  const { baseitem } = booking
  const service = useService()
  const [dates, setDates] = useState({
    start: dateToISOString(startOfWeek(startOfMonth(request.start))),
    end: dateToISOString(addDays(endOfMonth(request.start), 7)),
  })
  const [data, setData] = useState({
    bookings: [],
    tasks: [],
    requests: [],
  })

  /**
   * Fetches resources and related bookings
   * @returns Array
   */
  const reload = async () => {
    /**
     * A small wrapper on top of fetch to make many calls at once.
     * @param {string} key call key
     * @param {string} url api url
     * @param {Array} params api params
     * @returns
     */
    const fetch = async (key, url, params) => {
      const [result, error] = await service.get(url, params)
      return [key, result, error]
    }

    const apis = [
      fetch(
        'bookings',
        `dispositions/bookings?start[lte]=${encodeURIComponent(
          request.end
        )}&end[gte]=${encodeURIComponent(request.start)}&baseitem_id=${
          request.booking.baseitem.id
        }`
      ),
    ]

    if (baseitem?.is_resource) {
      apis.push(
        fetch(
          'tasks',
          `maintenance/tasks?archived=false&baseitem_id=${
            baseitem.id
          }&done=false&plan_date[gte]=${encodeURIComponent(
            dates.start
          )}&plan_date[lte]=${encodeURIComponent(dates.end)}`
        )
      )
    }

    // Runs request in parallel to fetch all required results
    const results = await Promise.all(apis)

    // Inflates collected data in a different form to use to populate UI.
    setData((data) => ({
      ...data,
      ...Object.fromEntries(
        results.map((e) => {
          const [key, result, error] = e
          const data = error ? [] : result.data
          return [key, data]
        })
      ),
    }))
  }

  // Registers an effect so when the dates or category changes it reloads data
  useEffect(() => {
    reload()
  }, [dates])

  return (
    <Row>
      <Column flex n={6}>
        <BookingRequestData request={request} />
      </Column>
      <Column flex n={6}>
        {request.type === BookingRequestTypeEnum.EXTEND ? (
          <BookingConflicts request={request} {...data} />
        ) : (
          <SiblingBookings request={request} {...data} />
        )}
      </Column>
      <Column flex n={12}>
        <SectionView>
          <BookingChangeCalendar
            request={request}
            onCalendarDateChange={(start, end) => setDates({ start, end })}
            {...data}
          />
          <BookingChangeLegends request={request} />
        </SectionView>
      </Column>
    </Row>
  )
}

const BookingConflicts = ({ request, bookings }) => {
  const { t } = useTranslation()

  // Finds conflicting bookings
  const conflicts = bookings.filter(
    (e) =>
      e.project.id !== request.project.id &&
      e.end >= request.booking.end &&
      e.start <= request.end
  )

  // Max number of conflicts to render
  const MAX_ITEMS = 5

  return (
    <SectionView>
      <h1>{t('Conflicts')}</h1>
      {conflicts.length === 0 && (
        <CommentView comment={t('No conflicts found!')} />
      )}
      {conflicts.length > MAX_ITEMS && (
        <CommentView
          warning
          comment={t('There are [{{count}}] more conflicts...', {
            count: conflicts.length - MAX_ITEMS,
          })}
        />
      )}
      {conflicts
        .filter((_, i) => i < MAX_ITEMS)
        .map((e, i) => (
          <Card divide key={i}>
            <Row>
              <GridFieldView
                n={4}
                s={4}
                label={t('Project')}
                value={formatProjectName(e.project)}
              />
              <GridFieldView
                n={5}
                s={5}
                label={t('Booking period')}
                value={formatDateRange(e.start, e.end)}
              />
              <GridFieldView n={3} s={3} label={t('Amount')} value={e.amount} />
            </Row>
          </Card>
        ))}
    </SectionView>
  )
}

const SiblingBookings = ({ request, bookings }) => {
  const { t } = useTranslation()

  // Finds conflicting bookings
  const siblings = bookings.filter(
    (e) =>
      e.project.id === request.project.id &&
      e.baseitem.id === request.booking.baseitem.id
  )

  // Max number of conflicts to render
  const MAX_ITEMS = 5

  return (
    <SectionView>
      <h1>{t('Bookings')}</h1>
      {siblings.length > MAX_ITEMS && (
        <CommentView
          warning
          comment={t('There are [{{overflow}}] more results...', {
            count: siblings.length - MAX_ITEMS,
          })}
        />
      )}
      {siblings
        .filter((_, i) => i < MAX_ITEMS)
        .map((e, i) => (
          <Card divide key={i}>
            <Row>
              <GridFieldView
                n={4}
                s={4}
                label={t('Project')}
                value={formatProjectName(e.project)}
              />
              <GridFieldView
                n={5}
                s={5}
                label={t('Booking period')}
                value={formatDateRange(e.start, e.end)}
              />
              <GridFieldView n={3} s={3} label={t('Amount')} value={e.amount} />
            </Row>
          </Card>
        ))}
    </SectionView>
  )
}

const BookingRequestData = ({ request }) => {
  const { t } = useTranslation()
  return (
    <SectionView>
      <Container flex shrink>
        <Row>
          <GridFieldView n={12}>
            <EquipmentLink equipment={request.booking.baseitem} />
          </GridFieldView>
          <GridFieldView
            n={6}
            label={t('Project')}
            value={formatProjectName(request.project)}
          />
          <GridFieldView
            n={6}
            s={6}
            label={t('Amount')}
            value={request.amount}
          />
          <GridFieldView
            n={6}
            s={6}
            label={t('Start')}
            value={formatDate(request.start)}
          />
          <GridFieldView
            n={6}
            s={6}
            label={t('End')}
            value={formatDate(request.end, { friendly: true })}
          />
        </Row>
      </Container>
    </SectionView>
  )
}

export const ChangeRequestConfirm = ({ backUrl }) => {
  const { t } = useTranslation(['equipments'])
  const navigate = useNavigate()
  const service = useService()
  const [showBookOverlay, setShowBookOverlay] = useState(false)
  const [rejectOverlayVisible, showRejectOverlay] = useState(false)

  /**
   * Rejects the change request.
   * @param {any} request request information
   * @returns
   */
  const reject = async (request) => {
    const [, error] = await service.put(
      `/dispositions/requests/${request.id}/reject`
    )
    if (!error) {
      navigate(backUrl)
    }
  }

  return (
    <DetailViewPage
      url="/dispositions/requests"
      title={(e) =>
        e.type == BookingRequestTypeEnum.EXTEND
          ? t('Booking extensions')
          : t('Booking shrinks')
      }
    >
      {(data) => (
        <>
          {data && <BookingData request={data} />}
          <Container flex gap="10px">
            <ConfirmButton onClick={() => setShowBookOverlay(true)} />
            <CancelButton
              text="Reject"
              onClick={() => showRejectOverlay(true)}
            />
          </Container>
          {showBookOverlay && (
            <BookingConfirmOverlay
              open={showBookOverlay}
              onClose={() => setShowBookOverlay(false)}
              request={data}
            />
          )}
          {rejectOverlayVisible && (
            <ConfirmOverlay
              open={rejectOverlayVisible}
              onReject={() => showRejectOverlay(false)}
              onConfirm={async () => await reject(data)}
              title={t('Remove')}
            >
              <p>
                <StyledText
                  text={t(
                    'Booking extension request for project [{{project}}] will get rejected, are you sure?',
                    { project: formatProjectName(data.booking.project) }
                  )}
                />
              </p>
            </ConfirmOverlay>
          )}
        </>
      )}
    </DetailViewPage>
  )
}
