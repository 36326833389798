import './timeline.css'
import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { SystemPage } from 'system/page/core'
import { FullCalendarWrapper } from 'common/widgets/calendar'
import { useService } from 'common/service/context'

const ProjectTimelineCalendar = ({ onEventClick }) => {
  const calendarRef = useRef()
  const service = useService()
  let events = []

  const fetchEvents = async (
    { start, end, startStr, endStr, timeZone },
    successCallback,
    failureCallback
  ) => {
    const [response, error] = await service.get(`/projects/minimal`, [
      { archived: false },
    ])
    if (error) {
      failureCallback()
    } else {
      events = response.data
      successCallback(
        response.data?.map((record) => ({
          title: record?.name,
          start: record?.start_date,
          end: record?.end_date,
          id: record?.id,
          allDay: true,
          className: 'project-event',
        }))
      )
    }
  }

  return (
    <FullCalendarWrapper
      ref={calendarRef}
      events={fetchEvents}
      eventClick={(event) =>
        onEventClick(event, events.filter((e) => e.id == event.event.id)[0])
      }
    />
  )
}

export const ProjectTimelinePage = () => {
  const navigate = useNavigate()
  return (
    <SystemPage>
      <ProjectTimelineCalendar
        onEventClick={(event, selection) => {
          navigate(`/projects/${selection.id}`)
        }}
      />
    </SystemPage>
  )
}
