.userSelectCard {
  padding: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

.scopeContainer {
  flex: 1 1 auto;
  padding-bottom: 10px;
  padding-top: 10px;
  align-items: center;
  padding-left: 15px;
  gap: 10px;
}
