import React from 'react'

import { useSession } from 'common/session/context'
import { ProfileAvatar } from 'common/widgets/avatar'

import { UserOverlay } from './overlay'

export const ProfileIcon = ({ name, active, onClose }) => {
  const { currentUser } = useSession()

  return (
    <>
      <ProfileAvatar
        name={currentUser?.fullname}
        size="40px"
        borderRadius="10px"
      />
      <UserOverlay name={name} open={active} onClose={onClose} />
    </>
  )
}
