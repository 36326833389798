import { useTranslation } from 'react-i18next'

import { DetailViewPage } from 'system/utils/view'
import { GridFieldView, CardView } from 'common/widgets/view'
import { Row } from 'common/widgets/grid'
import { formatDate } from 'common/utils/format'
import { ColorPreview } from 'common/widgets/color'

export const ContactTagViewPage = () => {
  const { t } = useTranslation()

  return (
    <DetailViewPage
      url="/contacts/defs/tags"
      navUrl="/master-data/contacts/tags"
      title={(e) => e.name}
      removable
      editable
    >
      {(data, reload) => (
        <CardView>
          <h2>{t('Master data')}</h2>
          <Row>
            <GridFieldView n={6} s={6} label={t('Name')} value={data?.name} />
            <GridFieldView
              n={6}
              s={6}
              label={t('Created at')}
              value={formatDate(data?.created_at)}
            />
            <GridFieldView
              n={6}
              s={6}
              label={t('Parent')}
              value={data?.parent?.name}
            />
            <GridFieldView n={6} s={6} label={t('Color')}>
              {data?.color ? (
                <ColorPreview background={data?.color}>
                  <h1>{data?.color}</h1>
                </ColorPreview>
              ) : (
                '-'
              )}
            </GridFieldView>
            <GridFieldView
              n={12}
              s={12}
              label={t('Comment')}
              value={data?.comment}
            />
          </Row>
        </CardView>
      )}
    </DetailViewPage>
  )
}
