import styled, { css } from 'styled-components'

import { Container } from 'common/widgets/container'

export const Card = styled(Container)`
  transition: 0.5s;
  border-radius: ${(props) => props.borderRadius || '10px'};
  ${(props) =>
    props.border &&
    css`
      border: 1px solid #eeeeee;
    `}
  ${(props) =>
    props.divide &&
    css`
      border-radius: 0;
      ${(props) =>
        props.noline !== 'true' &&
        css`
          border-top: 1px solid #eeeeee;
        `}
      padding-top: 15px;
    `}
  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${(props) =>
    props.grow &&
    css`
      flex: ${props.grow};
    `}
  ${(props) =>
    props.align &&
    css`
      align-items: ${props.align};
    `}
  ${(props) =>
    props.direction &&
    css`
      flex-direction: ${props.direction};
    `}
    ${(props) =>
    props.grow &&
    css`
      flex-grow: ${props.grow};
    `}
  ${(props) =>
    props.flex &&
    props.visible !== false &&
    css`
      display: flex;
    `}
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${(props) =>
    props.spaceAfter &&
    css`
      margin-bottom: ${props.spaceAfter};
    `}

  ${(props) =>
    props.spaceBefore &&
    css`
      margin-top: ${props.spaceBefore};
    `}

  background: ${(props) => props.background || '#ffffff'};

  :hover {
    ${(props) =>
      props.onClick &&
      css`
        cursor: pointer;
        background: #f8f8fd;
      `}
  }

  @media print {
    border: none;
  }
`
