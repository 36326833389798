/**
 * Returns user fullname.
 *
 * @param {object} user user dto
 * @returns string
 */
export const formatUserName = (user) => (user ? `${user.fullname}` : '')

export const UserStateEnum = {
  ARCHIVED: 'Inactive',
  ACTIVE: 'Active',
  NEW: 'New',
}

export const UserStateColor = {
  Inactive: '#FC4242',
  Active: '#95E68B',
  New: '#888888',
}

/**
 * Returns user state.
 * @param {any} user user data
 * @returns string
 */
export const getUserState = (user) => {
  if (user.archived_on !== null) {
    return UserStateEnum.ARCHIVED
  }
  if (user.last_login === null) {
    return UserStateEnum.NEW
  }
  return UserStateEnum.ACTIVE
}
