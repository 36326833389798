import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useService } from 'common/service/context'
import { Form } from 'common/widgets/form/context'
import { SystemPage } from 'system/page/core'

import { SmallScaleEquipmentFormContent } from './form'

/**
 * Renders page for adding a small scale equipment
 *
 * @returns ReactElement
 */
export const SmallScaleEquipmentAddPage = () => {
  const service = useService()
  const navigate = useNavigate()

  /**
   * Calls a post call and creates a new small scale equipment.
   *
   * @param {any} values user data
   * @returns
   */
  const handleAdd = async (values) => {
    // add COMMISSIONING value of field process to values
    values['process'] = 'COMMISSIONING'
    // call api
    return await service.post('items/resources/', values)
  }

  const handleSuccess = async (response) => {
    navigate(`/master-data/equipments/small-scales/${response.data.id}`)
  }

  return (
    <SystemPage>
      <Form>
        <SmallScaleEquipmentFormContent
          onAdd={handleAdd}
          onSuccess={handleSuccess}
        />
      </Form>
    </SystemPage>
  )
}
