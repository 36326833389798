import { SystemToolbar } from 'system/toolbar/core'
import { SystemMenu } from 'system/menu/core'
import { SystemPageLoading } from 'system/page/loading'
import { useSession } from 'common/session/context'

import styles from './core.module.css'

/**
 * System page layout.
 *
 * @param {*} children react child elements
 * @returns
 */
export const SystemPage = ({ loading = false, nopadding, children, style }) => {
  const { currentUser } = useSession()
  if (!currentUser) {
    return '...'
  }
  return (
    <>
      {/* Toolbar also contains top-level overlays */}
      <SystemToolbar />
      <SystemMenu />
      <SystemPageLoading loading={loading} />
      <div
        className={styles.content}
        style={{
          ...style,
          overflow: 'auto',
          padding: nopadding ? '0px' : '10px',
        }}
      >
        {children}
      </div>
    </>
  )
}
