import { DataSource, PagedDataSource } from 'common/widgets/data-source'
import { CardView, FieldView } from 'common/widgets/view'
import { Container } from 'common/widgets/container'
import { SearchInput } from 'common/widgets/search'
import { OverlayForm } from 'common/widgets/overlay'
import { Avatar } from 'common/widgets/avatar'
import { SingleImageView } from 'common/widgets/image'

import styles from '../security.module.css'

import { formatUserName } from './utils'

export const UserSelectOverlay = ({
  open,
  params,
  maxItems = 5,
  onSelect,
  onClose,
}) => {
  return (
    <OverlayForm open={open} onClose={onClose} title="Users">
      <PagedDataSource url="/users" limit={maxItems} params={params}>
        {({ data }) => (
          <>
            <SearchInput />
            <Container style={{ width: '300px', minHeight: '330px' }}>
              {data?.map((r, i) => (
                <CardView
                  className={styles.userSelectCard}
                  key={i}
                  divide
                  onClick={async () => {
                    if (onSelect) {
                      await onSelect(r)
                    }
                    onClose()
                  }}
                  flex
                  gap="10px"
                  interactive
                >
                  <Avatar name={r.fullname} id={r.profile_image?.id} mini />
                  <Container flex vertical>
                    <h3>{r.fullname}</h3>
                    <h5>{r.email}</h5>
                  </Container>
                </CardView>
              ))}
            </Container>
          </>
        )}
      </PagedDataSource>
    </OverlayForm>
  )
}

export const UserView = ({ user }) => {
  if (!user) {
    return null
  }
  return (
    <Container flex gap="10px">
      {user?.has_profile_image && (
        <SingleImageView
          imageRef={user.profile_image_ref}
          name={user.fullname}
          size="24px"
        />
      )}
      <FieldView
        href={`/security/users/${user.id}`}
        value={formatUserName(user)}
      />
    </Container>
  )
}

export const UserViewById = ({ userId }) => (
  <DataSource url={`/users/${userId}`}>
    {({ data }) => data && <UserView user={data} />}
  </DataSource>
)
