import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  NumericField,
  SelectField,
  TextField,
  TextAreaField,
} from 'common/widgets/form/field'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { Row, Column } from 'common/widgets/grid'
import { SectionView } from 'common/widgets/view'
import {
  EquipmentCategoryFreeField,
  EquipmentMaincategoryFreeField,
  EquipmentSubcategoryFreeField,
} from 'modules/master-data/equipments/widgets/category'
import { CostCenterSelectField } from 'modules/master-data/cost-centers/widgets'

import { OrderableField } from '../widgets/orderable'
import { AccountingTypeField } from '../widgets/accounting-type'
import { AccountingGroupField } from '../widgets/accounting-group'
import { ItemProcessEnum, ItemTypeEnum } from '../utils'
import { TelematicsIdField } from '../widgets/telematics-id'

/**
 * Renders large scale equipment's data entry form.
 *
 * @param {Function} onClose closing hook
 * @returns
 */
export const LargeScaleEquipmentFormContent = ({
  onAdd,
  onUpdate,
  onRemove,
  onClone,
  onSuccess,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Row>
        <Column flex n={6}>
          <SectionView>
            <h1>{t('Master data')}</h1>
            <h5>{t('Label')}*</h5>
            <TextField name="name" mandatory />
            <h5>{t('Inventory number')}</h5>
            <TextField name="inventory_number" />
            <h5>{t('Main Group')}*</h5>
            <EquipmentMaincategoryFreeField
              mandatory
              params={[
                { type: ItemTypeEnum.RESOURCE },
                { process: ItemProcessEnum.DISPOSITION },
              ]}
            />
            <h5>{t('Group')}*</h5>
            <EquipmentCategoryFreeField
              mandatory
              dependable={!onAdd}
              params={[
                { type: ItemTypeEnum.RESOURCE },
                { process: ItemProcessEnum.DISPOSITION },
              ]}
            />
            <h5>{t('Subgroup')}</h5>
            <EquipmentSubcategoryFreeField
              dependable={!onAdd}
              params={[
                { type: ItemTypeEnum.RESOURCE },
                { process: ItemProcessEnum.DISPOSITION },
              ]}
            />
            <h5>{t('Commission type')}*</h5>
            <SelectField
              name="commission_type"
              mandatory
              items={[
                { key: 'COMMISSIONER', title: t('COMMISSIONER') },
                { key: 'SELF', title: t('SELF') },
              ]}
            />
            <h5>{t('Accounting group')}</h5>
            <AccountingGroupField
              params={[{ type: 'RESOURCE' }, { process: 'DISPOSITION' }]}
            />
          </SectionView>
        </Column>
        <Column flex n={6}>
          <SectionView>
            <h1>{t('Billing')}</h1>
            <h5>{t('Cost center')}</h5>
            <CostCenterSelectField name="cost_center_id" removable />
            <h5>{t('Accounting type')}*</h5>
            <AccountingTypeField mandatory />
            <h5>{t('Cost rate')}</h5>
            <NumericField
              name="cost_rate"
              preDecimalScale={4}
              decimalScale={2}
            />
            <h5>{t('Balance sheet depreciation')}</h5>
            <NumericField
              name="balance_sheet_depreciation"
              preDecimalScale={3}
              decimalScale={0}
            />
            <h5>{t('Calculated Depreciation (Months)')}</h5>
            <NumericField
              name="calculated_depreciation"
              preDecimalScale={3}
              decimalScale={0}
            />
            <h5>{t('Orderable')}*</h5>
            <OrderableField mandatory selected={false} />
            <h5>{t('Serial number')}</h5>
            <TextField name="serial_number" />
          </SectionView>
        </Column>
        <Column n={6}>
          <SectionView>
            <h1>{t('Master data diverse')}</h1>
            <h5>{t('Manufacturer')}</h5>
            <TextField name="manufacturer" />
            <h5>{t('Supplier')}</h5>
            <TextField name="supplier" />
            <h5>{t('License plate')}</h5>
            <TextField name="license_plate" />
            <h5>{t('Engine power')}</h5>
            <NumericField
              name="engine_power"
              preDecimalScale={4}
              decimalScale={2}
            />
            <h5>{t('Average fuel consumption')}</h5>
            <NumericField
              name="avg_fuel_consumption"
              preDecimalScale={4}
              decimalScale={2}
            />
            <h5>{t('Comment')}</h5>
            <TextAreaField name="comment" rows="4" />
          </SectionView>
        </Column>
        <Column n={6}>
          <SectionView>
            <h1>{t('Telematics data')}</h1>
            <h5>{t('Telematics id')}</h5>
            <TelematicsIdField assigned={false} name="telematics_id" />
          </SectionView>
        </Column>
      </Row>
      <SimpleFormAction
        onAdd={onAdd}
        onUpdate={onUpdate}
        onRemove={onRemove}
        onClone={onClone}
        onSuccess={onSuccess}
      />
    </>
  )
}
