import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { FieldView, SectionView } from 'common/widgets/view'
import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'
import { Row, Column } from 'common/widgets/grid'
import { Container } from 'common/widgets/container'
import { formatDate } from 'common/utils/format'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { ItemTypeEnum } from 'modules/master-data/equipments/utils'
import {
  CropIconButton,
  FoldButtons,
  MaximizeIconButton,
} from 'common/widgets/button'
import { EquipmentLink } from 'modules/master-data/equipments/widgets/link'
import { DamageReportButton } from 'modules/yard/maintenance/damage_report'

import styles from '../../projects.module.css'

export const InventoryTabContent = ({ project }) => {
  const service = useService()

  const fetch = async () =>
    await service.get(`/projects/inventories/${project.id}/status`)

  const getItemCountByType = (data, type) =>
    data?.filter((inventory) => inventory.baseitem.type === type).length

  return (
    <DataSource
      fetch={fetch}
      render={({ data }) => {
        if (!data?.length) {
          return 'Inventory is empty.'
        }
        return (
          <Container flex vertical gap="10px">
            {getItemCountByType(data, ItemTypeEnum.RESOURCE) > 0 && (
              <ProjectInventory
                project={project}
                data={data}
                type={ItemTypeEnum.RESOURCE}
              />
            )}
            {getItemCountByType(data, ItemTypeEnum.FORMWORK) > 0 && (
              <ProjectInventory
                project={project}
                data={data}
                type={ItemTypeEnum.FORMWORK}
              />
            )}
            {getItemCountByType(data, ItemTypeEnum.CONSUMABLE) > 0 && (
              <ProjectInventory
                project={project}
                data={data}
                type={ItemTypeEnum.CONSUMABLE}
              />
            )}
          </Container>
        )
      }}
    />
  )
}

const ProjectInventory = ({ project, type }) => {
  const { t } = useTranslation(['projects'])
  const navigate = useNavigate()

  const service = useService()

  const normalize = (params) => {
    return [...params, { type: type }]
  }

  const fetch = async (params) =>
    await service.get(
      `/projects/inventories/${project.id}/status`,
      normalize(params)
    )

  /**
   * Indicates whether item is extendable or shrinkable.
   * @param {any} inventory info for item in project
   * @returns bool
   */
  const isBookingChangable = (inventory) =>
    (inventory.baseitem?.is_largescale || inventory.baseitem?.is_formwork) &&
    inventory.current_inventory > 0

  return (
    <SectionView>
      <SimpleDataTable
        name="projectinventorytab"
        nopadding
        nomargin
        white
        highlight
        divide
        fetch={fetch}
        title={t(type)}
        rowNumbers
      >
        <SimpleColumn n={12} s={12} sortable field="name">
          {(r) => (
            <EquipmentLink
              equipment={r.baseitem}
              showType={r.baseitem?.is_resource}
            />
          )}
        </SimpleColumn>
        <SimpleColumn
          n={3}
          s={6}
          sortable
          field="maincategory"
          label={t('Main group')}
          filter={(e) => e}
          title={(e) => e}
          fetch={async (params) =>
            await service.get('items/resources/maincategory', params)
          }
          value={(r) => r.baseitem.maincategory}
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          field="category"
          label={t('Group')}
          filter={(e) => e}
          title={(e) => e}
          fetch={async (params) =>
            await service.get('items/resources/category', params)
          }
          value={(r) => r.baseitem.category}
        />
        <SimpleColumn
          n={3}
          s={6}
          sortable
          field="subcategory"
          label={t('Subgroup')}
          filter={(e) => e}
          title={(e) => e}
          fetch={async (params) =>
            await service.get('items/resources/subcategory', params)
          }
          value={(r) => r.baseitem.maincategory}
        />
        <SimpleColumn
          n={3}
          s={6}
          label={t('Current inventory')}
          field="current_inventory"
        />
        <SimpleColumn
          n={3}
          s={6}
          label={t('Total check-in amount')}
          field="total_checkin_amount"
        />
        <SimpleColumn
          n={3}
          s={6}
          label={t('Total check-out amount')}
          field="total_checkout_amount"
        />
        <SimpleColumn n={12} s={12}>
          {(r) => <InventoryItemDetailView item={r} />}
        </SimpleColumn>
        <SimpleColumn fixed>
          {(r) => (
            <Container padding="4px 0px 0px 0px">
              <FoldButtons>
                {r.baseitem.is_resource && (
                  <DamageReportButton baseitemId={r.baseitem.id} />
                )}
                {isBookingChangable(r) && (
                  <MaximizeIconButton
                    white
                    onClick={(e) => {
                      e.stopPropagation()
                      navigate(
                        `/projects/${project.id}/extend/${r.baseitem.id}`
                      )
                    }}
                    title={t('Extend booking')}
                  />
                )}
                {isBookingChangable(r) && (
                  <CropIconButton
                    white
                    onClick={(e) => {
                      e.stopPropagation()
                      navigate(
                        `/projects/${project.id}/shrink/${r.baseitem.id}`
                      )
                    }}
                    title={t('End booking')}
                  />
                )}
              </FoldButtons>
            </Container>
          )}
        </SimpleColumn>
      </SimpleDataTable>
    </SectionView>
  )
}

const InventoryItemDetailView = ({ item }) => {
  const { t } = useTranslation(['projects'])

  return (
    <Container flex vertical>
      {item.details.map((e, i) => (
        <Container
          className={styles.detailCardView}
          key={i}
          background={i % 2 === 0 ? '#fafeff' : '#ffffff'}
        >
          <Row>
            <Column n={4} s={4}>
              <FieldView
                label={t('Checkin')}
                value={formatDate(e.checkin_date, { friendly: false })}
              />
            </Column>
            <Column n={4} s={4}>
              {!item.baseitem?.is_consumable && (
                <FieldView
                  label={t('Checkout')}
                  value={formatDate(e.checkout_date, { friendly: false })}
                />
              )}
            </Column>
            <Column n={4} s={4}>
              <FieldView label={t('Amount')} value={e.amount} />
            </Column>
          </Row>
        </Container>
      ))}
    </Container>
  )
}
