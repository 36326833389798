import React from 'react'

import { DetailViewPage } from 'system/utils/view'
import { Tab } from 'common/widgets/tab'
import { BackDeliveryCreateViewPage } from 'modules/yard/retour/deliveries/add'

import { TasksList } from '../tasks/list'
import { ProjectGanttChart } from '../widgets/ganttchart'

import { InventoryTabContent } from './inventory/tab'
import { ReturnDeliveryTabContent } from './back-deliveries'
import { BookingTabContent } from './bookings'
import { ProjectOrderedItems } from './ordered-items/view'
import { PhasePlanCalendar } from './phase-plan'
import { ProjectOverviewTab } from './overview'

/**
 * Renders project detail view page
 *
 * @returns ReactElement
 */
export const ProjectDetailsPage = () => {
  return (
    <DetailViewPage url="/projects" title={(e) => e.name} editable archivable>
      {(data, reload, update) => (
        <Tab
          items={[
            {
              key: 'overview',
              title: 'Overview',
              render: () => (
                <ProjectOverviewTab
                  project={data}
                  reload={reload}
                  update={update}
                />
              ),
            },
            {
              key: 'inventory',
              title: 'Inventory',
              render: () => <InventoryTabContent project={data} />,
            },
            {
              key: 'backdelivery',
              title: 'Retours',
              render: () => (
                <BackDeliveryCreateViewPage project={data} reload={reload} />
              ),
            },
            {
              key: 'ordered-items',
              title: 'Ordered items',
              render: () => (
                <ProjectOrderedItems
                  project={data}
                  reload={reload}
                  update={update}
                />
              ),
            },
            {
              key: 'deliveries',
              title: 'Return deliveries',
              render: () => <ReturnDeliveryTabContent data={data} />,
            },
            {
              key: 'booking',
              title: 'Booking',
              render: () => <BookingTabContent project={data} />,
            },
            {
              key: 'tasks',
              title: 'Tasks',
              render: () => <TasksList projectId={data.id} showAll />,
            },
            {
              key: 'phase-plan',
              title: 'Phase planning',
              render: () => <PhasePlanCalendar project={data} />,
            },
            {
              key: 'schedule',
              title: 'Schedule',
              render: () => <ProjectGanttChart project={data} />,
            },
          ]}
        />
      )}
    </DetailViewPage>
  )
}
