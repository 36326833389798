import { Shield } from 'react-feather'

import { register } from 'system/container'

register({
  icon: <Shield />,
  title: 'Security',
  // Subsystem base route, no trailing slash
  path: '/security',
  bottom: true,
  // mainmenu priority, lower is higher
  priority: 80,
})
