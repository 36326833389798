import { useEffect, useState } from 'react'
import { useParams, Navigate } from 'react-router-dom'

import { useService } from 'common/service/context'

import { getEquipmentNavPath } from './utils'

/**
 * This page navigates to corresponding resource page by Id.
 *
 * We have currently four types of assets with their own pages.
 * We need the "baseitem" data in order to choose the correct page.
 *
 **/
export function EquipmentDetails(props) {
  const { id } = useParams()
  const service = useService()
  const [item, setItem] = useState()

  const load = async () => {
    const [response, error] = await service.get(`/items/${id}`)
    if (!error) {
      setItem(response?.data)
    }
  }

  useEffect(() => {
    load()
  }, [])

  if (item) {
    return <Navigate to={getEquipmentNavPath(item)} replace />
  }
}
