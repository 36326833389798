import { useTranslation } from 'react-i18next'

import { SaveButton, CancelButton } from 'common/widgets/button'
import { OverlayBody, OverlayFooter, OverlayForm } from 'common/widgets/overlay'
import { useService } from 'common/service/context'
import { Row } from 'common/widgets/grid'
import { GridFieldView } from 'common/widgets/view'
import {
  DateField,
  DateRangeField,
  NumericField,
} from 'common/widgets/form/field'

/**
 * Renders the pickup item overlay in the addhooc delivery.
 *
 * @param {any} item baseitem
 * @param {any} delivery delivery note object
 * @param {any} onClose state of closing the dialog
 * @param {any} reload reloads the current page
 *
 * @returns ReactElement
 */
export const PickUpSelectedItem = ({
  open,
  item,
  delivery,
  onClose,
  reload,
}) => {
  const { t } = useTranslation()
  const service = useService()

  if (!item) {
    return null
  }
  /**
   * Add the selected item to the delivery note.
   *
   * @param {any} item baseitem object
   *
   * @returns Array[addDeliveryItemResult, addDeliveryItemError]
   */
  const handleBaseItemAdd = async (values) => {
    const [addDeliveryItemResult, addDeliveryItemError] = await service.post(
      `kommission/delivery/items`,
      {
        amount: parseInt(values.json.amount),
        baseitem_id: item.id,
        delivery_id: delivery.id,
        ordered_item_id: null,
        tmp_start: values.json.booking_start,
        tmp_end: values.json.booking_end,
      }
    )
    if (!addDeliveryItemError) {
      reload()
      onClose()
    }

    return [addDeliveryItemResult, addDeliveryItemError]
  }

  return (
    <OverlayForm
      title="Commissioned amount"
      data={{ amount: 1, bookingStart: null, bookingEnd: null }}
      open={open}
      onClose={() => onClose()}
    >
      <OverlayBody>
        <Row>
          <GridFieldView n={6} s={6} label="Item" value={item?.name} />
          <GridFieldView
            n={6}
            s={6}
            label="Quantity unit"
            value={item?.quantity_unit}
          />
          <GridFieldView
            n={6}
            s={6}
            label="Main group"
            value={item?.maincategory}
          />
          <GridFieldView n={6} s={6} label="Group" value={item?.category} />
          <GridFieldView
            n={6}
            s={6}
            label="Subgroup"
            value={item?.subcategory}
          />
          <GridFieldView
            n={12}
            s={12}
            label={t('Amount') + (item?.is_resource ? '' : '*')}
          >
            <NumericField
              name="amount"
              disabled={item?.is_resource}
              mandatory
            />
          </GridFieldView>
          {!item?.is_consumable && (
            <GridFieldView n={12} s={12} label="Booking period">
              <DateRangeField mandatory={!item.is_smallscale} />
            </GridFieldView>
          )}
          {item?.is_consumable && (
            <GridFieldView n={12} s={12} label="Fixed date">
              <DateField name="booking_start" mandatory />
            </GridFieldView>
          )}
        </Row>
      </OverlayBody>
      <OverlayFooter repel gap="10px">
        <SaveButton
          default
          disabled={(ctx) => !ctx.ready}
          onClick={(e, ctx) => handleBaseItemAdd(ctx.values)}
          text="Attach"
        />
        <CancelButton onClick={onClose} />
      </OverlayFooter>
    </OverlayForm>
  )
}
