var pages = []

/**
 * Registers a page inside system container.
 *
 * @param {object} page page data
 */
export const register = (page) => {
  // Insert each page into the the route Trie data structure
  const pageNode = routeTrie.insert(page.path, page)

  // For convenience add a refrenece to the trie to the page
  // TODO: remove this later
  page.trieNode = pageNode

  // Get breadcrumb pages
  page.getBreadCrumbPages = () => {
    let breadCrumbPages = []
    let current = pageNode
    // Keep last node in the chain of routes which had registered
    // data (page). This ignores middle routes with no title, etc.
    let lastNodeWithData = null

    let leafNodePath = current?.data?.path
    let leafNode = pageNode

    while (current) {
      if (!current.key.includes(':')) {
        if (current.data) {
          breadCrumbPages.push(current.data)
        } else {
          // For adding the root element
          if (current.key == '') {
            breadCrumbPages.push(lastNodeWithData.data)
          }
        }
      }
      if (current.data) {
        lastNodeWithData = current
      }
      current = current?.parent
    }

    // No breadcrumb for index pages
    if (leafNodePath && leafNodePath.endsWith('/')) {
      breadCrumbPages.pop()
    }

    // Since we have traversed the tree from bottom to the top
    // we have to reverse it to get the breadcrumb's order right
    breadCrumbPages.reverse()

    // Add edit, deatils and similar pages to the breadcrumb
    // Unless it is already added as the last part of the breadcrumb
    if (
      leafNode.key.includes(':') &&
      leafNode.data.path != breadCrumbPages[breadCrumbPages.length - 1]?.path
    ) {
      breadCrumbPages.push(leafNode.data)
    }

    // We remove the root and top level modules from breadcrumbs,
    return breadCrumbPages.slice(1)
  }

  // Get top level modules
  page.getModule = () => {
    let current = pageNode
    let previous = pageNode
    while (current) {
      if (current.key == '') {
        return previous.parent.data || previous.data
      }
      previous = current
      current = current?.parent
    }
    return current
  }

  page.priority = page.priority ? page.priority : 0.0

  // Log an error if the path is already registered.
  if (pages.filter((e) => e.path == page.path)?.length > 0) {
    console.error(`Path [${page.path}] already registered.`)
  }
  pages = [...pages.filter((e) => e.path !== page.path), page]
}

/**
 * Returns list of available pages, excludes modules.
 *
 * @returns Array
 */
export const getPages = () => {
  const available = pages.filter(
    (p) => p.render && p.path && (!p.when || (p.when && p.when()))
  )
  const allPages = available.filter(
    (p, i) => available.findIndex((e) => e.path === p.path) === i
  )

  return allPages.filter((page) => page.render)
}

/**
 * Returns list pages that are module page.
 *
 * @returns Array.
 */
export const getModules = () => {
  // return first tier siblings as main modules
  return Object.values(routeTrie.root.children)
    .filter((c) => c.data)
    .map((child) => child.data)
}

/*
RouteTree breaks application routes into a rooted Trie.

With this data structure it is possible to insert a route
at its correct location and get nodes (page) data based on
their routes. Moreover, it can be used to get module list
(first layer nodes) and direct desendants (children) of any
node (mostly for building menus and breadcrumbs).

Thre rationale is using a well-known data structure for all
purposes mentioned earlier instead of using fragile code.
Most importantly, this way we can insert one route per node
in our application that would allow to use relative traversal
in our application (for example navigating to the parent route).

See See: https://en.wikipedia.org/wiki/Trie for more info.

For example take the following routes:

/
/yard/retour/
/yard/retour/:id
/yard/commission
/yard/commission/deliveries
/yard/commission/deliveries/adhoc
/yard/commission/deliveries/:id
/yard/commission/deliveries/:id/add
/disposition
/disposition/requests
/projects

The following tree will be built for them:

                          root(/)
           ................|................
           |           |       |            |
      disposition   projects   |            |
       ....|                   |            |
       |                       |            |
    requests               commission     retour
                          ....|          ...|
                          |              |
                      deliveries        :id      
                     ....|.....
                     |        |
                   adhoc     :id
                           ...|
                           |
                          add

Note that we don't store forward slashes in this Trie.
*/
class Trie {
  constructor() {
    // Create the root node, represeing '/'
    this.root = new PageNode('', null)
  }

  insert(path, data) {
    // Split routes by forward slashes
    let parts = path.split('/')

    // This is an absolute path if it starts with slash
    // (if split returns empty string at the beginning it was a slash)
    let absolute = parts[0] == ''

    // Remove the root path
    if (absolute) {
      parts = parts.slice(1)
    }

    // Start traversing from the root node
    let current = this.root

    // Add each part to its corresponding node
    for (let part of parts) {
      if (!current.children[part]) {
        current.children[part] = new PageNode(part)
        current.children[part].parent = current
      }
      current = current.children[part]
    }
    // Mark the final node
    current.isEndRoute = true
    current.data = data
    return current
  }

  search(route) {
    let current = this.root

    for (let part of route.split('/')) {
      if (!part) {
        // ignore empty strings
        continue
      }
      if (!current.children[part]) {
        return null
      }
      current = current.children[part]
    }
    return current
  }
}

// Data structure for one node of the Trie
function PageNode(key, data) {
  this.key = key
  this.data = data
  this.isEndRoute = false
  this.children = {}
  this.parent = null
}

export const routeTrie = new Trie()
