// Defines available contacts categories
export const ContactCategoryEnum = {
  CUSTOMER: 'CUSTOMER',
  SUPPLIER: 'SUPPLIER',
  SUBCONTRACTOR: 'SUBCONTRACTOR',
}

// Defines available contacts categories
export const ContactTypeEnum = {
  PERSON: 'PERSON',
  COMPANY: 'COMPANY',
}

/**
 * Formats address.
 *
 * @param {any} address address info
 * @returns
 */
export const formatContactAddress = (address, ignoreCountry) => {
  const lines = []
  if (address) {
    if (address.street && address.street_number) {
      lines.push(`${address.street} ${address.street_number}`)
    }
    if (address.zip_code && address.city) {
      lines.push(`${address.zip_code} ${address.city}`)
    }
    if (!ignoreCountry && address.country) {
      lines.push(`${address.country}`)
    }
  }
  return lines.length > 0 ? lines : ['-']
}

/**
 * Returns primary address of the given contact.
 *
 * @param {any} contact contact info
 * @returns any
 */
export const getPrimaryAddress = (contact) =>
  contact?.addresses?.find((e) => e?.address?.type === 'PRIMARY')?.address
