const _temp0 = require("../disposition/translation.js");
const _temp1 = require("../logistics/translations.js");

const _temp2 = require("../orders/translation.js");
const _temp3 = require("../projects/translation.js");
const _temp4 = require("../security/translation.js");
const _temp5 = require("../yard/translation.js");
module.exports = {
  "disposition": _temp0,
  "logistics":   {
    "s": _temp1
  },
  "orders": _temp2,
  "projects": _temp3,
  "security": _temp4,
  "yard": _temp5
}