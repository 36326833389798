import React from 'react'

import { SystemPage } from 'system/page/core'
import { ColorPickerField, TextField } from 'common/widgets/form/field'
import { Form } from 'common/widgets/form/context'
import { useSettings } from 'system/settings/context'
import { SectionView } from 'common/widgets/view'
import { AddressField } from 'common/widgets/form/address'

import { SettingFileField, LanguageSelectField } from './fields'

export const SettingsPage = () => {
  const ctx = useSettings()

  const companyLogo = ctx.settings.filter((s) => s.key == 'company.logo')[0]
  const companySplash = ctx.settings.filter((s) => s.key == 'company.splash')[0]

  const data = {
    name: ctx.get('company.name'),
    address: ctx.get('company.address'),
    primaryColor: ctx.get('company.color.primary'),
    secondaryColor: ctx.get('company.color.secondary'),
  }

  return (
    <SystemPage>
      <Form
        data={data}
        showActions
        onSubmit={(values) => {
          ctx.update(values)
          return [null, null]
        }}
        //successMessage="Settings updated."
      >
        <SectionView title="Company">
          <TextField
            label="Company name"
            name="name"
            disabled={false}
            rows={1}
          />
          <AddressField label="Address" name="address" showMap editGeofence />
          <div className="flex gap-2">
            <SettingFileField
              label="Logo"
              entry={companyLogo}
              className="inline"
            />
            <SettingFileField
              label="Splash"
              entry={companySplash}
              className="inline"
            />
          </div>
        </SectionView>
        <SectionView title="Other">
          <div className="flex gap-2">
            <ColorPickerField
              name="primaryColor"
              label="company primary color"
            />
            <ColorPickerField
              name="secondaryColor"
              label="company secondary color"
            />
          </div>
          <LanguageSelectField label="Language" />
        </SectionView>
      </Form>
    </SystemPage>
  )
}
