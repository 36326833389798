.statusContainer {
  /*composes: statusContainer from "../shared/colors.css";*/
  display: inline-block;
  padding-top: 3px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  margin-right: 10px;
  height: 25px;
  border-radius: 5px;
  text-align: center;
  align-items: center;
}

.tasks {
  cursor: pointer;
}

.tasks :hover {
  background-color: #fafafa;
}

.tagsContainer {
  display: inline-block;
  padding-top: 3px;
  padding-right: 5px;
  padding-bottom: 0px;
  padding-left: 5px;
  margin-right: 5px;
  height: 32px;
  border-radius: 5px;
  background: #d5f5d0;
  text-align: center;
  align-items: center;
}

.task {
  display: flex;
  box-sizing: border-box;
  border-radius: 5px;
  transition: 0.2s ease-out;
  align-items: center;
  user-select: none;
  margin-bottom: 5px;
  padding: 5px;
}

.task p {
  margin: auto;
  color: #8b8b8b;
}

.task :hover {
  cursor: pointer;
  background: #4a7bf5;
}

.task :hover p {
  color: #ffffff;
}
.task :hover svg {
  color: #ffffff;
}

.selectedTask {
  composes: task;
  background: #e8eefd;
  border: 1px solid #4a7bf5;
}

.selectedTask p {
  color: #376df4;
}
