import { useTranslation } from 'react-i18next'
import { Database } from 'react-feather'

import { formatProjectName } from 'modules/projects/utils'
import { Container } from 'common/widgets/container'
import { Legend } from 'common/widgets/legend'
import { formatItemName } from 'modules/master-data/equipments/utils'
import { TimelineCalendar } from 'modules/disposition/calendar'
import { dateToISOString, formatDuration } from 'common/utils/format'
import { createEvent } from 'common/widgets/calendar'

import styles from '../../requests.module.css'

export const BookingFormworkTimeline = ({
  request,
  sources,
  bookings,
  formworks,
  selectedSources,
  onAdd,
  onUpdate,
  onMove,
  onCalendarDateChange,
  selectedItem,
}) => {
  const { t } = useTranslation()

  // Makes a map and groups items by project.
  const map = new Map()
  // Adds item with project id null as yard.
  formworks
    .filter((e) => e.is_orderable && e.id === selectedItem)
    .forEach((e) => {
      map.set(`${e.id}-null`, {
        amount: e.stock_level,
        project: { id: null, short_name: t('Yard'), cost_center: null },
        baseitem: e,
      })
      // Filters booking for just this item then adds them as resource also.
      sources
        .filter((s) => s.baseitem.id === e.id)
        .forEach((e) => {
          // Makes a key out of baseitem id and project id.
          const key = `${e.baseitem.id}-${e.project.id}`
          // If map has the key, increase the amount.
          if (map.has(key)) {
            map.get(key).amount += e.amount
          } else {
            // It is the first entry, adds it to map.
            map.set(key, {
              amount: e.amount,
              baseitem: e.baseitem,
              project: e.project,
            })
          }
        })
    })

  bookings
    .filter((e) => e.baseitem.id === selectedItem)
    .forEach((e) => {
      // Makes a key out of baseitem id and project id.
      const key = `${e.baseitem.id}-${e.project.id}`
      if (!map.has(key)) {
        map.set(key, {
          amount: 0,
          baseitem: e.baseitem,
          project: e.project,
        })
      }
    })

  // Creates resources out of map values, it gets grouped.
  const resources = Array.from(map.values()).map((r) => ({
    id: `${r.baseitem.id}#${r.project.id}`,
    title: r.project.short_name || r.project.name,
    eventOverlap: true,
    ...r,
  }))

  // Initializes events by already booked and commissioned items in project.
  // note that we should exclude those bookings which are also in `bookings`
  // variable to prevent duplicate rendering of bookings.
  // I don't really understand why these two services are both in-place:
  // 1. dispositions/bookings/sources
  // 2. dispositions/bookings
  // client sends `start=period_start` and `end=period_end` to no.1 service which
  // includes only those bookings which are fitting inside the time window.
  // client sends `start[lte]=period_end` and `end[gte]=period_start` to no.2 service
  // which includes any booking which has any common period with the whole time
  // window (including the results of service no.1).
  // so why both? could service no.1 produce something which won't be present in no.2 results?
  const alreadyAdded = []
  const events = sources
    .filter((e) => e.baseitem.id === selectedItem)
    .map((r) => {
      alreadyAdded.push(r.id)
      return createEvent({
        resourceId: `${r.baseitem.id}#${r.project.id}`,
        start: r.start,
        end: r.end,
        title: formatProjectName(r.project),
        className: 'event-booked',
        type: 'booked',
        extendedProps: {
          booking: r,
          project: r.project,
        },
      })
    })

  // Adds current approved bookings excluding those which are already added using source bookings.
  bookings
    .filter(
      (e) => e.baseitem.id === selectedItem && !alreadyAdded.includes(e.id)
    )
    .forEach((r) =>
      events.push(
        createEvent({
          resourceId: `${r.baseitem.id}#${r.project.id}`,
          start: r.start,
          end: r.end,
          title: formatProjectName(r.project),
          className: 'event-booked',
          type: 'booked',
          extendedProps: {
            booking: r,
            project: r.project,
          },
        })
      )
    )

  // Adds current period as background event to calendar,
  // for each resource we repeat the same peroid.
  resources.forEach((r, i) =>
    events.push(
      createEvent({
        resourceId: r.id,
        id: `background-${i}`,
        start: request.start,
        end: request.end,
        display: 'background',
        type: 'current',
      })
    )
  )

  // Shows selected sources in the calendar.
  selectedSources.forEach((r, i) => {
    const id = `${r.baseitem.id}#${r.source_project.id}`
    events.push(
      createEvent({
        id: i,
        resourceId: id,
        start: r.start,
        end: r.end,
        title: formatProjectName(request.project),
        className: 'event-selected',
        type: 'selected',
        editable: true,
        extendedProps: {
          data: r,
        },
      })
    )
  })

  /**
   * Finds related resource for the given event.
   * @param {any} event event data
   * @returns any
   */
  const getResource = (event) => {
    const resourceId = event.getResources()[0].id
    const resource = resources.find((r) => r.id === resourceId)
    return resource
  }

  return (
    <TimelineCalendar
      resourceAreaHeaderContent={<h1>{t('Availabilities')}</h1>}
      onCalendarDateChange={onCalendarDateChange}
      onSelect={(resource, start, end) => onAdd(resource, start, end)}
      initialDate={request.start}
      resources={resources}
      calendarType="formwork"
      renderResource={(data) => (
        <ResourceCell
          source={data.resource}
          onSelected={(r) => onAdd(r.extendedProps, request.start, request.end)}
        />
      )}
      events={events}
      renderEvent={({ event }) => <EventCell event={event} request={request} />}
      onEventClick={({ event }) => {
        if (event?.extendedProps?.booking) {
          const resource = getResource(event)
          if (resource) {
            onAdd(resource, request.start, request.end)
          }
        } else if (event?.extendedProps?.data) {
          const resource = getResource(event)
          if (resource) {
            onUpdate(resource, event.extendedProps.data.id)
          }
        }
      }}
      onEventChange={(event, resource) => {
        const start = dateToISOString(event.start)
        const end = dateToISOString(event.end)
        const { id } = event.extendedProps.data
        const { project, baseitem } = resource
        onMove({ id, start, end, project, baseitem })
      }}
    />
  )
}

const ResourceCell = ({ source, onSelected }) => {
  const { t } = useTranslation()
  return (
    <Container
      flex
      vertical
      grow
      hover="#FDFAD7"
      padding="4px"
      gap="5px"
      onClick={() => onSelected(source)}
    >
      <p>
        {t('Project')}: {formatProjectName(source.extendedProps.project)}
      </p>
      <h4>
        {t('Amount')}: {source.extendedProps.amount}
      </h4>
      <h5>{formatItemName(source.extendedProps.baseitem)}</h5>
    </Container>
  )
}

const EventCell = ({ request, event }) => {
  const project = event.extendedProps.project
    ? event.extendedProps.project
    : request.project
  const data = event.extendedProps.data
  const booking = event.extendedProps.booking
  const amount = booking ? booking.amount : data?.amount
  return (
    <Container className={styles.eventContainer} flex vertical shrink>
      {event.display !== 'background' && (
        <>
          <h4>{project.name}</h4>
          <p>
            <Database />
            {amount}
          </p>
          <Container flex reverse>
            <h5>{formatDuration(event.start, event.end)}</h5>
          </Container>
        </>
      )}
    </Container>
  )
}

export const BookingConfirmLegends = () => {
  const { t } = useTranslation()
  return (
    <Container flex gap="10px">
      <Legend text={t('Selected')} color="#376DF4" />
      <Legend text={t('Booked')} color="#FC4242" />
    </Container>
  )
}
