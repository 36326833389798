import { useTranslation } from 'react-i18next'
import { ResponsivePie } from '@nivo/pie'

import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'
import { Card } from 'common/widgets/card'
import { stringToColor } from 'common/utils/color'

const BaseItemTypePie = ({ data }) => {
  const { t } = useTranslation()

  const chartData = data.map((e, idx) => ({
    id: idx,
    label: e.type,
    value: e.count,
    color: stringToColor(e.type),
  }))

  return (
    <ResponsivePie
      colors={{ datum: 'data.color' }}
      data={chartData}
      sortByValue
      margin={{ top: 80, right: 60, bottom: 140, left: -70 }}
      innerRadius={0.4}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      enableArcLinkLabels={false}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          size: 4,
          padding: 1,
          stagger: true,
        },
      ]}
      fill={[
        {
          match: {
            id: t('SMALL-SCALE'),
          },
          id: 'dots',
        },
      ]}
      legends={[
        {
          anchor: 'right',
          direction: 'column',
          justify: false,
          translateX: 0,
          translateY: 0,
          itemsSpacing: 10,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#999',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
    />
  )
}

export const BaseItemTypesAnalytics = () => {
  const service = useService()
  const fetch = async () =>
    await service.get('reports/analytics/baseitems/by-types')

  return (
    <Card title="Equipments" style={{ height: '600px', padding: '10px' }}>
      <DataSource
        fetch={fetch}
        render={({ data }) => data && <BaseItemTypePie data={data} />}
      />
    </Card>
  )
}
