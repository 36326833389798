import { Calendar } from 'react-feather'

import { register } from 'system/container'

import { BookingViewPage } from './bookings/view'

register({
  icon: <Calendar />,
  title: 'Disposition',
  // Subsystem base route, no trailing slash
  path: '/disposition',
  // mainmenu priority, lower is higher
  priority: 50,
})

register({
  render: () => <BookingViewPage />,
  title: 'Details',
  path: '/disposition/bookings/:id',
  priority: 0.0,
})
