import { useRef, useEffect } from 'react'
import raf from 'rc-util/lib/raf'
import Tooltip from 'rc-tooltip'
import Slider from 'rc-slider'

import { ZIndexEnum } from 'common/utils/enumerations'
import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'

/**
 * Custom tooltip component
 *
 * @returns {JSX.Element}
 */
const CustomTooltip = (props) => {
  const {
    value,
    children,
    visible,
    tipFormatter = (val) => `${val}`,
    ...restProps
  } = props

  const tooltipRef = useRef()
  const rafRef = useRef(null)

  useEffect(() => {
    if (visible) {
      keepAlign()
    } else {
      cancelKeepAlign()
    }

    return cancelKeepAlign
  }, [value, visible])

  const cancelKeepAlign = () => {
    if (rafRef.current) {
      raf.cancel(rafRef.current)
    }
  }

  const keepAlign = () => {
    rafRef.current = raf(() => {
      tooltipRef.current?.forcePopupAlign()
    })
  }

  return (
    <Tooltip
      placement="top"
      overlay={tipFormatter(value)}
      overlayInnerStyle={{
        minHeight: 'auto',
        fontFamily: 'Red Hat Display',
        position: 'relative',
        zIndex: ZIndexEnum.ABOVE_MOST + 1,
      }}
      ref={tooltipRef}
      visible={visible}
      {...restProps}
    >
      {children}
    </Tooltip>
  )
}

/**
 * Slider input component.
 *
 * @param {Number} min minimum value of the slider
 * @param {Number} max maximum value of the slider
 * @param {Number} step slider incremental step
 * @param {Number} value the initial value of the slider
 * @param {Function} onChange a callback function to be called on slider value change
 * @param {Boolean} disabled a value indicating that the slider is disabled
 * @param {Object} style custom styles for the slider
 * @param {Boolean} showToolTip a value indicating that the slider must have a tooltip on handle
 * @param {Function} tipFormatter a callback function to customize the text for tooltip
 * @param {Object} tipProps other tooltip rproperties
 * @returns {JSX.Element}
 */
export const SliderInput = ({
  min = 0,
  max = 100,
  step = 1,
  value,
  onChange,
  disabled,
  style,
  showToolTip,
  tipFormatter,
  tipProps,
  ...rest
}) => {
  /**
   * Handles the tooltip rendering
   *
   * @param {JSX.Element} node child node
   * @param {Object} handleProps the handle properties
   * @returns {JSX.Element}
   */
  const handleToolTipRender = (node, handleProps) => {
    return (
      <CustomTooltip
        value={handleProps.value}
        visible={handleProps.dragging}
        tipFormatter={tipFormatter}
        {...tipProps}
      >
        {node}
      </CustomTooltip>
    )
  }

  return (
    <Slider
      handleRender={showToolTip ? handleToolTipRender : undefined}
      style={style}
      disabled={disabled}
      defaultValue={value}
      value={value}
      min={min}
      max={max}
      step={step}
      onChange={onChange}
      {...rest}
    />
  )
}
