import './calendar.css'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PagedDataSource } from 'common/widgets/data-source'
import { dateToISOString, formatDateRange } from 'common/utils/format'
import { addDays } from 'common/utils/date'
import { CardView } from 'common/widgets/view'
import { Container } from 'common/widgets/container'
import { Column, Row } from 'common/widgets/grid'
import { OverlayForm } from 'common/widgets/overlay'
import { SimpleFormAction } from 'common/widgets/form/utils'
import {
  CalendarSearch,
  FullCalendarWrapper,
  createEvent,
} from 'common/widgets/calendar'
import { UserView } from 'modules/security/users/widgets'
import { Legend } from 'common/widgets/legend'

import { getStateTextColor, getTypeTextColor } from '../widgets'
import { AbsenceDetails } from '../view'
import styles from '../absence.module.css'

const PAGE_SIZE = 50

/**
 * Renders the maintenance calendar.
 *
 * @returns ReactElemnt
 */
export const AbsenceCalendar = () => {
  const { t } = useTranslation()
  const [selectedAbsence, setSelectedAbsence] = useState(null)
  // Keep the start and end date of the calendar's current view
  const [fromDate, setFromDate] = useState(addDays(new Date(), -14))
  const [toDate, setToDate] = useState(addDays(new Date(), 14))

  /**
   * Handles the selection of absence in the calendar
   *
   * @param {any} selection calendar`s event object
   */
  const handleEventClick = (selection) => {
    const event = selection?.event?._def
    if (event?.ui?.display === 'background') {
      return
    }
    setSelectedAbsence(event?.extendedProps)
  }

  return (
    <PagedDataSource
      url={'/absences/report'}
      params={[
        { start: dateToISOString(fromDate, true) },
        { end: dateToISOString(toDate, true) },
      ]}
      limit={PAGE_SIZE}
    >
      {({ data }) => {
        const resources = data?.map((e) => ({
          id: e.id,
          title: e.fullname,
          data: e,
        }))

        const events = data
          ?.map((contact) =>
            contact?.absences?.map((e) => {
              return createEvent({
                id: e?.id,
                title: '',
                resourceId: contact?.id,
                color:
                  e?.state === 'APPROVED'
                    ? getTypeTextColor(e?.type)
                    : getStateTextColor(e?.state),
                className: 'absence-event',
                start: e?.start,
                end: e?.end,
                allDay: e?.daily,
                overlap: true,
                extendedProps: {
                  ...e,
                },
              })
            })
          )
          .flat()

        return (
          <>
            {selectedAbsence && (
              <AbsenceInfoOverlay
                absence={selectedAbsence}
                onClose={() => setSelectedAbsence(null)}
              />
            )}
            <CardView>
              <FullCalendarWrapper
                /// Callback when the calendar date/week is changed
                datesSet={(data) => {
                  setFromDate(addDays(data.start, -14))
                  setToDate(addDays(data.end, 14))
                }}
                initialView={'customTimeline'}
                eventResourceEditable={false}
                resourceAreaHeaderContent={() => (
                  <CalendarSearch title="Employees" />
                )}
                resourceLabelContent={(resource) => (
                  <EmployeeInfo data={resource} />
                )}
                eventMouseEnter={(info) => {
                  let absence = info.event?.extendedProps
                  if (info.event?.display === 'background' || !absence) {
                    return
                  }
                  info.el.title = `${formatDateRange(
                    absence.start,
                    absence.end,
                    { time: !absence.daily }
                  )} - ${absence.contact.fullname}`
                }}
                titleFormat={{ week: 'short' }}
                resources={resources}
                events={events}
                eventContent={({ event }) => <AbsenceEvent event={event} />}
                selectable={false}
                unselectAuto
                eventClick={handleEventClick}
                resourceAreaWidth="15%"
                views={{
                  customTimeline: {
                    type: 'resourceTimeline',
                    buttonText: t('Main view'),
                    duration: { weeks: 2 },
                    slotDuration: { day: 1 },
                    slotLabelFormat: [
                      { month: 'long', year: 'numeric' },
                      { day: 'numeric' },
                    ],
                  },
                  customMonthTimeline: {
                    type: 'resourceTimeline',
                    duration: { weeks: 2 * 6 },
                    slotDuration: { day: 1 },
                    slotLabelInterval: { week: 1 },
                    slotLabelFormat: [
                      { year: 'numeric' },
                      { month: 'long' },
                      { week: 'short' },
                    ],
                    slotMinWidth: 45,
                  },
                }}
                nextDayThreshold={'00:00:00'}
                defaultAllDay={false}
                scrollTime={{ weeks: 4 }}
                headerToolbar={{
                  left: 'customTimeline',
                }}
              />
              <CalendarLegend />
            </CardView>
          </>
        )
      }}
    </PagedDataSource>
  )
}

/**
 * Renders the resource section of the calendar.
 *
 * @param {any} data represents a contact/employee
 *
 * @returns ReactElemnt
 */
const EmployeeInfo = ({ data }) => {
  const resource = data?.resource?._resource?.extendedProps?.data

  return (
    <Container flex grow repel style={{ padding: '5px', cursor: 'default' }}>
      <Row>
        <Column n={12}>
          <UserView user={resource?.system_user} />
        </Column>
      </Row>
    </Container>
  )
}

const AbsenceEvent = ({ event }) => {
  const { t } = useTranslation()
  const absence = event.extendedProps
  return (
    <Container flex vertical shrink className={styles.absenceEvent}>
      {event.display !== 'background' && (
        <>
          <h4 style={{ color: '#FFFFFF' }}>
            {absence?.daily ? t('Daily') : t('Hourly')}
          </h4>
        </>
      )}
    </Container>
  )
}

export const AbsenceInfoOverlay = ({ absence, onClose }) => {
  if (!absence) {
    return
  }

  return (
    <OverlayForm open={!!absence} onClose={onClose} title="Details">
      <Row>
        <Column n={12} m={12} s={12}>
          <AbsenceDetails data={absence} showTitle={false} />
        </Column>
      </Row>
      <SimpleFormAction />
    </OverlayForm>
  )
}

export const CalendarLegend = () => {
  return (
    <Container flex gap="10px" style={{ paddingTop: '5px' }}>
      <Legend text="Vacation" color={getTypeTextColor('VACATION')} />
      <Legend text="Sick leave" color={getTypeTextColor('ILLNESS')} />
      <Legend text="School" color={getTypeTextColor('SCHOOL')} />
      <Legend text="Special" color={getTypeTextColor('SPECIAL_LEAVE')} />
      <Legend text="Pending" color={getStateTextColor('PENDING')} />
    </Container>
  )
}
