import de_translations from './de.json'
import en_translations from './en.json'

export const resources = {
  de: {
    common: de_translations,
  },
  en: {
    common: en_translations,
  },
}
