.legend {
  display: flex;
  flex-direction: row;
  gap: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.colorBox {
  border-radius: 5px;
  width: 25px;
  height: 25px;
}
