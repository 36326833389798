import { DetailViewPage } from 'system/utils/view'

import { DeviceForm } from './form'

export const DeviceView = () => {
  return (
    <DetailViewPage url="/telematics" navUrl="/logistics/iot/devices" editable>
      {(data) => <DeviceForm data={data} readOnly />}
    </DetailViewPage>
  )
}
