.toolbarMenu {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  padding-right: 10px;
  justify-content: flex-end;
  width: 100%;
}

.toolbarMenuItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px;
  background: #ffffff;
  transition: 0.3s;
}

.toolbarMenuItem svg {
  width: 20px;
  height: 20px;
  margin: auto;
}

.toolbarMenuItem > :first-child {
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
}

.toolbarWrapper {
  top: 0px;
  left: 60px;
  padding-left: 10px;
  width: calc(100% - 60px);
  height: 60px;
  box-sizing: border-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  border-bottom: 1px solid #e7e8ea;
  position: fixed;
  display: flex;
  gap: 10px;
  z-index: 1;
}

@media print {
  .toolbarWrapper {
    display: none;
  }
}

.breadCrumb {
  display: flex;
  flex: 1 1 auto;
  height: 60px;
  width: 100%;
  align-items: center;
}
@media (max-width: 768px) {
  .breadCrumb {
    display: none;
  }
}

.breadCrumbLink {
  color: #8b8b8b !important;
  font-weight: 600;
  font-size: 14px;
}

.breadCrumbLinkActive {
  composes: breadCrumbLink;
  font-weight: 900;
  color: #303030 !important;
}

.toastWrapper {
  z-index: 9999;
  position: absolute;
  box-sizing: content-box;
  bottom: 0;
  height: 81px;
}
.toastWrapperFill {
  composes: toastWrapper;
}
@media (max-width: 768px) {
  .toastWrapper,
  .toastWrapperFill {
    left: 0;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .toastWrapper {
    left: 60px;
    width: calc(100% - 60px);
  }
  .toastWrapperFill {
    left: 0;
    width: 100%;
  }
}
