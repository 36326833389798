import React from 'react'
import { useParams } from 'react-router-dom'

import { useService } from 'common/service/context'
import { Form } from 'common/widgets/form/context'
import { EditViewPage } from 'system/utils/edit'

import { FormworksFormContent } from './form'

/**
 * Renders page for editing a formwork
 *
 * @returns ReactElement
 */
export const FormworksEditPage = () => {
  const service = useService()
  const { id } = useParams()

  const handleEdit = async (values) =>
    await service.put(`items/formworks/${id}`, values)

  return (
    <EditViewPage url="/items/formworks" title={(e) => e.name}>
      {(data, reload) => {
        return (
          <Form data={data}>
            <FormworksFormContent onUpdate={handleEdit} />
          </Form>
        )
      }}
    </EditViewPage>
  )
}
