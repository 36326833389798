import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { SystemPage } from 'system/page/core'
import { useService } from 'common/service/context'
import { useSession } from 'common/session/context'
import { useToast } from 'system/toast/context'

export const UserEmailChangeConfirmationPage = () => {
  const service = useService()
  const { t } = useTranslation()
  const { token } = useParams()
  const { currentUser } = useSession()
  const { toasts } = useToast()
  const [timeout, setTimeoutState] = useState(null)

  const confirm = async () => {
    const [result, error] = await service.post(
      `/users/${currentUser?.id}/email-change/confirm/${token}`
    )

    if (!error) {
      toasts.success(t('Email change confirmed.'))
    }

    if (timeout) {
      clearTimeout(timeout)
    }
    // I had to use `window.open` to be able to rerender user overlay, otherwise
    // the pending email warning would be shown even if confirmation was successful.
    // the timeout is to let the user actually see the message before navigating.
    setTimeoutState(setTimeout(() => window.open('/', '_self'), 3500))
    return [result, error]
  }

  useEffect(() => {
    confirm()
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [])

  return <SystemPage></SystemPage>
}
