import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  formatItemName,
  getEquipmentNavPath,
} from 'modules/master-data/equipments/utils'
import { formatProjectName } from 'modules/projects/utils'
import { Container } from 'common/widgets/container'
import { Legend } from 'common/widgets/legend'
import { CommentView } from 'common/widgets/view'
import { TimelineCalendar } from 'modules/disposition/calendar'
import { createEvent } from 'common/widgets/calendar'
import { formatDuration } from 'common/utils/format'

import styles from '../../requests.module.css'

const diff = (event) => {
  const start = event.extendedProps.booking
    ? event.extendedProps.booking.start
    : event.start
  const end = event.extendedProps.booking
    ? event.extendedProps.booking.end
    : event.end

  if (!end) {
    return '-'
  }

  return formatDuration(start, end)
}

export const BookingResourceTimeline = ({
  request,
  resources,
  bookings,
  suggestions,
  onSelectSlot,
  onCalendarDateChange,
}) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState(null)

  // Populates existing booking first.
  const events = bookings.map((r) =>
    createEvent({
      resourceId: r.baseitem.id,
      start: r.start,
      end: r.end,
      title: formatProjectName(r.project),
      className: 'event-booked',
      type: 'booked',
      extendedProps: {
        project: r.project,
        booking: r,
      },
    })
  )

  // If there is a selected resources, creates an event with request start and end in that slot.
  if (selected) {
    events.push(
      createEvent({
        id: request.id,
        resourceId: selected,
        start: request.start,
        end: request.end,
        title: '',
        className: 'event-selected',
        type: 'selected',
        extendedProps: {
          project: request.project,
        },
      })
    )
  }

  // If there are suggestions shows the first one, as the best machine choice.
  if (suggestions.length > 0 && suggestions[0].id !== selected) {
    events.push(
      createEvent({
        resourceId: suggestions[0].id,
        start: request.start,
        end: request.end,
        title: '',
        className: 'event-suggested',
        type: 'suggested',
        extendedProps: {
          project: request.project,
          suggestion: suggestions[0],
        },
      })
    )
  }

  return (
    <TimelineCalendar
      resourceAreaHeaderContent={<h1>{t('Availabilities')}</h1>}
      onCalendarDateChange={onCalendarDateChange}
      initialDate={request.start}
      calendarType="resource"
      renderResource={(data) => (
        <ResourceCell
          resource={data.resource}
          onSelected={(r) => {
            setSelected(r.id)
          }}
        />
      )}
      resources={resources.map((r) => ({
        id: r.id,
        title: r.name,
        eventOverlap: true,
        ...r,
      }))}
      events={events}
      renderEvent={({ event }) => <EventCell event={event} request={request} />}
      onEventClick={({ event, resource, start, end }) => {
        if (!event.extendedProps?.booking) {
          onSelectSlot(resource, start, end)
        }
      }}
      onSelect={(resource, start, end) => {
        onSelectSlot(resource, start, end)
      }}
    />
  )
}

const ResourceCell = ({ resource, onSelected }) => {
  const { t } = useTranslation()
  return (
    <Container
      flex
      vertical
      grow
      hover="#FDFAD7"
      padding="2px"
      onClick={() => onSelected(resource)}
    >
      <h4>
        <a
          href={getEquipmentNavPath({
            id: resource.id,
            ...resource.extendedProps,
          })}
        >
          {formatItemName(resource.extendedProps)}
        </a>
      </h4>
      <CommentView
        comment={`${t('Cost rate')}: ${resource.extendedProps?.cost_rate}`}
      />
    </Container>
  )
}

const EventCell = ({ request, event }) => {
  const project = event.extendedProps.project
    ? event.extendedProps.project
    : request.project
  return (
    <Container className={styles.eventContainer}>
      {event.extendedProps.type !== 'holiday' && (
        <>
          <h4>{formatProjectName(project)}</h4>
          <Container flex reverse>
            <h5>{diff(event)}</h5>
          </Container>
        </>
      )}
    </Container>
  )
}

export const BookingConfirmLegends = () => (
  <Container flex gap="10px">
    <Legend text="Required booking period" color="#95E68B" />
    <Legend text="New booking period" color="#376DF4" />
    <Legend text="Booked" color="#FC4242" />
  </Container>
)
