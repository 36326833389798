import { useTranslation } from 'react-i18next'

import { NoButton, YesButton } from 'common/widgets/button'
import { OverlayBody, OverlayFooter, OverlayForm } from 'common/widgets/overlay'
import { useService } from 'common/service/context'
import { formatDeliveryNumber } from 'modules/yard/utils'
import { formatDateRange } from 'common/utils/format'
import { Row } from 'common/widgets/grid'
import { GridFieldView, GridLinkFieldView } from 'common/widgets/view'
import { formatOrderNumber } from 'modules/orders/utils'

/**
 * Renders the delivery note cancelation overlay.
 *
 * @param {any} delivery delivery note object
 * @param {any} onClose state of closing the dialog
 * @param {any} reload callback for reloading the page
 *
 * @returns ReactElement
 */
export const CancelDeliveryOverlay = ({
  delivery,
  onClose,
  reload,
  onCancel,
}) => {
  const { t } = useTranslation()
  const service = useService()

  const handleDeliveryCancelation = async () => {
    const [result, error] = await service.put(
      `kommission/delivery/${delivery.id}/cancel`
    )

    if (!error) {
      if (reload) {
        reload()
      }
      onClose && onClose()
      onCancel && onCancel()
    }

    return [result, error]
  }

  return (
    <OverlayForm open={delivery} onClose={onClose} title={t('Cancel delivery')}>
      <OverlayBody>
        <Row>
          <GridFieldView
            n={6}
            s={6}
            label={t('Delivery number')}
            value={formatDeliveryNumber(
              delivery?.order?.number,
              delivery?.number
            )}
          />
          <GridLinkFieldView
            n={6}
            s={6}
            label={t('Order number')}
            value={formatOrderNumber(delivery?.order?.number)}
            url={`/yard/commission/orders/${delivery?.order?.id}`}
          />
          <GridLinkFieldView
            n={6}
            s={6}
            label={t('Project')}
            value={
              delivery?.order?.project.short_name ||
              delivery?.order?.project.name
            }
            url={`/projects/${delivery?.order?.project.id}`}
          />
          <GridLinkFieldView
            n={6}
            s={6}
            label={t('Source project')}
            value={
              delivery?.source_project != null
                ? delivery?.source_project?.short_name
                : t('Unknown')
            }
            url={`/projects/${delivery?.source_project?.id}`}
          />
          <GridLinkFieldView
            n={6}
            s={6}
            label={t('Logistic user')}
            value={delivery?.creator_user?.fullname}
            url={`/security/users/${delivery?.creator_user?.id}`}
          />
          <GridFieldView
            n={6}
            s={6}
            label={t('Recipient')}
            value={delivery?.recipient}
          />
          <GridFieldView
            n={6}
            s={6}
            label={t('Delivery option')}
            value={
              delivery?.order?.requires_delivery
                ? t('Requires delivery')
                : t('Self-pickup')
            }
          />
          <GridFieldView
            n={6}
            s={6}
            label={t('Status')}
            value={t(delivery?.state)}
          />
        </Row>
      </OverlayBody>
      <OverlayFooter repel gap="10px">
        <YesButton onClick={handleDeliveryCancelation} />
        <NoButton onClick={onClose} />
      </OverlayFooter>
    </OverlayForm>
  )
}

/**
 * Renders the reset item amount overlay.
 *
 * @param {any} deliveryItem delivery item object
 * @param {any} onClose state of closing the dialog
 * @param {any} reload reload call back
 *
 * @returns ReactElement
 */
export const ResetPickedUpItemOverlay = ({
  open,
  orderedItem,
  deliveryItem,
  reload,
  onClose,
}) => {
  const { t } = useTranslation()
  const service = useService()

  const handleItemReset = async () => {
    const [, itemResetError] = await service.delete(
      `kommission/delivery/items/${
        orderedItem?.id !== null ? orderedItem.id : deliveryItem?.id
      }?hard-delete=true`
    )
    if (!itemResetError) {
      reload()
      onClose()
    }
  }

  const baseitem =
    orderedItem?.item !== null ? orderedItem?.item : deliveryItem?.baseitem

  return (
    <OverlayForm
      open={open}
      onClose={() => onClose()}
      title={
        orderedItem || deliveryItem?.ordered_item_id
          ? t('Reset delivery item`s amount')
          : t('Remove delivery item')
      }
    >
      <OverlayBody>
        <Row>
          <GridFieldView n={6} s={6} label={t('Item')} value={baseitem?.name} />
          {baseitem?.is_consumable && (
            <GridFieldView
              n={6}
              s={6}
              label={t('Quantity unit')}
              value={baseitem?.quantity_unit}
            />
          )}
          {deliveryItem && (baseitem?.is_resource || baseitem?.is_formwork) ? (
            <GridFieldView
              n={6}
              s={6}
              label={t('Booking period')}
              value={formatDateRange(
                deliveryItem?.tmp_start,
                deliveryItem?.tmp_end
              )}
            />
          ) : (
            <GridFieldView
              n={6}
              s={6}
              label={t('Packaging unit')}
              value={baseitem?.packaging_unit}
            />
          )}
          <GridFieldView
            n={6}
            s={6}
            label={t('Ordered amount')}
            value={orderedItem ? orderedItem.amount : deliveryItem?.amount}
          />
          <GridFieldView
            n={6}
            s={6}
            label={t('Commissioned amount')}
            value={deliveryItem?.amount}
          />
        </Row>
      </OverlayBody>
      <OverlayFooter repel gap="10px">
        <YesButton onClick={handleItemReset} />
        <NoButton onClick={() => onClose()} />
      </OverlayFooter>
    </OverlayForm>
  )
}

/**
 * Renders the reset item amount overlay.
 *
 * @param {any} deliveryItem delivery item object
 * @param {any} onClose state of closing the dialog
 * @param {any} reload reload call back
 *
 * @returns ReactElement
 */
export const CancelOrderedItemOverlay = ({
  open,
  orderedItem,
  deliveryItem,
  reload,
  onClose,
}) => {
  const { t } = useTranslation()
  const service = useService()

  const handleItemCancel = async () => {
    const [, itemResetError] = await service.put(
      `orders/items/${orderedItem?.id}/cancel`
    )
    if (!itemResetError) {
      reload()
      onClose()
    }
  }

  const baseitem = orderedItem?.item

  return (
    <OverlayForm
      open={open}
      onClose={() => onClose()}
      title={t('Cancel ordered item')}
    >
      <OverlayBody>
        <Row>
          <GridFieldView n={6} s={6} label={t('Item')} value={baseitem?.name} />
          {baseitem?.is_consumable && (
            <GridFieldView
              n={6}
              s={6}
              label={t('Quantity unit')}
              value={baseitem?.quantity_unit}
            />
          )}
          {!baseitem?.is_consumable ? (
            <GridFieldView
              n={6}
              s={6}
              label={t('Booking period')}
              value={formatDateRange(
                orderedItem?.booking_start,
                orderedItem?.booking_end
              )}
            />
          ) : (
            <GridFieldView
              n={6}
              s={6}
              label={t('Packaging unit')}
              value={baseitem?.packaging_unit}
            />
          )}
          <GridFieldView
            n={6}
            s={6}
            label={t('Ordered amount')}
            value={orderedItem?.amount}
          />
          <GridFieldView
            n={6}
            s={6}
            label={t('Commissioned amount')}
            value={deliveryItem?.amount ?? '0'}
          />
        </Row>
      </OverlayBody>
      <OverlayFooter repel gap="10px">
        <YesButton onClick={handleItemCancel} />
        <NoButton onClick={() => onClose()} />
      </OverlayFooter>
    </OverlayForm>
  )
}

/**
 * Renders the remove picked up item button.
 *
 * @param {any} deliveryItem delivery item object
 * @param {any} reload reload call back
 * @param {any} close state of closing the dialog
 *
 * @returns ReactElement
 */
export const RemovePickedItemAmountOverlay = ({
  open,
  onClose,
  orderedItem,
  deliveryItem,
  reload,
}) => (
  <ResetPickedUpItemOverlay
    open={open}
    orderedItem={orderedItem}
    deliveryItem={deliveryItem}
    reload={reload}
    onClose={onClose}
  />
)
