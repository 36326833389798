import React, { useEffect } from 'react'
import { I18nextProvider } from 'react-i18next'
import { Toaster } from 'react-hot-toast'
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom'

import i18n from 'common/i18n/core'
import { useSession, SessionProvider } from 'common/session/context'
import { ServiceProvider } from 'common/service/context'
import { NotificationProvider } from 'system/toolbar/notification/context'
import { SettingsProvider } from 'system/settings/context'
import { useToast, ToastProvider } from 'system/toast/context'
//import { SystemToast } from 'system/toast/toast'
import { PageProvider } from 'system/page/context'
import { UserRoutes } from 'modules/security/routes'

const ProtectedRoute = ({ page }) => {
  const session = useSession()
  const location = useLocation()

  if (!session.isLoggedIn()) {
    return <Navigate to="/users/sign_in" state={{ from: location }} replace />
  }

  return (
    <PageProvider data={page} title={page.title}>
      {page.render()}
    </PageProvider>
  )
}

const HomePage = () => {
  const session = useSession()
  const location = useLocation()

  if (!session.isLoggedIn()) {
    return <Navigate to="/users/sign_in" state={{ from: location }} replace />
  }

  //return <Navigate to="/home" />
  return <ProtectedRoute page={session.getPage('/home')} />
}

const NoMatch = () => {
  const { toasts } = useToast()
  toasts.error('Page not found.')
  return <Navigate to="/" replace />
}

/**
 * All routes of the application.
 */
const AppRoutes = () => {
  const session = useSession()
  // Load one Route per registered paged
  let protectedRoutes = session.pages.map((page, idx) => {
    return (
      <Route
        key={idx}
        path={page.path}
        element={<ProtectedRoute page={page} />}
      />
    )
  })

  return (
    <Routes>
      {protectedRoutes}
      <Route index element={<HomePage />} />
      <Route path="/users/*" element={<UserRoutes />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  )
}

export const App = () => {
  useEffect(() => {
    // Trigger the animation on the root element
    document.getElementById('root').style.opacity = 1
  }, [])

  return (
    <>
      <Toaster />
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <ToastProvider>
            <ServiceProvider>
              <SettingsProvider>
                <SessionProvider>
                  <NotificationProvider>
                    <AppRoutes />
                    {/*<SystemToast />*/}
                  </NotificationProvider>
                </SessionProvider>
              </SettingsProvider>
            </ServiceProvider>
          </ToastProvider>
        </BrowserRouter>
      </I18nextProvider>
    </>
  )
}
