/* eslint-disable no-template-curly-in-string */

import { addTranslation } from 'common/i18n/core'

const resources = {
  'Item [{{name}}] is already ordered [{{count}}] times with amount [{{amount}}].':
    'Artikel [{{name}}] wurde bereits [{{count}}] Mal mit Betrag [{{amount}}] bestellt.',
  'For delivery either fixed date or date range should be provided.':
    'Bei der Lieferoption "Lieferung" ist entweder Fixtermin oder Datumbereich erforderlich.',
  'For self-pickup fixed date is mandatory.':
    'Bei der Lieferoption "Selbstabholung" ist Fixtermin erforderlich.',
  'Order item [{{id}}] cancelled.': 'Artikel Nr. [{{id}}] wurde storniert.',
  'Order is not editable [{{state}}].':
    'Die Bestellung kann nicht geändert werden [{{state}}].',
}

addTranslation('de', 'orders', resources)
