import { useEffect, useState } from 'react'
import { AlertCircle, X } from 'react-feather'

import { CommentView, GridFieldView, SectionView } from 'common/widgets/view'
import { Column, Row } from 'common/widgets/grid'
import { Container } from 'common/widgets/container'
import { useService } from 'common/service/context'
import { AddIconButton } from 'common/widgets/button'
import { UserSelectOverlay } from 'modules/security/users/widgets'
import { Card } from 'common/widgets/card'

/**
 * Renders project status widget
 *
 * @returns ReactElement
 */
export const ProjectStatusView = ({ project, update, reload }) => {
  return (
    <SectionView title="Status">
      <Container flex vertical shrink>
        <Row>
          <GridFieldView
            n={6}
            s={6}
            label="Project template"
            value=<a href={`/master-data/projects/types/${project.type?.id}`}>
              {project.type?.name}
            </a>
          />
          <Column n={6} s={6} title="Current phase">
            <p>{project?.phase?.name}</p>
          </Column>
          {project.phase && (
            <Column n={12} title="Assignee">
              {project.phase.roles.map((role) => (
                <RoleAssignees
                  key={role.id}
                  project={project}
                  role={role}
                  reload={reload}
                />
              ))}
            </Column>
          )}
        </Row>
      </Container>
      <Container grow />
    </SectionView>
  )
}

const RoleAssignees = ({ project, role, reload }) => {
  // State for showing or hiding user select overlay
  const [userSelectVisible, setUserSelectVisible] = useState(false)
  // Extracts assignees from project according to the given role
  const assignees = project.assignees.filter((e) => e.role.id === role.id)
  const makeParams = () => {
    const params = assignees.map((e) => ({ 'id[neq]': e.assignee.id }))
    return [...params, { role: role.id }]
  }
  const [selectParams, setSelectParams] = useState(makeParams())
  const service = useService()

  /**
   * Adds the selected user to the project
   * @param {any} user user data
   */
  const assignUser = async (user) => {
    await service.post('projects/assignees', {
      project_id: project.id,
      project_phase_id: project.phase.id,
      role_id: role.id,
      assignee_id: user.id,
    })
    await reload()
  }

  /**
   * Removes assignee from project
   * @param {int} id assignee record id
   */
  const removeAssignee = async (id) => {
    await service.delete(`/projects/assignees/${id}`)
    await reload()
  }

  useEffect(() => {
    setSelectParams(makeParams())
  }, [project.assignees])

  return (
    <>
      <Container flex repel align="center">
        <Container flex vertical gap="5px">
          <h4>{role.name}</h4>
          {assignees.length === 0 && (
            <CommentView
              icon={<AlertCircle />}
              comment="Has no assignee."
              warning
            />
          )}
          {assignees.map((e) => (
            <Card key={e.assignee.id} border>
              <Container flex repel gap="10px">
                <p>{e.assignee.fullname}</p>
                <X onClick={async () => await removeAssignee(e.id)} />
              </Container>
            </Card>
          ))}
        </Container>
        <AddIconButton onClick={() => setUserSelectVisible(true)} />
      </Container>
      {userSelectVisible && (
        <UserSelectOverlay
          open={userSelectVisible}
          onClose={() => setUserSelectVisible(false)}
          onSelect={async (user) => await assignUser(user)}
          params={selectParams}
        />
      )}
    </>
  )
}
