import { useSettings } from 'system/settings/context'
import { BuildInfo } from 'system/info'
import Splash from 'assets/splash.svg'

import styles from './login.module.css'

export const NoSessionTemplate = ({ children }) => {
  const ctx = useSettings()
  const splashInfo = ctx.get('company.splash')
  const splash = ctx.get('company.splash', true)

  const SplashScreen = () => (splashInfo ? <img src={splash} /> : <Splash />)

  return (
    <div className={styles.noSessionTemplate}>
      <div className={styles.splashArea}>
        <SplashScreen className={styles.splash} />
      </div>
      <div className={styles.right}>{children}</div>
      <BuildInfo />
    </div>
  )
}
