import React from 'react'
import { useTranslation } from 'react-i18next'
import { ResponsivePie } from '@nivo/pie'

import { Container } from 'common/widgets/container'

import styles from './absence.module.css'

export const getStateTextColor = (state) => {
  if (state === 'PENDING') {
    return '#F4AC65'
  } else if (state === 'APPROVED') {
    return '#36CC35'
  } else if (state === 'REJECTED') {
    return '#F94F4A'
  }

  return
}

export const getStateBackgroundColor = (state) => {
  if (state === 'PENDING') {
    return '#FCEBD9'
  } else if (state === 'APPROVED') {
    return '#D6F5D2'
  } else if (state === 'REJECTED') {
    return '#FEDADA'
  }

  return
}

export const StateField = ({ state }) => {
  const { t } = useTranslation()

  const stateMap = {
    PENDING: t('Pending'),
    APPROVED: t('Approved'),
    REJECTED: t('Rejected'),
  }

  return (
    <Container flex style={{ minWidth: '100px' }}>
      <div
        className={styles.statusContainer}
        style={{
          width: '100%',
          height: '100%',
          background: getStateBackgroundColor(state),
        }}
      >
        <h4 style={{ color: getStateTextColor(state) }}>{stateMap[state]}</h4>
      </div>
    </Container>
  )
}

export const getTypeBackgroundColor = (type) => {
  if (type === 'VACATION') {
    return '#D6F5D2'
  } else if (type === 'ILLNESS') {
    return '#FEDADA'
  } else if (type === 'SCHOOL') {
    return '#D6B4EE'
  } else if (type === 'SPECIAL_LEAVE') {
    return '#D7E0FA'
  }

  return
}

export const getTypeTextColor = (type) => {
  if (type === 'VACATION') {
    return '#36CC35'
  } else if (type === 'ILLNESS') {
    return '#F94F4A'
  } else if (type === 'SCHOOL') {
    return '#723398'
  } else if (type === 'SPECIAL_LEAVE') {
    return '#4974F0'
  }

  return
}

export const TypeField = ({ type }) => {
  const { t } = useTranslation()

  const typeMap = {
    VACATION: t('Vacation'),
    ILLNESS: t('Sick leave'),
    SCHOOL: t('School'),
    SPECIAL_LEAVE: t('Special'),
  }

  return (
    <Container flex style={{ minWidth: '100px' }}>
      <Container
        className={styles.statusContainer}
        style={{
          width: '100%',
          height: '100%',
          background: getTypeBackgroundColor(type),
        }}
      >
        <h4 style={{ color: getTypeTextColor(type) }}>{typeMap[type]}</h4>
      </Container>
    </Container>
  )
}

export const StatsPie = ({ data, metricText, metricValue }) => {
  const { t } = useTranslation()

  const chartData = data.map((e) => ({
    id: t(e.label),
    label: t(e.label),
    value: e.value,
    color: e.color,
  }))

  const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    if (!metricText && !metricValue) {
      return
    }

    let diff = 0
    if (metricText && metricValue) {
      diff = 20
    }

    return (
      <>
        {metricValue && (
          <text
            x={centerX}
            y={centerY - diff}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
              fontSize: '30px',
              fontWeight: '900',
            }}
          >
            {metricValue}
          </text>
        )}
        {metricValue && metricText && <br />}
        {metricText && (
          <text
            x={centerX}
            y={centerY + diff}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
              fontSize: '17px',
              color: '#939393',
            }}
          >
            {t(metricText)}
          </text>
        )}
      </>
    )
  }

  return (
    <div className={styles.chartContainer}>
      <div className={styles.chartContent}>
        <ResponsivePie
          data={chartData}
          colors={{ datum: 'data.color' }}
          sortByValue={true}
          margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
          innerRadius={0.8}
          padAngle={0.7}
          cornerRadius={2}
          isInteractive={false}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          enableArcLinkLabels={false}
          enableArcLabels={false}
          layers={['arcs', CenteredMetric]}
        />
      </div>
    </div>
  )
}

export const VerticalDivider = ({ color, children, ...rest }) => {
  return (
    <div
      className={styles.verticalDivider}
      style={{ background: color }}
      {...rest}
    >
      {children}
    </div>
  )
}
