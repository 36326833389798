import { Form } from 'common/widgets/form/context'
import { SystemPage } from 'system/page/core'
import { useService } from 'common/service/context'

import { RoleFormContent } from './form'

export const RoleAddPage = () => {
  const service = useService()

  /**
   * Calls a post call and creates a new role.
   *
   * @param {any} values user data
   * @returns
   */
  const handleAdd = async (values) => {
    return await service.post('security/roles', values)
  }

  return (
    <SystemPage>
      <Form>
        <RoleFormContent onAdd={handleAdd} />
      </Form>
    </SystemPage>
  )
}
