import React, { useState } from 'react'
import { Calendar } from 'react-feather'
import { useNavigate } from 'react-router-dom'

import { useToast } from 'system/toast/context'
import { SimpleDataTable, SimpleColumn } from 'common/data-table/simple'
import { useService } from 'common/service/context'
import { formatDate, formatDateRange } from 'common/utils/format'
import { SectionView, CommentView } from 'common/widgets/view'
import { EquipmentLink } from 'modules/master-data/equipments/widgets/link'

import { OrederedItemsCRUDOverlay } from './overlays/add'

export const ProjectOrderedItems = ({ project, reload, update }) => {
  const service = useService()
  const { toasts } = useToast()
  const navigate = useNavigate()
  const [shouldOrderItems, setShouldOrderItems] = useState(false)
  const handleOrderItem = async (values) => {
    const [result, error] = await service.post(
      `/projects/${project.id}/ordered-items`,
      values
    )
    if (!error) {
      setShouldOrderItems(false)
      toasts.success('Item was ordered successfully')
      // We refresh the page using the navigation because so far there is no
      // other option to reload a datatable.
      navigate(0)
    }

    return [result, error]
  }

  return (
    <SectionView>
      <SimpleDataTable
        name="projectOrderedItems"
        nopadding
        nomargin
        white
        border
        url={`/projects/${project.id}/ordered-items`}
        onAddClick={() => {
          setShouldOrderItems(true)
        }}
      >
        <SimpleColumn n={12} s={12} field="name">
          {(r) => <EquipmentLink equipment={r.item} />}
        </SimpleColumn>
        <SimpleColumn
          n={12}
          s={6}
          field="booking"
          label="Booking"
          value={(r) => (
            <>
              {!r.item.is_consumable && r.booking_start && r.booking_end && (
                <CommentView
                  icon={<Calendar />}
                  comment={formatDateRange(r?.booking_start, r?.booking_end)}
                />
              )}
              {r.item.is_consumable && r.fixed_date && (
                <CommentView
                  icon={<Calendar />}
                  comment={formatDate(r.fixed_date)}
                />
              )}
            </>
          )}
        />
        <SimpleColumn
          n={12}
          s={6}
          label="Comment"
          field="comment"
          value={(r) => <CommentView comment={r.comment} />}
        />
        <SimpleColumn
          n={2}
          s={4}
          sortable
          field="original_amount"
          label="Ordered amount"
          value={(r) => r.original_amount}
        />
        <SimpleColumn
          n={2}
          s={4}
          sortable
          field="open_amount"
          label="Open amount"
          value={(r) => r.open_amount}
        />
        <SimpleColumn
          n={2}
          s={4}
          sortable
          field="commission_amount"
          label="Commissioned amount"
          value={(r) => r.commission_amount}
        />
        <SimpleColumn
          n={2}
          s={4}
          sortable
          label="Delivered amount"
          field="delivered_amount"
          value={(r) => r.delivered_amount}
        />
        <SimpleColumn
          n={2}
          s={4}
          label="Canceled amount"
          field="canceled_amount"
          value={(r) => r.canceled_amount}
        />
        <SimpleColumn
          n={2}
          s={4}
          label="Disposition amount"
          field="disposition_amount"
          value={(r) => r.disposition_amount}
        />
      </SimpleDataTable>
      <OrederedItemsCRUDOverlay
        open={shouldOrderItems}
        onClose={() => setShouldOrderItems(false)}
        onSelect={(item) => handleOrderItem(item)}
      />
    </SectionView>
  )
}
