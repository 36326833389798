import { useTranslation } from 'react-i18next'

import { FieldWrapper } from 'common/widgets/form/field'
import { Select } from 'common/widgets/select'

import { ResourceAccountingTypeEnum } from '../utils'

export const AccountingTypeField = ({ typeEnum, ...rest }) => {
  const { t } = useTranslation()

  const items = Object.values(
    typeEnum ? typeEnum : ResourceAccountingTypeEnum
  ).map((key) => ({ key, title: t(key) }))

  return (
    <FieldWrapper name="accounting_type" {...rest}>
      {(getValue, setValue) => (
        <Select
          selected={getValue()}
          items={items}
          onSelectChange={(item) => setValue(item.key)}
        />
      )}
    </FieldWrapper>
  )
}
