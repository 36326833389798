.phaseActions {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 4;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.phaseDesignWrapper {
  width: 100%;
  height: 100%;
  z-index: 0;
  background: #ffffff;
  background-size: 20px 20px;
  background-image: linear-gradient(to right, #eeeeee 0.5px, transparent 0.5px),
    linear-gradient(to bottom, #eeeeee 0.5px, transparent 0.5px);
}
