import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Calendar } from 'react-feather'

import { Container } from 'common/widgets/container'
import { PagedDataSource } from 'common/widgets/data-source'
import { CardView, GridFieldView } from 'common/widgets/view'
import { SearchInput } from 'common/widgets/search'
import { DropDown } from 'common/widgets/dropdown'
import { Column, Row } from 'common/widgets/grid'
import { AddButton } from 'common/widgets/button'
import {
  dateToISOString,
  formatDateRange,
  formatDate,
  formatDateTime,
} from 'common/utils/format'
import { addDays, startOfDay } from 'common/utils/date'
import { Tab } from 'common/widgets/tab'
import { UserView } from 'modules/security/users/widgets'

import { StateField, TypeField, VerticalDivider } from './widgets'
import { AbsenceStats } from './stats'
import { AbsenceCalendar } from './calendar/calendar'

export const AbsenceListPage = () => {
  const navigate = useNavigate()

  return (
    <>
      <Container
        title="Absences"
        align="center"
        flex
        repel
        style={{ paddingBottom: '10px' }}
      >
        <AddButton
          text="Add absence"
          default
          onClick={() => navigate('/master-data/contacts/absences/add')}
          style={{ maxWidth: '150px' }}
        />
      </Container>
      <Tab
        items={[
          {
            key: 'Absences',
            title: 'Absences',
            render: () => <AbsenceList />,
          },
          {
            key: 'Statistics',
            title: 'Statistics',
            render: () => <AbsenceStats />,
          },
          {
            key: 'Calendar',
            title: 'Calendar',
            render: () => <AbsenceCalendar />,
          },
        ]}
      />
    </>
  )
}

/**
 * Renders two lists of absences including today and upcomming absences.
 * The design does not include past absences, so I don't know how a user
 * should be able to see those past records.
 *
 * @returns ReactElement
 */
export const AbsenceList = () => {
  const [selectedState, setSelectedState] = useState(null)
  const [params, setParams] = useState([])

  const states = [
    {
      key: 'PENDING',
      title: 'Pending',
    },
    {
      key: 'APPROVED',
      title: 'Approved',
    },
    {
      key: 'REJECTED',
      title: 'Rejected',
    },
  ]

  return (
    <CardView>
      <Row>
        <GridFieldView n={6} m={6} s={12}>
          <Container title="Absences" />
        </GridFieldView>
        <GridFieldView n={6} m={6} s={12} style={{ alignItems: 'end' }}>
          <Container flex gap="10px">
            <DropDown
              style={{ minWidth: '180px' }}
              title="State"
              name="state"
              items={states}
              onSelectChange={(value) => {
                setSelectedState(value?.key)
                setParams([{ state: value?.key }])
              }}
              selectedIndex={states.findIndex((e) => e.key === selectedState)}
              removable
            />
            <SearchInput
              onChange={(value) => {
                setParams([{ search: value }])
              }}
            />
          </Container>
        </GridFieldView>
      </Row>
      <Absences
        params={[
          ...params,
          {
            'start[lt]': dateToISOString(
              startOfDay(addDays(new Date(), 1)),
              true
            ),
            'end[gt]': dateToISOString(startOfDay(new Date()), true),
            'order[start]': 'desc',
          },
        ]}
        today
      />
      <Absences
        params={[
          ...params,
          {
            'start[gte]': dateToISOString(
              startOfDay(addDays(new Date(), 1)),
              true
            ),
            'order[start]': 'asc',
          },
        ]}
      />
    </CardView>
  )
}

/**
 * Renders the absences list
 * @param {bool} params service call params
 * @param {bool} today should only render today's absences
 *
 * @returns ReactElement
 */
const Absences = ({ params, today }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  /**
   * Gets absence type background color
   *
   * @param {any} type absence type
   * @returns string
   */
  const getTypeColor = (type) => {
    if (type === 'VACATION') {
      return '#36CC35'
    } else if (type === 'ILLNESS') {
      return '#F94F4A'
    } else if (type === 'SCHOOL') {
      return '#723398'
    } else if (type === 'SPECIAL_LEAVE') {
      return '#4974F0'
    }

    return
  }

  return (
    <PagedDataSource url="/absences" params={params} limit={today ? 1000 : 10}>
      {({ data, totalCount }) => {
        return (
          <>
            <Container flex repel grow style={{ padding: '5px' }}>
              <Column>
                <h1
                  style={{
                    color: '#376DF4',
                    fontWeight: 'bold',
                    fontSize: '25px',
                  }}
                >
                  {totalCount}
                </h1>
                <h5 style={{ marginBottom: '10px' }}>
                  {t(today ? 'Today absences' : 'Upcoming absences')}
                </h5>
              </Column>
            </Container>
            {data &&
              data.map((e, i) => {
                return (
                  <Container
                    key={i}
                    onClick={() =>
                      navigate(`/master-data/contacts/absences/${e.id}`)
                    }
                    hover="#fafafa"
                    divider
                    style={{ borderTop: '1px solid #e0e0e0' }}
                  >
                    <Container
                      flex
                      style={{
                        borderLeft: `3px solid ${getTypeColor(e.type)}`,
                      }}
                    >
                      <Row style={{ paddingBottom: '10px' }}>
                        <h4>{'   '}</h4>
                        <Column n={3}>
                          <Container flex>
                            <VerticalDivider
                              color="#E9E9E9"
                              style={{ marginRight: '10px', width: '3px' }}
                            />
                            <GridFieldView n={3} m={3} s={3} label="Absence">
                              <UserView user={e.contact.system_user} />
                            </GridFieldView>
                          </Container>
                        </Column>
                        <Column n={3}>
                          <Container flex>
                            <VerticalDivider
                              color="#E9E9E9"
                              style={{ marginRight: '10px', width: '3px' }}
                            />
                            <GridFieldView
                              n={3}
                              m={3}
                              s={3}
                              label="Start date"
                              value={
                                e.daily
                                  ? formatDate(e.start)
                                  : formatDateTime(e.start)
                              }
                              style={{ minWidth: '150px' }}
                            />
                            <GridFieldView
                              n={3}
                              m={3}
                              s={3}
                              label="End date"
                              value={
                                e.daily
                                  ? formatDate(e.end, { friendly: true })
                                  : formatDateTime(e.end)
                              }
                              style={{ marginLeft: '50px', minWidth: '150px' }}
                            />
                          </Container>
                        </Column>
                        <Column n={3}>
                          <Container flex>
                            <VerticalDivider
                              color="#E9E9E9"
                              style={{ marginRight: '10px', width: '3px' }}
                            />
                            <GridFieldView n={3} m={3} s={3} label="Substitute">
                              {e.substitute_id ? (
                                <UserView user={e.substitute?.system_user} />
                              ) : (
                                '-'
                              )}
                            </GridFieldView>
                          </Container>
                        </Column>
                        <Column n={3}>
                          <Container flex>
                            <VerticalDivider
                              color="#E9E9E9"
                              style={{ marginRight: '10px', width: '3px' }}
                            />
                            <GridFieldView n={3} m={3} s={3}>
                              <TypeField type={e.type} />
                            </GridFieldView>
                            <GridFieldView n={3} m={3} s={3}>
                              <StateField state={e.state} />
                            </GridFieldView>
                          </Container>
                        </Column>
                        <GridFieldView n={4} m={4} s={12}>
                          <Container flex gap="5px">
                            <Calendar color="grey" />
                            <h5>
                              {formatDateRange(e.start, e.end, {
                                friendly: e.daily,
                              })}
                            </h5>
                          </Container>
                        </GridFieldView>
                      </Row>
                    </Container>
                  </Container>
                )
              })}
          </>
        )
      }}
    </PagedDataSource>
  )
}
