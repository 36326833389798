import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'

import { Container } from 'common/widgets/container'

/**
 * Renders a wrapper for printing content
 * @param {any} handler handler object
 * @returns ReactElement
 */
export const PrintContainer = ({ handler, children, ...rest }) => {
  const ref = useRef()

  // Creates a print function and puts it inside the given handler
  handler.print = useReactToPrint({
    content: () => ref.current,
  })

  return (
    <Container ref={ref} {...rest}>
      {children}
    </Container>
  )
}
