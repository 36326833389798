import { QRCodeSVG } from 'qrcode.react'

import { useSettings } from 'system/settings/context'

/**
 * Renders a barcode
 *
 * @returns ReactElement
 */
export const Barcode = ({ value, ...rest }) => {
  const settings = useSettings()
  const logo = settings.get('company.logo', true)
  return (
    <QRCodeSVG
      // Without printColorAdjust preview in Firefox would be totally black
      style={{
        printColorAdjust: 'exact',
        width: '100%',
        height: '100%',
        maxWidth: '150px',
      }}
      value={value}
      level={'H'}
      includeMargin={false}
      imageSettings={
        logo && {
          src: logo,
          height: 24,
          width: 24,
          excavate: true,
        }
      }
      {...rest}
    />
  )
}
