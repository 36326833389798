import { useTranslation } from 'react-i18next'

import { Container } from 'common/widgets/container'
import {
  TextAreaField,
  NumericField,
  DateRangeField,
  DateField,
} from 'common/widgets/form/field'
import { forceIntRange } from 'common/widgets/form/validate'
import { SimpleFormAction } from 'common/widgets/form/utils'
import { Row, Column } from 'common/widgets/grid'
import { CommentView, SectionView } from 'common/widgets/view'
import { EquipmentSimpleView } from 'modules/master-data/equipments/widgets/simple-view'
import {
  getQuantityUnit,
  hasQuantityUnit,
  formatItemName,
} from 'modules/master-data/equipments/utils'
import { sum } from 'common/utils/collection'
import { useMediaQuery } from 'common/utils/hooks'

import styles from '../../orders.module.css'

import { RelatedItemsView } from './related'

/**
 * Renders ordered item entry form.
 *
 * @param {Function} onClose closing hook
 * @returns
 */
export const OrderedItemFormContent = ({ data, onAdd, onUpdate, onRemove }) => {
  const isRowBased = useMediaQuery('(max-width: 768px)')
  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: isRowBased ? '1fr' : '1fr 1fr',
          gap: 10,
        }}
      >
        <Container flex vertical gap="10px" title="Item">
          <EquipmentSimpleView equipment={data.baseitem} />
          <SectionView grow="0">
            <OrderItemFields
              order={data.order}
              baseitem={data.baseitem}
              orderedItem={data?.orderedItem}
            />
          </SectionView>
        </Container>
        <div className={styles.orderRelatedItems}>
          {data.relateds?.length > 0 && (
            <RelatedItemsView relateds={data.relateds} order={data.order} />
          )}
          {data.frequents?.length > 0 && (
            <RelatedItemsView
              relateds={data.frequents}
              order={data.order}
              frequent
            />
          )}
        </div>
      </div>
      <SimpleFormAction
        onAdd={onAdd}
        onUpdate={onUpdate}
        onRemove={data?.orderedItem?.booked_amount === 0 ? onRemove : undefined}
      />
    </>
  )
}

export const OrderItemFields = ({ order, orderedItem, baseitem }) => {
  const { t } = useTranslation(['orders'])
  // Finds out minimum amount
  const minAmount = Math.max(1, orderedItem?.booked_amount)
  // Finds out max amount
  const maxAmount = baseitem?.is_resource ? 100 : 100000
  // Finds the same item in the order
  const found = order?.ordered_items.filter((e) => e.item_id === baseitem.id)
  // Calculates ordere amount
  const orderedAmount = sum(found, 'amount')

  return (
    <Row>
      <Column n={6} s={6}>
        <h5>
          {t('Amount')}
          {hasQuantityUnit(baseitem) ? `(${getQuantityUnit(baseitem)})` : ''}*
        </h5>
        <NumericField
          name="amount"
          validate={(value) => forceIntRange(value, minAmount, maxAmount)}
          mandatory
        />
      </Column>
      {baseitem && baseitem?.is_consumable && (
        <Column n={6} s={6}>
          <h5>{t('Fixed date')}*</h5>
          <DateField name="fixed_date" mandatory clearable excludeTime />
        </Column>
      )}
      {baseitem && !baseitem?.is_consumable && (
        <Column n={6} s={6}>
          <h5>{t('Booking period')}*</h5>
          <DateRangeField mandatory clearable />
        </Column>
      )}
      <Column n={12} s={12}>
        <h5>{t('Comment')}</h5>
        <TextAreaField name="comment" rows={4} />
      </Column>
      {orderedAmount > 0 && (
        <CommentView
          comment={t(
            'Item [{{name}}] is already ordered [{{count}}] times with amount [{{amount}}].',
            {
              name: formatItemName(baseitem),
              count: found.length,
              amount: orderedAmount,
            }
          )}
        />
      )}
    </Row>
  )
}
