import React from 'react'
import { useTranslation } from 'react-i18next'

import { Container } from 'common/widgets/container'
import { formatDate } from 'common/utils/format'
import { useService } from 'common/service/context'
import { SimpleColumn, SimpleDataTable } from 'common/data-table/simple'
import { ProjectLink } from 'modules/projects/widgets/link'
import { formatProjectName, getProjectLink } from 'modules/projects/utils'

/**
 * Renders resource and formworks booking histories
 *
 * @returns ReactElement
 */
export const BookingHistoriesView = ({ data }) => {
  const { t } = useTranslation(['equipments'])
  const service = useService()

  /**
   * Fetch present records using the given query parameters.
   *
   * @param {*} params query params
   * @returns
   */
  const fetch = async (params) =>
    await service.get('dispositions/bookings', [
      ...params,
      { baseitem_id: data.id },
      { real: true },
      { archived: false },
    ])
  return (
    <Container flex grow className="MyBookingHistory">
      <SimpleDataTable
        name="bookinghistory"
        title={t('Bookings history')}
        divide
        white
        nomargin
        nopadding
        fetch={fetch}
      >
        <SimpleColumn s={12} n={12} sortable>
          {(r) => <ProjectLink project={r.project} />}
        </SimpleColumn>
        <SimpleColumn
          n={4}
          s={6}
          sortable
          label={t('Booking start')}
          value={(r) => formatDate(r.start)}
        />
        <SimpleColumn
          n={4}
          s={6}
          sortable
          label={t('Booking end')}
          value={(r) => formatDate(r.end, { friendly: true })}
        />
        <SimpleColumn
          n={4}
          s={6}
          sortable
          label={t('Amount')}
          value={(r) => r.amount}
        />
        <SimpleColumn
          n={4}
          s={6}
          sortable
          label={t('Source project')}
          link={(r) => getProjectLink(r.source_project)}
          value={(r) => formatProjectName(r.source_project)}
        />
      </SimpleDataTable>
    </Container>
  )
}
