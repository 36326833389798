import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import fileDownload from 'js-file-download'

import { useService } from 'common/service/context'
import { DataSource } from 'common/widgets/data-source'
import { FileUploadOverlay } from 'common/widgets/file/upload'
import { Avatar } from 'common/widgets/avatar'
import { Container } from 'common/widgets/container'
import { SectionView } from 'common/widgets/view'
import { DeleteIconButton, DownloadIconButton } from 'common/widgets/button'
import { useSession } from 'common/session/context'

/**
 * Provides Data URL of a remote file from File API
 *
 * @param {Number} id file id. it has precedence over fileRef if both are provided
 * @param {Boolean} thumbnail specifies that it should get remote file thumbnail instead of the original file
 * @param {String} fileRef file reference to use for fetching the file if the file id is not available
 * @returns ReactElement
 */
export const RemoteFileProvider = ({ id, thumbnail, fileRef, children }) => {
  const service = useService()
  const [file, setFile] = useState(null)

  // Load file by its ref
  const loadByRef = async (ref) => {
    const [response, error] = await service.get(`files/by-ref/${ref}/newest`)
    if (!error) {
      setFile(response.data)
    }
  }

  // Runs once to load file if there is no id but there is a fileRef
  useEffect(() => {
    if (!id && fileRef && file === null) {
      loadByRef(fileRef)
    }
  }, [])

  const getID = () => {
    return id ? id : file?.id
  }

  // Don't make service calls if there is no id
  if (!getID()) return children(null, false)

  let url = thumbnail
    ? `/files/${getID()}/thumbnail/dataurl`
    : `/files/${getID()}/dataurl`

  return (
    <DataSource url={url}>
      {({ data, loading }) => children(data, loading)}
    </DataSource>
  )
}

/**
 * Provides a remote image from File API
 *
 * @param {Number} id image id. it has precedence over imageRef if both are provided
 * @param {Boolean} thumbnail specifies that it should get remote image thumbnail instead of the original image
 * @param {String} imageRef image reference to use for fetching the image if the image id is not available
 * @returns ReactElement
 */
export const RemoteImage = ({ id, thumbnail, imageRef, children, ...rest }) => (
  <RemoteFileProvider id={id} fileRef={imageRef} thumbnail={thumbnail}>
    {(data, loading) =>
      children ? children(data, loading) : <Image src={data} {...rest} />
    }
  </RemoteFileProvider>
)

const Image = styled.img`
  flex: 1;
  margin: auto;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`

export const ProfileImageField = ({ userId, onUpload, onRemove, ...rest }) => {
  const ctx = useSession()
  return (
    <SingleImageField
      onUpload={(file) => {
        if (ctx.currentUser.id == userId) {
          ctx.refresh()
        }
        onUpload && onUpload()
      }}
      onRemove={() => {
        if (ctx.currentUser.id == userId) {
          ctx.refresh()
        }
        onRemove && onRemove()
      }}
      {...rest}
    />
  )
}

/**
 * Renders a single image field with image picker.
 *
 * @param {String} imageRef image reference name
 * @param {String} name name to be used to generate abbreviation when empty. if not provided, the `title` will be also used for this
 * @param {String} title title to be used on upload overlay
 * @param {String} size image field size
 * @param {Boolean} readOnly specifies that the image field is readonly and disables the image picker
 * @param {Boolean} allowDelete specifies that a remove button should also be rendered if an image is available
 * @param {Boolean} allowDownload specifies that a download button should also be rendered if an image is available
 * @param {Boolean} thumbnail get the thumbnail of the image from server instead of original file
 * @param {Number} maxSize maximum allowed size of the image to be uploaded in bytes
 * @param {String} borderRadius image border radius
 * @param {Function} onClick a function to be called when image is clicked
 * @param {Function} onUpload a function to be called when an image is uploaded
 * @param {Function} onRemove a function to be called when image is removed
 * @returns JSX.Element
 */
export const SingleImageField = ({
  imageRef,
  name,
  title,
  size = '140px',
  readOnly = false,
  allowDelete = true,
  allowDownload = true,
  thumbnail = true,
  maxSize = 2 * 1024 * 1024,
  borderRadius = '5px',
  onClick = null,
  onUpload = null,
  onRemove = null,
}) => {
  const [overlayVisible, setOverlayVisible] = useState(false)
  const service = useService()

  /**
   * Downloads the current image.
   */
  const handleDownload = async (file) => {
    if (file?.id) {
      const [result, error] = await service.get(`files/${file.id}`, null, {
        responseType: 'blob',
      })
      if (!error) {
        fileDownload(result.data, file.name)
      }
    }
  }

  /**
   * Uploads the given image.
   */
  const handleUpload = async (
    service,
    imageRef,
    image,
    reload,
    onUploadProgress,
    onUpload
  ) => {
    var formData = new FormData()
    formData.append('file', image)
    formData.append('ref', imageRef)
    formData.append('file_type', image.type)
    const [result, error] = await service.post(`files`, formData, {
      'Content-Type': 'multipart/form-data',
      onUploadProgress: onUploadProgress,
    })
    if (!error) {
      await reload()
      onUpload && onUpload(result?.data)
    }
    return [result, error]
  }

  /**
   * Deletes images with given ref
   */
  const handleDelete = async (reload, onRemove) => {
    const [, error] = await service.delete(`files/by-ref/${imageRef}`)
    if (!error) {
      await reload()
      onRemove && onRemove()
    }
  }

  // decides whether actions should be rendered at all.
  const showActions = allowDelete || allowDownload

  if (!imageRef) {
    return null
  }

  return (
    <DataSource url={imageRef ? `files/by-ref/${imageRef}/newest` : null}>
      {({ data, reload }) => (
        <MainContainer flex relative width={size} height={size}>
          {overlayVisible && (
            <FileUploadOverlay
              open={overlayVisible}
              onUpload={async (image, onUploadProgress) =>
                await handleUpload(
                  service,
                  imageRef,
                  image,
                  reload,
                  onUploadProgress,
                  onUpload
                )
              }
              maxSize={maxSize}
              isPhoto
              onClose={() => setOverlayVisible(false)}
              accept="image/png, image/jpeg"
              title={title}
            />
          )}
          <Avatar
            name={name || title}
            size={size}
            id={data?.id}
            onClick={() => {
              setOverlayVisible(!readOnly)
              if (onClick) {
                onClick()
              }
            }}
            borderRadius={borderRadius}
            cursor={'pointer'}
            thumbnail={thumbnail}
          />
          {data?.id && showActions && (
            <ImageActionsContainer
              absolute
              flex
              gap="5px"
              justify="center"
              align="center"
            >
              {!readOnly && allowDelete && (
                <DeleteIconButton
                  onClick={async () => handleDelete(reload, onRemove)}
                />
              )}
              {allowDownload && (
                <DownloadIconButton
                  onClick={async () => handleDownload(data)}
                />
              )}
            </ImageActionsContainer>
          )}
        </MainContainer>
      )}
    </DataSource>
  )
}

/**
 * Renders a single image field with image picker inside a section view.
 *
 * @param {String} imageRef image reference name
 * @param {String} name name to be used to generate abbreviation when empty. if not provided, the `title` will be also used for this
 * @param {String} title title to be used on upload overlay
 * @param {String} size image field size
 * @param {Boolean} readOnly specifies that the image field is readonly and disables the image picker
 * @param {Boolean} allowDelete specifies that a remove button should also be rendered if an image is available
 * @param {Boolean} allowDownload specifies that a download button should also be rendered if an image is available
 * @param {Boolean} thumbnail get the thumbnail of the image from server instead of original file
 * @param {String} section_title section title to be used. if not provided, the name would also be used as section title
 * @param {Number} maxSize maximum allowed size of the image to be uploaded in bytes
 * @param {String} borderRadius image border radius
 * @param {Function} onClick a function to be called when image is clicked
 * @param {Function} onUpload a function to be called when an image is uploaded
 * @param {Function} onRemove a function to be called when image is removed
 * @returns JSX.Element
 */
export const SingleImageFieldSectionView = ({
  imageRef,
  name,
  title,
  size = '140px',
  readOnly = false,
  allowDelete = true,
  allowDownload = true,
  thumbnail = true,
  section_title = null,
  maxSize = 2 * 1024 * 1024,
  borderRadius = '5px',
  onClick = null,
  onUpload = null,
  onRemove = null,
}) => {
  return (
    <SectionView>
      <Container flex repel>
        <h1>{section_title ? section_title : name}</h1>
      </Container>
      <SingleImageField
        imageRef={imageRef}
        name={name}
        title={title}
        size={size}
        readOnly={readOnly}
        allowDelete={allowDelete}
        allowDownload={allowDownload}
        thumbnail={thumbnail}
        maxSize={maxSize}
        borderRadius={borderRadius}
        onClick={onClick}
        onUpload={onUpload}
        onRemove={onRemove}
      />
    </SectionView>
  )
}

/**
 * Renders a single image view which is readonly
 *
 * @param {String} imageRef image reference name
 * @param {String} name name to be used to generate abbreviation when empty.
 * @param {String} size image field size
 * @param {Boolean} allowDownload specifies that a download button should also be rendered if an image is available
 * @param {Boolean} thumbnail get the thumbnail of the image from server instead of original file
 * @param {Boolean} mini render a mini image with 32px size
 * @param {String} borderRadius image border radius
 * @param {Function} onClick a function to be called when image is clicked
 * @returns JSX.Element
 */
export const SingleImageView = ({
  imageRef,
  name,
  size = '140px',
  allowDownload = false,
  thumbnail = true,
  mini = false,
  borderRadius = '5px',
  onClick = null,
}) => {
  if (mini) {
    size = '32px'
  }
  return (
    <SingleImageField
      imageRef={imageRef}
      name={name}
      size={size}
      readOnly={true}
      allowDelete={false}
      allowDownload={allowDownload}
      thumbnail={thumbnail}
      borderRadius={borderRadius}
      onClick={onClick}
    />
  )
}

const ImageActionsContainer = styled(Container)`
  z-index: 1;
  bottom: 0;
  opacity: 1;

  @media (pointer: fine) {
    opacity: 0;
  }

  div {
    width: 32px;
    height: 32px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`

const MainContainer = styled(Container)`
  :hover ${ImageActionsContainer} {
    opacity: 1;
  }
`
