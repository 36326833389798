import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { OverlayBody, OverlayFooter, OverlayForm } from 'common/widgets/overlay'
import { formatItemName } from 'modules/master-data/equipments/utils'
import { CommentView, GridFieldView } from 'common/widgets/view'
import { Container } from 'common/widgets/container'
import { Column, Row } from 'common/widgets/grid'
import { ConfirmButton, BackButton, CancelButton } from 'common/widgets/button'
import { formatProjectName } from 'modules/projects/utils'
import { sum } from 'common/utils/collection'
import { Card } from 'common/widgets/card'
import { dateToISOString, formatDateRange } from 'common/utils/format'
import { useService } from 'common/service/context'
import { useToast } from 'system/toast/context'

export const BookOverlay = ({
  open,
  request,
  selectedSources,
  onClose,
  formworks,
}) => {
  const { t } = useTranslation()
  const service = useService()
  const navigate = useNavigate()
  // Calculates overbooked amount
  const overdraft =
    request.amount - sum(selectedSources.map((e) => parseInt(e.amount)))
  // Calculates remained amount from original amount
  const remained = Math.max(0, overdraft)
  const { toasts } = useToast()

  const handleConfirm = async (forceToFinish) => {
    const bookings = selectedSources.map(
      ({ amount, start, end, source_project, baseitem }) => ({
        amount: parseInt(amount),
        start,
        end,
        baseitem_id: baseitem.id,
        source_project_id: source_project.id,
        cost_rate: formworks.find((e) => e.id === baseitem.id)?.cost_rate,
        accounting_type: formworks.find((e) => e.id === baseitem.id)
          ?.accounting_type,
      })
    )
    const data = {
      force_to_finish: forceToFinish,
      bookings,
    }
    const [result, error] = await service.put(
      `dispositions/requests/${request.id}/formwork/confirm`,
      data
    )
    if (!error) {
      toasts.success('Booking is confirmed!')
      navigate('/disposition/requests/new')
    }
    return [result, error]
  }

  return (
    <OverlayForm open={open} onClose={onClose} title="Booking">
      <OverlayBody>
        <Row>
          <GridFieldView n={6} s={6} label={t('Project')}>
            <h4>{formatProjectName(request.project)}</h4>
          </GridFieldView>
          <GridFieldView n={6} s={6} label={t('Item')}>
            <h4>{formatItemName(request.ordered_item.item)}</h4>
          </GridFieldView>
          <GridFieldView n={6} s={6} label={t('Required booking period')}>
            <h4>{formatDateRange(request.start, request.end)}</h4>
          </GridFieldView>
          <GridFieldView n={6} s={6} label={t('Ordered amount')}>
            <h4>{request.amount}</h4>
          </GridFieldView>
          <GridFieldView n={6} s={6} label={t('Booked')}>
            <h4>{sum(selectedSources.map((e) => parseInt(e.amount)))}</h4>
          </GridFieldView>
          <GridFieldView n={6} s={6} label={t('Remained amount')}>
            <h4>{remained}</h4>
          </GridFieldView>
          <Column n={12}>
            <SourceList sources={selectedSources} request={request} />
          </Column>
          <Column n={12}>
            <OverBook request={request} overdraft={overdraft} />
          </Column>
        </Row>
      </OverlayBody>
      <OverlayFooter repel gap="10px">
        <ConfirmButton
          default
          onClick={async () => await handleConfirm(false)}
        />
        {remained > 0 && (
          <CancelButton
            title={t('Cancel remaining')}
            onClick={async () => await handleConfirm(true)}
          />
        )}
        <BackButton onClick={onClose} style={{ marginLeft: 'auto' }} />
      </OverlayFooter>
    </OverlayForm>
  )
}

const OverBook = ({ request, overdraft }) => {
  const { t } = useTranslation()
  return (
    <Container>
      {overdraft < 0 && (
        <CommentView
          warning
          comment={t('Amount of [{{overdraft}}] is overbooked!', {
            amount: request.amount,
            overdraft: Math.abs(overdraft),
          })}
        />
      )}
    </Container>
  )
}

const SourceList = ({ request, sources }) => {
  const { t } = useTranslation()
  return (
    <Container>
      <Card>
        <Row>
          <Column n={1} s={1}></Column>
          <Column n={5} s={5}>
            <h5>
              {t('Project')} / {t('Item')}
            </h5>
          </Column>
          <Column n={5} s={5}>
            <h5>{t('New booking period')}</h5>
          </Column>
          <Column n={1} s={1}>
            <Container flex reverse>
              <h5>{t('Amount')}</h5>
            </Container>
          </Column>
        </Row>
      </Card>
      {sources.map((e, i) => (
        <Card divide key={i}>
          <Row>
            <Column n={1} s={1}>
              <h4>{i + 1}</h4>
            </Column>
            <Column n={5} s={5}>
              <Container flex vertical>
                <h4>{formatProjectName(e.source_project)}</h4>
                <h5>{formatItemName(e.baseitem)}</h5>
              </Container>
            </Column>
            <Column n={5} s={5}>
              <Container flex vertical>
                <h4
                  style={{
                    color:
                      dateToISOString(e.start) !== request.start ||
                      dateToISOString(e.end) !== request.end
                        ? '#F49537'
                        : '#303030',
                  }}
                >
                  {formatDateRange(e.start, e.end)}
                </h4>
              </Container>
            </Column>
            <Column n={1} s={1}>
              <Container flex reverse>
                <h4>{e.amount}</h4>
              </Container>
            </Column>
          </Row>
        </Card>
      ))}
    </Container>
  )
}
